import styles from './Footer.module.scss'

const config = {
    ENVIRONMENT: process.env.REACT_APP_ENV
};

const Footer = () => {
    return (
        <footer className={`flex-shrink-0 bg-primary text-white text-center mt-2 lh-lg ${styles.footer}`}>&copy; 2023 CMC Exhibit Managers: {config.ENVIRONMENT}</footer>
    )
}

export default Footer