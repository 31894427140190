import styles from "./LabelInfo.module.scss";

const LabelInfo = ({ label, info = '' }) => {
    return (
        <span className={styles.info}>
            { label }: <strong>{info}</strong>
        </span>
    )
}

export default LabelInfo;