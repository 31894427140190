import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useState, useEffect } from "react";
import sxTableStyles from "../../style/sxTableStyles";
import {CustomTableCellOne, CustomTextField} from "../../style/mui/customStyle";
import CustomPaper from "../CustomPaper";
import { useSelector } from "react-redux";
import DeleteShipmentItem from "./DeleteShipmentItem";
import EditShipmentItem from "./EditShipmentItem";
import AddRowButton from "../ui/AddRowButton";
import { numFloat } from "../../utils/common";

function ShipmentItems(props){
    const { openAddShipmentItemHandler, showAddItem, showAction, refetchItemsToShip, setAvailableItems, showManualAdd } = props;
    const shipmentItemMapping = useSelector(state => state.shipmentData?.shipment?.shipmentItemMapping);
    const shipmentItemsTotal = useSelector(state => state.shipmentData?.shipment?.shipmentItemsTotal);
    const shipmentInfo = useSelector(state => state.shipmentData?.shipment?.shipment);
    const [rows, setRows] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);

    const newRow = {
        id: 0,
        itemId: 0,
        shipmentId: shipmentInfo?.id,
        "length": 0,
        "width": 0,
        "height": 0,
        "weight": 0,
        "content": "",
        item: {
            description: '',
            "quantity": 0,
        },
        isUpdated: true,
        isManualAdd: true,
    }

  const [itemsTotal, setItemsTotal] = useState([]);

  function AddShipmentItemClickHandler(){
    openAddShipmentItemHandler();
    }

    useEffect(() => {
        if (shipmentItemsTotal) {
            setItemsTotal(shipmentItemsTotal);
        }
    }, [shipmentItemsTotal]);

  const handleShipmentDescription = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
              ...row,
              item: {
                  ...row.item,
                  description: value,
              },            
            isUpdated: true
        }
        : row))
    });
  }

    const handleShipmentQuantity = (id, event) => {
        const value = event.target.value;
        setRows((rows) => {
            return rows.map(row => ((row.id === id) ?
                {
                    ...row,
                    item: {
                        ...row.item,
                        quantity: value,
                    },
                    isUpdated: true
                }
                : row))
        });
    }

    const handleAddRow = () => {
        setRows((rows) => [...rows, newRow]);
    }

    const handleDeleteRow = () => {
        
        let pendingRows = rows.filter(function (itm) {
            return !itm.isManualAdd;
        });
        setRows(pendingRows);
    }

  const handleShipmentContent = (id, event) => {
    const value = event.target.value;
    
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
            ...row,
              content: value,
            isUpdated: true
        }
        : row))
    });
  }

  const handleShipmentTotalQuantity = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
              ...row,
              item: {
                  ...row.item,
                  quantityForShipmentPrint: value,
              },
            isUpdated: true
        }
        : row))
    });
    }

    const handleBlurShipmentLength = (id, event) => {
        if (event.target.value === "") {
            setRows((rows) => {
                return rows.map(row => ((row.id === id) ?
                    {
                        ...row,
                        length: 0,
                        isUpdated: 0
                    }
                    : row))
            });
        }
    }

    const handleBlurShipmentQty = (id, event) => {
        if (event.target.value === "") {
            setRows((rows) => {
                return rows.map(row => ((row.id === id) ?
                    {
                        ...row,
                        item: {
                            ...row.item,
                            quantityForShipmentPrint: 0,
                        },
                        isUpdated: 0
                    }
                    : row))
            });
        }
    }

   const handleShipmentLength = (id, event) => {
        const value = event.target.value
        setRows((rows) => {
          return rows.map(row => ((row.id === id) ?
            {
                  ...row,
                  length: value,
                isUpdated: true
            }
            : row))
        });
    }

    const handleBlurShipmentWidth = (id, event) => {
        if (event.target.value === "") {
            setRows((rows) => {
                return rows.map(row => ((row.id === id) ?
                    {
                        ...row,
                        width: 0,
                        isUpdated: 0
                    }
                    : row))
            });
        }
    }

  const handleShipmentWidth = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
            ...row,
              width: value,
            isUpdated: true
        }
        : row))
    });
    }

    const handleBlurShipmentHeight = (id, event) => {
        if (event.target.value === "") {
            setRows((rows) => {
                return rows.map(row => ((row.id === id) ?
                    {
                        ...row,
                        height: 0,
                        isUpdated: 0
                    }
                    : row))
            });
        }
    }

    
  const handleShipmentHeight = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
              ...row,
              height: value,
            isUpdated: true
        }
        : row))
    });
  }
  const handleShipmentWeight = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
              ...row,
              weight: value,
            isUpdated: true
        }
        : row))
    });
  }

  useEffect(() => {        
    if(shipmentItemMapping){
        setRows(shipmentItemMapping);
    }
  }, [shipmentItemMapping]);

    useEffect(() => {
        if (rows) {
            window.console.log("===rows==", rows)
            let totalWeight = rows.reduce((n, { weight }) => n + Number(weight), 0);
            
            let totalQty = 0;
           // totalQty = rows.reduce((n, { item }) => n + Number(item.quantityAssignedToExhibit), 0);
            rows.forEach((row) => {
                totalQty = totalQty + Number(row?.item?.quantityForShipmentPrint ?? row?.item?.quantity)
            });
            window.console.log("====totalWeight===", totalWeight)
            setItemsTotal((prevState) => ({
                ...prevState,
                weight: totalWeight,
                quantity: totalQty
            }));
            window.console.log("====itemsTotal===", itemsTotal)
            const disabledFlag = rows?.some((row) => row.isManualAdd)
            setAddDisabled(disabledFlag)
        }
    }, [rows]);
  
    return (
        <>
            <TableContainer component={Paper}
                className="rounded-0 shadow-none mb-2">
                <Table sx={sxTableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: showAction ? '25%' : '30%' }}>Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell style={{ width: showAction ? '25%' : '30%' }}>Contents</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell style={{ width: showAction && '25%' }}>Dimensions</TableCell>
                            {showAction && <TableCell>
                                Actions
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.length > 0 ? rows.map(shipment_item => {
                            return <TableRow key={shipment_item?.id}>
                                <CustomTableCellOne>
                                    {
                                        showAction && (shipment_item?.isManualShipmentItem || shipment_item?.isManualAdd) ?
                                            <div>
                                                <CustomTextField variant="standard" name='description' value={shipment_item?.item?.description} onChange={(newValue) => handleShipmentDescription(shipment_item.id, newValue)} />
                                            </div>
                                        :
                                            <div>{shipment_item?.item?.description}</div>
                                    }
                                    
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-2">
                                    {
                                        showAction && (shipment_item?.isManualShipmentItem || shipment_item?.isManualAdd) ?
                                            <>
                                                <CustomTextField variant="standard" name='quantity' value={shipment_item?.item?.quantityForShipmentPrint} onBlur={(event) => handleBlurShipmentQty(shipment_item.id, event)} onChange={(newValue) => handleShipmentTotalQuantity(shipment_item.id, newValue)} />
                                            </>                                            
                                            :
                                            <div>{shipment_item?.item?.quantityForShipmentPrint}</div>
                                    }
                                    
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-2">
                                    {showAction ? <CustomTextField variant="standard" name='content' value={shipment_item?.content} onChange={(newValue) => handleShipmentContent(shipment_item.id, newValue)} /> :
                                        <div>{shipment_item?.content} </div>}
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-1">
                                    {showAction ? <CustomTextField variant="standard" name='weight' value={shipment_item?.weight ?? 0} onChange={(newValue) => handleShipmentWeight(shipment_item.id, newValue)} /> :
                                        <div>{shipment_item?.weight ?? 0} </div>}
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-1">
                                    {showAction ?
                                        <div className="row">
                                            <CustomTextField className="col-4 px-1 py-1" variant="standard" name='length' value={shipment_item?.length} onBlur={(event) => handleBlurShipmentLength(shipment_item.id, event)} onChange={(newValue) => handleShipmentLength(shipment_item.id, newValue)} />
                                            <CustomTextField className="col-4 px-1 py-1" variant="standard" name='width' value={shipment_item?.width} onBlur={(event) => handleBlurShipmentWidth(shipment_item.id, event)} onChange={(newValue) => handleShipmentWidth(shipment_item.id, newValue)} />
                                            <CustomTextField className="col-4 px-1 py-1" variant="standard" name='height' value={shipment_item?.height} onBlur={(event) => handleBlurShipmentHeight(shipment_item.id, event)} onChange={(newValue) => handleShipmentHeight(shipment_item.id, newValue)} />
                                        </div> :
                                        <div>{shipment_item?.length ?? 0}*{shipment_item?.width ?? 0}*{shipment_item?.height ?? 0}</div>}
                                </CustomTableCellOne>
                                {showAction && <CustomTableCellOne>
                                    <EditShipmentItem item={shipment_item} edit={!shipment_item?.isManualAdd} refetchItemsToShip={refetchItemsToShip} setAvailableItems={setAvailableItems} />
                                    <DeleteShipmentItem item={shipment_item} handleDeleteRow={handleDeleteRow} manualDelete={shipment_item?.isManualAdd} refetchItemsToShip={refetchItemsToShip} setAvailableItems={setAvailableItems} />
                                </CustomTableCellOne>}
                            </TableRow>
                        }) :
                            <TableRow>
                                <TableCell colSpan={showAction ? 6 : 5} style={{ padding: '0px' }}>
                                    No Shipment Items
                                </TableCell>
                            </TableRow>}
                        {shipmentItemMapping && shipmentItemMapping.length > 0 && itemsTotal &&
                            <TableRow>
                                <CustomTableCellOne style={{ fontWeight: 'bold' }}>
                                    Total
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }}>
                                    {itemsTotal.quantity}
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }}>

                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-1" style={{ fontWeight: 'bold' }}>
                                    {itemsTotal.weight}
                                </CustomTableCellOne>
                                <CustomTableCellOne className="px-1" style={{ fontWeight: 'bold' }}>
                                </CustomTableCellOne>
                                {showAction && <CustomTableCellOne>

                                </CustomTableCellOne>}
                            </TableRow>

                        }

                        {showAddItem && <TableRow style={{ padding: '0px' }}>
                            <TableCell colSpan={showAction ? 6 : 5} style={{ padding: '0px' }}>
                                <CustomPaper {...props} clickHandler={AddShipmentItemClickHandler} displayText={'+ Add Shipment Item'} />
                            </TableCell>
                        </TableRow>}  
                    </TableBody>
                </Table>
                {
                    showManualAdd &&
                    <AddRowButton
                        onAdd={handleAddRow}
                        disabled={addDisabled}
                    >
                        + Add Manual Shipment Items
                    </AddRowButton>
                }
            </TableContainer>
      </>
    
  )
}

export default ShipmentItems;