import { createSlice} from '@reduxjs/toolkit';
import {showInfoSave, showInfoComplete} from '../../actions/showInfo/showInfoActions';

const initialState = {
    showSave: null,
    showComplete : null,
    loading: false,
    error: null,
    showStatus: null,
    showInfo: {
        showId: null,
    },
    showInfoSave: {
        jobId: null,
        showNameId: 0,
        facilityId: 0,
        boothSpace: "",
        boothSize: "",
        opensOn: "",
        closesOn: "",
        generalContractorId: 0,
        generalContractorContactId: 0
    },
};

const showInfoSlice = createSlice({
    name: 'show',
    initialState,
    reducers: {
        setShowInfo: (state, { payload }) => {
            state.showInfo = {
                ...state.showInfo,
                ...payload
            }
        },
        setShowInfoSave: (state, { payload }) => {
            state.showInfoSave = {
                ...state.showInfoSave,
                ...payload
            };
        },
        setShowStatus: (state, { payload }) => {
            state.showStatus = payload;
        },
        resetShowInfo: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(showInfoSave.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(showInfoSave.fulfilled, (state, action) => {
                state.loading = false;
                state.showSave = action.payload;
            })
            .addCase(showInfoSave.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(showInfoComplete.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(showInfoComplete.fulfilled, (state, action) => {
                state.loading = false;
                state.showComplete = action.payload;
            })
            .addCase(showInfoComplete.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const {
    setShowInfo,
    setShowInfoSave,
    setShowStatus,
    resetShowInfo
} = showInfoSlice.actions;

export default showInfoSlice.reducer
