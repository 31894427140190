import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmDialog from "../../ConfirmDialog";
import {useDeleteIDSetupDismantleMutation} from "../../../services/authService";
import {SETUP_DISMANTLE} from "../../../utils/constants";
import messages from "../../../utils/messages";
import {useInstallDismantle} from "../../../context/InstallDismantleContext";
import styles from "../SetupDismantleTable.module.scss";

const DeleteSetupDismantleRow = ({ type, id }) => {
    const [,, refetch] = useInstallDismantle();
    const [open, setOpen] = useState(false);
    const [deleteSetupDismantle, { isSuccess, error, reset }] = useDeleteIDSetupDismantleMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleDelete = () => {
        console.log('id');
        deleteSetupDismantle(id);
    }

    const handleMessages = () => {
        if(isSuccess){
            enqueueSnackbar(
                type === SETUP_DISMANTLE.setup  ? `${messages.success.deleteSetup}` : `${messages.success.deleteDismantle}`,
                { variant: 'success' }
            );
            reset();
            refetch();
        }
        if(error){
            enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isSuccess || error){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isSuccess, error]);

    return (
        <>
            <DeleteOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon}`}
            />
            <ConfirmDialog
                title={`Delete ${type}`}
                open={open}
                setOpen={setOpen}
                onConfirm={handleDelete}
            >
                { type === SETUP_DISMANTLE.setup ? messages.confirm.deleteSetup : messages.confirm.deleteDismantle}
            </ConfirmDialog>
        </>
    )
}

export default DeleteSetupDismantleRow;