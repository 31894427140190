import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from "../ConfirmDialog";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetItemsInShipmentQuery, useRemoveShipmentItemMutation } from '../../services/authService';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import { setShipmentItems, setShipmentItemsTotal } from '../../redux/reducers/shipmentSlice';

function DeleteShipmentItem(props) {
    const { item, refetchItemsToShip, setAvailableItems, manualDelete, handleDeleteRow } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  const shipment = useSelector((state) => state.shipmentData.shipment);
  const [removeShipmentItem, { isLoading, isError }] = useRemoveShipmentItemMutation();

  const { data: itemsInShipment, isFetching: isFetchingItemsInShipment, refetch: refetchItemsInShipment } = useGetItemsInShipmentQuery(shipment && shipment?.shipment?.id);

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

    const handleDelete = async () => {
        if (manualDelete) {
            props.handleDeleteRow();
            return true;
        }
        const itemId = item.itemId || item.id;
        const response = await removeShipmentItem({ itemId: itemId, shipmentId: shipment?.shipment?.id });
        if (response.data === true) {
            if (!isFetchingItemsInShipment) {
                const refetchResult = await refetchItemsInShipment();
                const refetchedItems = refetchResult.data.shipmentItems;
                dispatch(setShipmentItemsTotal(refetchResult.data.shipmentItemsTotal));
                dispatch(setShipmentItems(refetchedItems));
            }

            const refetchResponse = await refetchItemsToShip();
            setAvailableItems(refetchResponse.data.shipmentItems);
            // setAvailableItems([{id: 3, description: "Dummy"}]);

            enqueueSnackbar(`${messages.success.removeShipmentItem}`, { variant: 'success' });
        } else {
            enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.genericDelete}`, { variant: 'error' });
        }
        
    };

  return (
    <>
      <Tooltip title="Remove Item">
        <RemoveCircleOutlineIcon
          fontSize="small"
          style={{
            color: "grey",
            cursor: "pointer"
          }}
          onClick={openDeleteModalHandler}
        />
      </Tooltip>
      <ConfirmDialog title="Remove Shipment Item"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to remove this item?
      </ConfirmDialog>
    </>
  );
}

export default DeleteShipmentItem;
