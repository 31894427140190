import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetJobChecklistQuery, useUpdateJobChecklistColorMutation } from "../../services/authService";
import { rowColors, textColors } from "./constants";
import {gridStyle} from "./styles/taskListStyle";
import {resetJob, setJobChecklistId} from "../../redux/reducers/jobSlice";
import {formatDateTime, getColorClasses} from "../../utils/common";
import GridSwatchSelector from "../../components/GridSwatchSelector";
import {ReactComponent as TextColor} from "../../assets/icons/textColor.svg";
import {ReactComponent as RowColor} from "../../assets/icons/rowColor.svg";
import useDebounce from "../../hooks/useDebounce";
import StatusToggle from "../../components/dashboard/StatusToggle";
import GridSearch from "../../components/dashboard/GridSearch";
import {useSnackbar} from "notistack";
import messages from "../../utils/messages";
import useAuthentication from "../../hooks/useAuthentication";
import { resetShipment } from "../../redux/reducers/shipmentSlice";
import { resetCarriers } from "../../redux/reducers/carrier/carrierSlice";
import { resetExhibit } from "../../redux/reducers/exhibit/exhibitSlice";
import { resetInstallDismantle } from "../../redux/reducers/installDismantle/installDismantleSlice";
import { resetShowInfo } from "../../redux/reducers/showInfo/showInfoSlice";

const JobChecklist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState("Open");

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const defaultSort = [{ field: 'showOpensOn', sort: 'asc' }];
  const [sortModel, setSortModel] = useState(defaultSort);

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText);
  const defaultFilter = {
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [],
  };
  const [filterModel, setFilterModel] = useState(defaultFilter);

  const {
    data,
    isFetching,
    error: getJobListError,
  } = useGetJobChecklistQuery(
    {status, paginationModel, sortModel, searchText: debouncedSearchText},
    { refetchOnMountOrArgChange: true }
  );
  
  const [
    updateJobChecklistColor,
    {
      isLoading: isJobChecklistUpdateLoading,
      error: isJobChecklistUpdateError,
      isSuccess: isJobChecklistUpdateSuccess,
      reset: jobChecklistReset,
    },
  ] = useUpdateJobChecklistColorMutation();
  
  useAuthentication([isJobChecklistUpdateError, getJobListError]);

  const jobList = [
    {
      field: 'showOpensOn',
      headerName: 'Show Dates',
      type: 'dateTime',
          renderCell: (params) => { return (<span>{params.row?.showOpensOn ? formatDateTime(params.row.showOpensOn) : 'TBD'} - {params.row?.showClosesOn ? formatDateTime(params.row.showClosesOn) : 'TBD'}</span>) },
      valueGetter: ({ value }) => value && new Date(value),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'showName',
      headerName: 'Show Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'facilityCity',
      headerName: 'City',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'generalContractorName',
      headerName: 'Contractor',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'client',
      headerName: 'Client',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'exhibitName',
      headerName: 'Exhibit',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'graphics',
      headerName: 'Graphics',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'carrierName',
      headerName: 'Carrier',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'shipDate',
      headerName: 'Ship Date',
      type: 'dateTime',
      renderCell: (params) => { return (<span>{params.row.shipDate ? formatDateTime(params.row.shipDate) : 'TBD'}</span>) },
      valueGetter: ({ value }) => value && new Date(value),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'exhibitNotes',
      headerName: 'Notes',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'JobId', // Weird issue: Using 'jobId' is hiding the column entirely
      headerName: 'Job Number',
      flex: 1,
      minWidth: 110,
      renderCell: (row) => row.row.jobId,
    },
    {
      field: 'icon',
      headerName: '',
      flex: 1,
      minWidth: 70,
      filterable: false,
      sortable: false,
      cellClassName: 'justify-content-end',
      renderCell: (row) => (
          <div className="flex-shrink-0">
            <GridSwatchSelector
                colors={textColors}
                taskId={row.row.jobId}
                rowId={row.id}
                handleColor={handleTextColor}
                icon={TextColor}
                title="Select text color"
            />
            <GridSwatchSelector
                colors={rowColors}
                taskId={row.row.jobId}
                rowId={row.id}
                handleColor={handleRowColor}
                icon={RowColor}
                title="Select row color"
            />
          </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setRows(data?.results);
      setRowCount(data?.totalNumberOfRows);
    }
  }, [data]);

  useEffect(() => {
    if(isJobChecklistUpdateError || isJobChecklistUpdateSuccess || getJobListError){
      handleMessages();
    }
    // eslint-disable-next-line
  }, [isJobChecklistUpdateError, isJobChecklistUpdateSuccess, getJobListError]);

  const handleMessages = () => {
    if(isJobChecklistUpdateSuccess){
      jobChecklistReset();
    }
    if(isJobChecklistUpdateError){
      enqueueSnackbar(`${messages.error.prefix} ${messages.error.updateColor}`, { variant: 'error' });
    }
    if(getJobListError){
      enqueueSnackbar(`${messages.error.prefix} ${messages.error.getJobCheckList}`, { variant: 'error' });
    }
  }

  const handleTextColor = (rowId, jobId, color) => {
    console.log(`text clicked with ID: ${rowId} - ${color}`);
    let row = document.querySelector(`.${rowId}`);
    row.style.color = color !== 'transparent' ? color : '';

      if (color === 'transparent') {

          for (let i = row.classList.length - 1; i >= 0; i--) {
              const className = row.classList[i];
              if (className.startsWith('textColor')) {
                  row.classList.remove(className);
              }
          }

          row.style.color = "#000";
          row.style.opacity = "0.87";
      }

    let mutationParams = {
      jobId: jobId,
      textHexColorCode: color !== 'transparent' ? color : null,
      jobColorType: "Foreground"
    }
    updateJobChecklistColor(mutationParams);
  };
    const handleRowColor = (rowId, jobId, color) => {
        console.log(`Row clicked with ID: ${rowId} - ${color}`);
        let row = document.querySelector(`.${rowId}`);
        row.style.backgroundColor = color !== 'transparent' ? color : '';

        if (color === 'transparent') {

            for (let i = row.classList.length - 1; i >= 0; i--) {
                const className = row.classList[i];
                if (className.startsWith('bgColor')) {
                    row.classList.remove(className);
                }
            }

            if (Array.from(row.parentNode.children).indexOf(row) % 2 === 0) {
                row.style.backgroundColor = "#E8E7E8";
            }
            else {
                row.style.backgroundColor = "#fff";
            }

        }

        let mutationParams = {
          jobId: jobId,
          backgroundHexColorCode: color !== 'transparent' ? color : null,
          jobColorType: "Background"
        }
        updateJobChecklistColor(mutationParams);
      };

  const onSearchTextChange = (e) => {
    let text = e.target.value;
    setSearchText(text);
    setFilterModel({
      ...filterModel,
      quickFilterValues: text === '' ? [] : text.split(" ")
    })
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Box className="w-100">
      <div className="d-flex justify-content-between align-items-end mb-2">
        <StatusToggle status={status} onChange={handleChange} />
        <GridSearch search={searchText} onSearch={onSearchTextChange} />
      </div>
      <DataGrid
        autoHeight
        rows={rows}
        sx={gridStyle}
        columns={jobList}
        rowCount={rowCount}
        loading={isFetching || isJobChecklistUpdateLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginations        
        getRowId={(row) => "row_" + row.jobId}
        pageSizeOptions={[5, 10, 20, 50]}
        disableColumnMenu={true}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        filterMode="server"
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          if(!newRowSelectionModel.length){
            dispatch(setJobChecklistId(null));
          }
        }}
        onRowClick={(selectedRow) => {
          dispatch(setJobChecklistId(selectedRow.row.jobId));
        }}
        onPageChange={(newPage) => setPage(newPage)}
        onRowDoubleClick={(selectedRow) => {
          dispatch(resetShipment());
          dispatch(resetCarriers());
          dispatch(resetExhibit());
          dispatch(resetInstallDismantle());
          dispatch(resetShowInfo());
          navigate(`/show-info/${selectedRow?.row?.jobId}`);
        }}
        rowHeight={30}
        getRowClassName={(params) => {
            let colorClassesArr = getColorClasses(params.row.backgroundHexColorCode, params.row.textHexColorCode);
            let colorClasses = colorClassesArr.filter(Boolean).join(' ');
            return colorClasses !== '' ? `${colorClasses} ${params.id}` : params.id;
          }
        }
        columnVisibilityModel={{
          jobId: false,
        }}
        initialState={{
          sorting: {
            sortModel: defaultSort,
          },
          filter: {
            filterModel: filterModel,
          },
        }}
      />
    </Box>
  );
}

export default JobChecklist;
