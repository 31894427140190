import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ContractorForm from "./ContractorForm";
import styles from "../showName/EditShowName.module.scss";

const AddContractor = ({ isOpen, onAdd, onCancel,setNewContractor, selectedOption }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`${styles.header} text-center`}>
                Add contractor
            </DialogTitle>
            <ContractorForm
                onCancel={onCancel}
                onAdd={onAdd}
                onUpdate={onAdd}
                setNewContractor={setNewContractor}
                selectedOption={selectedOption}
            />
        </Dialog>
    )
}

export default AddContractor;
