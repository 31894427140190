import {useState, useEffect} from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
    Paper,
    Checkbox
} from "@mui/material";
import classes from './ClientConsumableItemList.module.css'
import sxTableStyles from "../../style/sxTableStyles";
import AddRowButton from "../ui/AddRowButton";
import ClientConsumableItem from "./ClientConsumableItem";
import AddClientConsumableItem from "./AddClientConsumableItem";
import EditClientConsumableItem from "./EditClientConsumableItem";
import { Button } from "reactstrap";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { usePrintClientConsumableMutation } from "../../services/authService";
import messages from "../../utils/messages";
import { enqueueSnackbar } from "notistack";
import { saveAs } from 'file-saver';

import { useGetClientConsumablesQuery } from "../../services/authService";

const sxDialog = {
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      borderTop: "1px solid red",
    }
  },
}

function ClientConsumableList(props) {
    const { items, showAddItem, showAction, clientId, sortConfig, branchId } = props;
  const [isConsumableItemOpen, setConsumableItemOpen] = useState(false);
  const [isConsumableEditItemOpen, setConsumableEditItemOpen] = useState(false);
  const [consumable, setConsumable] = useState(null);
  // const [isConsumablePrintOpen, setConsumablePrintOpen] = useState(false);
    const [printConsumableIds, setPrintConsumableIds] = useState([]);
    const [clientConsumables, setClientConsumables] = useState([]);

    const [sortConfigConsumable, setSortConfigConsumable] = useState({ sortProperty: null, sortDirection: null });
  
  const [printClientConsumable, { isLoading: isPrintLoading, isSuccess: isPrintSuccess, error: isPrintError
    }] = usePrintClientConsumableMutation();

    const { data: clientConsumablesData, isFetching, refetch: getClientConsumables } = useGetClientConsumablesQuery({ clientId: clientId, ...sortConfigConsumable }, { skip: clientId === null });

    const [selectAllConsumables, setSelectAllConsumables] = useState(false);

    useEffect(() => {
        setSelectAllConsumables(false);
    }, [])

    /*useEffect(() => {
        window.console.log(Date.now(),"=====clientConsumables===", clientConsumables)
    }, [clientConsumables])*/

    useEffect(() => {
        const fetchclientConsumables = async () => {
            const response = await getClientConsumables();
            //setClientConsumables(response.data?.consumables);
            updateConsumablesState(response.data?.consumables)
        };
    
        if (clientId) {
            fetchclientConsumables();
        }
    }, [clientId, sortConfigConsumable]); // clientConsumablesData

    function getPreviousCheckedState(prevState,item) {
        const filteredPrev = prevState.filter(prevConsumable => prevConsumable.id === item.id);
        if (filteredPrev.length > 0) {
            return filteredPrev[0]['checked'] ?? false;
        }
        return false;
    }

    function updateConsumablesState(clientConsumables) {
        setClientConsumables((prevState) => {

            //window.console.log("---consumables---", clientConsumables);

            const updatedConsumables = clientConsumables.map(item => ({
                ...item, // Spread the existing object
                checked: (selectAllConsumables === true ? true : getPreviousCheckedState(prevState, item))
            }));

            //window.console.log("====prevState====", prevState);
            return updatedConsumables;
        });
    }

    const handleConsumbleUpdated = async () => {
        const response = await getClientConsumables();
        //setClientConsumables(response.data?.consumables);
        updateConsumablesState(response.data?.consumables);
        
    };

    const handleConsumableSort = (sortProperty) => {
        let sortDirection = 'asc';
        if (sortConfigConsumable.sortProperty === sortProperty && sortConfigConsumable.sortDirection === 'asc')
            sortDirection = 'desc';
        setSortConfigConsumable({ sortProperty, sortDirection });
        handleConsumbleUpdated();
    };

    
  /***********************TODO: Print Consumables Placeholder************** */
    const handlePrintConsumablesChecked = (event) => {
        const { value, checked } = event.target;

        const updatedConsumables = clientConsumables.map((item) =>
            item.id == value ? { ...item, checked: checked } : item
        );

        const filteredFalseItems = updatedConsumables.filter(consumable => (consumable?.checked === false || consumable?.checked == undefined));
        if (filteredFalseItems.length > 0) {
            setSelectAllConsumables(false);
        } else {
            const filteredTrueItems = updatedConsumables.filter(consumable => consumable.checked === true);

            if (filteredTrueItems.length == updatedConsumables.length) {
                setSelectAllConsumables(true);
            }
        }
        
        setClientConsumables(updatedConsumables);

        consumablesToPrint(value, checked);

    };

    const consumablesToPrint = (value, checked) => {
        if(checked) {
            setPrintConsumableIds((prevValues) => [...prevValues, value]);
        } else {
            setPrintConsumableIds((prevValues) =>
            prevValues.filter((item) => item !== value)
             );
        }
    }
  const onPrintConsumables = async () => {
    const requestObject = {
      branchId: branchId,
      consumableIds: printConsumableIds
    };
      const response = await printClientConsumable(requestObject);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `branch-consumables-${branchId}.pdf`);
  };

  const handleMessages = () => {
    if(isPrintSuccess) {
      enqueueSnackbar(`${messages.success.printClientConsumable}`, { variant: 'success' });
    }
    if(isPrintError) {
      enqueueSnackbar(`${messages.error.prefix} ${isPrintError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
    };
    

  useEffect(() => {
      if(isPrintSuccess || isPrintError){
          handleMessages();
      }
      // eslint-disable-next-line
  }, [isPrintSuccess, isPrintError]);

  
/*************************************************************************** */
  function openAddConsumableHandler() {
    setConsumableItemOpen(true);
  }

  function closeAddConsumableHandler() {
    setConsumableItemOpen(false);
  }

  function closeEditConsumableHandler() {
    setConsumableEditItemOpen(false);
    }

    function handleSelectAllChange() {
        setSelectAllConsumables(!selectAllConsumables);
        const updatedConsumables = clientConsumables.map(item => ({
            ...item, // Spread the existing object
            checked: !selectAllConsumables, // Add a new field
        }));

        setClientConsumables(updatedConsumables);

        updatedConsumables.forEach(consumables => {
            consumablesToPrint(consumables.id.toString(), consumables.checked ?? false);
        });
    }


  return (
    <>
{/************************TODO: Print Consumables Placeholder***************/}
      
        <div style={{ marginBottom: "10px" }} className="d-flex justify-content-center position-relative">
            <div style={{ position:"absolute",left:"0px",bottom:"-7px"} }>
                  <Checkbox name="selectAllConsumableItem" checked={selectAllConsumables} onChange={handleSelectAllChange} /> Select All
            </div>             
            <Button color="secondary" size="sm" className="ms-2 px-3" onClick={onPrintConsumables} disabled={isPrintLoading}>Print Consumables</Button>
      </div>    
      {/* <Dialog
        sx={sxDialog}
        open={isConsumablePrintOpen}
        onClose={() => setConsumablePrintOpen(false)}
        aria-labelledby="print-dialog"
      >
        <DialogTitle id="print-dialog">Print Consumables Placeholder</DialogTitle>
        <DialogContent className="pt-4">{printConsumableIds}</DialogContent>
        <DialogActions>
          <CustomButton
            variant="outlined"
            onClick={() => setConsumablePrintOpen(false)}
          >
            Cancel
          </CustomButton>
        
        </DialogActions>
      </Dialog> */}
{/*******************************************************************************************/}
        <AddClientConsumableItem
            isOpen={isConsumableItemOpen}
            onCancel={closeAddConsumableHandler}
            clientId={clientId}
              sortConfig={sortConfig}
              onConsumableUpdated={handleConsumbleUpdated}
        />
      <EditClientConsumableItem
        consumable={consumable}
        setConsumable={setConsumable}
        isOpen={isConsumableEditItemOpen}
        onCancel={closeEditConsumableHandler}
        clientId={clientId}
              onConsumableUpdated={handleConsumbleUpdated}
        />
      <TableContainer component={Paper} style={{maxHeight:'600px', overflow: 'auto'}}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_cell} onClick={() => handleConsumableSort('description')} style={{ cursor: 'pointer' }}>Description
                              {sortConfigConsumable.sortProperty == 'description' && (sortConfigConsumable.sortDirection === 'asc' ? <ArrowUpwardIcon style={{ height: '15px', width: '15px', paddingBottom: '2px' }} /> : sortConfigConsumable.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell className={classes.table_cell} onClick={() => handleConsumableSort('totalQuantity')} style={{ cursor: 'pointer' }}>Quantity
                              {sortConfigConsumable.sortProperty == 'totalQuantity' && (sortConfigConsumable.sortDirection === 'asc' ? <ArrowUpwardIcon style={{ height: '15px', width: '15px', paddingBottom: '2px' }} /> : sortConfigConsumable.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell className={classes.table_cell} onClick={() => handleConsumableSort('weight')} style={{ cursor: 'pointer' }}>Weight
                              {sortConfigConsumable.sortProperty == 'weight' && (sortConfigConsumable.sortDirection === 'asc' ? <ArrowUpwardIcon style={{ height: '15px', width: '15px', paddingBottom: '2px' }} /> : sortConfigConsumable.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              { showAction && <TableCell className={
                classes.table_cell
              }>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{
            clientConsumables && clientConsumables.length > 0 ? (clientConsumables.map((item, index) => {
                return <ClientConsumableItem item={item} showAction={showAction} setConsumable={setConsumable} onEditOpen={setConsumableEditItemOpen} onConsumableUpdated={handleConsumbleUpdated}
                    key={`consumable-item-${index}`} handlePrintConsumables={handlePrintConsumablesChecked} />
            })) : <ClientConsumableItem item={null} showAction={showAction}  setConsumable={setConsumable} key='consumable-no-item' />
          }</TableBody>
        </Table>
      </TableContainer>
      { showAddItem ? (<AddRowButton onAdd={openAddConsumableHandler}>+ Add Consumables </AddRowButton>) : null }
    </>
  )
}

export default ClientConsumableList;
