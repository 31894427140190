import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useDispatch, useSelector} from 'react-redux';
import {setExhibitItem} from '../../redux/reducers/exhibit/exhibitSlice';

const validationSchema = Yup.object().shape({
  item_id: Yup.number().required('Item ID is required'),
  exhibit_id: Yup.number().required('Exhibit ID is required'),
  quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
  itemType: Yup.string().required('Item Type is required'), // New field for item type
});

function ReturnItemForm(props) {
  const {onClose, onSubmit, cmcInventoryItems, clientInventoryItems} = props;
  const dispatch = useDispatch();

  const {jobExhibit} = useSelector((state) => {
    return state.jobExhibitData;
  });

  let cmcInventoryItemsDummy = [
    {
      id: 1,
      name: "CMC Item 1"
    }, {
      id: 1,
      name: "CMC Item 2"
    }
  ]
  let clientInventoryItemsDummy = [
    {
      id: 3,
      name: "Client Item 1"
    }, {
      id: 4,
      name: "Client Item 2"
    }
  ]

  const formik = useFormik({
    initialValues: {
      item_id: '',
      exhibit_id: jobExhibit?.exhibitGiveAway?.exhibit?.id,
      quantity: '',
      itemType: '', // New field for item type
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("submitted values", values);
      dispatch(setExhibitItem({description: 'value', quantity: 2, weight: 2, content: "sfdsf"}));

    }
  });

  useEffect(() => { // Update item options when item type changes
    if (formik.values.itemType === 'CMC') {
      formik.setFieldValue('item_id', ''); // Clear the selected item when changing type
    }
  }, [formik.values.itemType]);

  return (
    <form onSubmit={
      formik.handleSubmit
    }>
      <DialogTitle>Add Exhibit Item</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel htmlFor="itemType">Item Type</InputLabel>
          <Select label="Item Type" name="itemType"
            value={
              formik.values.itemType
            }
            onChange={
              formik.handleChange
            }
            onBlur={
              formik.handleBlur
            }
            error={
              formik.touched.itemType && Boolean(formik.errors.itemType)
          }>
            <MenuItem value="CMC">CMC</MenuItem>
            <MenuItem value="Client">Client</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth
          style={
            {marginTop: '10px'}
        }>
          <InputLabel htmlFor="item_id">Item</InputLabel>
          <Select label="Item" name="item_id"
            value={
              formik.values.item_id
            }
            onChange={
              formik.handleChange
            }
            onBlur={
              formik.handleBlur
            }
            error={
              formik.touched.item_id && Boolean(formik.errors.item_id)
          }>
            {
            formik.values.itemType === 'CMC' ? (cmcInventoryItemsDummy.map((item) => (
              <MenuItem key={
                  item.id
                }
                value={
                  item.id
              }>
                {
                item.name
              } </MenuItem>
            ))) : null
          }
            {
            formik.values.itemType === 'Client' ? (clientInventoryItemsDummy.map((item) => (
              <MenuItem key={
                  item.id
                }
                value={
                  item.id
              }>
                {
                item.name
              } </MenuItem>
            ))) : null
          } </Select>
        </FormControl>

        <TextField label="Quantity" name="quantity" type="number"
          value={
            formik.values.quantity
          }
          onChange={
            formik.handleChange
          }
          onBlur={
            formik.handleBlur
          }
          error={
            formik.touched.quantity && Boolean(formik.errors.quantity)
          }
          helperText={
            formik.touched.quantity && formik.errors.quantity
          }
          fullWidth
          style={
            {marginTop: '10px'}
          }/>
      </DialogContent>
      <DialogActions>
        <ActionBar hideDialog={onClose}
          onSave={
            formik.handleSubmit
          }/>
      </DialogActions>
    </form>
  );
}

export default ReturnItemForm;
