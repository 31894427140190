import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import EditContact from "./contact/EditContact";
import DeleteContact from "./contact/DeleteContact";
import {DISPATCH_TYPES} from "../../utils/constants";


const Contact = ({ id, contactData, setContactData, onUpdate, dispatchCallback, isContractor = true }) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [open, setOpen] = useState(false);

    const data = contactData;

    const handleChange = (event, value) => {
        if(value){
            dispatchCallback(DISPATCH_TYPES.contact, value.id);
            setSelectedOption(value);
            setFilter(value.id);
        } else {
            dispatchCallback(DISPATCH_TYPES.contact, null);
            setOpen(true);
            setFilter(null);
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            setFilter(null);
        }
    }

    useEffect(() => {
        if (id) {
            setFilter(id);
        }
    }, [id]);

    useEffect(() => {
        if(data?.contacts){
            setOptions(data?.contacts.filter(c => !c.isDeleted));
            // const selOption = data?.contacts?.find(option => (!option.isDeleted && option.id === id));
            const selOption = data?.contacts?.find(option => (!option.isDeleted && option.id === filter));
            setSelectedOption(selOption ?? null);
        }
        if(!data){
            setOptions([]);
            setSelectedOption(null);
        }
    }, [data, id]);

    return (
        <>
            <div className="d-flex align-items-center">
                <CustomAutocomplete
                    className="flex-grow-1"
                    sx={sxAutocomplete}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    options={options || []}
                    value={selectedOption}
                    isOptionEqualToValue={
                        (option, value) => option.id === value.id
                      }
                    renderOption={(props, option) => (
                        <li {...props}>
                            <span className="autocomplete-option-label">{option.name || ''}</span>
                        </li>
                    )}
                    getOptionLabel={(option) => (option ? option.name || '' : '')}
                    noOptionsText="Contact not found"
                    renderInput={(params) => (
                        <TextField {...params}
                           label={
                               <label className="autocomplete-input-label">Contacts</label>
                           }
                           variant="outlined"
                        />
                    )}
                />

                <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                    <EditContact
                        contactData={contactData}
                        contact={selectedOption}
                        setContact={setSelectedOption}
                        setContactData={setContactData}
                        onUpdate={onUpdate}
                        isContractor={isContractor}
                    />

                    <DeleteContact
                        contact={selectedOption}
                        setContact={setSelectedOption}
                        contactData={contactData}
                        setContactData={setContactData}
                        onDelete={onUpdate}
                        isContractor={isContractor}
                    />
                </div>
            </div>

            {selectedOption?.phone || selectedOption?.email ? (
                <div className="bg-white p-2 mt-2 common-text">
                    <div>Phone: {selectedOption.phone}</div>
                    <div>Email: {selectedOption.email}</div>
                </div>
            ): null
            }
        </>
    )
}

export default Contact;
