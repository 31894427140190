import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import Footer from "./Footer";
import styles from './AuthLayout.module.scss';

const AuthLayout = () => {
    return (
        <>
            <Container className="flex-grow-1 d-flex align-items-center justify-content-center">
                <div className={`p-4 mx-auto rounded-3 ${styles.authWrap}`}>
                    <Outlet />
                </div>
            </Container>
            <Footer />
        </>
    );
}

export default AuthLayout;