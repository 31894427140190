import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import BorderBox from "../../ui/BorderBox";
import messages from "../../../utils/messages";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import {useCreateCarRentalMutation, useUpdateCarRentalMutation} from "../../../services/authService";

const carRentalSchema = Yup.object({
    name: Yup.string().required('Car Rental Name is required'),
});

const CarRentalForm = ({ carRental, setCarRental, onCancel, onUpdate, onAdd }) => {
    const [createCarRental, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateCarRentalMutation();
    const [updateCarRental, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateCarRentalMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [previousName, setPreviousName] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: carRental ? carRental.name : '',
        },
        validationSchema: carRentalSchema,
        onSubmit: async (values) => {
            setPreviousName(values.name);
            setCarRental('');
            if(carRental){ // edit
                await updateCarRental({
                    id: carRental.id,
                    ...values
                });
            } else { // add
                await createCarRental({
                    id: 0,
                    ...values
                });
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createCarRental}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setCarRental(previousName);

        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateCarRental}`, { variant: 'success' })
            updateReset();
            onUpdate();
            onCancel();
            setCarRental(previousName);

        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);


    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Car Rental Name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        className="mt-2"
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default CarRentalForm;
