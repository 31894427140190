import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    palette: {
        primary: {
            main: '#A72228',
        },
        secondary: {
            main: '#635D63',
            light: '#B0ADB0',
        },
        error: {
            main: '#ff0000',
        },
        text: {
            main: '#2A2A2A',
        },
        background: {
            main: '#E8E7E8',
            light: '#E3E0DF',
        },
        color0: {
            main: '#EFBFFF',
            dark: '#D24BFF',
        },
        color1: {
            main: '#CEBFFF',
            dark: '#784EFF',
        },
        color2: {
            main: '#BFCEFF',
            dark: '#4772FF',
        },
        color3: {
            main: '#BFEFFF',
            dark: '#23ADDB',
        },
        color4: {
            main: '#BFFFDF',
            dark: '#1EC270',
        },
        color5: {
            main: '#CFFFBF',
            dark: '#F92D2D',
        },
        color6: {
            main: '#DBEBAB',
            dark: '#F9602D',
        },
        color7: {
            main: '#FFEFBF',
            dark: '#FF8811',
        },
        color8: {
            main: '#FFCFBF',
            textColor: '#DC143C'
        },
        color9: {
            main: '#FFDFBF',
            textColor: '#0047AB'
        },
        color10: {
            textColor: '#800020',
            bgColor: '#ACB6AD'
        },
        color11: {
            textColor: '#FFFF00',
            bgColor: '#FFFF00'
        },
        color12: {
            textColor: '#00B050',
            bgColor: '#73766F'
        },
        color13: {
            bgColor: '#00B050'
        },
        color14: {
            bgColor: '#0070C0'
        },
        color15: {
            bgColor: '#D24BFF'
        },
        color16: {
            bgColor: '#F92D2D'
        },
        color17: {
            bgColor: '#FF8811'
        },
        color18: {
            bgColor: '#800020'
        }

    },
    components: {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 'normal',
                    fontSize: 14,
                    lineHeight: '20px',
                    backgroundColor: '#635D63',
                    color: '#FFF',
                    minHeight: 30,
                    textAlign: 'center',
                    padding: 5,
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E3E0DF',
                    paddingBottom: 0,
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E3E0DF',
                    padding: '20px 24px',
                }
            }
        }
    }
});