import {useState} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import BranchForm from "./BranchForm";
import styles from "../showName/EditShowName.module.scss";

const EditBranch = ({ branch, setBranch, onUpdate }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <EditOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit branch
                </DialogTitle>
                <BranchForm
                    branch={branch}
                    setBranch={setBranch}
                    onCancel={handleClose}
                    onUpdate={onUpdate}
                    onDelete={onUpdate}
                />
            </Dialog>
        </>
    )
}

export default EditBranch;