import React, { useState, useEffect } from 'react';
import {Container} from "reactstrap";
import BorderBox from '../../components/ui/BorderBox';
import styles from "./ServicesRequest.module.scss";
import ServicesRequestDetails from "../../components/ServicesRequestDetails";
import { useGetServicesRequestQuery, useUpdateServicesRequestMutation } from '../../services/authService';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import LabelInfo from '../../components/ui/LabelInfo';
import useAuthentication from '../../hooks/useAuthentication';
import ActionBar from '../../components/ui/ActionBar';

const ServicesRequest = () => {
    let params = useParams();
    const {data: servicesRequestData, isFetching, error: servicesRequestDataError, refetch: getServicesRequestData} = useGetServicesRequestQuery(params.id);
    const [updateServicesRequest, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateServicesRequestMutation();
    const [furnishingsRowData, setFurnishingsRowData] = useState([]);
    const [servicesRowData, setServicesRowData] = useState([]);
    const [facilityRowData, setFacilityRowData] = useState([]);
    const [updatedServiceData, setUpdatedServiceData] = useState([]);

    const [furnishingsSaveData, setFurnishingsSaveData] = useState([]);
    const [servicesSaveData, setServicesSaveData] = useState([]);
    const [facilitySaveData, setFacilitySaveData] = useState([]);

    useAuthentication([servicesRequestDataError, isUpdateError]);
    
    const handleMessages = () => {
       
        if(isUpdateSuccess) {
            enqueueSnackbar(
                `${messages.success.updateServicesRequest}`,
                { variant: 'success' }
            );
            updateReset();
            getServicesRequestData();
        }
      
        if(isUpdateError){
            let errorMessage = isUpdateError?.data[0]?.errorMessage ? isUpdateError?.data[0]?.errorMessage : messages.error.generic;
            enqueueSnackbar(`${messages.error.prefix} ${errorMessage}`, { variant: 'error' });
        }
    }


    useEffect(() => {
        if(isUpdateSuccess || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isUpdateSuccess, isUpdateError]);


    useEffect(() => {
        if (servicesRequestData) {
            const {serviceRequests} = servicesRequestData;
           
            const furnishings = serviceRequests?.filter(item => item.category === 'Furnishings');
            setFurnishingsRowData(furnishings);

            const services = serviceRequests?.filter(item => item.category === 'Services');
            setServicesRowData(services);

            const facility = serviceRequests?.filter(item => item.category === 'Facility');
            setFacilityRowData(facility);
        }
    }, [servicesRequestData, isFetching]);

   
    const handleSubmitRow = (data) => {
        /*let serviceRequestObj = {
            serviceRequest: {}
        };

        serviceRequestObj.serviceRequest = data;
        updateServicesRequest(serviceRequestObj);*/
    };

    const handleValidationMessage = (message) => {
        enqueueSnackbar(`${messages.error.validationError} ${message}`, { variant: 'error' });
    };

    const handleSave = () => {
        let serviceRequestObj = {
            serviceRequests: {}
        };
        const saveData = [...furnishingsSaveData, ...servicesSaveData, ...facilitySaveData];
        serviceRequestObj.serviceRequests = saveData;
        updateServicesRequest(serviceRequestObj); // un comment 
    }

    const updateServiceTableData = (newValue) => {
        setUpdatedServiceData(newValue);
    };
    

    return (
        <Container className={`${styles.container} py-3 p-md-4`} >
            <div style={{marginBottom:"10px"}}>
                <LabelInfo label="CMC Job Number" info={params.id}/>
            </div>
            <ActionBar onSave={handleSave} ></ActionBar>
            <BorderBox title="Services Request" boxStyle="mb-3">
            <h4 className={styles.subTitle}>Furnishings</h4>
                <div className="mb-3" style={{overflow: 'auto'}}>
                    <ServicesRequestDetails
                        updateRequestTbl={updateServiceTableData}
                        submitRow={(data) => handleSubmitRow(data)}
                        showColor={true}
                        rowData={furnishingsRowData}
                        setFurnishingsSaveData={setFurnishingsSaveData}
                        category={"Furnishings"}
                        validationMessage={(message) => handleValidationMessage(message)}
                    />
                </div>

                <h4 className={styles.subTitle}>Services</h4>
                <div className="mb-3" style={{overflow: 'auto'}}>
                    <ServicesRequestDetails
                        updateRequestTbl={updateServiceTableData}
                        submitRow={(data) => handleSubmitRow(data)}
                        showColor={false}
                        rowData={servicesRowData}
                        setServicesSaveData={setServicesSaveData}
                        category={"Services"}
                        validationMessage={(message) => handleValidationMessage(message)}
                    />
                </div>

                <h4 className={styles.subTitle}>Facility</h4>
                <div className="mb-3" style={{overflow: 'auto'}}>
                    <ServicesRequestDetails
                        updateRequestTbl={updateServiceTableData}
                        submitRow={(data) => handleSubmitRow(data)}
                        showColor={false}
                        rowData={facilityRowData}
                        setFacilitySaveData={setFacilitySaveData}
                        category={"Facilities"}
                        validationMessage={(message) => handleValidationMessage(message)}
                    />
                </div>                
            </BorderBox>            
        </Container>
    )
}

export default ServicesRequest;
