import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CustomButton } from '../style/mui/customStyle';

const sxDialog = {
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      borderTop: "1px solid red",
    }
  },
}

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      sx={sxDialog}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent className="pt-4">{children}</DialogContent>
      <DialogActions>
        <CustomButton
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          No
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;