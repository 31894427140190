import {useState} from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from "../../ConfirmDialog";
import {useRemoveItemFromExhibitMutation} from "../../../services/authService";
import {useDispatch} from "react-redux";
import { enqueueSnackbar } from "notistack";
import messages from "../../../utils/messages";

function DeleteItem(props) {
  const {item, setClientInventoryItemsState, exhibit, handleItemUpdated} = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [removeItemFromExhibit, { isLoading, isError }] = useRemoveItemFromExhibitMutation();

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  const handleDelete = async () => {
    const response = await removeItemFromExhibit({clientId: item.clientId, exhibitId: exhibit?.id, itemId: item?.id});
    if (response.data == true) {
        handleItemUpdated();
        enqueueSnackbar(`${messages.success.removeItemFromExhibit}`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.genericDelete }`, { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title="Remove Item">
        <RemoveCircleOutlineIcon fontSize="small"
          style={
            {
              color: "grey",
              cursor: "pointer"
            }
          }
          onClick={openDeleteModalHandler}/>
      </Tooltip>
      <ConfirmDialog title="Delete Exhibit Item"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this exhibit item?
      </ConfirmDialog>
    </>
  )
}

export default DeleteItem;
