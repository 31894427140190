import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import authApis from '../services/authApis';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...authApis),
})

export default store;

// will remove once flow is working 
// import authReducer from './reducers/authSlice';
// import { authApi } from '../services/authService';

// const store = configureStore({
  // reducer: {
  //   auth: authReducer,
  //   [authApi.reducerPath]: authApi.reducer,
  // },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(authApi.middleware),
// })

// export default store
