import { useCreateClientInventoryMutation, useUpdateClientInventoryMutation} from "../../../services/authService";
import {DialogContent, FormControl, Grid} from '@mui/material';
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from "notistack";
import messages from "../../../utils/messages";
import ActionBar from '../../ui/ActionBar';
import {CustomTextField} from '../../../style/mui/customStyle';

const validationSchema = Yup.object({
  description: Yup.string().required('Description is required'),
  length: Yup.number().nullable().typeError('Length must a be number'),
  width: Yup.number().nullable().typeError('Width must a be number'),
  height: Yup.number().nullable().typeError('Height must a be number'),
  weight: Yup.number().nullable().typeError('Weight must a be number'),
  quantity: Yup.number().nullable().min(0, 'Quantity must be positive number').integer('Quantity must be integer')
});


const cleanData = (obj) => {
  const cleaned = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '' && obj[key] !== null) {
      cleaned[key] = obj[key];
    }
  });
  return cleaned;
};

function InventoryItemForm(props){
  const { item, clientId, closeDialog, onUpdated } = props;

  const [createClientInventory, {isLoading : isCreateClientInventoryLoading, isError : isCreateClientInventoryError }] = useCreateClientInventoryMutation();
  const [updateClientInventory, {isLoading : isUpdateClientInventoryLoading, isError : isUpdateClientInventoryError }] = useUpdateClientInventoryMutation();

  const formik = useFormik({
    initialValues: {
      clientId: clientId,
      description: item?.description || '',
      length: item?.length || '',
      width: item?.width || '',
      height: item?.height || '',
      weight: item?.weight || '',
      quantity: item?.totalQuantity || 0,
      content: item?.content || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (item) {
        const requestUpdateObject = cleanData({ id: item?.id,  ...values });
        const response = await updateClientInventory(requestUpdateObject);
        if (response.data === true) {
          onUpdated(); closeDialog();
          enqueueSnackbar(`${messages.success.updateClientInventory}`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${messages.error.prefix} ${response.error ? response.error.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
      } else { // Add new item
        const requestObject = cleanData(values);
        const responseCreate = await createClientInventory(requestObject);
        if (responseCreate.data) {
          formik.resetForm();
          onUpdated(); closeDialog();
          enqueueSnackbar(`${messages.success.createClientInventory}`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${messages.error.prefix} ${responseCreate.error ? responseCreate.error.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
      }
    }
  });

  return (
    <>
      <DialogContent>
        <form className="mt-2 mb-3" onSubmit={
          formik.handleSubmit
        }>
          <FormControl fullWidth className='mt-2'>
            <CustomTextField label="Description" name="description" fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}/>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth className='mt-2'>
                <CustomTextField label="Length" name="length" fullWidth
                  value={formik.values.length}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.length && Boolean(formik.errors.length)}
                  helperText={formik.touched.length && formik.errors.length}/>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className='mt-2'>
                <CustomTextField label="Width" name="width" fullWidth
                  value={formik.values.width}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.width && Boolean(formik.errors.width)}
                  helperText={formik.touched.width && formik.errors.width}/>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className='mt-2'>
                <CustomTextField label="Height" name="height" fullWidth
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}/>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth className='mt-2'>
                <CustomTextField label="Quantity" name="quantity" fullWidth
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}/>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className='mt-2'>
                <CustomTextField label="Weight" name="weight" fullWidth
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}/>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth className='mt-2 mb-2'>
            <CustomTextField label="Content" name="content" fullWidth
              multiline rows="3"
              value={formik.values.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.content && Boolean(formik.errors.content)}
              helperText={formik.touched.content && formik.errors.content}/>
          </FormControl>
          <ActionBar hideDialog={closeDialog}
            loadingSave={isCreateClientInventoryLoading || isUpdateClientInventoryLoading  }
            onSave={ formik.handleSubmit }
          />
        </form>
      </DialogContent>
    </>
  );

}

export default InventoryItemForm;