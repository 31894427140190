import {useEffect} from "react";
import { useSnackbar } from 'notistack';
import {
    useCreateContactMutation,
    useUpdateContactMutation,
} from "../../../services/authService";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from 'yup';
import BorderBox from "../../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import * as React from "react";
import {useFormik} from "formik";
import messages from "../../../utils/messages";

const contactSchema = Yup.object({
    name: Yup.string().required('Contact Name is required'),
    phone: Yup.string(),
    email: Yup.string().email('Email should be a valid email address')
});

const ContactForm = ({ contactData, setContactData, contact, setContact, onCancel, onUpdate, onAdd, isContractor = true }) => {
    const [createContact, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateContactMutation();
    const [updateContact, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateContactMutation();
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            name: contact?.name ?? '',
            phone: contact?.phone ?? '',
            email: contact?.email ?? ''
        },
        validationSchema: contactSchema,
        onSubmit: async (values) => {
            let updateObject = {
                id: contact?.id,
                contractorId: contactData?.id,
                ...values
            };

            let createObject = {
                contractorId: contactData?.id,
                ...values
            };

            if(!isContractor){
                updateObject = {
                    id: contact?.id,
                    clientBranchId: contactData?.id,
                    ...values
                };

                createObject = {
                    clientBranchId: contactData?.id,
                    ...values
                };
            }

            if(contact){ // edit
                await updateContact(updateObject);
            } else { // add
                await createContact(createObject);
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createContact}`, { variant: 'success' });
            formik.resetForm();
            onAdd();

            let createContact = {
                id: isCreateSuccess,
                contractorId: contactData?.id,
                ...formik.values
            };

            if(!isContractor){
                createContact = {
                    id: isCreateSuccess,
                    clientBranchId: contactData?.id,
                    ...formik.values
                };
            }

            setContactData({
                ...contactData,
                contacts: [
                    ...contactData?.contacts,
                    createContact
                ]
            });
            createReset();
            onCancel();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateContact}`, { variant: 'success' });
            let updatedContact = {
                id: contact?.id,
                contractorId: contactData?.id,
                ...formik.values
            };

            if(!isContractor) {
                updatedContact = {
                    id: contact?.id,
                    clientBranchId: contactData?.id,
                    ...formik.values
                };
            }
            setContactData({
                ...contactData,
                contacts: [
                    ...contactData.contacts.filter(c => c.id !== contact?.id),
                    updatedContact
                ]
            });
            setContact(updatedContact);
            onUpdate();
            updateReset();
            onCancel();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Contact name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <CustomTextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        variant="outlined"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <CustomTextField
                        fullWidth
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ContactForm;
