import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from "@mui/material";
import dayjs from 'dayjs';
import sxTableStyles from "../../style/sxTableStyles";
import {CustomDatePicker, CustomFormControlLabel, CustomIconButton, CustomTableCellOne, CustomTextField} from "../../style/mui/customStyle";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";
import { Container, Row, Col } from 'reactstrap';

function AdvancedReceiving(props) {
  const {handleAdvanceReceivingChange, advancedReceivingData, formik, checkedAdvanceReceiving} = props;
  
  return (
      <>
          <Container>
              <Row>
                  <Col xs={12} md={7} style={{ paddingLeft: '0px' }}>
                      <CustomFormControlLabel
                          fullWidth
                          control={<Checkbox checked={checkedAdvanceReceiving}
                              size='sm' onChange={handleAdvanceReceivingChange}
                          />}
                          name='isAdvancedReceiving'
                          label="Advanced Receiving"
                          labelPlacement="start"
                          style={{ marginLeft: '0px' }}
                      />
                      <CustomTextField name="advancedReceivingAddress" onChange={formik.handleChange} value={formik.values.advancedReceivingAddress} multiline fullWidth rows={5} className="mb-2" />
                  </Col>
                  <Col xs={12} md={5} className="d-flex justify-content-center align-items-center clsadvRecevingRightCol">
                      <TableContainer component={Paper}
                          className="rounded-0 shadow-none mb-2">

                          <Table sx={sxTableStyles} className="clsAdvReceivingTbl">
                              <TableBody>
                                  <TableRow>
                                      <TableCell className="cellheading" style={{ width: '33%' }}>Ready</TableCell>  
                                      <CustomTableCellOne className="cellcontent">
                                          <CustomIconButton className="table-cell-picker">
                                              <CustomDatePicker variant="standard" style={{ width: '100%' }} name="advancedReceivingReadyDate"
                                                  value={formik.values.advancedReceivingReadyDate ? dayjs(formik.values.advancedReceivingReadyDate) : ''}
                                                  onChange={(newValue) => { formik.setFieldValue('advancedReceivingReadyDate', formatDateTimeDayjs(newValue, FORMATS.date)) }}
                                              />
                                          </CustomIconButton>
                                      </CustomTableCellOne>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell className="cellheading" style={{ width: '33%' }}>Deadline</TableCell>
                                      <CustomTableCellOne className="px-2 cellcontent">
                                          <CustomDatePicker variant="standard" name="advancedReceivingDeadline" style={{ width: '100%' }} 
                                              value={formik.values.advancedReceivingDeadline ? dayjs(formik.values.advancedReceivingDeadline) : ''}
                                              onChange={(newValue) => { formik.setFieldValue('advancedReceivingDeadline', formatDateTimeDayjs(newValue, FORMATS.date)) }}
                                          />
                                      </CustomTableCellOne>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell className="cellheading" style={{ width: '33%' }}>Handling Rate</TableCell>
                                      <CustomTableCellOne className="px-2 cellcontent">
                                          <CustomTextField variant="standard" name="advancedReceivingHandlingRate" style={{ width: '100%' }} 
                                              value={formik.values.advancedReceivingHandlingRate}
                                              onChange={formik.handleChange}
                                              error={formik.touched.advancedReceivingHandlingRate && Boolean(formik.errors.advancedReceivingHandlingRate)}
                                              helperText={formik.touched.advancedReceivingHandlingRate && formik.errors.advancedReceivingHandlingRate}
                                          />
                                      </CustomTableCellOne>
                                  </TableRow>
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Col>
              </Row>
          </Container>      
    </>
  )
}

export default AdvancedReceiving;
