import  React,{useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ToggleText =(props) =>{
    const {label,id,variant, handleTab} = props;
    const CustomButton = styled(Button)({
        textTransform: 'capitalize',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 'normal',
        fontFamily: "Helvetica",
        height: '20px',
        padding: '5px 6px',
        borderRadius: '1px'
    });
    return (
        <CustomButton className='toggle_button' variant={variant} 
        onClick={() =>handleTab(id)}
        >{label}</CustomButton>
    )   
}
export default ToggleText;