import ShipmentDetail from "../../components/shipments/ShipmentDetail";

function ShowShipment(props) {

  return (
    <ShipmentDetail> {
      props.children
    } </ShipmentDetail>
  )
}

export default ShowShipment;
