import {TableRow, TableCell, debounce} from "@mui/material";
import { CustomTextField } from "../../style/mui/customStyle";
import { useEffect, useState } from "react";
import { useGetAssignedConsumablesQuery, useUpdateReturnInventoryMutation } from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import { setAllocatedConsumableItems } from "../../redux/reducers/exhibit/exhibitSlice";

function ReturnItem(props) {
  const {item, handleReturnItemChange} = props;
  const dispatch = useDispatch();
  const [updatedRowData, setUpdatedRowData] = useState();
  const handleChange = (event) => {
    const { value } = event.target;
    handleReturnItemChange(item, value);
  };
  // const debounceTimeout = 100;
  const {jobExhibit} = useSelector((state) => {
    return state.jobExhibitData;
  });
  const [updateReturnInventory, {
    isSuccess: isUpdateSuccess, error: isUpdateError
  }
  ] = useUpdateReturnInventoryMutation();
  
  const {data: assignedConsumableItems, isFetching: fetchingAssignedConsumableItems, refetch: refetchAssignedConsumableItems} = useGetAssignedConsumablesQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.id);

  const handleMessages = async () => {       
    if(isUpdateSuccess) {
      enqueueSnackbar(
        `${messages.success.updateReturnInventory}`,
        { variant: 'success' }
      );
      const refetch = await refetchAssignedConsumableItems();
      dispatch(setAllocatedConsumableItems(refetch.data.consumables));
    }
    if(isUpdateError){
      enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
  }

useEffect(() => {
    if(isUpdateSuccess || isUpdateError){
        handleMessages();
    }
}, [isUpdateSuccess, isUpdateError]);

  useEffect(()=>{
    if(updatedRowData){
      updateReturnInventory(updatedRowData);
    }
  },[updatedRowData]);

  const setReturnInventory = (data, numReturned) => {
    const parsedNumReturned = parseFloat(numReturned);
    if (parsedNumReturned !== data.numReturned) {
      setUpdatedRowData({
        consumableId: data.id,
        exhibitGiveAwayId: jobExhibit?.exhibitGiveAway?.id,
        numReturned: parsedNumReturned
      });
    }
  };

  // const debouncedSetReturnedInventory = debounce(setReturnInventory, debounceTimeout);

  const handleBlur = (event, item) => {
    setReturnInventory(item, event.target.value);
  };

  return (
    <TableRow>{
      item ? (
        <>
          <TableCell>
            {
            item.description
          }</TableCell>
          <TableCell>{
            item.quantityAssignedToExhibitGiveAway
          }</TableCell>
          <TableCell>
            <CustomTextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={item.numReturned} 
                sx={{border: 'none',"& fieldset": { border: 'none' },}}
                // onChange={(event) => {  debouncedSetReturnedInventory(item, event.target.value);  }}
                onBlur={(event) => handleBlur(event, item)}
                onChange={handleChange}
            />
          </TableCell>
          <TableCell>{
            item.inventory
          }</TableCell>
        </>
      ) : <TableCell align="center" colSpan={4} className="py-1">
          No Items found
        </TableCell>
    }</TableRow>
  )
}

export default ReturnItem;
