import React from "react";
import Contractor from "./Contractor";
import Contact from "./Contact";

const ContractorContact = ({ id, contractorName, contractor, setContractor, onUpdate, dispatchCallback }) => {
    const setContactData = () => {
        // console.log("----------setContactData-------------------")
    }
    return (
        <>
            <div className="mb-2">
                <Contractor
                    name={contractorName}
                    setContractor={setContractor}
                    onUpdate={onUpdate}
                    dispatchCallback={dispatchCallback}
                />
            </div>

            <div>
                <Contact
                    id={id}
                    contactData={contractor}
                    setContactData={setContactData}
                    onUpdate={onUpdate}
                    dispatchCallback={dispatchCallback}
                    isContractor={true}
                />
            </div>
        </>
    )
}

export default ContractorContact;
