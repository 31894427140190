import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {Button, Col, Collapse, Row} from "reactstrap";
import ContractorContact from "../lookups/ContractorContact";
import SetupDismantleLabor from "./SetupDismantleLabor";
import LeadSupervisor from "../lookups/LeadSupervisor";
import SetupDismantleTable from "./SetupDismantleTable";
import BorderBox from "../ui/BorderBox";
import ItineraryModal from "./ItineraryModal";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import styles from "../../pages/InstallDismantle.module.scss";
import {DISPATCH_TYPES, SETUP_DISMANTLE, TASK_TYPE} from "../../utils/constants";
import {setInstallDismantleInfo} from "../../redux/reducers/installDismantle/installDismantleSlice";

const Install = () => {
    const dispatch = useDispatch();
    const [state, handleState, refetch] = useInstallDismantle();
    const [contractorDetails, setContractorDetails] = useState(null);
    const [secondContractorDetails, setSecondContractorDetails] = useState(null);
    const [isSecondContractorOpen, setIsSecondContractorOpen] = useState(false);

    const [isSecondSupervisorOpen, setIsSecondSupervisorOpen] = useState(false);

    const [installLaborData, setInstallLaborData] = useState(null);
    const [installItineraryData, setInstallItineraryData] = useState(null);
    const [showItineraryModal, setShowItineraryModal] = useState(false);
    const [showSecondItineraryModal, setShowSecondItineraryModal] = useState(false);

    const [setupDismantles, setSetupDismantles] = useState([]);

    const dispatchCallback = (type, value) => {
        switch (type) {
            case DISPATCH_TYPES.contractor:
                dispatch(setInstallDismantleInfo({installationContractorId: value}));
                break;
            case DISPATCH_TYPES.contact:
                dispatch(setInstallDismantleInfo({installationContractorContactId: value}));
                break;
            case DISPATCH_TYPES.leadSupervisor:
                dispatch(setInstallDismantleInfo({installSupervisorId: value?.id}));
                handleState({
                    installSupervisorId: value?.id,
                    installSupervisor: value
                });
                break;
            default:
                break;
        }
    }

    const dispatchSecondCallback = (type, value) => {
        switch (type) {
            case DISPATCH_TYPES.contractor:
                dispatch(setInstallDismantleInfo({secondInstallationContractorId: value}));
                break;
            case DISPATCH_TYPES.contact:
                dispatch(setInstallDismantleInfo({secondInstallationContractorContactId: value}));
                break;
            case DISPATCH_TYPES.leadSupervisor:
                dispatch(setInstallDismantleInfo({secondInstallSupervisorId: value?.id}));
                handleState({
                    secondInstallSupervisorId: value?.id,
                    secondInstallSupervisor: value
                });
                break;
            default:
                break;
        }
    }

    const filterTasksByType = (type) => {
        return state?.tasks.find(task => task.type === type) ?? null
    }

    const filterSetupDismantles = (type) => {
        return state?.installDismantleSetupDismantles.filter(item => item.type === type)
    }
    useEffect(() => {
        if(state?.tasks?.length) {
            const installLaborTasks = filterTasksByType(TASK_TYPE.InstallationContractor);
            setInstallLaborData(installLaborTasks);

            const installItineraryTasks = filterTasksByType(TASK_TYPE.InstallItineraryReservations);
            setInstallItineraryData(installItineraryTasks);
        }
        // eslint-disable-next-line
    }, [state?.tasks]);

    useEffect(() => {
        if(state?.secondInstallationContractor && state?.secondInstallationContractorId){
            setIsSecondContractorOpen(true);
        }
        if(state?.secondInstallSupervisor && state?.secondInstallSupervisorId){
            setIsSecondSupervisorOpen(true);
        }
        // console.log(isSecondSupervisorOpen, isSecondContractorOpen);
    }, [state?.secondInstallationContractor, state?.secondInstallationContractorId, state?.secondInstallSupervisor, state?.secondInstallSupervisorId]);

    useEffect(() => {
        if(state?.installDismantleSetupDismantles?.length){
            const setupDismantleData = filterSetupDismantles(SETUP_DISMANTLE.setup);
            setSetupDismantles(setupDismantleData);
        }
        // eslint-disable-next-line
    }, [state?.installDismantleSetupDismantles]);

    const updateHandler = () => {
        console.log("handle update");
    }

    return (
        <BorderBox title="Installation" boxStyle="mb-3 mb-md-4">
            <h4 className="sub-title">Installation Contractor</h4>
            <ContractorContact
                id={state?.installationContractorContactId}
                contractorName={state?.installationContractor?.name}
                contractor={contractorDetails}
                setContractor={setContractorDetails}
                // onUpdate={refetch}
                onUpdate={updateHandler}
                dispatchCallback={dispatchCallback}
            />

            <div>
                <Collapse isOpen={isSecondContractorOpen}>
                    <h4 className="sub-title mt-4">Second Installation Contractor</h4>
                    <ContractorContact
                        id={state?.secondInstallationContractorContactId}
                        contractorName={state?.secondInstallationContractor?.name}
                        contractor={secondContractorDetails}
                        setContractor={setSecondContractorDetails}
                        // onUpdate={refetch}
                        onUpdate={updateHandler}
                        dispatchCallback={dispatchSecondCallback}
                    />
                </Collapse>
                <Button
                    color="link"
                    className={`${styles.textButton} mt-2`}
                    onClick={() => setIsSecondContractorOpen(true)}
                    disabled={isSecondContractorOpen}
                >+ Add Installation Contractor</Button>
            </div>            

            <Row className="mt-3 mt-md-4 align-items-center">
                <Col>
                    <LeadSupervisor
                        name={state?.installSupervisor?.fullName}
                        // onUpdate={refetch}
                        onUpdate={updateHandler}
                        dispatchCallback={dispatchCallback}
                        placeholder="Installation Supervisor"
                    />
                </Col>
                <Col>
                    <Button color="secondary" size="sm"
                            disabled={!state?.installSupervisor}
                            onClick={() => setShowItineraryModal(true)}
                    >
                        Itinerary
                    </Button>
                    {
                        showItineraryModal &&
                        <ItineraryModal
                            type="Install"
                            userId={state?.installSupervisorId}
                            userName={state?.installSupervisor?.fullName}
                            isOpen={showItineraryModal}
                            onCancel={() => setShowItineraryModal(false)}
                        />
                    }
                </Col>
            </Row>
            <div>
                <Collapse isOpen={isSecondSupervisorOpen}>
                    <Row className="mb-2 mt-3 mt-md-4 align-items-center">
                        <Col>
                            <LeadSupervisor
                                name={state?.secondInstallSupervisor?.fullName}
                                onUpdate={refetch}
                                dispatchCallback={dispatchSecondCallback}
                                placeholder="Installation Supervisor"
                            />
                        </Col>
                        <Col>
                            <Button color="secondary" size="sm"
                                    disabled={!state?.secondInstallSupervisor}
                                    onClick={() => setShowSecondItineraryModal(true)}
                            >
                                Itinerary
                            </Button>
                            {
                                showSecondItineraryModal &&
                                <ItineraryModal
                                    type="Install"
                                    userId={state?.secondInstallSupervisorId}
                                    userName={state?.secondInstallSupervisor?.fullName}
                                    isOpen={showSecondItineraryModal}
                                    onCancel={() => setShowSecondItineraryModal(false)}
                                />
                            }
                        </Col>
                    </Row>
                </Collapse>
                <Button
                    color="link"
                    className={`${styles.textButton} mt-2`}
                    onClick={() => setIsSecondSupervisorOpen(true)}
                    disabled={isSecondSupervisorOpen}
                >+ Add Installation Supervisor</Button>
            </div>

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Installer Itinerary Reservation</h4>
            <SetupDismantleLabor
                type={TASK_TYPE.InstallItineraryReservations}
                data={installItineraryData}
            />

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Install Labor</h4>
            <SetupDismantleLabor
                type={TASK_TYPE.InstallationContractor}
                data={installLaborData}
            />

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Setup</h4>
            <SetupDismantleTable
                type={SETUP_DISMANTLE.setup}
                data={setupDismantles}
            />
        </BorderBox>
    )
}

export default Install;
