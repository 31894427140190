import {useState} from "react";
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import './style/gridswatchselector.css';

const ColorSwatches = ({colors, selectedColor, onChange}) => {
    const handleClick = (color, e) => {
        onChange(color, e);
    }

    return (
        <div className="swatch-wrapper">
            {
                colors.map((color) => {
                    const highlight = color === selectedColor;

                    return (
                        <span key={`swatch_${color}`}
                                className={`swatch cursor-pointer ${color === 'transparent' ? 'blank' : ''} ${highlight ? 'highlight' : ''}`}
                                style={{ backgroundColor: color }}
                                data-color={color}
                                onClick={(e) => handleClick(color, e)}
                        />
                    )
                })
            }
        </div>
    )
}

const GridSwatchSelector = ({ taskId, rowId, icon, handleColor, colors, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [color, setColor] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let Icon = icon;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleColorChange = (color) => {
        handleColor(rowId, taskId, color);
        setColor(color);
    }

    return (
        <>
            <Tooltip placement="top" arrow title={title}>
                <IconButton aria-describedby={id} onClick={handleClick}>
                    <Icon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ColorSwatches
                    colors={colors}
                    selectedColor={color ?? 'transparent'}
                    onChange={handleColorChange}
                />
            </Popover>
        </>
    )
}

export default GridSwatchSelector;
