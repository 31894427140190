import React, { useEffect, useState } from "react";
import {Table, TableHead, TableBody, TableRow, MenuItem, Select, TableCell } from "@mui/material";
import "./style/dismantleDetails.css";
import DatePicker from "./DatePicker";
import { CustomSpan, CustomTableCell, CustomTableCellOne, CustomTextField} from "./customStyled/servicesRequestDetailsStyle";
import dayjs from "dayjs";
import { useGetInternalUsersQuery } from "../services/authService";
import { numFloat } from "../utils/common";
import debounce from 'lodash/debounce';

const ServiceRequestDetails = (props) => {
    const {
        submitRow,       
        rowData = [],
        showColor = false,
        validationMessage,
        category = "",
        setFurnishingsSaveData,
        setServicesSaveData,
        setFacilitySaveData
    } = props;

    const debounceTimeout = 100;
    const {data: employeesData, isFetching} = useGetInternalUsersQuery('Employee');
    const [employees, setEmployees] = useState([]);
    const [updatedRowData, setUpdatedRowData] = useState();
    const [tableData, setTableData] = useState([]);
    const statusData = [
        { value: "None"},
        { value: "Assigned"},
        { value: "Complete"},
        { value: "Cancelled"},
        { value: "ReadyToShip"},
        { value: "Shipped"},
        { value: "Delivered"},
        { value: "Ordered"},
        { value: "Verified"}
    ];
    const sxCellStyle = {
        backgroundColor: "white",
        
        "& .MuiStack-root": {
            paddingTop: 0,

            "& .MuiTextField-root": {
                minWidth: '105px !important' ,
                overflow: 'hidden !important',
                maxWidth: '140px !important'
            },
            "& .MuiOutlinedInput-input": {
                paddingLeft: '5px',
            }
        }
    };

    const debouncedSearch = debounce((value) => {
        console.log("Performing search with:", value);
        // Here you can perform any action that requires debouncing,
        // such as fetching data from an API
    }, debounceTimeout); // 1000 milliseconds debounce delay

    useEffect(() => {
        if (rowData) {
            setTableData(rowData);
        }
    }, [rowData]);

    useEffect(() => {
        if (employeesData) {
            const {users} = employeesData;
            setEmployees(users)           
        }
    }, [employeesData, isFetching]);


    const setDescription = (data, newDescription) => {
        if (newDescription != data.description) {
           // setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, description: newDescription }));
            
        }
    };
    const debouncedSetDescription = debounce(setDescription, debounceTimeout);

    const setColor = (data, newColor) => {
        if (newColor != data.color) {
            setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, color: newColor }));
           
        }            
    };
     const debouncedSetColor = debounce(setColor, debounceTimeout);

    const setEstimate = (data, newEstimate) => {
        if(isNaN(+newEstimate)){
            validationMessage("Estimate value must be a number.");
            return;
        }
        if (newEstimate != data.estimate) {
            setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, estimate: newEstimate }));
            
        }
            
    };
     const debouncedSetEstimate = debounce(setEstimate, debounceTimeout);

    const setActual = (data, newActual) => {
        if(isNaN(+newActual)){
            validationMessage("Actual value must be a number.");
            return;
        }
        if (newActual != data.actual) {
            setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, actual: newActual }));
           
        }
            
    };
     const debouncedSetActual = debounce(setActual, debounceTimeout);

    const setAssignedToId = (data, newAssignedToId) => {        
        if (newAssignedToId === ''){
            newAssignedToId = null;
        }
        
        setUpdatedRowData(updatedRowData => (
            {
                ...updatedRowData, 
                ...data, 
                task:{
                    ...data.task, 
                    assignedToId: newAssignedToId, 
                    jobId: data.jobId, 
                    status: ((data?.task?.assignedToId == null || data?.task?.assignedToId == undefined) ? "Assigned" : data?.task?.status), 
                    expenseId: data.expenseId
                }
            }
        ));


        const newData = tableData.map((item, i) => {
            if (item.expenseId === data.expenseId) {
                let task = {
                        ...data.task,
                        assignedToId: newAssignedToId,
                        jobId: data.jobId,
                        status: ((data?.task?.assignedToId == null || data?.task?.assignedToId == undefined) ? "Assigned" : data?.task?.status),
                        expenseId: data.expenseId
                    }
                return {
                    ...item,
                    task: task,
                };
            }
            return item;
        });
        
        setTableData(newData);

        storeFormatData(newData);

    };

    const setStatus = (data, newStatus) => {
        setUpdatedRowData(updatedRowData => (
            {
                ...updatedRowData, 
                ...data, 
                task:{
                    ...data.task, 
                    status: newStatus, 
                    jobId: data.jobId, 
                    expenseId: data.expenseId
                }
            }
        ));

        const newData = tableData.map((item, i) => {
            if (item.expenseId === data.expenseId) {
                let task = {
                    ...data.task,
                    status: newStatus,
                    jobId: data.jobId,
                    expenseId: data.expenseId
                }
                return {
                    ...item,
                    task: task,
                };
            }
            return item;
        });

        setTableData(newData);

        storeFormatData(newData);

    };

    const setDeadlineDate = (data, newDate) => {
        setUpdatedRowData(updatedRowData => (
            {
                ...updatedRowData, 
                ...data, 
                task: {
                    ...data.task, 
                    deadline: dayjs(newDate).format('YYYY-MM-DDT00:00:00'), 
                    jobId: data.jobId, 
                    expenseId: data.expenseId
                }
            }
        ));

        const newData = tableData.map((item, i) => {
            if (item.expenseId === data.expenseId) {
                let task = {
                    ...data.task,
                    deadline: dayjs(newDate).format('YYYY-MM-DDT00:00:00'),
                    jobId: data.jobId,
                    expenseId: data.expenseId
                }
                return {
                    ...item,
                    task: task,
                };
            }
            return item;
        });
        setTableData(newData)

        storeFormatData(newData);

    };
    

    const handleChange = async  (id, key, value) => {
        const updatedData = tableData.map(row =>
            row.expenseId === id ? { ...row, [key]: value } : row
        );
        setTableData(updatedData);

        storeFormatData(updatedData);
    };


    function storeFormatData(data) {

        if (category === "Furnishings") {
            setFurnishingsSaveData(data);
        } else if (category === "Services") {
            setServicesSaveData(data);
        } else if (category === "Facilities") {
            setFacilitySaveData(data);
        }

    }
    

    return (
        <div style={{ border: "1px  solid gray" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <CustomTableCell>Item</CustomTableCell>
                        <CustomTableCell>Description</CustomTableCell>
                        {showColor && (
                        <CustomTableCell>Color</CustomTableCell>
                        )}
                        <CustomTableCell>Estimate</CustomTableCell>
                        <CustomTableCell>Actual</CustomTableCell>
                        <CustomTableCell>Assigned To</CustomTableCell>
                        <CustomTableCell>Deadline</CustomTableCell>
                        <CustomTableCell>Status</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                {tableData?.map((val) => (
                        <TableRow key={val.expenseId}>
                            <CustomTableCellOne>
                                <CustomSpan>{val.label}</CustomSpan>  
                            </CustomTableCellOne>
                            <CustomTableCellOne>
                                <CustomTextField
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    defaultValue={val.description} 
                                    sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                                    onBlur={(e) => handleChange(val.expenseId, 'description', e.target.value)}
                                   //  onChange={(event) => { debouncedSetDescription(val, event.target.value); }}
                                   // onBlur={(event) => setDescription(val, event.target.value)}
                                />
                            </CustomTableCellOne>
                            {showColor && (
                                <CustomTableCellOne>
                                    <CustomTextField
                                        id="outlined-basic"
                                        label=""
                                        variant="outlined"
                                        defaultValue={val.color} 
                                        sx={{maxWidth:'100px',border: 'none',"& fieldset": { border: 'none' },}}
                                       // onChange={(event) => {debouncedSetColor(val, event.target.value);}}
                                        //onBlur={(event) => setColor(val, event.target.value)}
                                        onBlur={(e) => handleChange(val.expenseId, 'color', e.target.value)}
                                    />
                                </CustomTableCellOne>
                            )}
                            <CustomTableCellOne>
                                <CustomTextField
                                    id="outlined-basic"
                                    label=""
                                    variant="outlined"
                                    defaultValue={val.estimate != null ? numFloat(val.estimate) : ''} 
                                    sx={{maxWidth:'75px',border: 'none',"& fieldset": { border: 'none' },}}
                                    // onChange={(event) => {debouncedSetEstimate(val, event.target.value);}}
                                    //onBlur={(event) => setEstimate(val, numFloat(event.target.value))}
                                    onBlur={(e) => handleChange(val.expenseId, 'estimate', e.target.value)}
                                />
                            </CustomTableCellOne>
                            <CustomTableCellOne>
                                <CustomTextField
                                    id="outlined-basic"
                                    label=""
                                    variant="outlined"
                                    defaultValue={val.actual != null ? numFloat(val.actual) : ''} 
                                    sx={{maxWidth:'75px',border: 'none',"& fieldset": { border: 'none' },}}
                                   //  onChange={(event) => {debouncedSetActual(val, event.target.value);}}
                                    //onBlur={(event) => setActual(val, numFloat(event.target.value))}
                                    onBlur={(e) => handleChange(val.expenseId, 'actual', e.target.value)}
                                />
                            </CustomTableCellOne>
                            <CustomTableCellOne>
                                <Select
                                    key={`assignedTo-${val.id}`}
                                    fullWidth
                                    displayEmpty
                                    defaultValue={val?.task?.assignedToId ?? ''}
                                    style={{ height: '30px', fontSize: '8pt' }} 
                                    onChange={(event) => { setAssignedToId(val, event.target.value); }}
                                >
                                    <MenuItem value=''>None</MenuItem>
                                    {employees.map(({ id, fullName }, index) => (
                                        <MenuItem key={index} value={id}>
                                            {fullName}
                                        </MenuItem>
                                    ))}
                                </Select>                                 
                            </CustomTableCellOne>
                            <TableCell className="p-0" sx={sxCellStyle}>
                                    <DatePicker
                                        key={`deadline-${val.id}`}
                                        value={val.task?.deadline ? dayjs(val.task?.deadline) : ''}
                                        onDateChange={(newValue) => setDeadlineDate(val, newValue)}
                                    />                                
                            </TableCell>
                            <CustomTableCellOne>
                                <Select
                                    key={`statuses-${val.id}`}
                                    fullWidth
                                    displayEmpty
                                    value={val?.task?.status ?? ''}
                                    defaultValue={val?.task?.status ?? ''}
                                    style={{ height: '30px', fontSize: '8pt' }} 
                                    onChange={(event) => {setStatus(val, event.target.value); }}
                                >
                                    <MenuItem value=''></MenuItem>
                                    {statusData.map(({ value }, index) => (
                                        <MenuItem key={index} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </CustomTableCellOne>
                        </TableRow>
                ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default ServiceRequestDetails;
