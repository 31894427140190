import { Container } from 'reactstrap';
import  NavMenu  from './header/NavMenu';
import Footer from "./Footer";
import { Outlet } from 'react-router-dom';

const Layout = ({children}) => {
    return (
      <>
        <NavMenu />
        <Container tag="main" className="flex-grow-1 py-3 d-flex flex-column">
          { children }
          <Outlet />
        </Container>
        <Footer />
      </>
    );
}

export default Layout;