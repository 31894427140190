const sxTableTextFieldStyle = {
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        height: 20,
    },
    "& .MuiInputBase-root": {
        backgroundColor: 'transparent',
    },
    "& .MuiInputBase-input": {
        padding: 0,
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    }
}

export default sxTableTextFieldStyle;
