import {useEffect, useState} from "react";
import { useSnackbar } from 'notistack';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {useDeleteShowNameMutation} from "../../../services/authService";
import ConfirmDialog from "../../ConfirmDialog";
import messages from "../../../utils/messages";
import styles from "./DeleteShowName.module.scss";

const DeleteShowName = ({ showName, setShowName, onDelete }) => {
    const [deleteShowName, { isSuccess, error, reset }] = useDeleteShowNameMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleDelete = () => {
        deleteShowName(showName.id);
    }

    const handleMessages = () => {
        if(isSuccess){
            enqueueSnackbar(`${messages.success.deleteShowName}`, { variant: 'success' });
            reset();
            onDelete();
            setShowName('');
        }
        if(error){
            enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isSuccess || error){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isSuccess, error]);

    return (
        <>
            <DeleteOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <ConfirmDialog
                title="Delete show name"
                open={open}
                setOpen={setOpen}
                onConfirm={handleDelete}
            >
                {messages.confirm.deleteShowName}
            </ConfirmDialog>
        </>
    )
}

export default DeleteShowName;