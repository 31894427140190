import { DialogTitle } from "@mui/material";
import { CustomDialog } from "../../exhibit/ExhibitCustomStyle";
import InventoryItemForm from "./InventoryItemForm";

function AddInventoryItem(props) {
    const { addDialogOpen, closeAddDialog, onUpdated, clientId, dialogTitle } = props;

  return (
      <CustomDialog open={addDialogOpen}
      onClose={closeAddDialog}
      maxWidth="sm" 
      fullWidth
      slotProps={{ backdrop: { onClick: closeAddDialog } }}>
          <DialogTitle>Add {dialogTitle} Inventory Item</DialogTitle>
        <InventoryItemForm  closeDialog={closeAddDialog} onUpdated={onUpdated} clientId={clientId} />
      </CustomDialog>
  )
}

export default AddInventoryItem;
