import {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './EditShowName.module.scss';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShowNameForm from "./ShowNameForm";

const EditShowName = ({ showName, setShowName, onUpdate, setNewShowName }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <EditOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit show name
                </DialogTitle>
                <ShowNameForm
                    showName={showName}
                    setShowName={setShowName}
                    onCancel={handleClose}
                    onUpdate={onUpdate}
                    setNewShowName={setNewShowName}
                />
            </Dialog>
        </>
    );
}

export default EditShowName;
