import {useEffect, useState} from "react";
import {Paper, TextField} from "@mui/material";
import EditShowName from "./showName/EditShowName";
import {useGetShowNamesQuery} from "../../services/authService";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import AddShowName from "./showName/AddShowName";
import DeleteShowName from "./showName/DeleteShowName";
import {DISPATCH_TYPES} from "../../utils/constants";

const ShowName = ({name, onUpdate, dispatchCallback}) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const {data, isFetching } = useGetShowNamesQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        if(value){
            dispatchCallback(DISPATCH_TYPES.showName, value.id);
            setSelectedOption(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.showName, null);
            setOpen(true);
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            setOptions(data?.showNames);
            let selOption = data?.showNames?.find(option => option.name === filter);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.showName, selOption?.id);
        }
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const AddNewShowNameRow = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Show Name
            </div>
        </Paper>
    );

    const handleOpen = () => {
        if(!filter && !selectedOption){
            setSelectedOption(null);
            setFilter('');
        }       
        setOpen(true);
    }

    const setNewShowName = (name) => {
        setFilter(name);
    }

    return (
        <div className="d-flex align-items-center">
            <CustomAutocomplete
                id="show-name-list"
                className="flex-grow-1"
                sx={sxAutocomplete}
                open={open}
                onOpen={() => handleOpen()}
                onClose={() => setOpen(false)}
                loading={isFetching}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options || []}
                value={selectedOption}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.name || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.name || '' : '')}
                // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                noOptionsText="Show name not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">Show Name</label>
                       }
                       variant="outlined"
                    />
                )}
                PaperComponent={AddNewShowNameRow}
            />

            <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                <EditShowName
                    showName={selectedOption}
                    setShowName={setSelectedOption}
                    onUpdate={onUpdate}
                    setNewShowName={setNewShowName}
                />

                <DeleteShowName
                    showName={selectedOption}
                    setShowName={setSelectedOption}
                    onDelete={onUpdate}
                />
            </div>

            <AddShowName
                name={name}
                isOpen={addDialogOpen}
                onAdd={onUpdate}
                onCancel={closeAddDialog}
                setNewShowName={setNewShowName}
            />

        </div>
    )
}

export default ShowName;
