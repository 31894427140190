import React, {useEffect, useState} from 'react'
import {useGetHotelsQuery} from "../../services/authService";
import {DISPATCH_TYPES} from "../../utils/constants";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {Paper, TextField} from "@mui/material";
import EditHotel from "./hotel/EditHotel";
import DeleteHotel from "./hotel/DeleteHotel";
import AddHotel from "./hotel/AddHotel";

const Hotel = ({name, onUpdate, dispatchCallback, size, label = 'Hotel', containerStyle}) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const {data, isFetching } = useGetHotelsQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        console.log('handleChange', value);
        if(value){
            dispatchCallback(DISPATCH_TYPES.hotel, value);
            setSelectedOption(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.hotel, null);
        }
    };

    const handleInputChange = (event, value) => {
        console.log('handleInputChange', value);
        if(value === '') {
            setSelectedOption(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            console.log('useEffect', filter, data?.hotels);
            setOptions(data?.hotels);
            let selOption = data?.hotels?.find(option => option.name === filter);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.hotel, selOption);
        }
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const updateHandler = () => {
        // console.log("updateHandler");
    }
    const setHotel = (name) => {
        setFilter(name);
    }
    const AddNewHotel = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Hotel
            </div>
        </Paper>
    );

    return (
        <>
            <div className={`d-flex align-items-center ${containerStyle}`}>
                <CustomAutocomplete
                    fullWidth
                    size={size ?? "medium"}
                    // id="hotel-list"
                    className="flex-grow-1"
                    sx={sxAutocomplete}
                    loading={isFetching}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    options={options || []}
                    value={selectedOption}
                    filterOptions={(x) => x}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <span className="autocomplete-option-label">{option.name || ''}</span>
                        </li>
                    )}
                    getOptionLabel={(option) => (option ? option.name || '' : '')}
                    // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                    noOptionsText="Hotel not found"
                    renderInput={(params) => (
                        <TextField {...params}
                           label={
                               <label className="autocomplete-input-label">{label}</label>
                           }
                           variant="outlined"
                        />
                    )}
                    PaperComponent={AddNewHotel}
                />

                <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                    <EditHotel
                        hotel={selectedOption}
                        setHotel={setHotel}
                        onUpdate={updateHandler}
                    />

                    <DeleteHotel
                        hotel={selectedOption}
                        setHotel={setHotel}
                        onDelete={updateHandler}
                    />
                </div>

                <AddHotel
                    isOpen={addDialogOpen}
                    onAdd={updateHandler}
                    onCancel={closeAddDialog}
                    setHotel={setHotel}
                />

            </div>

            {selectedOption?.addressInfo ? (
                <div className="bg-white p-2 mt-1 common-text">
                    {selectedOption.addressInfo}
                </div>
            ) : (
                <div className="bg-white p-3 mt-2 common-text">
                    <div>{ selectedOption ? 'No hotel address found' : 'No hotel selected' }</div>
                </div>
            )}
        </>
    )
}

export default Hotel
