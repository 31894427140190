import {
  Dialog
} from "@mui/material";
import ReturnItemForm from "./ReturnItemForm";

function AddReturnItem(props) {
  const {isOpen, onClose} = props;

  return (
    <Dialog open={isOpen}
      onClose={onClose}>
      <ReturnItemForm onClose={onClose}/>
    </Dialog>
  )
}
export default AddReturnItem;
