import {useState} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {Dialog} from "@mui/material";
import CarrierForm from "./CarrierForm";
import { useGetFreightCarrierDetailQuery } from "../../services/authService";
import useAuthentication from "../../hooks/useAuthentication";

function EditCarrier(props) {
  const {selectedCarrier} = props;
  const [isEditOpen, setEditOpen] = useState(false);
  const { data, isFetching, isError } =  useGetFreightCarrierDetailQuery(selectedCarrier && selectedCarrier.name);
  useAuthentication([isError]);
  const carrier = data  ? data.freightCarriers[0]: {};  
  function openEditCarrierHandler() {
    setEditOpen(true);
  }
  function closeEditCarrierHandler() {
    setEditOpen(false);
  }

  return (
    <>
      <EditOutlinedIcon fontSize="small"
        style={
          {
            color: "grey",
            cursor: "pointer"
          }
        }
        onClick={openEditCarrierHandler}/>
      <Dialog open={isEditOpen}
        onClose={closeEditCarrierHandler}
        maxWidth="sm" 
        fullWidth
        slotProps={
          {
            backdrop: {
              onClick: closeEditCarrierHandler
            }
          }
      }>
        <CarrierForm 
          onClose={closeEditCarrierHandler}
          carrier={carrier}
          />
      </Dialog>
    </>
  )
}

export default EditCarrier;
