import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from "../ConfirmDialog";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetJobShipmentsQuery, useRemoveShipmentMutation } from '../../services/authService';
import { setShipment } from "../../redux/reducers/shipmentSlice";
import Error from "../../pages/Error";
import { useParams } from 'react-router-dom';

function DeleteShipment(props) {
    const { setShipmentId, selectedShipment } = props;
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState(null); // State variable for error message  
    const { id } = useParams();
    const [removeShipment] = useRemoveShipmentMutation();
    const { data: jobShipments, isFetching, isError, refetch: refetchShipments } = useGetJobShipmentsQuery(id && id);
   

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  const handleDelete = async () => {
      const shipmentId = selectedShipment;
      const response = await removeShipment(shipmentId);
      if (response.data) {
          const refetch = await refetchShipments();
         // window.console.log("===jobShipments====", refetch);
          dispatch(setShipment(refetch.data.shipments));
          setShipmentId(refetch?.data?.shipments[0]?.id ?? null);
      } else if (response.error) {
          const messages = response.error.data.title;
          setError(messages ? messages : 'Error in delete shipment');
      }
  };

  return (
    <>
           <DeleteOutlinedIcon fontSize="small"
              style={
                  {
                      color: "grey",
                      cursor: "pointer",
                      marginLeft:"5px"
                  }
              }
              onClick={openDeleteModalHandler} />
          <ConfirmDialog title="Remove Shipment"
              open={isDeleteModalOpen}
              setOpen={setDeleteModalOpen}
              onConfirm={handleDelete}>
              Are you sure you want to remove this shipment?
          </ConfirmDialog>
          {isError && <Error >{error}</Error>}
    </>
  );
}

export default DeleteShipment;
