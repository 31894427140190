import styles from './BorderBox.module.scss';

const BorderBox = ({title, children, boxStyle, contentStyle}) => {
    return (
        <div className={`${boxStyle ?? ''}`}>
            { title && <h3 className={`lh-sm ${styles.title}`}>{title}</h3> }
            <div className={`border border-1 border-secondary rounded-1 p-3 ${contentStyle ?? ''}`}>
                {children}
            </div>
        </div>
    )
}

export default BorderBox;
