const messages = {
    success: {
        // job
        updateJobDetails: 'Job details updated successfully',
        updateJobStatus: 'Job status updated successfully',
        printShowInfo: 'Job details downloaded successfully',

        // show name
        createShowName: 'Show name created successfully',
        updateShowName: 'Show name updated successfully',
        deleteShowName: 'Show name deleted successfully',

        // facility
        createFacility: 'Facility created successfully',
        updateFacility: 'Facility updated successfully',
        deleteFacility: 'Facility deleted successfully',

        // contractor
        createContractor: 'Contractor created successfully',
        updateContractor: 'Contractor updated successfully',
        deleteContractor: 'Contractor deleted successfully',

        // contact
        createContact: 'Contact created successfully',
        updateContact: 'Contact updated successfully',
        deleteContact: 'Contact deleted successfully',

        // car rental
        createCarRental: 'Car Rental created successfully',
        updateCarRental: 'Car Rental updated successfully',
        deleteCarRental: 'Car Rental deleted successfully',

        // flight carrier
        createFlightCarrier: 'Flight Carrier created successfully',
        updateFlightCarrier: 'Flight Carrier updated successfully',
        deleteFlightCarrier: 'Flight Carrier deleted successfully',

        // hotel
        createHotel: 'Hotel created successfully',
        updateHotel: 'Hotel updated successfully',
        deleteHotel: 'Hotel deleted successfully',

        // lead supervisor
        createLeadSupervisor: 'Lead Supervisor created successfully',
        updateLeadSupervisor: 'Lead Supervisor updated successfully',
        deleteLeadSupervisor: 'Lead Supervisor deleted successfully',

        // setup dismantle
        createSetup: 'Setup created successfully',
        updateSetup: 'Setup updated successfully',
        deleteSetup: 'Setup deleted successfully',
        createDismantle: 'Dismantle created successfully',
        updateDismantle: 'Dismantle updated successfully',
        deleteDismantle: 'Dismantle deleted successfully',

        // services request
        updateServicesRequest: 'Services Request updated successfully',
        
        // expenses
        createExpense: 'Expense created successfully',
        updateExpense: 'Expense updated successfully',
        deleteExpense: 'Expense deleted successfully',

        // travel expenses
        createTravelExpense: 'Travel expense created successfully',
        updateTravelExpense: 'Travel expense updated successfully',
        deleteTravelExpense: 'Travel expense deleted successfully',

        // return inventory
        updateReturnInventory: 'Return Inventory Updated Successfully',

        // exhibit giveaway
        updateItemToExhibit: 'Item added successfully',
        removeItemFromExhibit: 'Item removed successfully',
        createExhibit: 'Exhibit added successfully',
        updateExhibit: 'Exhibit updated successfully',
        deleteExhibit: 'Exhibit deleted successfully',

        // consumables
        createExhibitConsumable: 'Consumable added successfully',
        removeExhibitConsumable: 'Consumable removed successfully',

        // install dismantle
        updateInstallDismantle: 'Install Dismantle updated successfully',

        // itinerary
        updateItinerary: 'Itinerary updated successfully',

        // shipments
        createShipment: "Shipment updated successfully",
        printShipment: "Shipment downloaded successfully",
        
        // shipment items
        removeShipmentItem: "Item removed successfully",
        createShipmentItem: "Item addded successfully",
        updateShipmentItem: "Item updated successfully",

        // pro value
        createProValue: "Pro number created successfully",
        updateProValue: "Pro number updated successfully",
        deleteProValue: "Pro number deleted successfully",

         // client Branch
         createBranch: 'Branch created successfully',
         updateBranch: 'Branch updated successfully',
         deleteBranch: 'Branch deleted successfully',

         // client name
        createClientName: 'Client name created successfully',
        updateClientName: 'Client name updated successfully',
        deleteClientName: 'Client name deleted successfully',

        // client consumables
        createClientConsumable: 'Client consumable created successfully',
        updateClientConsumable: 'Client consumable updated successfully',
        deleteClientConsumable: 'Client consumable deleted successfully',
        printClientConsumable: "Consumables downloaded successfully",


        // client inventories
        createClientInventory: 'Inventory item created successfully',
        updateClientInventory: 'Inventory item updated successfully',
        deleteClientInventory: 'Inventory item deleted successfully',

        // client job
        createClientJob: 'Client job created successfully'
    },
    error: {
        // General
        prefix: 'Error:',
        validationError: 'Validation Error:',
        generic: 'An error occurred, please try again.',
        genericDelete: 'Unable to remove item',

        // dashboard
        updateColor: 'An error occurred while updating the color',
        getPersonalTaskList: 'An error occurred while getting the personal task list',
        getJobCheckList: 'An error occurred while getting the job checklist',
        unauthorizedAccess: 'Your session has expired, please log in again to continue working' 
    },
    confirm: {
        // show name
        deleteShowName: 'Are you sure you want to delete the show?',

        // facility
        deleteFacility: 'Are you sure you want to delete the facility?',

        // contractor
        deleteContractor: 'Are you sure you want to delete the contractor?',

        // contact
        deleteContact: 'Are you sure you want to delete the contact?',

        // car rental
        deleteCarRental: 'Are you sure you want to delete the car rental?',

        // flight carrier
        deleteFlightCarrier: 'Are you sure you want to delete the flight carrier?',

        // hotel
        deleteHotel: 'Are you sure you want to delete the hotel?',

        // lead supervisor
        deleteLeadSupervisor: 'Are you sure you want to delete the lead supervisor?',

        // setup dismantle
        deleteSetup: 'Are you sure you want to delete the setup?',
        deleteDismantle: 'Are you sure you want to delete the dismantle?',

        // travel expense
        deleteTravelExpense: 'Are you sure you want to delete the travel expense?',
        
        // client name
        deleteClientName: 'Are you sure you want to delete the client?',

        // client Consumable
        deleteClientConsumable: 'Are you sure you want to delete the consumable?',

        deleteProValue: 'Are you sure you want to delete Pro number?',
    },
    info: {

    }
}

export default messages;
