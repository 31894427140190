import {Container} from "reactstrap";
import classes from './Exhibit.module.scss';
// import SaveJobExhibit from './SaveJobExhibit';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ItemList from "../invertoryItems/ItemList";
import ConsumableItemList from "../consumableItems/ConsumableItemList";
import ExhibitDetail from "./ExhibitDetail";
import BorderBox from "../ui/BorderBox";
import ReturnItemList from "../returnItems/ReturnItemList";
import {Col, Row} from "reactstrap";
import LabelInfo from "../../components/ui/LabelInfo";
import { useGetExhibitGiveAwayQuery } from "../../services/authService";
import {setExhibitGiveAway} from "../../redux/reducers/exhibit/exhibitSlice";
import useAuthentication from "../../hooks/useAuthentication";

function Exhibit() {
  const dispatch = useDispatch();
    const [selectedExhibit, setSelectedExhibit] = useState(null);
    const [returnConsumables, setReturnConsumables] = useState(null);
  const {jobExhibit, loading, error} = useSelector((state) => {
    return state.jobExhibitData;
  });
  const {id} = useParams();  
  const {data: getExhibitGiveAway, isFetching: isfetchingExhibitGiveaway, error: exhibitGiveAwayError, refetch: refetchGetExhibitGiveAway} = useGetExhibitGiveAwayQuery(id && id);
  useAuthentication([exhibitGiveAwayError]);
  
  useEffect(() => {
    const fetchExhibitGiveAway = async () => {
      const result = await refetchGetExhibitGiveAway();
      if (result.data) {
        dispatch(setExhibitGiveAway(result.data.exhibitGiveAway));
      }
    };
    if (getExhibitGiveAway) {
      fetchExhibitGiveAway();
    }
  }, [getExhibitGiveAway, refetchGetExhibitGiveAway, dispatch]);
  
  useEffect(() => {
      if (jobExhibit) {
      const newSelectedExhibit = {
        name: jobExhibit?.exhibitGiveAway?.exhibit?.name,
        id: jobExhibit?.exhibitGiveAway?.exhibit?.id,
      };
          setSelectedExhibit(newSelectedExhibit);

          setReturnConsumables(jobExhibit?.exhibitGiveAway?.consumables);
    }
  }, [jobExhibit]);
  
  return (
    <Container className={`${ classes.container } py-3 p-md-4`} style={{maxWidth: "sm"}}>
      {
      jobExhibit ? (
        <>        
          <Row className="py-3 py-md-4">
            <Col>
              <LabelInfo label="CMC Job Number"
                info={id}/>
            </Col>
            <Col>
              <LabelInfo label="Status"
                info={jobExhibit?.exhibitGiveAway?.job?.status}/>
            </Col>
          </Row>

          <BorderBox title="Exhibit" boxStyle="mb-3">
            <ExhibitDetail selectedExhibit={selectedExhibit}
              // Pass down selectedExhibit state
              setSelectedExhibit={setSelectedExhibit}
              jobExhibit={jobExhibit}
              refetchGetExhibitGiveAway={refetchGetExhibitGiveAway}
            />
            <ItemList showAddItem={false}
              items={
                jobExhibit?.exhibitGiveAway?.exhibit?.items
              }/>
          </BorderBox>
          {/* { jobExhibit?.exhibitGiveAway?.exhibit?.id &&  */}

            <BorderBox title="Consumables" boxStyle="mb-3">
              <ConsumableItemList showAddItem={true} showAction={false}
                items={
                  jobExhibit?.exhibitGiveAway?.consumables
                }/>
                      </BorderBox>

            {
                returnConsumables &&
                <BorderBox title="Return Inventory" boxStyle="mb-3">
                    <ReturnItemList showAddItem={true}
                        items={
                            returnConsumables
                        } />
                </BorderBox>
            }
            

          {/* } */}
        </>
      ) : (
        <p>Loading...</p>
      )
    } </Container>
  )
}

export default Exhibit;
