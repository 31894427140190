import { DialogTitle } from "@mui/material";
import { CustomDialog } from "../../exhibit/ExhibitCustomStyle";
import InventoryItemForm from "./InventoryItemForm";

function EditInventoryItem(props) {
    const { open, onClose, item, clientId, onUpdated, dialogTitle } = props;
  return (
      <CustomDialog open={open}
      onClose={onClose}
      maxWidth="sm" 
      fullWidth
      slotProps={{ backdrop: { onClick: onClose } }}>
          <DialogTitle>Edit {dialogTitle} Inventory</DialogTitle>
        <InventoryItemForm closeDialog={onClose} item={item} clientId={clientId} onUpdated={onUpdated}/>
      </CustomDialog>
  )
}

export default EditInventoryItem;
