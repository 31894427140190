import { DialogTitle } from "@mui/material";
import { CustomDialog } from "../../exhibit/ExhibitCustomStyle";
import ExhibitForm from "./ExhibitForm";

function AddExhibit(props) {
  const { addDialogOpen, closeAddDialog, onExhibitAdded, clientId } = props;

  return (
      <CustomDialog open={addDialogOpen}
      onClose={closeAddDialog}
      maxWidth="sm" 
      fullWidth
      slotProps={{ backdrop: { onClick: closeAddDialog } }}>
        <DialogTitle>Add Exhibit</DialogTitle>
        <ExhibitForm closeDialog={closeAddDialog} onExhibitAdded={onExhibitAdded} clientId={clientId} />
      </CustomDialog>
  )
}

export default AddExhibit;
