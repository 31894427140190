import {useEffect} from "react";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from 'notistack';
import * as Yup from "yup";
import BorderBox from "../../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import DialogActions from "@mui/material/DialogActions";
import {useFormik} from "formik";
import {useCreateClientNameMutation, useUpdateClientNameMutation} from "../../../services/authService";
import messages from "../../../utils/messages";

const clientNameSchema = Yup.object({
    name: Yup.string().required('Client Name is required'),
});

const ClientNameForm = ({ clientName, setClientName, onCancel, onUpdate, onAdd, storedClientId, setCreatedClientId, setFilter }) => {
    const [createClientName, { data:createData, isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateClientNameMutation();
    const [updateClientName, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateClientNameMutation();
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            name: clientName ? clientName.name : '',
        },
        validationSchema: clientNameSchema,
        onSubmit: async (values) => {
            console.log(JSON.stringify(values, null, 2));
            if(clientName){ // edit
                await updateClientName({
                    id: clientName.id,
                    ...values
                });
            } else { // add
                await createClientName(values);                
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            setClientName(null);
            setFilter('');  
            enqueueSnackbar(`${messages.success.createClientName}`, { variant: 'success' });
            setCreatedClientId(createData);
            createReset();    
            formik.resetForm();
            onCancel();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateClientName}`, { variant: 'success' })
            setClientName({
                id: clientName.id,
                ...formik.values
            });
            updateReset();
            onUpdate();
            onCancel();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox>
                    <CustomTextField
                        fullWidth
                        label="Client Name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ClientNameForm;