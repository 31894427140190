import {useSelector} from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styles from "./ShipmentDetail.module.scss";
import {useEffect} from "react";
import messages from "../../utils/messages";
import {useSnackbar} from "notistack";
import {useCreateProNumberMutation, useUpdateProNumberMutation } from "../../services/authService";

const EditProNumber = ({ row, onUpdate }) => {
  const shipment = useSelector((state) => state.shipmentData.shipment?.shipment);

  const [createProNumber, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateProNumberMutation();
  const [updateProNumber, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateProNumberMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdate = () => {
    const data = {
      id: row?.id,
      proNumber: row?.proNumber,
      shipmentId: shipment?.id
    }        
    if(row?.isNew) {
      createProNumber(data);
    } else {
      updateProNumber(data);
    }
  }

  const handleMessages = () => {
    if(isCreateSuccess) {
      enqueueSnackbar(`${messages.success.createProValue}`, {variant: 'success' });
      createReset();
      onUpdate();
    }
    if(isUpdateSuccess) {
      enqueueSnackbar( `${messages.success.updateProValue}`, { variant: 'success' });
      updateReset();
      onUpdate();
    }
    if(isCreateError) {
      enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
    if(isUpdateError){
        enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
  }

  useEffect(() => {
    if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
      handleMessages();
    }
  }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

  return (
    <AddCircleOutlineIcon
      fontSize="small"
      onClick={handleUpdate}
      className={`${styles.icon}`}
    />
  )
}

export default EditProNumber;