import React from "react";
import {FormControl} from "@mui/base";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {jobStatus} from "../../pages/dashboard/constants";
import {StyledFormLabel, sxRadio} from "../../pages/dashboard/styles/taskListStyle";

const StatusToggle = ({ status, onChange }) => {
    return (
        <FormControl component="fieldset" className="mt-3">
            <StyledFormLabel>Job Status</StyledFormLabel>
            <RadioGroup
                row
                aria-label="job status"
                name="jobStatus"
                value={status}
                onChange={onChange}
            >
                {jobStatus.map((val, index) => (
                    <FormControlLabel
                        value={val.value}
                        control={<Radio size="small" sx={{ sxRadio }} />}
                        label={
                            <span className="common-text">{val.label}</span>
                        }
                        key={index}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default StatusToggle;
