// reducers/exhibit/exhibitSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  carriers: [],
  loading: false,
  error: null,
};
const carrierSlice = createSlice({
  name: 'carriers',
  initialState: initialState,
  reducers: {
    addCarrier: (state, action) => {
      // Add the new carrier to the state
      state.carriers.push(action.payload);
    },
    setCarriers: (state, action) => {
      state.carriers = action.payload;
    },
    setCarriersFetching: (state, action) => {
      state.loading = action.payload;
    },
    setCarriersError: (state, action) => {
      state.error = action.payload;
    },
    resetCarriers: () => initialState,
    // Add other actions for updating and managing carriers as needed
  },
});

export const { addCarrier,setCarriers, setCarriersFetching, setCarriersError, resetCarriers} = carrierSlice.actions;
export default carrierSlice.reducer;