export const rowColorObject = {
    bgColor0: '#EFBFFF',
    bgColor1: '#CEBFFF',
    bgColor2: '#BFCEFF',
    bgColor3: '#BFEFFF',
    bgColor4: '#BFFFDF',
    bgColor5: '#CFFFBF',
    bgColor6: '#DBEBAB',
    bgColor7: '#FFEFBF',
    bgColor8: '#FFCFBF',
    bgColor9: '#FFDFBF',
    bgColor10: '#CFC2CB',
    bgColor11: '#FFFF00',
    bgColor12: '#73766F',
    bgColor13: '#00B050',
    bgColor14: '#0070C0',
    bgColor15: '#D24BFF',
    bgColor16: '#F92D2D',
    bgColor17: '#FF8811',
    bgColor18: '#800020',
    bgColor19: 'transparent'
}
export const textColorObject = {
    textColor0: '#D24BFF',
    textColor1: '#784EFF',
    textColor2: '#4772FF',
    textColor3: '#23ADDB',
    textColor4: '#1EC270',
    textColor5: '#F92D2D',
    textColor6: '#F9602D',
    textColor7: '#FF8811',
    textColor8: '#DC143C',
    textColor9: '#0047AB',
    textColor10: '#800020',
    textColor11: '#FFFF00',
    textColor12: '#00B050',
    textColor13: 'transparent',
}
export const rowColors = Object.values(rowColorObject);
export const textColors = Object.values(textColorObject);

export const jobStatus = [
    {
        id: 1,
        value: "Open",
        label: "Open",
    },
    {
        id: 2,
        value: "Complete",
        label: "Complete",
    },
    {
        id: 3,
        value: "Cancelled",
        label: "Cancelled",
    },
];
export const jobTypeId = [
    {
        id: 1,
        value: "pertlist",
        label: "Personal Task list",
    },
    {
        id: 2,
        value: "Jobtlist",
        label: "Job CheckList",
    },
]