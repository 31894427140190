import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import sxTableStyles from "../../style/sxTableStyles";
import {CustomTableCellOne, CustomTextField} from "../../style/mui/customStyle";
import { useState, useEffect } from "react";
import AddProNumber from "./AddProNumber";
import DeleteProValue from "./DeleteProValue";
import EditProNumber from "./EditProNumber";
import { useSelector } from "react-redux";
import { useGetProNumbersQuery } from "../../services/authService";

function ProGrid(props) {
  const {} = props;
  const [rows, setRows] = useState([]);
  const [addDisabled, setAddDisabled] = useState(false);
  const shipmentProNumberMappings = useSelector((state) => state.shipmentData.shipment?.shipment?.shipmentProNumberMappings);
  const shipment = useSelector((state) => state.shipmentData.shipment?.shipment);
  
  const{data: proNumbers, isFetching, isError , refetch: refetchProNumbers} = useGetProNumbersQuery(shipment && shipment.id);
  
  const handleProNumber = (id, event) => {
    const value = event.target.value;
    setRows((rows) => {
      return rows.map(row => ((row.id === id) ?
        {
            ...row,
            proNumber: value,
            isUpdated: true
        }
        : row))
    });
  }

  const removeNewRow = () =>{
    setAddDisabled(false);
    setRows((rows) => {
      return rows.filter(row => (row.isNew !== true));          
    });
  }

  const handleProNumberUpdated = async () => {
    const response = await refetchProNumbers();
    setRows(response.data?.shipmentProNumbers);
    setAddDisabled(false);
  };

  useEffect(() => {
    if(shipmentProNumberMappings){
        setRows(shipmentProNumberMappings);
    }
  }, [shipmentProNumberMappings]);

  useEffect(() => {
    if(rows){
      const disabledFlag = rows?.some((row) => row.isNew)
      setAddDisabled(disabledFlag)
    }
  }, [rows]);

  return (
    <div style={{width:'70%'}}>
    <TableContainer component={Paper}
      className="rounded-0 shadow-none">
      <Table sx={sxTableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>Pro#</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows && rows.length > 0 ? rows.map(row => {
          return <TableRow key={row?.id}>
            <CustomTableCellOne>
              <CustomTextField variant="standard" name='proNumber' value={row?.proNumber}  onChange={(newValue) => handleProNumber(row.id, newValue)} />
            </CustomTableCellOne>
            <CustomTableCellOne>
            {
              row.isUpdated ?
                <EditProNumber row={row} onUpdate={handleProNumberUpdated} />
              :
              <DeleteProValue id={row.id} removeNewRow={removeNewRow} onDelete={handleProNumberUpdated} />
            }
            </CustomTableCellOne>
          </TableRow>
          }) :
          <TableRow>
            <TableCell colSpan={2} style={{padding:'0px'}}>
              No Pro Number
            </TableCell>
          </TableRow>}
        </TableBody>        
      </Table>
    </TableContainer>
    <AddProNumber  setRows={setRows} disabled={addDisabled} />
    </div>
  )
}

export default ProGrid;