import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
import {Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";
import DatePicker from "../DatePicker";
import InternalUser from "../lookups/InternalUser";
import TaskStatus from "../lookups/TaskStatus";
import sxTableStyles from "../../style/sxTableStyles";
import {formatDateTimeDayjs} from "../../utils/common";
import {FORMATS, TASK_TYPE, TASK_TYPE_SAVE, USER_TYPES} from "../../utils/constants";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {setInstallDismantleInfo} from "../../redux/reducers/installDismantle/installDismantleSlice";
//import { CustomDateTimePicker } from "../../style/mui/customStyle";
import {CustomDatePicker} from '../../style/mui/customStyle';

const sxCellStyle = {
    "& .MuiStack-root": {
        padding: 0,
        "& .MuiTextField-root": {
            width: '100%',
            minWidth: 100
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 0,
        },
    },
    "& .MuiAutocomplete-root": {
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: '0 55px 0 0',
        },
        "& .MuiInputBase-input": {
            padding: 0,
        },
        "& .MuiAutocomplete-input": {
            padding: 0,
            border: 0,
        },
        "& .MuiAutocomplete-clearIndicator": {
            padding: 0,
        },
        "& .MuiAutocomplete-popupIndicator": {
            padding: 0,
        }
    },
    "& .MuiInputBase-root.MuiInputBase-sizeSmall": {
        padding: 0,
        "& .MuiSelect-select": {
            paddingLeft: 0,
            paddingTop: '4px',
            paddingBottom: 0,
            paddingRight: '24px'
        },
        "& .MuiSelect-icon": {
            right: 0,
        }
    },
}

const SetupDismantleLabor = ({type, data, containerStyle}) => {
    const dispatch = useDispatch();
    const [state] = useInstallDismantle();
    const [row, setRow] = useState(null);

    // const handleRowUpdate = (update) => {
    //     console.log('rowUpdate -- ', update);
    //     setRow((row) => ({...row, ...update}));
    // }

    const handleRowUpdate = (update) => {
        setRow((row) => {
          let newRow = { ...row };

          Object.keys(update).forEach((key) => {
            const value = update[key];    
            if (value !== undefined && value !== null && value !== '') {
              newRow[key] = value;
            } else {
              delete newRow[key];
            }
              
          });
          
          if (update["assignedToId"] != null && (newRow["status"] === undefined || newRow["status"] === "None" || newRow["status"] === "")) {
                newRow["status"] = "Assigned";
          }            
          
          return newRow;
        });
    };      
      
    const getParam = () => {
        switch (type) {
            case TASK_TYPE.InstallationContractor:
                return TASK_TYPE_SAVE.installationContractor
            case TASK_TYPE.InstallItineraryReservations:
                return TASK_TYPE_SAVE.installItineraryReservations
            case TASK_TYPE.DismantleContractor:
                return TASK_TYPE_SAVE.dismantleContractor
            case TASK_TYPE.DismantleItineraryReservations:
                return TASK_TYPE_SAVE.dismantleItineraryReservations
            default:
                return false
        }
    }

    useEffect(() => {
        // if(row && row.status !== ""){ // as jobId, type and status are mandatory
        const param = getParam();
        if( row?.status !== "" || row?.assignedToId !== "" || row?.deadline !== "" ) { 
            dispatch(setInstallDismantleInfo({[param]: row}));
        } else {
            dispatch(setInstallDismantleInfo({[param]: null}));
        }
    }, [row]);

    useEffect(() => {
        if(data){
            setRow(data)
        } else {
            setRow({
                lastModifiedDate: formatDateTimeDayjs(dayjs(), FORMATS.dateTime),
                id: 0,
                jobId: state?.jobId,
                type: type,
                // shipmentId: 0,
                // expenseId: 0
            })
        }
    }, [data, state?.jobId, type]);

    const RenderRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableCell className="p-0" sx={sxCellStyle}>
                    <InternalUser
                        id={row.assignedToId}
                        type={USER_TYPES.employee}
                        placeholder=""
                        onChange={(e, val) => handleRowUpdate({ assignedToId: val?.id || '' })}
                    />
                </TableCell>
                <TableCell className="p-0" sx={sxCellStyle} style={{ width: 150 }}>
                    {/* <DatePicker
                                            value={dayjs(row.deadline)}
                                            onDateChange={(newValue) => handleRowUpdate({deadline: formatDateTimeDayjs(newValue, FORMATS.dateTime)})}
                                        /> */}
                    <CustomDatePicker
                        // sx={{border: 'none',"& fieldset": { border: 'none' },}}
                        value={row?.deadline ? dayjs(row.deadline) : null}
                        
                        // onChange={(newValue) =>{formik.setFieldValue('shipExhibitToShow.deadline',formatDateTimeDayjs(newValue, FORMATS.local))}}
                        onChange={(newValue) => handleRowUpdate({ deadline: formatDateTimeDayjs(newValue, FORMATS.date) })}
                    />
                </TableCell>
                <TableCell className="p-0" sx={sxCellStyle} style={{ width: 100 }}>
                    <TaskStatus
                        status={row.status ?? "None"}
                        onChange={(newValue) => handleRowUpdate({ status: newValue })}
                    />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <div className={`${containerStyle}`}>
            <TableContainer component={Paper} className={`rounded-0 shadow-none overflow-hidden`}>
                <Table sx={sxTableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Assigned To</TableCell>
                            <TableCell style={{width: '35%'}}>Deadline</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            row &&
                            <RenderRow {...row} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default SetupDismantleLabor;
