import {TableRow, TableCell} from "@mui/material";
import DeleteItem from "./DeleteItem";

function Item(props) {
  const {item, exhibit, showAction, setClientInventoryItemsState, handleItemUpdated} = props;
  return (
    <TableRow>{
      item ? (
        <>
          <TableCell>{item.description}</TableCell>
          <TableCell>{item.quantityAssignedToExhibit}</TableCell>
          <TableCell>{item.weight}</TableCell>
          <TableCell>{item.content}</TableCell>
          {showAction&&<TableCell>
            <DeleteItem item={item} exhibit={exhibit} setClientInventoryItemsState={setClientInventoryItemsState} handleItemUpdated={handleItemUpdated}/>
          </TableCell>}
        </>
      ) : <TableCell align="center" colSpan={showAction ? 5 : 4} className="py-1">
          No Items found
        </TableCell>
    }</TableRow>
  )
}
export default Item;