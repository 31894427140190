import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import dayjs from 'dayjs';
import sxTableStyles from "../../style/sxTableStyles";
import {CustomDatePicker, CustomIconButton, CustomTableCellOne, CustomTextField} from "../../style/mui/customStyle";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";

function ShipmentQuoteGrid(props) {
  const { formik } = props;
  const handleDate = (value) => {
    const formattedDate = formatDateTimeDayjs(value, FORMATS.date)
    formik.setFieldValue('shipDate', formattedDate || '');      
  }

  return (
    <TableContainer component={Paper}
      className="rounded-0 shadow-none mb-2">
      <Table sx={sxTableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>Ship Date</TableCell>
            <TableCell>Quote</TableCell>
            <TableCell>Actual</TableCell>
            {/* <TableCell>PRO#</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <CustomTableCellOne>
              <CustomIconButton className="table-cell-picker">
                <CustomDatePicker variant="standard" name='shipDate' 
                  value={dayjs(formik.values.shipDate ? formik.values.shipDate : '')}
                  onChange={(newValue) => handleDate(newValue)}
                  // onBlur={formik.handleBlur }
                  // error={formik.touched.shipDate && Boolean(formik.errors.shipDate) }
                  // helperText={formik.touched.shipDate && formik.errors.shipDate}
                  />                  
              </CustomIconButton>
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <CustomTextField variant="standard" name='quoteAmount' 
                value={formik.values.quoteAmount}
                onChange={formik.handleChange}
                error={formik.touched.quoteAmount && Boolean(formik.errors.quoteAmount)}
                helperText={formik.touched.quoteAmount && formik.errors.quoteAmount}
              />
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <CustomTextField variant="standard" name='actualAmount'  
                value={formik.values.actualAmount}
                onChange={formik.handleChange}
                error={formik.touched.actualAmount && Boolean(formik.errors.actualAmount)}
                helperText={formik.touched.actualAmount && formik.errors.actualAmount}
              />
            </CustomTableCellOne>
            {/* <CustomTableCellOne className="px-1">
              <CustomTextField variant="standard" name='proNumber' 
                value={formik.values.proNumber}
                onChange={formik.handleChange }
              />
            </CustomTableCellOne> */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ShipmentQuoteGrid;
