import {Dialog} from "@mui/material";
import CarrierForm from "./CarrierForm";

function AddCarrier(props) {
    const { isOpen, onClose,setCarrier } = props;
  return (
    <Dialog open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
          <CarrierForm
              onClose={onClose}
              setCarrier={setCarrier} />
    </Dialog>
  )
}

export default AddCarrier;
