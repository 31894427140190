import classes from './ShipmentDetail.module.scss'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select
} from "@mui/material";
import sxTableStyles from "../../style/sxTableStyles";
import { CustomSpan, CustomTableCellOne} from "../../style/mui/customStyle";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";
import { useEffect, useState } from 'react';
import { useGetInternalUsersQuery } from '../../services/authService';
import dayjs from 'dayjs';
import {CustomDatePicker} from '../../style/mui/customStyle';

function ShipmentVerfication(props){
  const {formik} = props;
  
  const [employees, setEmployees] = useState([]);
  const {data: employeesData, isFetching} = useGetInternalUsersQuery('Employee');
  const statusData = [
    { value: "None"},
    { value: "Assigned"},
    { value: "Complete"},
    { value: "Cancelled"},
    { value: "ReadyToShip"},
    { value: "Shipped"},
    { value: "Delivered"},
    { value: "Ordered"},
    { value: "Verified"}
  ];
  
  const [shipExhibitText,  setShipExhibitText] = useState('');
  const [verifyExhibitText,  setVerifiyExhibitText] = useState('');
  useEffect(() => {
    if (employeesData) {
        const {users} = employeesData;
        setEmployees(users)           
    }
}, [employeesData, isFetching]);

useEffect(() => {
  if (formik.values.shipmentDirection) {
    if(formik.values.shipmentDirection == 'ShipToShow'){
      setShipExhibitText('Ship Exhibit to Show');
      setVerifiyExhibitText('Verify Exhibit at Show');
    } else {
      setShipExhibitText('Ship Exhibit to CMC');
      setVerifiyExhibitText('Verify Exhibit at CMC');
    }
  }
}, [formik.values.shipmentDirection]);

    const setStatusShipmentVerfication = (e,type) => {
        if (e.target.value) {
            if (type === "show") {
                if (formik.values.shipExhibitToShow?.status === "None" || formik.values.shipExhibitToShow?.status === "")
                    formik.setFieldValue('shipExhibitToShow.status', "Assigned");
            }
            else if (type === "verify") {
                if (formik.values.verifyExhibitAtShow?.status === "None" || formik.values.verifyExhibitAtShow?.status === "")
                    formik.setFieldValue('verifyExhibitAtShow.status', "Assigned");
            }
        }
    }

  return (
    <>
      <h3 className={`lh-sm mt-2 ${classes.title}`}>{shipExhibitText}</h3>
      <TableContainer component={Paper}
      className="rounded-0 shadow-none mb-2">
      <Table sx={sxTableStyles} style={{overflow: 'hidden' }}>
        <TableHead>
          <TableRow style={{ height: '30px'}}>
            <TableCell>Assigned To</TableCell>
            <TableCell>Deadline</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <CustomTableCellOne>
              <Select
                fullWidth
                displayEmpty
                style={{ height: '30px', fontSize: '14px' }} 
                name="shipExhibitToShow.assignedToId"
                onChange={(event) => { formik.handleChange(event); setStatusShipmentVerfication(event,"show"); }}
                value={formik.values.shipExhibitToShow?.assignedToId ? formik.values.shipExhibitToShow?.assignedToId : ''}
              >
                <MenuItem value='' disabled="true"><em>Select Employee</em></MenuItem>
                {employees.map(({ id, fullName }, index) => (
                    <MenuItem key={index} value={id}>
                        {fullName}
                    </MenuItem>
                ))}
              </Select> 
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <CustomDatePicker 
                variant="outlined"
                sx={{border: 'none',"& fieldset": { border: 'none' },}}
                name='shipExhibitToShow.deadline' 
                value={formik.values.shipExhibitToShow?.deadline ? dayjs(formik.values.shipExhibitToShow?.deadline) : null}
                onChange={(newValue) =>{formik.setFieldValue('shipExhibitToShow.deadline',formatDateTimeDayjs(newValue, FORMATS.date))}} 
                fullWidth />
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <Select
                fullWidth
                displayEmpty
                style={{ height: '30px', fontSize: '14px' }} 
                name="shipExhibitToShow.status"
                onChange={formik.handleChange}
                value={formik.values.shipExhibitToShow?.status ? formik.values.shipExhibitToShow?.status : ''}
              >
                <MenuItem value='' disabled="true"><em>Select Status</em></MenuItem>
                {statusData.map(({ value }, index) => (
                    <MenuItem key={index} value={value}>
                        {value}
                    </MenuItem>
                ))}
              </Select>
            </CustomTableCellOne>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
      <h3 className={`lh-sm mt-2 ${classes.title}`}>{verifyExhibitText}</h3>
      <TableContainer component={Paper}
      className="rounded-0 shadow-none mb-2">
      <Table sx={sxTableStyles} style={{overflow: 'hidden'}}>
        <TableHead>
          <TableRow style={{ height: '30px'}}>
            <TableCell>Assigned To</TableCell>
            <TableCell>Deadline</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <CustomTableCellOne>
            <Select
                fullWidth
                displayEmpty
                style={{ height: '30px', fontSize: '14px' }}
                name="verifyExhibitAtShow.assignedToId"
                onChange={(event) => { formik.handleChange(event); setStatusShipmentVerfication(event, "verify"); }}
                value={formik.values.verifyExhibitAtShow?.assignedToId ? formik.values.verifyExhibitAtShow?.assignedToId : ''}
              >
                <MenuItem value='' disabled="true"><em>Select Employee</em></MenuItem>
                {employees.map(({ id, fullName }, index) => (
                    <MenuItem key={index} value={id}>
                        {fullName}
                    </MenuItem>
                ))}
              </Select> 
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <CustomDatePicker 
                variant="outlined"
                sx={{border: 'none',"& fieldset": { border: 'none' },}}
                name='verifyExhibitAtShow.deadline' 
                value={formik.values.verifyExhibitAtShow?.deadline ? dayjs(formik.values.verifyExhibitAtShow?.deadline) : null}
                onChange={(newValue) => { formik.setFieldValue('verifyExhibitAtShow.deadline', formatDateTimeDayjs(newValue, FORMATS.date))}} 
                fullWidth />
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              <Select
                fullWidth
                displayEmpty
                style={{ height: '30px', fontSize: '14px' }} 
                name="verifyExhibitAtShow.status"
                onChange={formik.handleChange}
                value={formik.values.verifyExhibitAtShow?.status ? formik.values.verifyExhibitAtShow?.status : ''}
              > 
                <MenuItem value='' disabled="true"><em>Select Status</em></MenuItem>
                  {statusData.map(({ value }, index) => (
                      <MenuItem key={index} value={value}>
                          {value}
                      </MenuItem>
                  ))}
              </Select>
            </CustomTableCellOne>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default ShipmentVerfication;