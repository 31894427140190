import {Input} from "@mui/base/Input";
import {StyledFormLabel} from "../../pages/dashboard/styles/taskListStyle";

const GridSearch = ({search, onSearch}) => {
    return (
        <div className="mb-2">
            <StyledFormLabel className="mb-1">Search:</StyledFormLabel>
            <Input value={search} onChange={onSearch} />
        </div>
    )
}

export default GridSearch;