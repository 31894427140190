import {useEffect, useState} from "react";
import {useGetExhibitsQuery, useGetExhibitQuery, useUpdateExhibitGiveAwayMutation, useClientExhibitQuery} from "../../services/authService";
import {Paper, TextField} from "@mui/material";
import EditExhibit from "./EditExhibit";
import AddExhibit from "./AddExhibit";
import DeleteExhibit from "./DeleteExhibit";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import styles from './ExhibitDetail.module.scss';
import {Col, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {setExhibit} from "../../redux/reducers/exhibit/exhibitSlice";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {setExhibitGiveAway} from "../../redux/reducers/exhibit/exhibitSlice";
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import { useParams } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";


function ExhibitDetail(props) {
  const {id} = useParams();
  const [filter, setFilter] = useState(null);
  const [options, setOptions] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);
  const {setSelectedExhibit, selectedExhibit, refetchGetExhibitGiveAway} = props;
  const dispatch = useDispatch();
  const {jobExhibit, loading, error} = useSelector((state) => {
    return state.jobExhibitData;
  });
  const clientId = jobExhibit?.exhibitGiveAway?.job?.clientId;
  const [isAddOpen, setAddOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const {data, isFetching, error: getExhibitError, refetch: getExhibits} = useGetExhibitsQuery({filter: filter, clientId: clientId});
  // const {data, isFetching, error: getExhibitError, refetch: getExhibits} = useClientExhibitQuery({clientId: clientId});

  const {data: exhibitData, isLoading, isError, refetch: getExhibit} = useGetExhibitQuery(selectedExhibit && selectedExhibit.id, { skip: selectedExhibit === null });
  
  const [updateExhibitGiveAway, { isLoading: isUpdatingExhibit, isError: errorsUpdatingExhibit }] = useUpdateExhibitGiveAwayMutation();

  useAuthentication([getExhibitError,errorsUpdatingExhibit,isError]);

  const handleInputChange = (event, value) => {
    if (value === '') {
      setSelectedExhibit(null);
      setFilter('');
    } else {
      setFilter(value);
    }
  };
  
  useEffect(() => {
    if (data && data.exhibits) {
      setOptions(data.exhibits);
      const selOption = data.exhibits.find((option) => option.name === filter);
      setSelectedExhibit(selOption || null);
    }
  }, [data, filter]);
  
  useEffect(() => {
    const fetchData = async () => {
      const updatedData = await getExhibits();
      if (updatedData) {
        setOptions(updatedData.data.exhibits);
        const selOption = updatedData.data.exhibits.find(
          (option) => option.name === filter
        );
        setSelectedExhibit(selOption || null);
      }
    };
  
    if (filter && clientId) {
      fetchData();
    }
  }, [filter, getExhibits, clientId]);

  
  function openAddExhibitHandler() {
    setAddOpen(true);
  }
  function closeAddExhibitHandler() {
    setAddOpen(false);
  }
  function handleExhibitAdded() {
    getExhibits();
  }
  function handleExhibitUpdated(params) {
    setFilter(params);
  }
  async function changeExhibitHandler(value) {
    // if(value?.id) {
      const jobExhibitValues = {
        jobId: jobExhibit?.exhibitGiveAway?.jobId || id,
        exhibitId: value?.id
      }
      const response = await updateExhibitGiveAway(jobExhibitValues);
      if (response.data === true) {
        const refetch = await refetchGetExhibitGiveAway();
        dispatch(setExhibitGiveAway(refetch.data?.exhibitGiveAway));
        setSelectedExhibit(value);
        enqueueSnackbar(`${messages.success.updateExhibit}`, { variant: 'success' });
      } else {
        enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
      }
    // }
    // else{
    //   setOpen(true);
    // }
  }

  // if (isFetching) {
  //   return <p>Loading...</p>; // Show a loading message while fetching data
  // }

  // if (!data || !data.exhibits) {
  //   return null; // Don't render anything if data or data.exhibits is not present
  // }

  return (
    <div className="pb-2">
      <div className="d-flex align-items-center">
        <CustomAutocomplete id="exhibitId" className="flex-grow-1"
          sx={sxAutocomplete}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={
            options ? options.map(option => ({name: option.name, id: option.id})) : []
          }
          getOptionLabel={
            (option) => option.name
          }
          renderOption={
            (props, option) => (
              <li {...props}
                key={
                  option.id
              }>
                <span className="common-text">
                  {
                  option.name || ''
                }</span>
              </li>
            )
          }
          renderInput={
            (params) => (
              <TextField {...params}
                label={
                  <label
                className="common-text">Name</label>
                }
                variant="outlined"/>
            )
          }
          noOptionsText="Exhibit not found"
          value={ selectedExhibit?.id ?  selectedExhibit : null}
          onChange={(event, newValue) => changeExhibitHandler(newValue)}
          onInputChange={handleInputChange}
          isOptionEqualToValue={
            (option, value) => option.id === value?.id
          }/>
        <div className={
          `${
            styles.container
          } ps-2`
        }>
          { jobExhibit && jobExhibit?.exhibitGiveAway?.exhibit && <>
          <EditExhibit exhibit={
              selectedExhibit ? selectedExhibit : null
            }
            notes={
              jobExhibit ? jobExhibit?.exhibitGiveAway?.notes : ''
            }
            graphics={
              jobExhibit ? jobExhibit?.exhibitGiveAway?.graphics : ''
            }
            onExhibitUpdated={handleExhibitUpdated}
            setSelectedExhibit={setSelectedExhibit}/>
          {/* <DeleteExhibit exhibit={selectedExhibit ? selectedExhibit : null }
            setSelectedExhibit={setSelectedExhibit}
          /> */}
          </>}
        </div>
      </div>
      { jobExhibit && jobExhibit?.exhibitGiveAway?.graphics && 
      <Row className="gx-2"
        style={
          {width: 'calc(100% - 40px)'}
      }>
        <Col>
          <h3 className={
            `lh-sm mt-2 ${
              styles.title
            }`
          }>Graphics</h3>
          <div className="p-2 common-text">
            <div>{
              jobExhibit ? jobExhibit?.exhibitGiveAway?.graphics : ''
            }</div>
          </div>
        </Col>
      </Row>
      }
      { jobExhibit && jobExhibit?.exhibitGiveAway?.notes && 
        <Row className="gx-2"
          style={
            {width: 'calc(100% - 40px)'}
        }>
          <Col>
          <h3 className={
              `lh-sm mt-2 ${
                styles.title
              }`
            }>Notes</h3>
            <div className="p-2 common-text"
              style={
                {minHeight: '50px'}
            }>
              <div>{
                jobExhibit ? jobExhibit?.exhibitGiveAway?.notes : ''
              }</div>
            </div>
          </Col>
        </Row>
      }
      <AddExhibit isAddOpen={isAddOpen}
        openAddExhibitHandler={openAddExhibitHandler}
        closeAddExhibitHandler={closeAddExhibitHandler}
        onExhibitAdded={handleExhibitAdded}/>
    </div>
  )
}
export default ExhibitDetail;
