import { TextField } from "@mui/material";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import CustomPaper from "../CustomPaper";
import { useSelector } from "react-redux";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import { useState, useEffect } from "react";

function SelectCarrier(props){
  const carriers = useSelector((state) => state.carrierData.carriers);
  const { openAddCarrierDialog, fieldName, formik, setCarrier, selectedCarrier} = props;
  const [open, setOpen] = useState(false);
    
  function handleClick() {
    openAddCarrierDialog();
  }
   const changeCarrierHandler = (event, value) => {
    
    formik.setFieldValue(fieldName, value?.id || '');
    setCarrier(value);    
    if(!value){
      setOpen(true);
    }
   };

    useEffect(() => {
        if (selectedCarrier) {
            formik.setFieldValue(fieldName, selectedCarrier?.id || '');
            setCarrier(selectedCarrier);
        }
    }, [selectedCarrier]);

    useEffect(() => {
        if (selectedCarrier) {
            formik.setFieldValue(fieldName, selectedCarrier?.id || '');
            setCarrier(selectedCarrier);
        }
    }, [selectedCarrier]);

  return (
    <>
      <CustomAutocomplete className="flex-grow-1 mb-2"
        id="carrier-list"
        sx={sxAutocomplete}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={carriers ? carriers.map(option => ({name: option.name, id: option.id}) ) : [] }
        getOptionLabel={(option) => (option ? option.name || '' : '')}
        value={selectedCarrier}
        onChange={changeCarrierHandler}
        renderOption={
          (props, option) => (
            <li {...props}
              key={
                option.id
            }>
              <span className="common-text">
                {
                option.name || ''
              }</span>
            </li>
          )
        }
        renderInput={
          (params) => (
            <TextField {...params}
              label={
                <label
              className="common-text">Freight Carrier</label>
              }
              error={formik.touched.carrierId && Boolean(formik.errors.carrierId)}
              helperText={formik.touched.carrierId && formik.errors.carrierId }
              variant="outlined"/>
          )
        }
        PaperComponent={(props) => (
          <CustomPaper {...props} clickHandler={handleClick} displayText={'+ Add Freight Carrier'} />
        )}
        noOptionsText="Carrier not found"
        isOptionEqualToValue={
          (option, value) => option.id === value.id
        }
        />
    </>
  )
}
export default SelectCarrier;