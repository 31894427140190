import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import BranchForm from "./BranchForm";
import styles from "../showName/EditShowName.module.scss";

const AddBranch = ({ clientId, isOpen, onAdd, onCancel, setStoredBranchId, storedBranchId, setCreatedBranchId, setFilter, setBranch }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`${styles.header} text-center`}>
                Add Branch
            </DialogTitle>
            <BranchForm
                clientId={clientId}
                onCancel={onCancel}
                onAdd={onAdd}
                setStoredBranchId={setStoredBranchId}
                storedBranchId={storedBranchId}
                setCreatedBranchId={setCreatedBranchId}
                setFilter={setFilter}
                setBranch={setBranch}
            />
        </Dialog>
    )
}

export default AddBranch;
