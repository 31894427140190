import { TextField } from "@mui/material";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import CustomPaper from "../CustomPaper";
import { useSelector } from "react-redux";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";

function SelectShipment(props) {
    const { AddShipmentClickHandler, jobShipments, changeShipmentHandler, formik } = props;
  const shipment = useSelector((state) => state.shipmentData.shipment);
  const selectedShipmentValue = shipment?.shipment?.shipDate ? formatDateTimeDayjs(shipment?.shipment?.shipDate, FORMATS.displayDate) + " - " + shipment?.shipment?.shipmentDirection : "TBD - " + shipment?.shipment?.shipmentDirection;
  return (
    <>
          <CustomAutocomplete id="exhibitId" className="flex-grow-1 mb-2"
              disableClearable
        style={
          {backgroundColor: "#fff"}
        }
        options={
          jobShipments && jobShipments.shipments
            ? jobShipments.shipments.map((option) => ({
                name: option.shipDate ? formatDateTimeDayjs(option.shipDate, FORMATS.displayDate) + " - " + option.shipmentDirection : "TBD - "  + option.shipmentDirection,
                id: option.id,
              }))
            : []
        }
        getOptionLabel={
          (option) => option.name
        }
        renderOption={
          (props, option) => (
            <li {...props}
              key={
                option.id
            }>
              <span className="common-text">
                {
                option.name || ''
              }</span>
            </li>
          )
        }
        renderInput={
          (params) => (
              <TextField {...params}
              label={
                <label
              className="common-text">Shipment</label>
              }
                  variant="outlined"
              />
          )
        }
        PaperComponent={(props) => (
          <CustomPaper {...props} clickHandler={AddShipmentClickHandler} displayText={'+ Add Shipment'} />
        )}
        noOptionsText="Shipment not found"
        onChange={(event, newValue) => changeShipmentHandler(newValue)}
        isOptionEqualToValue={
          (option, value) => {
            if (value) {
              return option.id === value.id
            }
          }
        }
        value={shipment && shipment?.shipment && { name: selectedShipmentValue, id: shipment?.shipment?.id }}
        />
    </>
  )
}

export default SelectShipment;
