import {useEffect} from "react";
import {useCreateTravelExpenseMutation, useUpdateTravelExpenseMutation} from "../../../services/authService";
import {useSnackbar} from "notistack";
import {useInstallDismantle} from "../../../context/InstallDismantleContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import messages from "../../../utils/messages";
import styles from "../Travel.module.scss";

const EditTravelExpense = ({ expense, onUpdate }) => {
    const [state] = useInstallDismantle();
    const [createTravelExpense, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateTravelExpenseMutation();
    const [updateTravelExpense, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateTravelExpenseMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleUpdate = () => {
        const data = {
            id: expense?.id,
            installDismantleId: state?.id,
            description: expense?.description,
            estimate: expense?.estimate,
            actual: expense?.actual,
            invoice: expense?.invoice
        }
        
        if(expense.isNew) {
            console.log('create');
            createTravelExpense(data);
        } else {
            console.log('update');
            updateTravelExpense(data);
        }
    }

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createTravelExpense}`, { variant: 'success' });
            createReset();
            onUpdate();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateTravelExpense}`,{ variant: 'success' });
            updateReset();
            onUpdate();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <AddCircleOutlineIcon
            fontSize="small"
            onClick={handleUpdate}
            className={`${styles.icon}`}
        />        
    )
}

export default EditTravelExpense;
