import {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import BorderBox from "../../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import DialogActions from "@mui/material/DialogActions";
import {useFormik} from "formik";
import {useCreateShowNameMutation, useUpdateShowNameMutation} from "../../../services/authService";
import messages from "../../../utils/messages";

const showNameSchema = Yup.object({
    name: Yup.string().required('Show Name is required')
});

const ShowNameForm = ({ showName, setShowName, onCancel, onUpdate, onAdd, setNewShowName, name}) => {
    const [createShowName, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateShowNameMutation();
    const [updateShowName, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateShowNameMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [previousName, setPreviousName] = useState(name);

    const formik = useFormik({
        initialValues: {
            name: showName ? showName.name : '',
        },
        validationSchema: showNameSchema,
        onSubmit: async (values) => {
            setPreviousName(values.name);
            setNewShowName('');
            if(showName){ // edit
                await updateShowName({
                    id: showName.id,
                    ...values
                });
            } else { // add
                await createShowName(values);
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createShowName}`, { variant: 'success' });
            // formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setNewShowName(previousName);
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateShowName}`, { variant: 'success' })
            // setShowName({
            //     id: showName.id,
            //     ...formik.values
            // });
            setNewShowName(previousName);
            updateReset();
            onUpdate();
            onCancel();
        }
        if(isCreateError) {
            setNewShowName(name);
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            // setNewShowName(name);
            onCancel();
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox>
                    <CustomTextField
                        fullWidth
                        label="Show Name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ShowNameForm;