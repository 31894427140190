import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import HotelForm from "./HotelForm";
import styles from "../showName/EditShowName.module.scss";

const AddHotel = ({ isOpen, onAdd, onCancel, setHotel }) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add hotel
                </DialogTitle>
                <HotelForm
                    onCancel={onCancel}
                    onAdd={onAdd}
                    setHotel={setHotel}
                />
            </Dialog>
        </div>
    )
}

export default AddHotel
