import {useState} from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from "../ConfirmDialog";
import {useRemoveItemFromExhibitMutation, useGetAssignedExhibitItemsQuery, useGetCmcInventoryItemsQuery, useGetClientInventoryItemsQuery} from "../../services/authService";
import {useDispatch, useSelector} from "react-redux";
import { setAllocatedExhibitItems} from "../../redux/reducers/exhibit/exhibitSlice";
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import useAuthentication from "../../hooks/useAuthentication";

function DeleteItem(props) {
  const {item, setCmcInventoryItemsState, setClientInventoryItemsState } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const {jobExhibit, loading, error} = useSelector((state) => {
    return state.jobExhibitData;
  }); 
  const [removeItemFromExhibit, {
      isLoading,
      isError
    }
  ] = useRemoveItemFromExhibitMutation();
  
  const {data: getAssignedExhibitItems, isFetching, error: getAssignedExhibitItemsError, refetch: refetchGetAssignedExhibitItems} = useGetAssignedExhibitItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.exhibit?.id);

  const {data: clientInventoryItems, isFetching: fetchingClientInventoryItems,error: clientInventoryItemsError, refetch: refetchClientInventoryItems} = useGetClientInventoryItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.exhibit?.clientId);

  const {data: cmcInventoryItems, isFetching: fetchingCmcInventoryItems,error: cmcInventoryItemsError, refetch: refetchCmcInventoryItems} = useGetCmcInventoryItemsQuery();
  useAuthentication([isError, getAssignedExhibitItemsError, clientInventoryItemsError, cmcInventoryItemsError]);

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  const handleDelete = async () => {
    const response = await removeItemFromExhibit({clientId: item.clientId, exhibitId: jobExhibit?.exhibitGiveAway?.exhibit?.id, itemId: item?.id});

    if (response.data == true) {
        if (!fetchingCmcInventoryItems) {
          const refetechCmcResponse = await refetchCmcInventoryItems();
          setCmcInventoryItemsState(refetechCmcResponse.data.items);
        }
        if (!fetchingClientInventoryItems) {
          const refetechClientResponse = await refetchClientInventoryItems();
          setClientInventoryItemsState(refetechClientResponse.data.items);
        }
        if (!isFetching) {
          const refetch = await refetchGetAssignedExhibitItems();
          dispatch(setAllocatedExhibitItems(refetch.data.items));
        }
        enqueueSnackbar(`${messages.success.removeItemFromExhibit}`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.genericDelete }`, { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title="Remove Item">
        <RemoveCircleOutlineIcon fontSize="small"
          style={
            {
              color: "grey",
              cursor: "pointer"
            }
          }
          onClick={openDeleteModalHandler}/>
      </Tooltip>
      <ConfirmDialog title="Delete Exhibit Item"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this exhibit item?
      </ConfirmDialog>
    </>
  )
}

export default DeleteItem;
