import Item from "./Item";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import classes from './ItemList.module.css'
import {useState} from "react";
import sxTableStyles from "../../../style/sxTableStyles";
import AddRowButton from "../../ui/AddRowButton";

function ItemList(props) {
  const {items, exhibit, showAddItem, showAction, setClientInventoryItemsState, handleItemUpdated} = props;
  const [isExhibitItemOpen, setExhibitItemOpen] = useState(false);

  function openExhibitItemHandler() {
    setExhibitItemOpen(true);
  }
  function closeExhibitItemHandler() {
    setExhibitItemOpen(false);
  }
  return (
    <>
      <h3 className={`lh-sm ${classes.title}`}>Items</h3>
      <TableContainer component={Paper}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_cell}>Description</TableCell>
              <TableCell className={classes.table_cell}>Quantity</TableCell>
              <TableCell className={classes.table_cell}>Weight</TableCell>
              <TableCell className={classes.table_cell}>Content</TableCell>
              { showAction && <TableCell className={classes.table_cell}>Actions</TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>{
            items && items.length > 0 ? (items.map((item, index) => {
              return <Item item={item} key={`item-${index}`} exhibit={exhibit} showAction={showAction} setClientInventoryItemsState={setClientInventoryItemsState} handleItemUpdated={handleItemUpdated} />
            })) : <Item item={null} key="no-items" showAction={showAction} />
          }</TableBody>
        </Table>
      </TableContainer>
      {
      showAddItem ? (
        <AddRowButton onAdd={openExhibitItemHandler}>Add Item</AddRowButton>
      ) : null
    } </>
  )
}
export default ItemList;
