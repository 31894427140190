import {TableCell, TextField} from "@mui/material";
import { styled } from '@mui/material/styles';
import {theme} from "../../utils/colors";

export const CustomTextField = styled(TextField)({
    padding: "2px 0px",

    "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        top: "4px",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        }
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        height: "47px",
    },
    "& .MuiInputBase-input": {
        letterSpacing: "0px",
        fontSize: "8pt",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        paddingLeft: "5px",
    },
});

export const CustomTableCell= styled(TableCell)({
    backgroundColor: "rgb(99, 93, 99)",
    border: "1px solid black",
    color: "#fff",
    letterSpacing: '0px',
    fontSize: '12px',
    lineHeight:'18px',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif',
    paddingLeft: '10px !important',
    '& .MuiTableCell-root':{
        paddingLeft: '10px !important'
    },
});

export const CustomTableCellOne= styled(TableCell)({
    backgroundColor: "white",
    fontSize: '8pt'
});

export const CustomSpan = styled('span')`
    white-space: nowrap;
    letter-spacing: 0px;
    font-size: 8pt;
    line-height: 18px;
    font-weight: normal;
    text-align: center;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
`;

export const CustomSpanBold = styled('span')`
    white-space: nowrap;
    letter-spacing: 0px;
    font-size: 11pt;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
`;