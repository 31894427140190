import {useEffect} from "react";
import { useSnackbar } from 'notistack';
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BorderBox from "../ui/BorderBox";
import {CustomButton, CustomSelect} from "../../style/mui/customStyle";
import messages from "../../utils/messages";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { useCreateClientJobMutation } from "../../services/authService";
import useAuthentication from "../../hooks/useAuthentication";

const ClientJobsForm = ({clientId, onCancel, clientJobEdit}) => {
    
    const [createClientJob, { data: clientJobData, isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateClientJobMutation();
    const { enqueueSnackbar } = useSnackbar();
    useAuthentication([isCreateError]);
    const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i);

    const formik = useFormik({
        initialValues: {
            jobYear: new Date().getFullYear(),
            clientId: clientId
        },
        onSubmit: async (values) => {
                await createClientJob({
                    ...values
                });
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createClientJob}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onCancel();   
            clientJobEdit(clientJobData);       
        }
        
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        if(isCreateSuccess || isCreateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isCreateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <label>Select the year the job will be performed</label>
                    <FormControl fullWidth className='mt-2'>
                        <InputLabel htmlFor="jobYear">Year</InputLabel>
                        <CustomSelect
                        label="Year" 
                        name="jobYear"
                        value={formik.values.jobYear}
                        onChange={formik.handleChange}
                        >
                        {years.map(( year , index) => (
                            <MenuItem key={index} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                        </CustomSelect>                   
                    </FormControl>
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ClientJobsForm;
