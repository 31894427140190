import React, { useEffect } from 'react';
import { DialogActions, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ActionBar from "../ui/ActionBar";
import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import sxTableStyles from "../../style/sxTableStyles";
import { CustomTableCellOne, CustomTextField } from "../../style/mui/customStyle";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { usePrintJobShipmentMutation } from '../../services/authService';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import { saveAs } from 'file-saver';

function PrintShipmentDialog(props) {
  const { isOpen, onCancel } = props;
  const shipment = useSelector((state) => state.shipmentData.shipment);
  const shipmentItemMapping = useSelector(state => state.shipmentData?.shipment?.shipmentItemMapping);
  const [printJobShipment, { isLoading: isPrintLoading, isSuccess: isPrintSuccess, error: isPrintError
  }] = usePrintJobShipmentMutation();
  const validationSchema = Yup.object().shape({
    shipmentItems: Yup.array().of(
      Yup.object().shape({
        noOfPieces: Yup.number().required('Number of pieces is required').integer('Number of pieces must be an integer')
        .min(0,'Number of pieces must be 0 or greater than 0'),
        description: Yup.string().required('Description is required'),
        dimensions: Yup.string().required('Dimensions are required'),
        weight: Yup.number().required('Weight is required'),
      })
    )
  });

  const formik = useFormik({
    initialValues: {
      shipmentId: shipment?shipment?.id : '',
      shipmentItems: shipmentItemMapping ? shipmentItemMapping.map(item => ({
          noOfPieces: item?.item?.quantityForShipmentPrint,
          description: item?.item?.description,
          dimensions: `${item?.length ?? 0}*${item?.width ?? 0}*${item?.height ?? 0}`,
          weight: item?.weight ?? 0
      })) : []
    },
    validationSchema,
    onSubmit: async (values) => {
        const requestObject = { ...values };        
        requestObject.shipmentId = shipment?.shipment?.id;
        const response = await printJobShipment(requestObject);
      // const blob = new Blob([response.data], { type: "application/pdf" });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download ='shipment.pdf';
      // a.click();
      // window.URL.revokeObjectURL(url);      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `${shipment?.shipment?.jobId}_Shipment.pdf`);
    }
  });
  
  const handleMessages = () => {
    if(isPrintSuccess) {
        enqueueSnackbar(`${messages.success.printShipment}`, { variant: 'success' });
        onCancel();
    }
    if(isPrintError) {
        enqueueSnackbar(`${messages.error.prefix} ${isPrintError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
};

useEffect(() => {
    if(isPrintSuccess || isPrintError){
        handleMessages();
    }
    // eslint-disable-next-line
}, [isPrintSuccess, isPrintError]);

  const calculateTotals = () => {
    let totalPieces = 0;
    let totalWeight = 0;     
    formik.values.shipmentItems.forEach((item) => {
      totalPieces += parseInt(item.noOfPieces, 10);
      totalWeight += parseFloat(item.weight);
    });
    return { totalPieces, totalWeight };
  };

  const { totalPieces, totalWeight } = calculateTotals();

  return (
    <form onSubmit={
      formik.handleSubmit
    }>
      <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle styles={`text-center`}> Shipment Items </DialogTitle>
      <DialogContent>
        <div className='mt-3 mb-3'>
          <TableContainer component={Paper} className="rounded-0 shadow-none mb-2">
              <Table sx={sxTableStyles}>
                <TableHead>
                  <TableRow>
                    <TableCell># of pieces</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Dimensions</TableCell>
                    <TableCell>Weight</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.shipmentItems.map((item, index) => (
                      <TableRow key={index}>
                      <CustomTableCellOne>
                        <CustomTextField
                          type="number"
                          name={`shipmentItems.${index}.noOfPieces`}
                          value={item.noOfPieces}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].noOfPieces}
                          helperText={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].noOfPieces}
                        />
                      </CustomTableCellOne>
                      <CustomTableCellOne>
                        <CustomTextField
                          type="text"
                          name={`shipmentItems.${index}.description`}
                          value={item.description}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].description}
                          helperText={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].description}
                        />
                      </CustomTableCellOne>
                      <CustomTableCellOne>
                        <CustomTextField
                          type="text"
                          name={`shipmentItems.${index}.dimensions`}
                          value={item.dimensions}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].dimensions}
                          helperText={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].dimensions}
                        />
                      </CustomTableCellOne>
                      <CustomTableCellOne>
                        <CustomTextField
                          type="number"
                          name={`shipmentItems.${index}.weight`}
                          value={item.weight}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].weight}
                          helperText={formik.touched.shipmentItems && formik.touched.shipmentItems[index] && formik.errors.shipmentItems && formik.errors.shipmentItems[index] && formik.errors.shipmentItems[index].weight}
                        />
                      </CustomTableCellOne>
                    </TableRow>                      
                  ))}
                  {formik.values.shipmentItems.length > 0 && (
                    <TableRow>
                      <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }}>
                        {totalPieces} Pcs
                      </CustomTableCellOne>
                      <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }} colSpan={2}>
                        Total
                      </CustomTableCellOne>
                      <CustomTableCellOne className="px-1" style={{ fontWeight: 'bold' }}>
                        {totalWeight.toFixed(2)}
                      </CustomTableCellOne>
                    </TableRow>
                  )}                  
                </TableBody>
              </Table>
          </TableContainer>
          <DialogActions>
            {shipment ? <ActionBar hideDialog={onCancel} type="submit" onPrint={formik.handleSubmit} loadingPrint={isPrintLoading} />
              : <ActionBar hideDialog={onCancel} />
            }
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
    </form>
  );
}

export default PrintShipmentDialog;
