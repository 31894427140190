import ExhibitForm from "./ExhibitForm";
import { CustomDialog } from "./ExhibitCustomStyle";

function AddExhibit(props) {
  const { isAddOpen, onExhibitAdded, closeAddExhibitHandler } = props;

  return (
      <CustomDialog open={isAddOpen}
      onClose={closeAddExhibitHandler}
      maxWidth="sm" 
      fullWidth
      slotProps={{ backdrop: { onClick: closeAddExhibitHandler } }}>      
        <ExhibitForm onCancel={closeAddExhibitHandler}  onExhibitAdded={onExhibitAdded}/> 
      </CustomDialog>
  )
}

export default AddExhibit;
