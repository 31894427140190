import React, { useState } from "react";
import Box from "@mui/material/Box";
import { jobTypeId } from "./constants";
import ToggleButton from '../../components/ToggleText';
import Stack from '@mui/material/Stack';
import JobChecklist from "./JobChecklist";
import PersonalTaskList from './PersonalTasList';

const TaskList = () => {
  const [activeButton, setActiveButton] = useState(1);

  return (
    <Box>
        <Stack spacing={1} direction="row">
          {
            jobTypeId.map((val, index) => (
              <ToggleButton
                value={val.value}
                label={val.label}
                variant={val.id === activeButton ? 'contained' : 'outlined'}
                id={val.id}
                key={index}
                handleTab={(id) => setActiveButton(id)}
              />
            ))
          }
        </Stack>
        { activeButton === 1 ?
          <PersonalTaskList />
          : 
          <JobChecklist />
        }
    </Box>
  );
};
export default TaskList;
