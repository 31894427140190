import {rowColorObject, textColorObject} from "../pages/dashboard/constants";
import dayjs from "dayjs";

export const formatDateTime = (value) => {
    return new Date(value).toDateString()
}

export const getColorClasses = (bgColor, textColor) => {
    const getClass = (colorObj, color) => {
        let classArr = Object.keys(colorObj).filter((c) =>
            color === colorObj[c] ? c : ""
        );
        return classArr.length ? classArr[0] : "";
    };
    let bgClass = getClass(rowColorObject, bgColor);
    let textClass = getClass(textColorObject, textColor);
    return [bgClass, textClass];
}

export const formatDateTimeDayjs = (date, format) => {
    return dayjs(date).format(format);
}

export const numFloat = (num, decimal = 2) => {
    return num ? parseFloat(num).toFixed(decimal) : (0).toFixed(decimal)
}

export const calculateSum = (array, property) => {
    const total = array.reduce((accumulator, object) => {
        return accumulator + parseFloat(object[property]);
    }, 0);

    return numFloat(total);
}

export const FormatTime = (value) => {
const currentDate = new Date();
const [hours, minutes, seconds] = value.split(':').map(Number);
return currentDate.setHours(hours, minutes, seconds);
}
