import { createSlice} from '@reduxjs/toolkit';

const initialState = {
    installDismantleInfo: {
        jobId: null,
        jobStatus: null,
        // install
        installationContractorId: null,
        installationContractorContactId: null,
        secondInstallationContractorId: null,
        secondInstallationContractorContactId: null,
        installSupervisorId: null,
        secondInstallSupervisorId: null,
        // dismantle
        dismantleContractorId: null,
        dismantleContractorContactId: null,
        secondDismantleContractorId: null,
        secondDismantleContractorContactId: null,
        dismantleSupervisorId: null,
        secondDismantleSupervisorId: null,
        // task
        installationContractor: null,
        dismantleContractor: null,
        installItineraryReservations: null,
        dismantleItineraryReservations: null
    }
}

const InstallDismantleSlice = createSlice({
    name: 'installDismantle',
    initialState,
    reducers: {
        setInstallDismantleInfo: (state, { payload }) => {
            state.installDismantleInfo = {
                ...state.installDismantleInfo,
                ...payload
            }
        },
        resetInstallDismantle: () => initialState,
    }
});

export const {
    setInstallDismantleInfo,
    resetInstallDismantle
} = InstallDismantleSlice.actions;

export default InstallDismantleSlice.reducer
