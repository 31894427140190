// api/exhibitAPI.js
import axios from './api'; // Import the main Axios instance


export const showExhibitGiveaway = async (jobId) => {
  const response = await axios.get(`/api/Job/exhibit-giveaway?jobId=${jobId}`);
  return response.data;
};

export const updateExhibitGiveaway = async (jobExhibitData) => {
  const response = await axios.put(`/api/Job/exhibit-giveaway`, jobExhibitData);
  return response.data;
};

export const getAllExhibits = async () => {
  const response = await axios.get(`/api/Lookup/exhibits`);
  return response.data;
};

export const addExhibit = async (exhibitData) => {
  const response = await axios.post(`/api/Lookup/exhibits`, exhibitData);
  return response.data;
};

export const updateExhibit = async (exhibitData) => {
  const response = await axios.put(`/api/Lookup/exhibits`, exhibitData);
  return response.data;
};

export const deleteExhibit = async (id) => {
  const response = await axios.delete(`/api/Lookup/exhibits`, id);
  return response.data;
};
