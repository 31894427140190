import {useEffect} from "react";
import {useCreateBranchMutation, useUpdateBranchMutation} from "../../../services/authService";
import { useSnackbar } from 'notistack';
import {useFormik} from "formik";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BorderBox from "../../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import {Col, Row} from "reactstrap";
import Countries from "../../Countries";
import States from "../../States";
import messages from "../../../utils/messages";
import { Checkbox, FormControlLabel } from "@mui/material";

const branchSchema = Yup.object({
    name: Yup.string().required('Branch Name is required'),
    isMainBranch: Yup.boolean(),
    address: Yup.object().shape(
        {
            address1: Yup.string(),
            address2: Yup.string(),
            city: Yup.string(),
            stateCode: Yup.string().nullable(),
            province: Yup.string(),
            zip: Yup.string(),
            countryCode: Yup.string(),
            phone: Yup.string(),
            fax: Yup.string(),
        }
    )
});

const BranchForm = ({clientId, branch, setBranch, onCancel, onUpdate, onAdd, setCreatedBranchId, setFilter }) => {
    const [createBranch, { data:CreateBranchData, isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateBranchMutation();
    const [updateBranch, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateBranchMutation();
    const { enqueueSnackbar } = useSnackbar();

    const address = branch?.address;

    const formik = useFormik({
        initialValues: {
            name: branch?.name ?? '',
            isMainBranch: branch?.isMainBranch ?? false,
            clientId:branch?.clientId ?? clientId,
            address: {
                address1: address?.address1 ?? '',
                address2: address?.address2 ?? '',
                city: address?.city ?? '',
                stateCode: address?.stateCode ?? null,
                province: address?.province ?? '',
                zip: address?.zip ?? '',
                countryCode: address?.countryCode ?? 'USA',
                phone: address?.phone ?? '',
                fax: address?.fax ?? ''
            }
        },
        validationSchema: branchSchema,
        onSubmit: async (values) => {
            console.log(JSON.stringify(values, null, 2));
            if(branch){ // edit
                await updateBranch({
                    id: branch?.id,
                    ...values
                });
            } else { // add
                await createBranch({
                    id: 0,
                    ...values
                });
            }
        },
    })

    const handleMessages = () => {
        if(isCreateSuccess) {
            setBranch(null);
            setFilter('');
            enqueueSnackbar(`${messages.success.createBranch}`, { variant: 'success' });
            formik.resetForm();
            setCreatedBranchId(CreateBranchData);
            createReset();
            onAdd();
            onCancel();            
        }
        
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateBranch}`, { variant: 'success' })
            setBranch({
                ...branch,
                id: branch.id,
                ...formik.values
            });
            updateReset();
            onUpdate();
            onCancel();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Branch"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <Row className="gx-2">
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Phone"
                                name="address.phone"
                                variant="outlined"
                                value={formik.values.address.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.phone && Boolean(formik.errors.address?.phone)}
                                helperText={formik.touched.address?.phone && formik.errors.address?.phone}
                                className="mt-2"
                            />
                        </Col>
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Fax"
                                name="address.fax"
                                variant="outlined"
                                value={formik.values.address.fax}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.fax && Boolean(formik.errors.address?.fax)}
                                helperText={formik.touched.address?.fax && formik.errors.address?.fax}
                                className="mt-2"
                            />
                        </Col>
                    </Row>
                    <Countries
                        formik={formik}
                        name="address.countryCode"
                        onChange={(e, val) => {
                            formik.values.address.countryCode = val?.countryCode;
                        }}
                        countryCode={formik.values.address.countryCode}
                    />
                    <CustomTextField
                        fullWidth
                        label="Address"
                        name="address.address1"
                        variant="outlined"
                        value={formik.values.address.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.address1 && Boolean(formik.errors.address?.address1)}
                        helperText={formik.touched.address?.address1 && formik.errors.address?.address1}
                        className="mt-2"
                    />
                    <CustomTextField
                        fullWidth
                        label="Address2"
                        name="address.address2"
                        variant="outlined"
                        value={formik.values.address.address2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.address2 && Boolean(formik.errors.address?.address2)}
                        helperText={formik.touched.address?.address2 && formik.errors.address?.address2}
                        className="mt-2"
                    />
                    <CustomTextField
                        fullWidth
                        label="City"
                        name="address.city"
                        variant="outlined"
                        value={formik.values.address.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.city && Boolean(formik.errors.address?.city)}
                        helperText={formik.touched.address?.city && formik.errors.address?.city}
                        className="mt-2"
                    />
                    <Row className="gx-2 mb-3">
                        <Col md={8} className="mt-2">
                            {formik.values.address.countryCode === "USA" ?
                                <States
                                    formik={formik}
                                    name="address.stateCode"
                                    onChange={(e, val) => {
                                        formik.values.address.stateCode = val?.stateCode;
                                    }}
                                    stateCode={formik.values.address.stateCode}
                                />
                                :
                                <CustomTextField
                                    fullWidth
                                    label="Province"
                                    name="address.province"
                                    variant="outlined"
                                    value={formik.values.address.province}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address?.province && Boolean(formik.errors.address?.province)}
                                    helperText={formik.touched.address?.province && formik.errors.address?.province}
                                />
                            }
                        </Col>
                        <Col md={4} className="mt-2">
                            <CustomTextField
                                label="ZIP / Postal Code"
                                name="address.zip"
                                variant="outlined"
                                value={formik.values.address.zip}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.zip && Boolean(formik.errors.address?.zip)}
                                helperText={formik.touched.address?.zip && formik.errors.address?.zip}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>  
                        <FormControlLabel                            
                            control={<Checkbox
                                name="isMainBranch"
                                checked={formik.values.isMainBranch}
                                onChange={formik.handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                error={formik.touched.isMainBranch && Boolean(formik.errors.isMainBranch)}
                                helperText={formik.touched.isMainBranch && formik.errors.isMainBranch}
                                />}
                            label="Is main office?"
                            labelPlacement="end"
                            />                            
                        </Col>
                    </Row>
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default BranchForm;
