import {useState} from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmDialog from "../ConfirmDialog";
import {useDeleteExhibitMutation} from "../../services/authService";

function DeleteExhibit(props) {
  const {exhibit, setSelectedExhibit, changeExhibitHandler} = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteExhibit] = useDeleteExhibitMutation();

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  const handleDelete = async () => {
    const response = await deleteExhibit(exhibit.id);
    if (response.data == true) {
      setSelectedExhibit(null);
    }    
  };

  return (
    <>
      <DeleteOutlinedIcon fontSize="small"
        style={
          {
            color: "grey",
            cursor: "pointer"
          }
        }
        onClick={openDeleteModalHandler}/>
      <ConfirmDialog title="Delete Exhibit"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this exhibit?
      </ConfirmDialog>
    </>
  )
}

export default DeleteExhibit;
