import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import BorderBox from "../../components/ui/BorderBox";
import styles from "./Client.module.scss";
import ClientBranchContact from "../../components/lookups/ClientBranchContact";
import ClientName from "../../components/lookups/ClientName";
import ContactsGrid from "../../components/lookups/ContactsGrid";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ExhibitList from "../../components/lookups/exhibits/ExhibitList";
import InventoryList from "../../components/lookups/inventory/InventoryList";
import { useGetClientJobsQuery } from "../../services/authService";
import ClientConsumableList from "../../components/clientConsumables/ClientConsumableList";
import ClientJobsList from "../../components/clientJobs/ClientJobsList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJobChecklistId } from "../../redux/reducers/jobSlice";


const Client = () => {
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);
    const [tabValue, setTabValue] = useState('0');
    
    const [sortConfigJob, setSortConfigJob] = useState({sortProperty: null, sortDirection: null});
    const [ clientConsumables, setClientConsumables ] =  useState(null);
    const [ clientJobs, setClientJobs ] =  useState(null);
    
    
    
    const {data: clientJobsData, isFetching: isFetchingClientJobs, refetch: getClientJobs } = useGetClientJobsQuery({clientId: selectedClientId, ...sortConfigJob }, { skip: selectedClientId === null  });

    const navigate = useNavigate();
    const [storedClientId, setStoredClientId] = useState(localStorage.getItem('storedClientId'));
    const [storedBranchId, setStoredBranchId] = useState(localStorage.getItem('storedBranchId'));
    const [preLoadMainBranch, setPreLoadMainBranch] = useState(true);
    const [createdClientId, setCreatedClientId] = useState(null); 
    const [createdBranchId, setCreatedBranchId] = useState(null);    
    const dispatch = useDispatch();
    const updatePlaceholder = () =>{
        console.log('updated called ----');
    };
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleClientJobEdit = (jobId) => {
        dispatch(setJobChecklistId(jobId));
        navigate(`/show-info/${jobId}`);
    };

    // set client jobs
    useEffect(() => {
        const fetchclientJobs = async () => {
            const response = await getClientJobs();
            setClientJobs(response.data?.jobsByClients);
        };
    
        if (selectedClientId) {
            fetchclientJobs();
        }
    }, [selectedClientId, sortConfigJob, clientJobsData]);

    const handleJobSort = (sortProperty) => {
        let sortDirection = 'asc';
        if (sortConfigJob.sortProperty === sortProperty && sortConfigJob.sortDirection === 'asc')
          sortDirection = 'desc';
        setSortConfigJob({sortProperty, sortDirection});
        handleJobUpdated();
    };

    const handleJobUpdated = async () => {
        const response = await getClientJobs();
        setClientJobs(response.data?.jobsByClients);
    };
    return (
        <Container className={`${styles.container} py-3 p-md-4`} style={{ maxWidth: "sm" }}>          
            <BorderBox title="Client" boxStyle="mb-3 mb-md-4">
                <div className="mb-2">
                    <ClientName
                        onUpdate={updatePlaceholder}
                        setSelectedClientId={setSelectedClientId}
                        storedClientId={storedClientId}
                        setStoredClientId={setStoredClientId}
                        setCreatedClientId={setCreatedClientId}
                        createdClientId={createdClientId}
                        setPreLoadMainBranch={setPreLoadMainBranch}
                    />
                </div>
            </BorderBox>

            <BorderBox title="Branches" boxStyle="mb-3 mb-md-4">
                {selectedClientId ? (
                    <ClientBranchContact
                        clientId={selectedClientId}
                        branch={branchDetails}
                        setBranch={setBranchDetails}
                        onUpdate={updatePlaceholder}
                        storedBranchId={storedBranchId}
                        setStoredBranchId={setStoredBranchId}
                        preLoadMainBranch={preLoadMainBranch}
                        setPreLoadMainBranch={setPreLoadMainBranch}
                        setCreatedBranchId={setCreatedBranchId}
                        createdBranchId={createdBranchId}
                    />
                ) : (
                    <h3 className="text-center fw-light muted opacity-50 mb-4">Please select a client</h3>
                )}
            </BorderBox>
            {branchDetails && selectedClientId  ? (
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Contacts" value={'0'} />
                            <Tab label="Jobs" value={'1'} />
                            <Tab label="Inventory" value={'2'} />
                            <Tab label="Exhibits" value={'3'} />
                            <Tab label="Consumables" value={'4'} />
                        </Tabs>
                    </Box>
                    <TabPanel  value={'0'}>
                        <ContactsGrid
                            data={branchDetails}
                            setData={setBranchDetails}
                            onDelete={updatePlaceholder}
                            onUpdate={updatePlaceholder}
                            isContractor={false}
                        />
                    </TabPanel>
                    <TabPanel  value={'1'}>
                        <ClientJobsList 
                            clientJobEdit={handleClientJobEdit}
                            clientId={selectedClientId}
                            items={clientJobs}
                            handleJobSort={handleJobSort}
                            sortConfig={sortConfigJob}
                        />
                    </TabPanel>
                    <TabPanel  value={'2'}>
                        <InventoryList clientId={selectedClientId} />
                    </TabPanel>
                    <TabPanel  value={'3'}>
                        <ExhibitList clientId={selectedClientId} />
                    </TabPanel>
                    <TabPanel value={'4'}>
                        <ClientConsumableList
                            showAddItem={true}
                            showAction={true}
                            clientId={selectedClientId}
                            branchId={branchDetails.id}
                        />
                    </TabPanel>
                </TabContext>            
            ) : (
                <>
                {selectedClientId && 
                    <h3 className="text-center fw-light muted opacity-50 mb-4">Please select a branch</h3>
                }
                </>
            )}
        </Container>
    )
}
export default Client;