import {useEffect, useState} from "react";
import { useSnackbar } from 'notistack';
import {useDeleteContractorMutation} from "../../../services/authService";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import styles from "../showName/DeleteShowName.module.scss";
import messages from "../../../utils/messages";
import ConfirmDialog from "../../ConfirmDialog";

const DeleteContractor = ({ contractor, setContractor, onDelete }) => {
    const [deleteContractor, { isSuccess, error, reset }] = useDeleteContractorMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleDelete = () => {
        deleteContractor(contractor.id);
    }

    const handleMessages = () => {
        if(isSuccess){
            enqueueSnackbar(`${messages.success.deleteContractor}`, { variant: 'success' });
            reset();
            onDelete();
            setContractor(null);
        }
        if(error){
            enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isSuccess || error){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isSuccess, error]);


    return (
        <>
            <DeleteOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <ConfirmDialog
                title="Delete Contractor"
                open={open}
                setOpen={setOpen}
                onConfirm={handleDelete}
            >
                {messages.confirm.deleteContractor}
            </ConfirmDialog>
        </>
    )
}

export default DeleteContractor;
