import {useState} from "react";
import styles from "../showName/EditShowName.module.scss";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";

const EditContact = ({ contactData, setContactData, contact, setContact, onUpdate, isContractor = true }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return(
        <>
            <EditOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit contact
                </DialogTitle>
                <ContactForm
                    contactData={contactData}
                    setContactData={setContactData}
                    contact={contact}
                    setContact={setContact}
                    onCancel={handleClose}
                    onUpdate={onUpdate}
                    isContractor={isContractor}
                />
            </Dialog>
        </>
    )
}

export default EditContact;
