import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {CustomTimePicker} from '../style/mui/customStyle';

export default function TimePickerValue(props) {
    const {onTimeChange, value, sx} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
                <CustomTimePicker
                    value={value}
                    onChange={onTimeChange}
                    closeOnSelect={false}
                    sx={sx}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}