import {
  Dialog,
  DialogTitle
} from "@mui/material";
import ShipmentItemForm from "./ShipmentItemForm";

function AddShipmentItem(props) {
    const { isOpen, onClose, shipmentId, shipmentData } = props;

  return (
    <Dialog open={isOpen}
      onClose={onClose}
      maxWidth="sm" 
      fullWidth
      >
          <ShipmentItemForm onClose={onClose} shipmentId={shipmentId} shipmentData={shipmentData} />
    </Dialog>
  )
}
export default AddShipmentItem;
