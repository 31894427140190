import {TableRow, TableCell} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styles from "../lookups/showName/DeleteShowName.module.scss";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setJobChecklistId } from "../../redux/reducers/jobSlice";

function ClientJobsItem(props) {
  const {item, onEdit} = props;
  const dispatch = useDispatch();

  const handleEditClientJob = (item) => {
    dispatch(setJobChecklistId(item));
    onEdit(item);
  };

  return (
    <TableRow>{
      item ? (
        <>          
          <TableCell style={{ width: "100px" }}>
            {item.jobId}
          </TableCell>
          <TableCell>
            {item.showName}
          </TableCell>
          <TableCell style={{ width: "80px" }}>
            {item.opensOn ? dayjs(item.opensOn).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell style={{ width: "75px" }}>
            {item.status}
          </TableCell>          
          <TableCell style={{ width: "75px" }}>
            <EditOutlinedIcon
                fontSize="small"
                onClick={() => handleEditClientJob(item.jobId)}
                className={`${styles.icon} m-1`}
            />
          </TableCell>
        </>
      ) : <TableCell align="center" colSpan={5} className="py-1">
            No client jobs found
          </TableCell>
    }</TableRow>
  )
}

export default ClientJobsItem;
