import {TableRow, TableCell} from "@mui/material";
import DeleteConsumableItem from "./DeleteConsumableItem";

function ConsumableItem(props) {
  const {item,setConsumableItemsState, showAction} = props;
  return (
    <TableRow>{
      item ? (
        <>
          <TableCell>
            {
            item.description
          }</TableCell>
          <TableCell>{
            item.quantityAssignedToExhibitGiveAway
          }</TableCell>
          <TableCell>{
            item.weight
          }</TableCell>
          {showAction && <TableCell>
            <DeleteConsumableItem consumableId={item.id} setConsumableItemsState={setConsumableItemsState} />
          </TableCell>}
        </>
      ) : <TableCell align="center" colSpan={ showAction ? 4 : 3 } className="py-1">
          No Consumable found
        </TableCell>
    }</TableRow>
  )
}

export default ConsumableItem;
