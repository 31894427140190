import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Col, Container, Row} from "reactstrap";

import ActionBar from "../components/ui/ActionBar";
import LabelInfo from "../components/ui/LabelInfo";
import Install from "../components/installdismantle/Install";
import Dismantle from "../components/installdismantle/Dismantle";
import Travel from "../components/installdismantle/Travel";
import LaborRates from "../components/installdismantle/LaborRates";

import {useGetInstallDismantleQuery, useUpdateInstallDismantleMutation,} from "../services/authService";
import {setInstallDismantleInfo} from "../redux/reducers/installDismantle/installDismantleSlice";
import {InstallDismantleContext} from "../context/InstallDismantleContext";
import {TASK_TYPE} from "../utils/constants";
import messages from "../utils/messages";
import styles from "./InstallDismantle.module.scss";


const InstallDismantle = () => {
    let params = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // Queries
    const {data, isFetching, refetch} = useGetInstallDismantleQuery(params.id);
    const [updateInstallDismantle, {isSuccess: isInstallDismantleUpdateSuccess, isError: isInstallDismantleUpdateError, error: installDismantleUpdateError ,  reset: installDismantleUpdateReset}] = useUpdateInstallDismantleMutation();

    // Local state
    const [state, setState] = useState({});

    const { installDismantleInfo } = useSelector(state => state.installDismantleData);
    const [initialLoad, setInitialLoad] = useState(true);

    const handleSave = () => {
        const requestObj = {
            ...installDismantleInfo,
            laborStraightTime: state?.laborStraightTime,
            laborOvertime: state?.laborOvertime,
            laborDoubleTime: state?.laborDoubleTime
        }
        updateInstallDismantle(requestObj);
    }

    const handleMessages = () => {
        if(isInstallDismantleUpdateSuccess){
            enqueueSnackbar(`${messages.success.updateInstallDismantle}`, { variant: 'success' });
            installDismantleUpdateReset();
            refetch();
        }
        if(isInstallDismantleUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${installDismantleUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    const handleState = (newState) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...newState
            }
        })
    }

    const filterTasksByType = (type) => {
        return state?.tasks?.find(task => task.type === type) ?? null
    }

    useEffect(() => {
        if (data) {
            // set initial data
            const { installDismantle } = data;
            setState(installDismantle);
            let dispatchInfo = {};
            if (initialLoad) {
                dispatchInfo = {
                    jobId: installDismantle?.jobId,
                    jobStatus: installDismantle?.job?.status,
                    // install
                    installationContractorId: installDismantle?.installationContractorId,
                    installationContractorContactId: installDismantle?.installationContractorContactId,
                    secondInstallationContractorId: installDismantle?.secondInstallationContractorId,
                    secondInstallationContractorContactId: installDismantle?.secondInstallationContractorContactId,
                    installSupervisorId: installDismantle?.installSupervisorId,
                    secondInstallSupervisorId: installDismantle?.secondInstallSupervisorId,
                    // dismantle
                    dismantleContractorId: installDismantle?.dismantleContractorId,
                    dismantleContractorContactId: installDismantle?.dismantleContractorContactId,
                    secondDismantleContractorId: installDismantle?.secondDismantleContractorId,
                    secondDismantleContractorContactId: installDismantle?.secondDismantleContractorContactId,
                    dismantleSupervisorId: installDismantle?.dismantleSupervisorId,
                    secondDismantleSupervisorId: installDismantle?.secondDismantleSupervisorId,
                    // tasks
                    installationContractor: filterTasksByType(TASK_TYPE.InstallationContractor),
                    dismantleContractor: filterTasksByType(TASK_TYPE.DismantleContractor),
                    installItineraryReservations: filterTasksByType(TASK_TYPE.InstallItineraryReservations),
                    dismantleItineraryReservations: filterTasksByType(TASK_TYPE.DismantleItineraryReservations)
                };
            }
            dispatch(setInstallDismantleInfo(dispatchInfo));
            setInitialLoad(false);
        }
        // eslint-disable-next-line
    },[data, dispatch, initialLoad]);

    useEffect(() => {
        if(isInstallDismantleUpdateSuccess || isInstallDismantleUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isInstallDismantleUpdateSuccess, isInstallDismantleUpdateError]);
    
    return (
        <InstallDismantleContext.Provider
            value={useMemo( () => ([state, handleState, refetch, handleSave]), [state] )}
        >
            <Container className={`${styles.container} py-3 p-md-4`} style={{ maxWidth: "sm" }}>
                <ActionBar onSave={handleSave}/>

                <Row className="py-3 py-md-4">
                    <Col>
                        <LabelInfo label="CMC Job Number" info={installDismantleInfo?.jobId}/>
                    </Col>
                    <Col>
                        <LabelInfo label="Status" info={installDismantleInfo?.jobStatus}/>
                    </Col>
                </Row>

                <Install />

                <Dismantle />

                <LaborRates />

                <Travel />

                <ActionBar onSave={handleSave}/>
            </Container>
        </InstallDismantleContext.Provider>
    )
}

export default InstallDismantle;