import {useEffect, useState} from "react";
import { useSnackbar } from 'notistack';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import styles from "../lookups/showName/DeleteShowName.module.scss";
import messages from "../../utils/messages";
import ConfirmDialog from "../ConfirmDialog";
import { useDeleteClientConsumableMutation } from "../../services/authService";
import useAuthentication from "../../hooks/useAuthentication";

const DeleteConsumableItem = ({ consumable, setConsumable, onConsumableUpdated }) => {
    const [deleteClientConsumable, { isSuccess, error, reset }] = useDeleteClientConsumableMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    useAuthentication([error]);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleDelete = () => {
      deleteClientConsumable(consumable.id);
    }

    const handleMessages = () => {
        if(isSuccess){
            enqueueSnackbar(`${messages.success.deleteClientConsumable}`, { variant: 'success' });
            reset();
            setConsumable(null);
            onConsumableUpdated();
        }
        if(error){
            enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isSuccess || error){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isSuccess, error]);
    
    return (
        <>
            <DeleteOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <ConfirmDialog
                title="Delete Consumable"
                open={open}
                setOpen={setOpen}
                onConfirm={handleDelete}
            >
                {messages.confirm.deleteClientConsumable}
            </ConfirmDialog>
        </>
    )
}

export default DeleteConsumableItem;
