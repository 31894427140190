import {useEffect, useState} from "react";
import {useSnackbar} from 'notistack';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import AddRowButton from "../../ui/AddRowButton";
import ConfirmDialog from "../../ConfirmDialog";
import sxTableStyles from "../../../style/sxTableStyles";
import { useDeleteExhibitMutation, useGetExhibitsQuery, useClientExhibitQuery} from "../../../services/authService";
import messages from "../../../utils/messages";
import AddExhibit from "./AddExhibit";
import EditExhibit from "./EditExhibit";
import styles from "./ExhibitList.module.scss";
import useAuthentication from "../../../hooks/useAuthentication";

function ExhibitList(props) {
  const { clientId } = props;
  
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedExhibit, setSelectedExhibit] = useState(null);
  const [exhibits, setExhibits] = useState([]);
  const [sortConfig, setSortConfig] = useState({sortProperty: 'createdDate', sortDirection: 'asc'});

  const {data: exhibitsData, isLoading, error: exhibitError, refetch: getExhibits} = useClientExhibitQuery({clientId: clientId, ...sortConfig});
  const [deleteExhibit, { isSuccess, error: deleteExpenseError, reset }] = useDeleteExhibitMutation();
  const {enqueueSnackbar} = useSnackbar();
  let actionCellWidth = 66;
  useAuthentication([deleteExpenseError, exhibitError]);
  const handleRequestSort = (sortProperty) => {
    let sortDirection = 'asc';
    if (sortConfig.sortProperty === sortProperty && sortConfig.sortDirection === 'asc')
      sortDirection = 'desc';
    setSortConfig({sortProperty, sortDirection});
    handleExhibitUpdated();
  };
  
  useEffect(() => {
    const fetchExhibits = async () => {
      const response = await getExhibits();
      setExhibits(response.data?.exhibits);
    };

    if (clientId) {
      fetchExhibits();
    }
  }, [clientId, sortConfig]);

  const handleExhibitUpdated = async () => {
    const response = await getExhibits();
    setExhibits(response.data?.exhibits);
  };
  
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleDeleteConfirm = (id) => {
    setSelectedId(id);
    setConfirmOpen(true);
  };

  const handleEditExhibit = (exhibit) => { 
    setSelectedExhibit(exhibit);
    setOpenEditDialog(true);
  };

  const handleDelete =  async () => {
    const response = await deleteExhibit(selectedId);    
    if (response.data === true) {
      enqueueSnackbar(`${messages.success.deleteExhibit}`, { variant: 'success' });
      handleExhibitUpdated();
    } else {
      enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
    }
  };

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
  };
  
  return (
    <>
      <TableContainer component={Paper}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell  onClick={() => handleRequestSort('name')} style={{ cursor: 'pointer' }}>Name
                {sortConfig.sortProperty == 'name' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('notes')} style={{ cursor: 'pointer' }}>Notes
              {sortConfig.sortProperty == 'notes' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{
            exhibits ?. map((exhibit) => (
              <TableRow key={exhibit.id}>
                <TableCell className="px-2">{exhibit.name}</TableCell>
                <TableCell className="px-2">{exhibit.notes}</TableCell>
                <TableCell className="px-1" style={{width: actionCellWidth}}>
                  <EditOutlinedIcon fontSize="small" className={`${styles.icon} m-1`}
                    onClick={
                      () => handleEditExhibit(exhibit)
                    }
                  />
                  <DeleteOutlinedIcon fontSize="small" className={`${styles.icon}`}
                    onClick={
                      () => handleDeleteConfirm(exhibit.id)
                    }                    
                  />
                </TableCell>
              </TableRow>
            ))
          }
            {
            !exhibits.length && <TableRow>
              <TableCell align="center"
                colSpan={4}
                className="py-1">No exhibits found</TableCell>
            </TableRow>
          }</TableBody>
        </Table>
      </TableContainer>
      <AddRowButton onAdd={openAddDialog}>
        + Add Exhibit
      </AddRowButton>
      <AddExhibit addDialogOpen={addDialogOpen}
        openAddDialog={openAddDialog}
        closeAddDialog={closeAddDialog}
        onExhibitAdded={handleExhibitUpdated}
        clientId={clientId}
      />
      <EditExhibit open={openEditDialog}
        onClose={handleCloseEditDialog}
        exhibit={selectedExhibit}
        onExhibitUpdated={handleExhibitUpdated}
        clientId={clientId}
    /> 
    {
      confirmOpen && <ConfirmDialog title="Delete Exhibit"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this exhibit?
      </ConfirmDialog>
    } </>
  )
}

export default ExhibitList;
