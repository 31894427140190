import { useEffect, Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../redux/actions/authActions'
import Error from '../Error'
import { ReactComponent as Logo } from "../../assets/image/logo.svg";

const LoginScreen = () => {
  const [customError, setCustomError] = useState(null)
  const {loginData:{ loading, userInfo, error} } = useSelector((state) => state)
    const dispatch = useDispatch()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      rememberMe: true
    }
  }
    )
  const navigate = useNavigate()

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo?.isAuthenticated && userInfo?.token) {
      navigate('/tasklist')
    }
    else if(userInfo?.isAuthenticated === false){
      setCustomError("Login failed. Please try again.")
    }
  }, [navigate, userInfo])

  const submitForm = (data) => {
    dispatch(userLogin(data))
  }

  return (
      <Fragment>
          <div className="text-center mb-4">
              <Logo />
          </div>
          <form onSubmit={handleSubmit(submitForm)}>
              {error && <Error>{error}</Error>}
              {customError && <Error>{customError}</Error>}
              <div className="form-group mb-3">
                  <label htmlFor="usernameInput">Username</label>
                  <input type="text" id="usernameInput" className="form-control" {...register('userName')} required placeholder="Username" />
              </div>
              <div className="form-group mb-3">
                  <label htmlFor="passwordInput">Password</label>
                  <input type="password" id="passwordInput" className="form-control" {...register('password')} required placeholder="Password" />
              </div>
              <div className="text-center mb-4 mt-4">
                  <button type="submit" className="btn btn-primary px-4" disabled={loading}>Login</button>
              </div>
              <div className='text-center'>
                  <NavLink tag={Link} className="text-dark" to="/forgotpassword">Forgot password?</NavLink>
              </div>
          </form>
      </Fragment>
  )
}
export default LoginScreen