import React, {useEffect, useState} from "react";
import {FLIGHT_TYPE} from "../../../utils/constants";
import AddRowButton from "../../ui/AddRowButton";

const AddFlight = ({ flights, onAddFlight }) => {
    const [addDepartureFlightDisabled, setAddDepartureFlightDisabled] = useState(false);
    const [addReturnFlightDisabled, setAddReturnFlightDisabled] = useState(false);

    useEffect(() => {
        if(flights){
            const departingFlights = flights.filter(f => f.type === FLIGHT_TYPE.Departure);
            const returnFlights = flights.filter(f => f.type === FLIGHT_TYPE.Return);

            if(departingFlights){
                const disabledFlag = departingFlights?.some((flight) => flight.isNew || departingFlights.length > 1)
                setAddDepartureFlightDisabled(disabledFlag);
            }
            if(returnFlights){
                const disabledFlag = returnFlights?.some((flight) => flight.isNew || returnFlights.length > 1)
                setAddReturnFlightDisabled(disabledFlag);
            }
        }
    }, [flights]);

    return (
        <div className="d-flex">
            <AddRowButton
                onAdd={() => onAddFlight(FLIGHT_TYPE.Departure)}
                disabled={addDepartureFlightDisabled}
            >
                +Add Departure Flight
            </AddRowButton>
            <AddRowButton
                onAdd={() => onAddFlight(FLIGHT_TYPE.Return)}
                disabled={addReturnFlightDisabled}
            >
                +Add Return Flight
            </AddRowButton>
        </div>
    )
}

export default AddFlight;
