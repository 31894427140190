import {useEffect, useState} from "react";
import { useSnackbar } from 'notistack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Dialog, DialogTitle, Paper} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddRowButton from "../ui/AddRowButton";
import ConfirmDialog from "../ConfirmDialog";
import styles from "./ContactGrid.module.scss";
import sxTableStyles from "../../style/sxTableStyles";
import AddContact from "./contact/AddContact";
import {useDeleteContactMutation} from "../../services/authService";
import messages from "../../utils/messages";
import ContactForm from "./contact/ContactForm";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ContactsGrid = (props) => {
    const { data, setData, onDelete, onUpdate, isContractor = true } = props;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [sortConfig, setSortConfig] = useState({sortProperty: 'createdDate', sortDirection: 'asc'});

    const [selectedContact, setSelectedContact] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deleteContact, { isSuccess, error, reset }] = useDeleteContactMutation();
    const { enqueueSnackbar } = useSnackbar();
    let actionCellWidth = isContractor ? 36 : 66;

    useEffect(() => {
        if(data?.contacts){
            setContacts(data?.contacts?.filter((c) => !c.isDeleted));
        }
    }, [data]);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleDeleteConfirm = (id) => {
        setSelectedId(id);
        setConfirmOpen(true);
    };

    const handleEditContact = (contact) => {
        setSelectedContact(contact);
        setOpenEditDialog(true);
    }; 

    const handleDelete = () => {
        deleteContact(selectedId);
    };

    const openAddDialog = () => {
        setAddDialogOpen(true);
    };

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    };

    const handleMessages = () => {
        if (isSuccess) {
            enqueueSnackbar(`${messages.success.deleteContact}`, { variant: 'success' });
            onDelete();
            reset();
            if(data?.contacts){
                setData({
                    ...data,
                    contacts: data?.contacts?.filter(c => !c.isDeleted),
                });
                setContacts(data?.contacts?.filter((c) => !c.isDeleted));
            }
        }
        if(error){
            enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        if(isSuccess || error){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isSuccess, error]);

    const handleSort = (property) => {
        const sortedContacts = contacts.slice().sort((a, b) => {
          const valueA = a[property].toUpperCase(); // Convert to uppercase for case-insensitive comparison
          const valueB = b[property].toUpperCase(); // Convert to uppercase for case-insensitive comparison
      
          if (valueA < valueB) {
            return sortConfig.sortDirection === "asc" ? 1 : -1;
          }
          if (valueA > valueB) {
            return sortConfig.sortDirection === "asc" ? -1 : 1;
          }
          return 0;
        });
      
        setContacts(sortedContacts);
        setSortConfig({
          sortProperty: property,
          sortDirection:
            sortConfig.sortDirection === "asc" ? "desc" : "asc",
        });
        console.log("handleSort --", property);
        console.log('sortConfig', sortConfig);
      };
      

    return (
        <>
            <TableContainer component={Paper} style={{maxHeight:'600px', overflow: 'auto'}} className="rounded-0 shadow-none">
                <Table sx={sxTableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Contact 
                            {sortConfig.sortProperty == 'name' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : '')} 
                            </TableCell>
                            <TableCell onClick={() => handleSort('phone')} style={{ cursor: 'pointer' }}>Phone 
                            {sortConfig.sortProperty == 'phone' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : '')} 
                            </TableCell>
                            <TableCell onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>Email 
                            {sortConfig.sortProperty == 'email' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}}  /> : '')} 
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            contacts?.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell className="px-2">{contact.name}</TableCell>
                                    <TableCell className="px-2">{contact.phone}</TableCell>
                                    <TableCell className="px-2">{contact.email}</TableCell>
                                    <TableCell className="px-1" style={{ width: actionCellWidth }}>
                                        {!isContractor && (
                                            <EditOutlinedIcon
                                                fontSize="small"
                                                onClick={() => handleEditContact(contact)}
                                                className={`${styles.icon} m-1`}
                                            />
                                        )}
                                        <DeleteOutlinedIcon
                                            fontSize="small"
                                            onClick={() => handleDeleteConfirm(contact.id)}
                                            className={`${styles.icon}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            !contacts.length &&
                            <TableRow>
                                <TableCell align="center" colSpan={4} className="py-1">No contacts found</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRowButton onAdd={openAddDialog}>
                + Add Contact
            </AddRowButton>

            <AddContact
                contactData={data}
                setContactData={setData}
                isOpen={addDialogOpen}
                onCancel={closeAddDialog}
                onAdd={onUpdate}
                isContractor={isContractor}
            />

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit contact
                </DialogTitle>
                <ContactForm
                    contactData={data}
                    setContactData={setData}
                    contact={selectedContact}
                    setContact={setSelectedContact}
                    onCancel={handleCloseEditDialog}
                    onUpdate={onUpdate}
                    isContractor={isContractor}
                />
            </Dialog>

            {
                confirmOpen &&
                <ConfirmDialog
                    title="Delete Contact?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete this contact?
                </ConfirmDialog>
            }
        </>
    )
}

export default ContactsGrid;
