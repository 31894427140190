import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../showName/EditShowName.module.scss";
import ContactForm from "./ContactForm";

const AddContact = ({ contactData, setContactData, isOpen, onAdd, onCancel, isContractor = true }) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add contact
                </DialogTitle>
                <ContactForm
                    contactData={contactData}
                    setContactData={setContactData}
                    onCancel={onCancel}
                    onAdd={onAdd}
                    isContractor={isContractor}
                />
            </Dialog>
        </div>
    )
}

export default AddContact;
