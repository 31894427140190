import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import sxTableStyles from "../../style/sxTableStyles";
import dayjs from "dayjs";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import AddSetupDismantle from "./AddSetupDismantle";
import DeleteSetupDismantle from "./DeleteSetupDismantle";
import EditSetupDismantle from "./EditSetupDismantle";
import {FORMATS} from "../../utils/constants";
import {formatDateTimeDayjs} from "../../utils/common";


const SetupDismantle = ({ type, data, onUpdate, containerStyle }) => {
    const [rows, setRows] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);

    const sxCellStyle = {
        "& .MuiStack-root": {
            paddingTop: 0,

            "& .MuiTextField-root": {
                width: '100%',
                minWidth: 100
            },
            "& .MuiOutlinedInput-input": {
                paddingLeft: 0,
            }
        }
    }

    const sxTableTextFieldStyle = {
        backgroundColor: 'transparent'
    }

    const handleDate = (id, value) => {
        setRows((rows) => {
            return rows.map(row => ((row.id === id) ?
                {
                    ...row,
                    eventDate: formatDateTimeDayjs(value, FORMATS.dateTime),
                    eventDateOnly: formatDateTimeDayjs(value, FORMATS.date),
                    isUpdated: true
                }
                : row))
        });
    }

    const handleStartTime = (id, value) => {
        setRows((rows) => {
            return rows.map(row => ((row.id === id) ?
                {
                    ...row,
                    startTime: formatDateTimeDayjs(value, FORMATS.time),
                    isUpdated: true
                }
                : row))
        });
    }

    const handleEndTime = (id, value) => {
        setRows((rows) => {
            return rows.map(row => ((row.id === id) ?
                {
                    ...row,
                    endTime: formatDateTimeDayjs(value, FORMATS.time),
                    isUpdated: true
                }
                : row))
        });
    }

    useEffect(() => {
        if(data){
            let selRows = data?.filter(d => d.type === type);
            setRows(selRows);
        }
    }, [data, type]);

    useEffect(() => {
        if(rows){
            const disabledFlag = rows?.some((row) => row.isNew)
            setAddDisabled(disabledFlag)
        }
    }, [rows]);

    return (
        <div className={`${containerStyle}`}>
            <TableContainer component={Paper} className={`rounded-0 shadow-none overflow-hidden`}>
                <Table sx={sxTableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Event Date</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows?.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <DatePicker
                                                value={dayjs(row.eventDateOnly)}
                                                onDateChange={(newValue) => handleDate(row.id, newValue)}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <TimePicker
                                                value={dayjs(`${row.eventDateOnly}T${row.startTime}`)}
                                                onTimeChange={(newValue) => handleStartTime(row.id, newValue)}
                                                sx={sxTableTextFieldStyle}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <TimePicker
                                                value={dayjs(`${row.eventDateOnly}T${row.endTime}`)}
                                                onTimeChange={(newValue) => handleEndTime(row.id, newValue)}
                                                sx={sxTableTextFieldStyle}
                                            />
                                        </TableCell>
                                        <TableCell className="px-1" style={{ width: 36 }}>
                                            {
                                                row.isUpdated ?
                                                    <EditSetupDismantle
                                                        row={row}
                                                        type={type}
                                                        onUpdate={onUpdate}
                                                    />
                                                    :
                                                    <DeleteSetupDismantle
                                                        type={type}
                                                        id={row.id}
                                                        onDelete={onUpdate}
                                                    />
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            !rows.length &&
                            <TableRow>
                                <TableCell align="center" colSpan={4} className="py-1">No rows to show</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AddSetupDismantle
                type={type}
                setRows={setRows}
                disabled={addDisabled}
            />
        </div>
    )
}

export default SetupDismantle;
