import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import BorderBox from "../../ui/BorderBox";
import {useCreateHotelMutation, useUpdateHotelMutation} from "../../../services/authService";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import messages from "../../../utils/messages";

const hotelSchema = Yup.object({
    name: Yup.string().required('Hotel Name is required'),
    addressInfo: Yup.string()
});

const HotelForm = ({ hotel , setHotel, onCancel, onUpdate, onAdd }) => {
    const [createHotel, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateHotelMutation();
    const [updateHotel, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateHotelMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [previousName, setPreviousName] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: hotel?.name ?? '',
            addressInfo: hotel?.addressInfo ?? '',
        },
        validationSchema: hotelSchema,
        onSubmit: async (values) => {
            setPreviousName(values.name);
            setHotel('');
            if(hotel){ // edit
                await updateHotel({
                    id: hotel?.id,
                    ...values
                });
            } else { // add
                await createHotel({
                    id: 0,
                    ...values
                });
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createHotel}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setHotel(previousName);
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateHotel}`, { variant: 'success' })
            updateReset();
            onUpdate();
            onCancel();
            setHotel(previousName);
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Hotel Name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <CustomTextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Address Info"
                        name="addressInfo"
                        variant="outlined"
                        value={formik.values.addressInfo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressInfo && Boolean(formik.errors.addressInfo)}
                        helperText={formik.touched.addressInfo && formik.errors.addressInfo}
                        className="mt-2"
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default HotelForm
