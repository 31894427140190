import { useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addNewUser } from '../../redux/actions/authActions'
import Error from '../Error'
import { ReactComponent as Logo } from "../../assets/image/logo.svg";
import styles from './NewUser.module.scss';

const NewUser = () => {

    const { loginData:{userToken, loading, error, success} } = useSelector((state) => state)

    const token = userToken;

    const dispatch = useDispatch()

    const { register, handleSubmit } = useForm({
        defaultValues: {
            token: token
        }
    }
    )
    const navigate = useNavigate()

    useEffect(() => {
        if (success) navigate('/tasklist')
    }, [navigate, success])

    const submitForm = (data) => {
        
        // transform email string to lowercase to avoid case sensitivity issues in login
        data.email = data.email.toLowerCase()

        dispatch(addNewUser(data))
    }

    return (
        <Fragment>
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <div className={`p-4 mx-auto rounded-3 ${styles.formWrap}`}>
                    <div className="text-center mb-4">
                        <Logo />
                    </div>
                    <form onSubmit={handleSubmit(submitForm)}>
                        {success && <div>User has been added successfully.</div>}
                        {error && <Error>{error}</Error>}

                        <div className='form-group mb-3'>
                            <label htmlFor='firstName'>First Name</label>
                            <input
                                type='text'
                                className='form-control'
                                {...register('firstName')}
                                required
                            />
                        </div>
                        <div className='form-group mb-3'>
                            <label htmlFor='lastName'>Last Name</label>
                            <input
                                type='text'
                                className='form-control'
                                {...register('lastName')}
                                required
                            />
                        </div>
                        <div className='form-group mb-3'>
                            <label htmlFor='username'>Username</label>
                            <input
                                type='text'
                                className='form-control'
                                {...register('username')}
                                required
                            />
                        </div>
                        <div className='form-group mb-3'>
                            <label htmlFor='email'>Email</label>
                            <input
                                type='email'
                                className='form-control'
                                {...register('email')}
                                required
                            />
                        </div>
                        <div className="text-center mb-3 mt-4">
                            <button type="submit" className="btn btn-primary px-4" disabled={loading}>Add User</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default NewUser