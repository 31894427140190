import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styles from "../showName/EditShowName.module.scss";
import DialogTitle from "@mui/material/DialogTitle";
import FacilityForm from "./FacilityForm";

const EditFacility = ({ facility, setFacility, onUpdate, setNewFacility }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <EditOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit facility
                </DialogTitle>
                <FacilityForm
                    facility={facility}
                    setFacility={setFacility}
                    onCancel={handleClose}
                    onUpdate={onUpdate}
                    setNewFacility={setNewFacility}
                />
            </Dialog>
        </>
    )
}

export default EditFacility;