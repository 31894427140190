import React, { useEffect, useState, useRef } from "react";
import {Paper, TableContainer, Table, TableHead, TableBody, TableFooter, TableRow, TableCell} from "@mui/material";
import BorderBox from "../ui/BorderBox";
import AddRowButton from "../ui/AddRowButton";
import EditTravelExpense from "./travelExpense/EditTravelExpense";
import DeleteTravelExpense from "./travelExpense/DeleteTravelExpense";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {useGetTravelExpensesQuery} from "../../services/authService";
import {calculateSum, numFloat} from "../../utils/common";
import {EXPENSE_TYPE, TRAVEL_EXPENSE} from "../../utils/constants";
import sxTableStyles from "../../style/sxTableStyles";
import sxTableTextFieldStyle from "../../style/sxTableTextFieldStyles";
import {CustomTextField} from "../../style/mui/customStyle";
import useAuthentication from "../../hooks/useAuthentication";

const Travel = ({ containerStyle }) => {
    const tableRef = useRef(null);
    const [state] = useInstallDismantle();
    const {data, isFetching, error, refetch: refetchTravelExpenses} = useGetTravelExpensesQuery(state?.id, { skip: !state?.id });
    useAuthentication([error]);
    const notEditableTypes = [TRAVEL_EXPENSE.Airfare, TRAVEL_EXPENSE.Hotel, TRAVEL_EXPENSE.CarRental];

    const [expenses, setExpenses] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);

    const [estimateTotal, setEstimateTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);

    const [expenseRows, setExpenseRows] = useState([]);

    const newExpense = {
        rowid: Date.now(),
        id: 0,
        installDismantleId: state?.id,
        description: "",
        estimate: 0,
        actual: 0,
        invoice: 0,
        isUpdated: true,
        isNew: true,
    }

    const handleAddExpense = () => {
        setExpenses((expenses) => [...expenses, newExpense]);
        setExpenseRows((expenses) => [...expenses, newExpense])
    }

    useEffect(() => {
        if (data) {
            const expenseList = data.travelExpenses.filter(item => !item.isDeleted);

            const expenseRows = expenseList.map(item => ({
                ...item,
                rowId: item.id+Date.now() // Generating random auto-number
            }));
            
            setExpenseRows(expenseRows)
            setExpenses(expenseRows); //expenseRows
        }
    }, [data, isFetching]);

    useEffect(() => {
        if(expenses){
            const disabledFlag = expenses?.some((expense) => expense.isNew)
            setAddDisabled(disabledFlag);
            updateTotal(EXPENSE_TYPE.Estimate);
            updateTotal(EXPENSE_TYPE.Actual);

            setExpenseRows(expenses)
        }
        // eslint-disable-next-line
    }, [expenses]);

    const updateTotal = (type) => {
        const expenseList = expenses?.filter(item => !item.isDeleted);
        const total = expenseList ? calculateSum(expenseList, type) : 0;

        if(type === EXPENSE_TYPE.Estimate){
            setEstimateTotal(total);
        }
        if(type === EXPENSE_TYPE.Actual){
            setActualTotal(total);
        }
    }

    function handleRowUpdate(id, update) {
       // console.log(id, update);
        setExpenses((expenses) => {
            return expenses.map(expense => ((expense.id === id) ?
                {
                    ...expense,
                    ...update,
                    isUpdated: true,
                }
                : expense))
        });
        
    }

    const isEditable = (type) => !notEditableTypes.some(t => t === type);

    return (
        <BorderBox title="Travel" boxStyle="mb-3 mb-md-4">
            <div className={`${containerStyle}`}>
                <TableContainer component={Paper} className={`rounded-0 shadow-none overflow-hidden`}>
                    <Table sx={sxTableStyles} ref={tableRef} >
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Estimate</TableCell>
                                <TableCell>Actual</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            expenseRows?.length > 0 ?
                                <>
                                    <TableBody>
                                        {
                                            expenseRows.map((expense) => {
                                                return (
                                                    /*<RenderRow {...expense}/>*/
                                                    <TableRow key={expense.rowId}>
                                                        <TableCell>
                                                            <CustomTextField
                                                                fullWidth
                                                                size="small"
                                                                variant="outlined"
                                                                sx={sxTableTextFieldStyle}
                                                                name="description"
                                                                defaultValue={expense.description ?? ""}
                                                                onChange={(event) => {
                                                                    handleRowUpdate(expense.id, { description: (event.target.value) });
                                                                }}
                                                                className={!isEditable(expense.type) ? 'pe-none opacity-75' : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <CustomTextField
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                variant="outlined"
                                                                sx={sxTableTextFieldStyle}
                                                                name="estimate"
                                                                defaultValue={numFloat(expense.estimate) ?? ""}
                                                                onChange={(event) => {
                                                                    handleRowUpdate(expense.id, { estimate: numFloat(event.target.value) });
                                                                    updateTotal(EXPENSE_TYPE.Estimate);
                                                                }}
                                                                className={!isEditable(expense.type) ? 'pe-none opacity-75' : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <CustomTextField
                                                                fullWidth
                                                                type="number"
                                                                size="small"
                                                                variant="outlined"
                                                                sx={sxTableTextFieldStyle}
                                                                name="actual"
                                                                defaultValue={numFloat(expense.actual) ?? ""}
                                                                //value={numFloat(expense.actual) ?? ""}
                                                                onChange={(event) => {
                                                                    handleRowUpdate(expense.id, { actual: event.target.value != 0 ? numFloat(event.target.value) : event.target.value });
                                                                    updateTotal(EXPENSE_TYPE.Actual);
                                                                }}
                                                                className={!isEditable(expense.type) ? 'pe-none opacity-75' : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell className="px-1" style={{ width: 36 }}>
                                                            {
                                                                expense.isUpdated ?
                                                                    <EditTravelExpense
                                                                        expense={expense}
                                                                        onUpdate={refetchTravelExpenses}
                                                                    />
                                                                    :
                                                                    <DeleteTravelExpense
                                                                        id={expense.id}
                                                                        disabled={!isEditable(expense.type)}
                                                                        onDelete={refetchTravelExpenses}
                                                                    />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell>Total</TableCell>
                                            <TableCell>{estimateTotal}</TableCell>
                                            <TableCell>{actualTotal}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </>

                                :

                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4} className="py-1">No expenses to show</TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                    </Table>
                </TableContainer>
                <AddRowButton
                    onAdd={handleAddExpense}
                    disabled={addDisabled}
                >
                    + Add Expense
                </AddRowButton>
            </div>
        </BorderBox>
    )
}

export default Travel;
