import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../lookups/showName/EditShowName.module.scss";
import ClientJobsForm from "./ClientJobsForm";

const AddClientJobsItem = ({ clientId, isOpen, onCancel, clientJobEdit }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`${styles.header} text-center`}>
                Add Client Job
            </DialogTitle>
            <ClientJobsForm
                clientId={clientId}
                onCancel={onCancel}
                clientJobEdit={clientJobEdit}
            />
        </Dialog>
    )
}

export default AddClientJobsItem;