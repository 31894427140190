import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useDispatch, useSelector} from 'react-redux';
import {setAllocatedConsumableItems} from '../../redux/reducers/exhibit/exhibitSlice';
import {useCreateExhibitConsumableMutation, useGetAssignedConsumablesQuery, useGetConsumableItemsQuery} from "../../services/authService";
import ConsumableItemList from './ConsumableItemList';
import {CustomButton, CustomTextField, CustomSelect, CustomAutocomplete} from '../../style/mui/customStyle';
import {useEffect, useState} from 'react';
import Error from '../../pages/Error';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import useAuthentication from '../../hooks/useAuthentication';

const validationSchema = Yup.object().shape({
  consumableId: Yup.number().required('Consumable is required'),
  quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1')
});

function ConsumableItemForm(props) {
  const [error, setError] = useState(null); // State variable for error message
  const [consumableItemsState, setConsumableItemsState] = useState(null);
  const [open, setOpen] = useState(false);

  const {onClose, onSubmit} = props;

  const {jobExhibit} = useSelector((state) => {
    return state.jobExhibitData;
  });

  const dispatch = useDispatch();

  const [createExhibitConsumable, {
      isLoading,
      isError
    }
  ] = useCreateExhibitConsumableMutation();

  const {data: consumableItems, isFetchingConsumableItems, error: consumableItemsError, refetch: refetchConsumableItems} = useGetConsumableItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.job?.clientId);
  const {data: assignedConsumableItems, isFetching: fetchingAssignedConsumableItems, error: assignedConsumableItemsError, refetch: refetchAssignedConsumableItems} = useGetAssignedConsumablesQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.id);

  useAuthentication([assignedConsumableItemsError, consumableItemsError]);
  useEffect(() => {
    if (consumableItems) {
      setConsumableItemsState(consumableItems);
    }
  }, [consumableItems]);

  const formik = useFormik({
    initialValues: {
      consumableId: '',
      exhibitGiveAwayId: '',
      quantity: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      values.exhibitGiveAwayId = jobExhibit?.exhibitGiveAway?.id;
      const response = await createExhibitConsumable(values);
      if (response.data === true) {
        const refetch = await refetchAssignedConsumableItems();
        dispatch(setAllocatedConsumableItems(refetch.data.consumables));

        const refetchResponse = await refetchConsumableItems();
        setConsumableItemsState(refetchResponse.data);
        enqueueSnackbar(`${messages.success.createExhibitConsumable}`, { variant: 'success' });
        // formik.resetForm();
      } else {
        const errorMessages = response.error.data.map(error => error.errorMessage);
        enqueueSnackbar(`${messages.error.prefix} ${errorMessages ? errorMessages : messages.error.generic }`, { variant: 'error' });
      }
    }
  });

  const onChangeHandler = (event,value) =>{
    formik.setFieldValue("consumableId", value ? value.id : "")
    if(!value){
      setOpen(true);
    }
  }

  return (
    <>
      <DialogTitle>Add Consumable</DialogTitle>
      <DialogContent>
        <form onSubmit={
          formik.handleSubmit
        }>
          
          <FormControl fullWidth className='mt-2'>
            {/* <InputLabel htmlFor="consumableId">Consumable</InputLabel> */}
            {/* <CustomSelect label="Consumable" name="consumableId"
              value={ consumableItemsState && consumableItemsState.consumables.length > 0 ? formik.values.consumableId : '' }
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              error={
                formik.touched.consumableId && Boolean(formik.errors.consumableId)
            }>
              <MenuItem disabled value="">
                <em>Select Consumable</em>
              </MenuItem>
              {
              consumableItemsState ? consumableItemsState.consumables.map((item) => (
                <MenuItem key={
                    item.id
                  }
                  value={
                    item.id
                }>
                  {
                  item.displayText
                } </MenuItem>
              )) : null
            } </CustomSelect> */}
             <CustomAutocomplete
              id="consumableId"
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={consumableItemsState ? consumableItemsState.consumables : []}
              getOptionLabel={(option) => option.displayText || ""}
              value={consumableItemsState ? consumableItemsState.consumables.find(item => item.id === formik.values.consumableId) : null}
              onChange={onChangeHandler}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Consumable"
                  error={formik.touched.consumableId && Boolean(formik.errors.consumableId)}
                  helperText={formik.touched.consumableId && formik.errors.consumableId ? formik.errors.consumableId : ""}
                />
              )}
            />
            {/* {
            formik.touched.consumableId && formik.errors.consumableId && (
              <FormHelperText error>
                {
                formik.errors.consumableId
              }</FormHelperText>
            )
          }  */}
          </FormControl>
          <CustomTextField label="Quantity" name="quantity" type="number"
            value={
              formik.values.quantity
            }
            onChange={
              formik.handleChange
            }
            onBlur={
              formik.handleBlur
            }
            error={
              formik.touched.quantity && Boolean(formik.errors.quantity)
            }
            helperText={
              formik.touched.quantity && formik.errors.quantity
            }
            fullWidth
            style={
              {marginTop: '10px'}
            }/>
          <div className='mt-2 mb-2'>
            <ActionBar loadingAdd={isLoading}
              onAdd={
                formik.handleSubmit
              }/>
          </div>
        </form>
        <ConsumableItemList showAddItem={false}
          showAction={true}
          setConsumableItemsState={setConsumableItemsState}
          items={
            jobExhibit?.exhibitGiveAway?.consumables
          }/>
      </DialogContent>
      <DialogActions>
        <ActionBar hideDialog={onClose}/>
      </DialogActions>
    </>
  );
}

export default ConsumableItemForm;
