import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useSelector, useDispatch} from 'react-redux';
import {CustomSelect} from '../../style/mui/customStyle';
import ShipmentItems from './ShipmentItems';
import { useCreateShipmentItemMutation, useGetItemsInShipmentQuery, useGetItemsToShipQuery } from '../../services/authService';
import { useParams } from 'react-router-dom';
import { setShipmentItems, setShipmentItemsTotal } from '../../redux/reducers/shipmentSlice';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';

const validationSchema = Yup.object().shape({itemId: Yup.number().required('Item is required')});

function ShipmentItemForm(props) {
    const { onClose, onSubmit, shipmentId, shipmentData } = props;
  const dispatch = useDispatch();
    const { id, shipmentDirection } = useParams();
  const [availableItems, setAvailableItems] = useState(null);
  const [createShipmentItem, {isLoading, isError}] = useCreateShipmentItemMutation();
  const {data: itemsInShipment, isFetchingItemsInShipment, refetch: refetchItemsInShipment} = useGetItemsInShipmentQuery(shipmentId && shipmentId);
    const { data: itemsToShip, isFetchingItemsToShip, refetch: refetchItemsToShip } = useGetItemsToShipQuery({ jobId: id, shipmentDirection: shipmentData?.shipment.shipmentDirection });
  
  useEffect( ()=>{
      if (itemsToShip) {
        setAvailableItems(itemsToShip.shipmentItems);
      }
  },[itemsToShip]);

  const formik = useFormik({
    initialValues: {
      itemId: '',
      shipmentId: shipmentId
    },
    validationSchema,
    onSubmit: async (values) => {
      const response = await createShipmentItem(values);
        if (response.data) {
          if (!isFetchingItemsInShipment) {
              const responseItemsInShipment = await refetchItemsInShipment();
              dispatch(setShipmentItems(responseItemsInShipment.data.shipmentItems));
              dispatch(setShipmentItemsTotal(responseItemsInShipment.data.shipmentItemsTotal));
          }
          if (!isFetchingItemsToShip) {
              const responseItemsToShip = await refetchItemsToShip();
              setAvailableItems(responseItemsToShip.data.shipmentItems);
          }
          formik.resetForm();
          enqueueSnackbar(`${messages.success.createShipmentItem}`, { variant: 'success' });
      } else {
          const errorMessages = response.error.data.map(error => error.errorMessage);
          enqueueSnackbar(`${messages.error.prefix} ${errorMessages ? errorMessages : messages.error.generic}`, { variant: 'error' });
      }
    }
  });

  return (
    <form onSubmit={
      formik.handleSubmit
    }>
      <DialogTitle>Add Shipment Item</DialogTitle>

      <DialogContent>
        <div className='mt-2'>
          <ActionBar hideDialog={onClose}/>
        </div>

        <FormControl fullWidth className='mt-2'>
          <InputLabel htmlFor="itemId">Item</InputLabel>
          <CustomSelect label="Item" name="itemId"
            value={
              formik.values.itemId
            }
            onChange={
              formik.handleChange
            }
            error={
              formik.touched.itemId && Boolean(formik.errors.itemId)
          }>
            <MenuItem disabled value="">
              <em>Select Item</em>
            </MenuItem>
            {availableItems && availableItems.length > 0 && availableItems.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}  
          </CustomSelect>
          {
          formik.touched.itemId && formik.errors.itemId && (
            <FormHelperText error>
              {
              formik.errors.itemId
            } </FormHelperText>
          )
        } </FormControl>

        <div className='mt-2'>
          <ActionBar onAdd={
            formik.handleSubmit
          }/>
        </div>

        <div className='mt-3 mb-3'>
          <ShipmentItems showAddItem={false}
                      showAction={true} refetchItemsToShip={refetchItemsToShip} setAvailableItems={setAvailableItems}
                      showManualAdd={true}
          />
        </div>
      </DialogContent>
    </form>
  );
}

export default ShipmentItemForm;
