import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {useGetInternalUsersQuery} from "../../services/authService";

const InternalUser = ({ id = null, type, placeholder = 'Employee', onChange }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const {data, isFetching } = useGetInternalUsersQuery(type);

    const handleChange = (event, value) => {
        setSelectedOption(value);
        if(value && onChange) {
            onChange(event, value);
        } else {
            onChange(event, null);
        }
    };

    useEffect(() => {
        if(data){
            setOptions(data?.users);
            let selOption = data?.users?.find(option => (option.id === id && !option.isDeleted));
            setSelectedOption(selOption ?? null);
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div className="d-flex align-items-center">
            <CustomAutocomplete
                // id="employee-list"
                className="flex-grow-1"
                sx={sxAutocomplete}
                loading={isFetching}
                onChange={handleChange}
                options={options}
                value={selectedOption}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.fullName || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.fullName || '' : '')}
                noOptionsText="User not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">{placeholder}</label>
                       }
                       variant="outlined"
                    />
                )}
            />
        </div>
    )
}

export default InternalUser;
