import {useEffect, useState} from "react";
import {useCreateContractorMutation, useUpdateContractorMutation} from "../../../services/authService";
import { useSnackbar } from 'notistack';
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from 'yup';
import BorderBox from "../../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import {Col, Row} from "reactstrap";
import Countries from "../../Countries";
import States from "../../States";
import ContactsGrid from "../ContactsGrid";
import messages from "../../../utils/messages";

const contractorSchema = Yup.object({
    name: Yup.string().required('Contractor Name is required'),
    address: Yup.object().shape(
        {
            address1: Yup.string(),
            address2: Yup.string(),
            city: Yup.string(),
            stateCode: Yup.string().nullable(),
            province: Yup.string(),
            zip: Yup.string(),
            countryCode: Yup.string(),
            phone: Yup.string(),
            fax: Yup.string(),
        }
    )
});

const ContractorForm = ({ contractor, setContractor, onCancel, onUpdate, onAdd, onDelete, setNewContractor, name }) => {
    const [createContractor, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateContractorMutation();
    const [updateContractor, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateContractorMutation();
    const { enqueueSnackbar } = useSnackbar();
    const address = contractor?.address;
    const [previousName, setPreviousName] = useState(name);

    const formik = useFormik({
        initialValues: {
            name: contractor?.name ?? '',
            address: {
                address1: address?.address1 ?? '',
                address2: address?.address2 ?? '',
                city: address?.city ?? '',
                stateCode: address?.stateCode ?? null,
                province: address?.province ?? '',
                zip: address?.zip ?? '',
                countryCode: address?.countryCode ?? 'USA',
                phone: address?.phone ?? '',
                fax: address?.fax ?? ''
            }
        },
        validationSchema: contractorSchema,
        onSubmit: async (values) => {
            setPreviousName(values.name);
            if(contractor){ // edit
                setNewContractor('');
                
                const response = await updateContractor({
                    id: contractor?.id,
                    ...values
                });
                
            } else { // add
                setNewContractor('');
                await createContractor({
                    id: 0,
                    ...values
                });
            }
        },
    })

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createContractor}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setNewContractor(formik.values.name);
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateContractor}`, { variant: 'success' })
            setNewContractor(formik.values.name);

            // setContractor({
            //     ...contractor,
            //     id: contractor.id,
            //     ...formik.values
            // });

            updateReset();
            onUpdate();
            onCancel();
        }
        if(isCreateError) {
            setNewContractor(previousName);
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
            onCancel();
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Contractor"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <Row className="gx-2">
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Phone"
                                name="address.phone"
                                variant="outlined"
                                value={formik.values.address.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.phone && Boolean(formik.errors.address?.phone)}
                                helperText={formik.touched.address?.phone && formik.errors.address?.phone}
                                className="mt-2"
                            />
                        </Col>
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Fax"
                                name="address.fax"
                                variant="outlined"
                                value={formik.values.address.fax}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.fax && Boolean(formik.errors.address?.fax)}
                                helperText={formik.touched.address?.fax && formik.errors.address?.fax}
                                className="mt-2"
                            />
                        </Col>
                    </Row>
                    <Countries
                        formik={formik}
                        name="address.countryCode"
                        onChange={(e, val) => {
                            formik.values.address.countryCode = val?.countryCode;
                        }}
                        countryCode={formik.values.address.countryCode}
                    />
                    <CustomTextField
                        fullWidth
                        label="Address"
                        name="address.address1"
                        variant="outlined"
                        value={formik.values.address.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.address1 && Boolean(formik.errors.address?.address1)}
                        helperText={formik.touched.address?.address1 && formik.errors.address?.address1}
                        className="mt-2"
                    />
                    <CustomTextField
                        fullWidth
                        label="Address2"
                        name="address.address2"
                        variant="outlined"
                        value={formik.values.address.address2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.address2 && Boolean(formik.errors.address?.address2)}
                        helperText={formik.touched.address?.address2 && formik.errors.address?.address2}
                        className="mt-2"
                    />
                    <CustomTextField
                        fullWidth
                        label="City"
                        name="address.city"
                        variant="outlined"
                        value={formik.values.address.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address?.city && Boolean(formik.errors.address?.city)}
                        helperText={formik.touched.address?.city && formik.errors.address?.city}
                        className="mt-2"
                    />
                    <Row className="gx-2 mb-3">
                        <Col md={8} className="mt-2">

                        {formik.values.address.countryCode === "USA" ?
                            <States
                                formik={formik}
                                name="address.stateCode"
                                onChange={(e, val) => {
                                    formik.values.address.stateCode = val?.stateCode;
                                }}
                                stateCode={formik.values.address.stateCode}
                            />
                            :
                            <CustomTextField
                                fullWidth
                                label="Province"
                                name="address.province"
                                variant="outlined"
                                value={formik.values.address.province}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.province && Boolean(formik.errors.address?.province)}
                                helperText={formik.touched.address?.province && formik.errors.address?.province}
                            />
                        }

                        </Col>
                        <Col md={4} className="mt-2">
                            <CustomTextField
                                label="ZIP / Postal Code"
                                name="address.zip"
                                variant="outlined"
                                value={formik.values.address.zip}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address?.zip && Boolean(formik.errors.address?.zip)}
                                helperText={formik.touched.address?.zip && formik.errors.address?.zip}
                            />
                        </Col>
                    </Row>

                    { contractor && <ContactsGrid
                        data={contractor}
                        setData={setContractor}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                        isContractor={true}
                    /> }
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ContractorForm;
