import {Fragment, useEffect, useState} from "react"
import {useGetCountriesQuery} from "../services/authService"
import {TextField } from "@mui/material";
import sxAutocomplete from "../style/sxAutocompleteStyles";
import {CustomAutocomplete} from "../style/mui/customStyle";

const Countries = (props) => {
  const {formik, fieldName, countryCode, onChange} = props;  
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (event, value) => {
    if(formik) {
        formik.setFieldValue(fieldName, value?.countryCode || '');
    }
    setSelectedOptions(value);
    if(onChange){
        onChange(event, value);
    }
  };
  const { data, isFetching } = useGetCountriesQuery()

  useEffect(() => {
    if (data) {
      let fetchedOption = null;
      setOptions(data?.countries);
      if(countryCode !== '' && countryCode !== null ){
        fetchedOption = data?.countries?.find(option => option.countryCode === countryCode);
      }
      else{
        fetchedOption = data?.countries?.find(option => option.countryCode === "USA");
      }      
      setSelectedOptions(fetchedOption);
    }
  }, [data, countryCode]);

  return (
    <Fragment >
      <CustomAutocomplete
        id="countries-list"
        sx={sxAutocomplete}
        loading={isFetching}
        onChange={handleChange}
        value={selectedOptions}
        options={options}
        renderOption={(props, option) => (
          <li {...props} key={option.countryCode}>
            <span className="autocomplete-option-label">{option.name || ''}</span>
          </li>
        )}
        getOptionLabel={(option) => (option ? option.name || '' : '')}
        style={{ width: '100%', marginTop: '10px' }}
        noOptionsText="Country not found"
        renderInput={(params) => (
          <TextField {...params}
            variant="outlined"
            label={
              <label className="autocomplete-input-label">Countries</label>
            }
            error={formik && (formik.touched['address'] && formik.touched['address']['countryCode'] && Boolean(formik.errors['address']&&formik.errors['address']['countryCode']))}
            helperText={formik && (formik.touched['address'] && formik.touched['address']['countryCode'] && formik.errors['address'] && formik.errors['address']['countryCode'])}
          />
        )} />
    </Fragment>
  )
}

export default Countries