import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import sxTableStyles from "../../style/sxTableStyles";
import {CustomTableCellOne} from "../../style/mui/customStyle";
import { useSelector } from "react-redux";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";
import classes from './ShipmentDetail.module.scss'
import { numFloat } from "../../utils/common";

function ShipmentExpenses(){
  const shipmentExpenses = useSelector(state => state.shipmentData?.shipment?.shipmentExpenses);
  const shipmentExpensesTotal = useSelector(state => state.shipmentData?.shipment?.shipmentExpensesTotal);

  return (
    <>
      <h3 className={`lh-sm mt-2 ${classes.title}`}>Shipment Expenses</h3>
      <TableContainer component={Paper}
        className="rounded-0 shadow-none mb-2">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell>Ship Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Estimate</TableCell>
              <TableCell>Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {shipmentExpenses && shipmentExpenses.length > 0 ? shipmentExpenses.map(expense => {
            return (<TableRow key={expense.id}>
            <CustomTableCellOne>
              {expense?.shipDate ? formatDateTimeDayjs(expense.shipDate, FORMATS.displayDate) : "TBD"}
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
              {expense.shipmentDirection}
            </CustomTableCellOne>
            <CustomTableCellOne className="px-2">
                    {numFloat(expense.quoteAmount)}
            </CustomTableCellOne>
            <CustomTableCellOne className="px-1">
                    {numFloat(expense.actualAmount)}
            </CustomTableCellOne>
          </TableRow>)
          }):
            <TableRow>
              <TableCell colSpan={4} style={{padding:'0px'}}>
                No Shipment Expenses
              </TableCell>
            </TableRow>  
          }
          {shipmentExpenses && shipmentExpenses.length > 0 && shipmentExpensesTotal &&
            <TableRow>
              <CustomTableCellOne style={{ fontWeight: 'bold' }}>
                  Totals
              </CustomTableCellOne>
              <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }}></CustomTableCellOne>
              <CustomTableCellOne className="px-2" style={{ fontWeight: 'bold' }}>
                    {numFloat(shipmentExpensesTotal.quoteAmount)}
              </CustomTableCellOne>
              <CustomTableCellOne className="px-1" style={{ fontWeight: 'bold' }}>
                    {numFloat(shipmentExpensesTotal.actualAmount)}
              </CustomTableCellOne>
            </TableRow>         
          }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ShipmentExpenses;