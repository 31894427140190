import {Button} from "reactstrap";

const AddRowButton = ({onAdd, disabled = false, children}) => {
    return (
        <Button
            block
            color="secondary"
            className="common-text rounded-0 py-1 px-2"
            onClick={onAdd}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}

export default AddRowButton;