import {styled} from "@mui/material/styles";
import {FormLabel} from "@mui/material";
import {theme} from "../../../utils/colors";

export const gridStyle = {
    "& .MuiDataGrid-row": {
        /* alternate row colors */
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.main,
        },

        /* row background colors */
        "&.bgColor0": {
            backgroundColor: theme.palette.color0.main,
        },
        "&.bgColor1": {
            backgroundColor: theme.palette.color1.main,
        },
        "&.bgColor2": {
            backgroundColor: theme.palette.color2.main,
        },
        "&.bgColor3": {
            backgroundColor: theme.palette.color3.main,
        },
        "&.bgColor4": {
            backgroundColor: theme.palette.color4.main,
        },
        "&.bgColor5": {
            backgroundColor: theme.palette.color5.main,
        },
        "&.bgColor6": {
            backgroundColor: theme.palette.color6.main,
        },
        "&.bgColor7": {
            backgroundColor: theme.palette.color7.main,
        },
        "&.bgColor8": {
            backgroundColor: theme.palette.color8.main,
        },
        "&.bgColor9": {
            backgroundColor: theme.palette.color9.main,
        },
        "&.bgColor10": {
            backgroundColor: theme.palette.color10.bgColor,
        },
        "&.bgColor11": {
            backgroundColor: theme.palette.color11.bgColor,
        },
        "&.bgColor12": {
            backgroundColor: theme.palette.color12.bgColor,
        },
        "&.bgColor13": {
            backgroundColor: theme.palette.color13.bgColor,
        },
        "&.bgColor14": {
            backgroundColor: theme.palette.color14.bgColor,
        },
        "&.bgColor15": {
            backgroundColor: theme.palette.color15.bgColor,
        },
        "&.bgColor16": {
            backgroundColor: theme.palette.color16.bgColor,
        },
        "&.bgColor17": {
            backgroundColor: theme.palette.color17.bgColor,
        },
        "&.bgColor18": {
            backgroundColor: theme.palette.color18.bgColor,
        },
        "&.bgColor19": {
            backgroundColor: 'transparent',
        },

        /* row text colors */
        "&.textColor0": {
            color: theme.palette.color0.dark,
        },
        "&.textColor1": {
            color: theme.palette.color1.dark,
        },
        "&.textColor2": {
            color: theme.palette.color2.dark,
        },
        "&.textColor3": {
            color: theme.palette.color3.dark,
        },
        "&.textColor4": {
            color: theme.palette.color4.dark,
        },
        "&.textColor5": {
            color: theme.palette.color5.dark,
        },
        "&.textColor6": {
            color: theme.palette.color6.dark,
        },
        "&.textColor7": {
            color: theme.palette.color7.dark,
        },
        "&.textColor8": {
            color: theme.palette.color8.textColor,
        },
        "&.textColor9": {
            color: theme.palette.color9.textColor,
        },
        "&.textColor10": {
            color: theme.palette.color10.textColor,
        },
        "&.textColor11": {
            color: theme.palette.color11.textColor,
        },
        "&.textColor12": {
            color: theme.palette.color12.textColor,
        },
        "&.textColor13": {
            color: 'transparent',
        },
    },

    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "8pt !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
        color: theme.palette.text.main
    },
    "& .MuiToolbar-root-MuiTablePagination-toolbar": {
        display: "flex",
        alignItems: "center",
    },
    "& .MuiTablePagination-root .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
        marginBottom: 0,
    }
}

export const sxRadio = {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
        color: theme.palette.primary.main
    },
    '& .MuiTypography-root': {
        fontFamily: "Roboto",
        fontWeight: '400',
        fontSize: '1rem'
    },
    '& .MuiFormControlLabel-label':{
        textAlign: 'center',
        fontStyle: 'normal',
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: 'normal',
        fontFamily: "Helvetica LT Std",
        color:'green'
    }
};

export const StyledFormLabel = styled(FormLabel)({
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 'bold',
    fontFamily: "Helvetica",
});