import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import styles from "../showName/EditShowName.module.scss";
import LeadSupervisorForm from "./LeadSupervisorForm";

const AddLeadSupervisor = ({ isOpen, onAdd, onCancel, setFilter, setSupervisor }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`${styles.header} text-center`}>
                Add lead supervisor
            </DialogTitle>
            <LeadSupervisorForm
                onCancel={onCancel}
                onAdd={onAdd}
                setFilter={setFilter}
                setSupervisor={setSupervisor}
            />
        </Dialog>
    )
}

export default AddLeadSupervisor;
