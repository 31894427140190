// reducers/exhibit/exhibitSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchJobExhibit, updateJobExhibit } from '../../actions/exhibit/exhibitActions';

const initialState = {
  jobExhibit: null,
  loading: false,
  error: null,
};

const exhibitSlice = createSlice({
  name: 'jobExhibit',
  initialState,
  reducers: {
    setExhibitGiveAway: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: action.payload
      };
      return state;
    },
    setExhibit: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          exhibit: action.payload,
        } 
      };
      return state;
    },
    setAllocatedExhibitItems: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          exhibit: {
            ...state.jobExhibit.exhibitGiveAway.exhibit,
            items: action.payload
          },
        } 
      };
      return state;
    },
    setAllocatedConsumableItems: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          consumables: action.payload
        } 
      };
      return state;
    },
    setExhibitItem: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          exhibit: {
            ...state.jobExhibit.exhibitGiveAway.exhibit,
            items: [...state.jobExhibit.exhibitGiveAway.exhibit.items, action.payload]
          },
        } 
      };
      return state;
    },
    removeExhibitItem: (state,action) => {
      state.jobExhibit = {
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          exhibit: {
            ...state.jobExhibit.exhibitGiveAway.exhibit,
            items: state.jobExhibit.exhibitGiveAway.exhibit.items.filter(item => item.id !== action.payload.itemId)
          }
        }
      };
      
    },
    setExhibitName: (state, action) => {
      state.jobExhibit = { 
        ...state.jobExhibit,
        exhibitGiveAway: {
          ...state.jobExhibit.exhibitGiveAway,
          exhibit: {
            ...state.jobExhibit.exhibitGiveAway.exhibit,
            name: action.payload,
            items: state.jobExhibit.exhibitGiveAway.exhibit.items
          },
        } 
      };
      return state;
    },
    resetExhibit: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobExhibit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobExhibit.fulfilled, (state, action) => {
        state.loading = false;
        state.jobExhibit = action.payload;
      })
      .addCase(fetchJobExhibit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateJobExhibit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateJobExhibit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateJobExhibit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setExhibitGiveAway, setExhibit, setAllocatedExhibitItems, setExhibitItem, removeExhibitItem, setAllocatedConsumableItems, setExhibitName, resetExhibit } = exhibitSlice.actions;

export default exhibitSlice.reducer;
