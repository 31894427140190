import {useState} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ContractorForm from "./ContractorForm";
import styles from "../showName/EditShowName.module.scss";

const EditContractor = ({ contractor, setContractor, onUpdate, setNewContractor, name }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <EditOutlinedIcon
                fontSize="small"
                onClick={handleOpen}
                className={`${styles.icon} m-1`}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit contractor
                </DialogTitle>
                <ContractorForm
                    contractor={contractor}
                    setContractor={setContractor}
                    onCancel={handleClose}
                    onUpdate={onUpdate}
                    onDelete={onUpdate}
                    setNewContractor={setNewContractor}
                    name={name}
                />
            </Dialog>
        </>
    )
}

export default EditContractor;