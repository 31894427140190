import {useState} from "react";
import {Dialog} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveConsumableItemForm from "./RemoveConsumableItemForm";
import Tooltip from '@mui/material/Tooltip';
function DeleteConsumableItem(props) {
  const {consumableId, setConsumableItemsState} = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  function closeDeleteModalHandler() {
    setDeleteModalOpen(false);
  }

  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  return (
    <>
      <Tooltip title="Return Consumable Item">
        <RemoveCircleOutlineIcon fontSize="small"
          style={
            {
              color: "grey",
              cursor: "pointer"
            }
          }
          onClick={openDeleteModalHandler}/>
      </Tooltip>
      <Dialog open={isDeleteModalOpen}
        onClose={closeDeleteModalHandler}>
        <RemoveConsumableItemForm onClose={closeDeleteModalHandler}
          consumableId={consumableId} setConsumableItemsState={setConsumableItemsState} />
      </Dialog>
    </>
  )
}

export default DeleteConsumableItem;
