import {useEffect, useState} from "react";
import {Paper, TextField} from "@mui/material";
import EditClientName from "./clientName/EditClientName";
import {useGetClientNamesQuery} from "../../services/authService";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import AddClientName from "./clientName/AddClientName";
import DeleteClientName from "./clientName/DeleteClientName";
import useAuthentication from "../../hooks/useAuthentication";

const ClientName = ({onUpdate, setSelectedClientId, storedClientId, setPreLoadMainBranch, setStoredClientId, setCreatedClientId, createdClientId}) => {
    const [filter, setFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const {data, error: clientNamesError, isFetching } = useGetClientNamesQuery(filter);
    const [open, setOpen] = useState(false);
    useAuthentication([clientNamesError]);
    const handleChange = (event, value) => {
        setPreLoadMainBranch(true);
        if(value){            
            setSelectedClientId(value.id);
            setSelectedOption(value);
            localStorage.setItem('storedClientId',value.id);
            localStorage.removeItem('storedBranchId');
        }
        else{
            setSelectedClientId(null);
            setOpen(true);
            setFilter('');
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            // setFilter('');
            localStorage.removeItem('storedClientId');
            localStorage.removeItem('storedBranchId');
            setStoredClientId(null);
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(data){
            setOptions(data?.clients);
            let selOption = null;
            if(filter !== '' && filter !== null){
                selOption = data?.clients?.find(option => option.name === filter);
            }
            else if(storedClientId !== null){
                selOption = data?.clients?.find(option => option.id === Number(storedClientId));
                setSelectedClientId(selOption ? selOption.id : null);
            }
            else if(createdClientId !== null){
                selOption = data?.clients?.find(option => option.id === Number(createdClientId));
                
                if(selOption){
                    localStorage.setItem('storedClientId',createdClientId);
                    setCreatedClientId(null);
                }                
                setSelectedClientId(selOption ? selOption.id : null);
            }
            
            setSelectedOption(selOption ?? null);
        }
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const AddNewClientNameRow = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Client
            </div>
        </Paper>
    );

    return (
        <div className="d-flex align-items-center">
            <CustomAutocomplete
                id="client-name-list"
                className="flex-grow-1"
                sx={sxAutocomplete}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                loading={isFetching}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options || []}
                value={selectedOption}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.name || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.name || '' : '')}
                noOptionsText="Client not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">Client Name</label>
                       }
                       variant="outlined"
                    />
                )}
                PaperComponent={AddNewClientNameRow}
            />

            <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                <EditClientName
                    clientName={selectedOption}
                    setClientName={setSelectedOption}
                    onUpdate={onUpdate}
                />

                <DeleteClientName
                    clientName={selectedOption}
                    setClientName={setSelectedOption}
                    onDelete={onUpdate}
                    setSelectedClientId={setSelectedClientId}
                />
            </div>

            <AddClientName
                isOpen={addDialogOpen}
                setClientName={setSelectedOption}
                onCancel={closeAddDialog}
                storedClientId={storedClientId}
                setCreatedClientId={setCreatedClientId}
                setFilter={setFilter}                
            />

        </div>
    )
}

export default ClientName;
