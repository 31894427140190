import { useCreateExhibitMutation, useUpdateExhibitMutation} from "../../../services/authService";
import {DialogContent, FormControl} from '@mui/material';
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { enqueueSnackbar } from "notistack";
import messages from "../../../utils/messages";
import ActionBar from '../../ui/ActionBar';
import {CustomTextField} from '../../../style/mui/customStyle';
import ItemForm from "./ItemForm";
import BorderBox from "../../ui/BorderBox";

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
});

function ExhibitForm(props){
  const { exhibit, closeDialog,onExhibitUpdated, onExhibitAdded, clientId} = props;

  const [createExhibit, { isLoading : isCreateExhibitLoading, isError : isCreateExhibitError}] = useCreateExhibitMutation();
  const [updateExhibit, { isLoading : isUpdateExhibitLoading, isError : isUpdateExhibitError}] = useUpdateExhibitMutation();
  const formik = useFormik({
    initialValues: {
      clientId: clientId,
      name: exhibit?.name,
      notes: exhibit?.notes
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (exhibit) {
        const response = await updateExhibit({ id: exhibit?.id,  ...values });
        if (response.data === true) {
          enqueueSnackbar(`${messages.success.updateExhibit}`, { variant: 'success' });
          onExhibitUpdated();
        } else {
          enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
      } else { // Add new exhibit
        const responseCreate = await createExhibit(values);
        if (responseCreate.data) {
          formik.resetForm();
          enqueueSnackbar(`${messages.success.createExhibit}`, { variant: 'success' });
          onExhibitAdded();
          closeDialog();
        } else {
          enqueueSnackbar(`${messages.error.prefix} ${responseCreate.error ? responseCreate.error.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
      }
    }
  });

  return (
    <>
      <DialogContent>
        <form className="mt-2 mb-2" onSubmit={
          formik.handleSubmit
        }>
          <ActionBar hideDialog={closeDialog}
            loadingSave={isCreateExhibitLoading || isUpdateExhibitLoading  }
            onSave={ formik.handleSubmit }
          />
          <FormControl fullWidth className='mt-2'>
            <CustomTextField label="Name" name="name" fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}  
            />
          </FormControl>
          
          <FormControl fullWidth className='mt-2'>
            <CustomTextField label="Notes" name="notes" fullWidth
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}/>
          </FormControl>
          
        </form>
        { exhibit && 
        <BorderBox title="Exhibit Items" boxStyle="mb-3">
          <ItemForm showAction={true} showCmcItems={false} exhibit={exhibit} clientId={clientId} />
        </BorderBox> }
      </DialogContent>
    </>
  );

}

export default ExhibitForm;