import {useSelector} from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useCreateDismantleMutation, useUpdateDismantleMutation} from "../../services/authService";
import styles from "./SetupDismantle.module.scss";
import {useEffect} from "react";
import messages from "../../utils/messages";
import {SETUP_DISMANTLE} from "../../utils/constants";
import {useSnackbar} from "notistack";

const EditSetupDismantle = ({ row, type, onUpdate }) => {
    const [createDismantle, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateDismantleMutation();
    const [updateDismantle, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateDismantleMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showInfo } = useSelector(state => state.showData);

    const handleUpdate = () => {
        const data = {
            id: row?.id,
            showId: showInfo?.showId,
            eventDate: row.eventDate,
            startTime: row.startTime,
            endTime: row.endTime,
            type: type
        }
        console.log(data);
        if(row.isNew) {
            console.log('create');
            createDismantle(data);
        } else {
            console.log('update');
            updateDismantle(data);
        }
    }

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(
                type === SETUP_DISMANTLE.setup  ? `${messages.success.createSetup}` : `${messages.success.createDismantle}`,
                { variant: 'success' }
            );
            /*formik.resetForm();*/
            createReset();
            onUpdate();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(
                type === SETUP_DISMANTLE.setup  ? `${messages.success.updateSetup}` : `${messages.success.updateDismantle}`,
                { variant: 'success' }
            );
            updateReset();
            onUpdate();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <AddCircleOutlineIcon
            fontSize="small"
            onClick={handleUpdate}
            className={`${styles.icon}`}
        />
    )
}

export default EditSetupDismantle;
