import { useEffect,Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserPassword } from '../../redux/actions/authActions'
import Error from '../Error'
import { ReactComponent as Logo } from "../../assets/image/logo.svg";

const ResetPassword = () => {
    let [searchParams] = useSearchParams()
    const resetToken = searchParams.get("token")
    const [customError, setCustomError] = useState(null)
    const {loginData: {loading, success, error} } = useSelector((state) => state);

    const dispatch = useDispatch()
    const { register, handleSubmit } = useForm({
            defaultValues: {
                passwordResetToken: resetToken
            }
            }
        )
    const navigate = useNavigate()

    // redirect authenticated user to profile screen
    useEffect(() => {
        if (success) {
        navigate('/login')
        }
    }, [navigate, success])

    const submitForm = (data) => {

        if (data.password !== data.confirmPassword) {
            setCustomError('Password mismatch')
            return
        }

        dispatch(resetUserPassword(data))
    }

    return (
        <Fragment>
            <div className="text-center mb-4">
                <Logo />
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
                {error && <Error>{error}</Error>}
                {customError && <Error>{customError}</Error>}
                <div className="form-group mb-3">
                  <label htmlFor="usernameInput">Username</label>
                  <input type="text" id="usernameInput" className="form-control" {...register('username')} required placeholder="Username" />
              </div>
                <div className="form-group mb-3">
                    <label htmlFor="passwordInput">Password</label>
                    <input type="password" id="passwordInput" className="form-control" {...register('password')} required placeholder="Password" />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="confirmPasswordInput">Confirm Password</label>
                    <input type="password" id="confirmPasswordInput" className="form-control" {...register('confirmPassword')} required placeholder="Confirm Password" />
                </div>
                <div className="text-center mt-4 mb-3 px-4">
                    <button type="submit" className="btn btn-primary" disabled={loading}>Reset Password</button>
                </div>
            </form>
        </Fragment>
    )
}
export default ResetPassword