import { DialogTitle } from "@mui/material";
import { CustomDialog } from "../../exhibit/ExhibitCustomStyle";
import ExhibitForm from "./ExhibitForm";

function EditExhibit(props) {
  const { open, onClose, exhibit, onExhibitUpdated, clientId } = props;
  return (
      <CustomDialog open={open}
      onClose={onClose}
      maxWidth="sm" 
      fullWidth
      slotProps={{ backdrop: { onClick: onClose } }}>
        <DialogTitle>Edit Exhibit</DialogTitle>
        <ExhibitForm  closeDialog={onClose} exhibit={exhibit} onExhibitUpdated={onExhibitUpdated} clientId={clientId} />
      </CustomDialog>
  )
}

export default EditExhibit;
