import AddRowButton from "../ui/AddRowButton";

const AddSetupDismantle = ({ type, setRows, disabled }) => {
    const newRow = {
        id: 0,
        eventDateOnly: '',
        startTime: '00:00:01',
        endTime: '00:00:01',
        isUpdated: true,
        isNew: true,
    }

    const handleAddRow = () => {
        setRows((rows) => [...rows, newRow]);
    }

    return (
        <AddRowButton
            onAdd={handleAddRow}
            disabled={disabled}
        >
            + Add {type}
        </AddRowButton>
    )
}

export default AddSetupDismantle;
