//import {useState} from "react";
//import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../lookups/showName/EditShowName.module.scss";
import ClientConsumableForm from "./ClientConsumableForm";

const EditClientConsumableItem = ({ consumable, setConsumable, isOpen, onCancel, onConsumableUpdated }) => {
    return (
        <>          
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Edit Consumable
                </DialogTitle>
                <ClientConsumableForm
                    consumable={consumable}
                    setConsumable={setConsumable}
                    onCancel={onCancel}
                    onConsumableUpdated={onConsumableUpdated}
                />
            </Dialog>
        </>
    )
}

export default EditClientConsumableItem;