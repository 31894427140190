import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import dayjs from "dayjs";
import FlightCarrier from "../../lookups/FlightCarrier";
import {CustomDatePicker2, CustomTextField, CustomTimePickerSmall} from "../../../style/mui/customStyle";
import {useItinerary} from "../../../context/ItineraryContext";
import styles from "../ItineraryModal.module.scss";
import {formatDateTimeDayjs} from "../../../utils/common";
import {DISPATCH_TYPES, FLIGHT_TYPE, FORMATS} from "../../../utils/constants";
import DeleteFlight from "./DeleteFlight";

const CarrierInfo = ({data, type, showDelete, onDelete}) => {
    const [itinerary, handleItinerary, refetchItinerary] = useItinerary();
    const [flight, setFlight] = useState(null);

    let isDeparture = type === FLIGHT_TYPE.Departure;

    const dispatchCallback = (type, value) => {
        if(type === DISPATCH_TYPES.flightCarrier) {
            handleFlight({carrierId: value?.id, carrier: value});
        }
    }

    const handleFlight = (update) => {
        setFlight((flightObj) => ({...flightObj, ...update}));
        const updatedFlights = itinerary.flights.map((f) => f.fid === flight.fid ?
            {
               ...f,
               ...update,
            }
            : f
        )
        handleItinerary({flights: updatedFlights});
    }

    useEffect(() => {
        if(data){
            setFlight(data);
        }
    }, [data]);
    const updateHandler = () => {
        // console.log("updateHandler");
    }
    return (
        <div>
            <h4 className="sub-title">{ isDeparture ? 'Departing Flight' : 'Returning Flight' }</h4>
            <Row className="align-items-start mb-2 mb-xl-0">
                <Col xl={2} className="mb-2 mb-xl-0">
                    <CustomDatePicker2
                        // label={isDeparture ? 'Departing Flight' : 'Returning Flight'}
                        name={isDeparture ? 'departureDateTime' : 'returnDateTime'}
                        size="small"
                        value={dayjs(flight?.date)}
                        onChange={(newValue) => {
                            let newDate = formatDateTimeDayjs(newValue, FORMATS.local);
                            handleFlight({date: newDate});
                        }}
                    />
                </Col>
                <Col xl={ showDelete ? 10 : 10 }>
                    <Row>
                        <Col md>
                            <div className="d-flex align-items-center">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>From</label>
                                <CustomTextField
                                    size="small"
                                    fullWidth
                                    // label="From"
                                    name={isDeparture ? 'departureFrom' : 'returnFrom'}
                                    variant="outlined"
                                    value={flight?.from}
                                    onChange={(event) => {
                                        let newValue = event.target.value;
                                        handleFlight({from: newValue});
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-1 mt-md-2">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>To</label>
                                <CustomTextField
                                    size="small"
                                    fullWidth
                                    // label="To"
                                    name={isDeparture ? 'departureTo' : 'returnTo'}
                                    variant="outlined"
                                    value={flight?.to}
                                    onChange={(event) => {
                                        let newValue = event.target.value;
                                        handleFlight({to: newValue});
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md className="mt-2 mt-md-0">
                            <div className="d-flex align-items-center">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>Departure</label>
                                <CustomTimePickerSmall
                                    value={dayjs(flight?.date)}
                                    onChange={(newValue) => {
                                        let newTime = formatDateTimeDayjs(newValue, FORMATS.local);
                                        handleFlight({date: newTime});
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-1 mt-md-2">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>Arrival</label>
                                <CustomTimePickerSmall
                                    value={dayjs(flight?.arrivalTime)}
                                    onChange={(newValue) => {
                                        let newTime = formatDateTimeDayjs(newValue, FORMATS.local);
                                        handleFlight({arrivalTime: newTime});
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md className="mt-2 mt-md-0">
                            <div className="d-flex align-items-center">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>Carrier</label>
                                <FlightCarrier
                                    size="small"
                                    label=""
                                    containerStyle="w-100"
                                    name={flight?.carrier?.name}
                                    // onUpdate={refetchItinerary}
                                    onUpdate={updateHandler}
                                    dispatchCallback={dispatchCallback}
                                />
                                {
                                    showDelete &&
                                    <DeleteFlight flightId={flight?.id} onDelete={onDelete} fid={data.fid} />
                                }
                                
                            </div>
                            <div className="d-flex align-items-center mt-1 mt-md-2">
                                <label className={`${styles.horizontalLabel} text-ellipsis`}>Flight</label>
                                <CustomTextField
                                    size="small"
                                    fullWidth
                                    // label="Flight"
                                    name={isDeparture ? 'departureFlight' : 'returnFlight'}
                                    variant="outlined"
                                    value={flight?.flightName}
                                    onChange={(event) => {
                                        let newValue = event.target.value;
                                        handleFlight({flightName: newValue});
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/*showDelete && <Col xl={1}>
                    <DeleteFlight flightId={flight?.id}  onDelete={onDelete} fid={data.fid}/>
                </Col>*/}
            </Row>
        </div>
    )
}

export default CarrierInfo;
