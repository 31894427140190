import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import BorderBox from "../../ui/BorderBox";
import {useCreateCarrierMutation, useUpdateCarrierMutation} from "../../../services/authService";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import messages from "../../../utils/messages";

const flightCarrierSchema = Yup.object({
    name: Yup.string().required('Flight Carrier Name is required')
});

const FlightCarrierForm = ({ flightCarrier, setFlightCarrier, onCancel, onUpdate, onAdd }) => {
    const [createCarrier, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateCarrierMutation();
    const [updateCarrier, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateCarrierMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [previousName, setPreviousName] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: flightCarrier ? flightCarrier.name : '',
        },
        validationSchema: flightCarrierSchema,
        onSubmit: async (values) => {
            setPreviousName(values.name);
            setFlightCarrier('');
            console.log(JSON.stringify(values, null, 2));
            if(flightCarrier){ // edit
                await updateCarrier({
                    id: flightCarrier.id,
                    ...values
                });
            } else { // add
                await createCarrier({
                    id: 0,
                    ...values
                });
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createFlightCarrier}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setFlightCarrier(previousName);
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateFlightCarrier}`, { variant: 'success' })
            // setFlightCarrier({
            //     id: flightCarrier.id,
            //     ...formik.values
            // });
            updateReset();
            onUpdate();
            onCancel();
            setFlightCarrier(previousName);
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Flight Carrier Name"
                        name="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        className="mt-2"
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default FlightCarrierForm;
