import {Col, Row} from "reactstrap";
import BorderBox from "../ui/BorderBox";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {CustomTextField} from "../../style/mui/customStyle";

const LaborRates= () => {
    const [state, handleState] = useInstallDismantle();

    const numberInputProps = {
        step: .25,
    };

    return (
        <BorderBox title="Labor Rates" boxStyle="mb-3 mb-md-4">
            <Row className="gx-2">
                <Col>
                    <CustomTextField
                        fullWidth
                        type="number"
                        inputProps={numberInputProps}
                        label="Straight Time"
                        variant="outlined"
                        value={state?.laborStraightTime ?? 0}
                        onChange={(event) => {
                            const value = event.target.value.trim();
                            const straightTime = value === '' ? null : value;
                            handleState({ laborStraightTime: straightTime });
                        }}
                    />
                </Col>
                <Col>
                    <CustomTextField
                        fullWidth
                        type="number"
                        inputProps={numberInputProps}
                        label="Overtime"
                        variant="outlined"
                        value={state?.laborOvertime ?? 0}
                        onChange={(event) => {
                            // handleState({laborOvertime: event.target.value})
                            const value = event.target.value.trim();
                            const overtime = value === '' ? null : value;
                            handleState({ laborOvertime: overtime });
                        }}
                    />
                </Col>
                <Col>
                    <CustomTextField
                        fullWidth
                        type="number"
                        inputProps={numberInputProps}
                        label="Double Time"
                        variant="outlined"
                        value={state?.laborDoubleTime ?? 0}
                        onChange={(event) => {
                            // handleState({laborOvertime: event.target.value})
                            const value = event.target.value.trim();
                            const doubleTime = value === '' ? null : value;
                            handleState({ laborDoubleTime: doubleTime });
                        }}
                    />
                </Col>
            </Row>
        </BorderBox>
    )
}

export default LaborRates;
