import React, { useEffect, useState } from 'react';
import {Dialog, DialogTitle, DialogActions, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import ActionBar from "../../components/ui/ActionBar";
import { useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import {Col, Row} from "reactstrap";
import { CustomTextField, CustomFormControlLabel } from "../../style/mui/customStyle";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useGetExhibitGiveAwayQuery, usePrintJobShowInfoMutation} from '../../services/authService';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import { saveAs } from 'file-saver';
import classes from "./ShowInfo.module.scss";
import BorderBox from '../../components/ui/BorderBox';

function PrintShowInfo(props) {
  const {id} = useParams();  
  const {isOpen, onCancel} = props;
  const {showInfoSave, showStatus} = useSelector(state => state.showData);
  const [printJobShowInfo, {isLoading: isPrintLoading, isSuccess: isPrintSuccess, error: isPrintError
  }] = usePrintJobShowInfoMutation();
  const {data: getExhibitGiveAway, isFetching: isfetchingExhibitGiveaway, refetch: refetchGetExhibitGiveAway} = useGetExhibitGiveAwayQuery(id && id);

  const validationSchema = Yup.object().shape({    
    quotedPrice: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Price is invalid'),
    straightTime: Yup.number().typeError('Time must be a number'),
    overTime: Yup.number().typeError('Time must be a number')
  });

  const formik = useFormik({
    initialValues: {
      exhibitName: '',      
    },
    validationSchema,
    onSubmit: async (values) => {
      // const requestObject = {...values};
      const requestObject = {};
      Object.keys(values).forEach(key => {
        if (values[key]) {
          requestObject[key] = values[key];
        }
      });
       requestObject.showId = showInfoSave.showId; //showInfoSave.showNameId;
      const response = await printJobShowInfo(requestObject);
      if (response.data){
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, `${showInfoSave.jobId}_ShowInfo.pdf`);
      }      
    }
  })
  useEffect(()=>{
    if (getExhibitGiveAway){
      formik.setFieldValue('exhibitName', getExhibitGiveAway?.exhibitGiveAway?.exhibit?.name);
    }
  }
  ,[getExhibitGiveAway]);
  const handleMessages = () => {
    if(isPrintSuccess) {
        enqueueSnackbar(`${messages.success.printShowInfo}`, { variant: 'success' });
        onCancel();
    }
    if(isPrintError) {
        enqueueSnackbar(`${messages.error.prefix} ${isPrintError?.data[0]?.errorMessage}`, { variant: 'error' });
    }
};

useEffect(() => {
    if(isPrintSuccess || isPrintError){
        handleMessages();
    }
    // eslint-disable-next-line
}, [isPrintSuccess, isPrintError]);


  return (
    <form onSubmit={formik.handleSubmit}>
    <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle styles={`text-center`}>Print Show Info</DialogTitle>
      <DialogContent>
        <div className='mt-3 mb-3'>
          <CustomTextField
            fullWidth
            label="Exhibit Name"
            name="exhibitName"
            variant="outlined"
            value={formik.values.exhibitName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.exhibitName && Boolean(formik.errors.exhibitName)}
            helperText={formik.touched.exhibitName && formik.errors.exhibitName}
            className="mt-2"
          />
          <CustomTextField
            fullWidth
            label="Materials Requested"
            name="materialsRequested"
            variant="outlined"
            value={formik.values.materialsRequested}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.materialsRequested && Boolean(formik.errors.materialsRequested)}
            helperText={formik.touched.materialsRequested && formik.errors.materialsRequested}
            className="mt-2"
          />
          <CustomTextField
            fullWidth
            label="Notes"
            name="notes"
            variant="outlined"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.notes && Boolean(formik.errors.notes)}
            helperText={formik.touched.notes && formik.errors.notes}
            className="mt-2"
          /> 
        </div>
      </DialogContent>
      <DialogActions>
        {showInfoSave ? <ActionBar hideDialog={onCancel} type="submit" onPrint={formik.handleSubmit} loadingPrint={isPrintLoading} />
          : <ActionBar hideDialog={onCancel} />
        }
      </DialogActions>
    </Dialog>
    </form>
  );
}

export default PrintShowInfo;
