import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userForgotPassword } from '../../redux/actions/authActions'
import Error from '../Error'
import { ReactComponent as Logo } from "../../assets/image/logo.svg";

const ForgotPassword = () => {
  const { loginData: {loading, success, error} } = useSelector((state) => state)
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  //const navigate = useNavigate()

  // redirect authenticated user to profile screen
//   useEffect(() => {
//     if (success) {
//       navigate('/login')
//     }
//   }, [navigate, success])

  const submitForm = (data) => {
    dispatch(userForgotPassword(data))
  }

  return (
      <Fragment>
          <div className="text-center mb-4">
              <Logo />
          </div>

          {error && <Error>{error}</Error>}
          {success ? (
                <div className='text-center mb-4'>
                    <div>Please check your email for recovery link.</div>
                    <NavLink tag={Link} className="text-dark" to="/login">Return to Login</NavLink>
                </div>

            )
          :(
              <form onSubmit={handleSubmit(submitForm)}>
                  <div className="form-group">
                      <label htmlFor="userNameInput">User Name</label>
                      <input type="text" id="userNameInput" className="form-control" {...register('username')} required placeholder="User Name" />
                  </div>

                  <div className="text-center mt-4">
                      <button type="submit" className="btn btn-primary px-4" disabled={loading}>Send</button>
                  </div>
              </form>
          )
          }
      </Fragment>
  )
}
export default ForgotPassword