import {theme} from "../utils/colors";

const sxTableStyle = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,

    "& .MuiTableRow-root": {
        /* alternate row colors */
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.background.main,
        },
        "&:last-child": {
            "& .MuiTableCell-root": {
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: theme.palette.secondary.main,
            }
        }
    },

    "& .MuiTableCell-head": {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
    },

    "& .MuiTableCell-footer": {
        font: 'normal 13px/15px Arial, Helvetica, sans-serif',
        fontWeight: 'bold !important',
        color: '#000',
    },

    "& .MuiTableCell-root": {
        font: 'normal 13px/15px Arial, Helvetica, sans-serif',
        letterSpacing: 0,
        borderRight: '1px solid #E4E1E0',
        borderBottom: 'none',
        borderRadius: 0,
        padding: '2px 8px !important',

        "&:last-child": {
            borderRightColor: theme.palette.secondary.main,
        }
    },
}

export default sxTableStyle;