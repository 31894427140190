import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    personalTaskId: null,
    jobCheckListId: null,
}

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setPersonalTaskId: (state, { payload }) => {
            return {...state, personalTaskId: payload, jobCheckListId: null}
        },
        setJobChecklistId: (state, { payload }) => {
            return {...state, jobCheckListId: payload, personalTaskId: null}
        },
        resetJob: () => initialState,
    }
});

export const {
    setPersonalTaskId,
    setJobChecklistId,
    resetJob
} = jobSlice.actions;

export default jobSlice.reducer;
