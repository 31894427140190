import React from "react";
import ClientBranch from "./ClientBranch";

const ClientBranchContact = ({ clientId, name, setBranch, onUpdate, storedBranchId, setStoredBranchId, 
        preLoadMainBranch, setPreLoadMainBranch, setCreatedBranchId, createdBranchId }) => {
    return (
        <>
            <div className="mb-2">
                <ClientBranch
                    clientId={clientId}
                    name={name}
                    setBranch={setBranch}
                    onUpdate={onUpdate}
                    storedBranchId={storedBranchId}
                    setStoredBranchId={setStoredBranchId}
                    preLoadMainBranch={preLoadMainBranch}
                    setPreLoadMainBranch={setPreLoadMainBranch}
                    setCreatedBranchId={setCreatedBranchId}
                    createdBranchId={createdBranchId}
                />
            </div>

        </>
    )
}

export default ClientBranchContact;
