import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useDispatch, useSelector} from 'react-redux';
import {setAllocatedConsumableItems} from '../../redux/reducers/exhibit/exhibitSlice';
import {useRemoveExhibitConsumableMutation, useGetAssignedConsumablesQuery, useGetConsumableItemsQuery} from "../../services/authService";
import Error from '../../pages/Error';
import { CustomTextField } from '../../style/mui/customStyle';
import messages from '../../utils/messages';
import { enqueueSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({ // item_id: Yup.number().required('Item ID is required'),
  quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1')
});

function RemoveConsumableItemForm(props) {
  const {onClose, consumableId, setConsumableItemsState} = props;
  const [error, setError] = useState(null); // State variable for error message
  const dispatch = useDispatch();

  const {jobExhibit} = useSelector((state) => {
    return state.jobExhibitData;
  });

  const [removeExhibitConsumable, {
      isLoading,
      isError
    }
  ] = useRemoveExhibitConsumableMutation();
  const {data: assignedConsumableItems, isFetching: fetchingAssignedConsumableItems, refetch: refetchAssignedConsumableItems} = useGetAssignedConsumablesQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.id);
  
  const {data: consumableItems, isFetchingConsumableItems, refetch: refetchConsumableItems} = useGetConsumableItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.job?.clientId);
  
  const formik = useFormik({
    initialValues: {
      consumableId: consumableId,
      quantity: '',
      exhibitGiveAwayId: ''
    },
    validationSchema,
    onSubmit: async (values) => {      
      values.exhibitGiveAwayId = jobExhibit?.exhibitGiveAway?.id;
      const response = await removeExhibitConsumable(values);
      if (response.data === true) {
        const refetch = await refetchAssignedConsumableItems();
        dispatch(setAllocatedConsumableItems(refetch.data.consumables));
        if (setConsumableItemsState) {
          const refetchResponse = await refetchConsumableItems();
          setConsumableItemsState(refetchResponse.data);
        }
        enqueueSnackbar(`${messages.success.removeExhibitConsumable}`, { variant: 'success' });
        // formik.resetForm();
        onClose();
      } else if ( response.error) {
        enqueueSnackbar(`${messages.error.prefix} ${response.error.error ? response.error.error : messages.error.generic }`, { variant: 'error' });
      }
    }
  });

  return (
    <form onSubmit={
      formik.handleSubmit
    }>
      <DialogTitle>Return Consumable</DialogTitle>
      <DialogContent>
        <CustomTextField label="Quantity" name="quantity" type="number"
          value={
            formik.values.quantity
          }
          onChange={
            formik.handleChange
          }
          onBlur={
            formik.handleBlur
          }
          error={
            formik.touched.quantity && Boolean(formik.errors.quantity)
          }
          helperText={
            formik.touched.quantity && formik.errors.quantity
          }
          fullWidth
          style={
            {marginTop: '10px'}
          }/>
      </DialogContent>
      <DialogActions>
        <ActionBar hideDialog={onClose}
          loadingSave={isLoading}
          onSave={
            formik.handleSubmit
          }/>
      </DialogActions>
    </form>
  );
}

export default RemoveConsumableItemForm;
