import {useEffect, useState} from "react";
import {useGetBranchQuery} from "../../services/authService";
import {Paper, TextField} from "@mui/material";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import AddBranch from "./clientBranch/AddBranch";
import EditBranch from "./clientBranch/EditBranch";
import DeleteBranch from "./clientBranch/DeleteBranch";

const ClientBranch = ({ clientId, name, setBranch, onUpdate, storedBranchId, setStoredBranchId,
        preLoadMainBranch, setPreLoadMainBranch, setCreatedBranchId, createdBranchId}) => {
    
    const [filter, setFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const {data, isFetching } = useGetBranchQuery({clientId, filter}, { skip: clientId === null  });

    const handleChange = (event, value) => {
        if(value){
            setSelectedOption(value);
            setBranch(value);
            localStorage.setItem('storedBranchId',value.id);
        } else {
            setBranch(null);
            setOpen(true);
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            setBranch(null);
            setFilter('');
            localStorage.removeItem('storedBranchId');
            setStoredBranchId(null);
            setPreLoadMainBranch(false);
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            setOptions(data?.clientBranches);
            let selOption = null;

            if(filter !== '' && filter !== null){
                selOption = data?.clientBranches?.find(option => option.name === filter);
            }
            else if(createdBranchId !== null){
                selOption = data?.clientBranches?.find(option => option.id === Number(createdBranchId));
                if(selOption){
                    localStorage.setItem('storedBranchId',createdBranchId);
                    setCreatedBranchId(null);
                }
            }
            else if (preLoadMainBranch){
                selOption = data?.clientBranches?.find(option => option.isMainBranch === true);
                if(!selOption && storedBranchId !== null){
                    selOption = data?.clientBranches?.find(option => option.id === Number(storedBranchId));
                }
            }

            setSelectedOption(selOption ?? null);
            setBranch(selOption);
        }
        // eslint-disable-next-line
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const AddNewBranchRow = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Branch
            </div>
        </Paper>
    );

    return (
        <>
            <div className="d-flex align-items-center">
                <CustomAutocomplete
                    id="branch-list"
                    className="flex-grow-1"
                    sx={sxAutocomplete}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    loading={isFetching}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    options={options || []}
                    value={selectedOption}
                    filterOptions={(x) => x}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <span className="autocomplete-option-label">{option.name || ''}</span>
                        </li>
                    )}
                    getOptionLabel={(option) => (option ? option.name || '' : '')}
                    // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                    noOptionsText="Branch not found"
                    renderInput={(params) => (
                        <TextField {...params}
                           label={
                               <label className="autocomplete-input-label">Branch Name</label>
                           }
                           variant="outlined"
                        />
                    )}
                    PaperComponent={AddNewBranchRow}
                />

                <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                    <EditBranch
                        branch={selectedOption}
                        setBranch={setSelectedOption}
                        onUpdate={onUpdate}
                    />

                    <DeleteBranch
                        branch={selectedOption}
                        setBranch={setSelectedOption}
                        onDelete={onUpdate}
                    />
                </div>

                <AddBranch
                    clientId={clientId}
                    isOpen={addDialogOpen}
                    onAdd={onUpdate}
                    onCancel={closeAddDialog}
                    setStoredBranchId={setStoredBranchId}
                    storedBranchId={storedBranchId}
                    setCreatedBranchId={setCreatedBranchId}
                    setFilter={setFilter}
                    setBranch={setSelectedOption}
                />
            </div>

            { selectedOption?.address ? (
                <div className="bg-white p-2 mt-2 common-text">
                    <div>{selectedOption.address.address1}</div>
                    <div>{selectedOption.address.address2}</div>
                    <div>{selectedOption.address.city ? `${selectedOption.address.city}, ` : ''}{selectedOption.address.countryCode === "USA" ? selectedOption.address.stateCode : selectedOption.address.province} {selectedOption.address.zip} {selectedOption.address.countryCode}</div>
                    <div style={{marginTop:"5px"}}>Phone: {selectedOption.address.phone}</div>
                    <div>Fax: {selectedOption.address.fax}</div>
                </div>
            ): (
                <div className="bg-white p-3 mt-2 common-text">
                    <div>No branch address found</div>
                </div>
            )
            }
            { selectedOption && (
            <div style={{paddingTop:"5px"}}>
                <span style={{fontWeight:"500"}}>Main Branch:</span> {selectedOption?.isMainBranch ? "Yes" : "No"}
            </div>
            )}
        </>
    )
}

export default ClientBranch;