import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useSnackbar} from "notistack";
import BorderBox from "../ui/BorderBox";
import Hotel from "../lookups/Hotel";
import CarRental from "../lookups/CarRental";
import AddFlight from "./itineraryModal/AddFlight";
import CarrierInfo from "./itineraryModal/CarrierInfo";
import ItineraryInfo from "./itineraryModal/ItineraryInfo";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {useGetItineraryQuery, useUpdateItineraryMutation} from "../../services/authService";
import {ItineraryContext} from "../../context/ItineraryContext";
import {numFloat} from "../../utils/common";
import messages from "../../utils/messages";
import {DISPATCH_TYPES, FLIGHT_TYPE} from "../../utils/constants";
import {CustomTextField} from "../../style/mui/customStyle";
import styles from "./ItineraryModal.module.scss";
import useAuthentication from "../../hooks/useAuthentication";

const ItineraryModal = ({ type, isOpen, userId, userName, onCancel }) => {
    const [state,,refetch, handleSave] = useInstallDismantle();
    const [itinerary, setItinerary] = useState(null);

    // Queries
    const { data, isFetching, refetch: refetchItinerary} = useGetItineraryQuery({jobId: state?.jobId, userId, type}, { skip: !userId });
    const [updateItinerary, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateItineraryMutation();
    useAuthentication([isUpdateError]);

    const { enqueueSnackbar } = useSnackbar();

    const flightBlank = {
        itineraryId: 0,
        date: null,
        from: '',
        to: '',
        arrivalTime: null,
        carrierId: null,
        flightName: '',
        carrier: undefined,
    }

    const dispatchCallback = (type, value) => {
        if(type === DISPATCH_TYPES.hotel){
            handleItinerary({hotelId: value?.id, hotel: value})
        }
        if(type === DISPATCH_TYPES.carRental){
            handleItinerary({carRentalId: value?.id, carRental: value})
        }
    }

    const handleSubmit = async () => {
        if(itinerary){
            const updatedData = {
                id: itinerary.id,
                type: itinerary.type,
                jobId: itinerary.jobId,
                userId: itinerary.userId,
                flights: [...itinerary.flights],
                airFare: parseFloat(itinerary.airFare),
                hotelId: itinerary.hotelId,
                hotelDaysInfo: itinerary.hotelDaysInfo,
                noOfDaysInHotel: itinerary.noOfDaysInHotel,
                hotelRate: parseFloat(itinerary.hotelRate),
                hotelConfirmation: itinerary.hotelConfirmation,
                hotelAward: itinerary.hotelAward,
                hotelTotal: parseFloat(itinerary.hotelTotal),
                carRentalId: itinerary.carRentalId,
                carRentalDaysInfo: itinerary.carRentalDaysInfo,
                noOfDaysForCarRental: itinerary.noOfDaysForCarRental,
                carRentalRate: parseFloat(itinerary.carRentalRate),
                carRentalConfirmation: itinerary.carRentalConfirmation,
                carRentalAward: itinerary.carRentalAward,
                carRentalTotal: parseFloat(itinerary.carRentalTotal),
            }

            await updateItinerary(updatedData);
            handleSave();
            refetch();
        }
    }

    const handleMessages = () => {
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateItinerary}`, {variant: 'success'});
            updateReset();
            onCancel();
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isUpdateSuccess || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isUpdateSuccess, isUpdateError]);

    const handleItinerary = (newState) => {
        setItinerary((prevState) => {
            return {
                ...prevState,
                ...newState
            }
        })
    }

    const handleAddFlight = (type) => {
        const newFlight = {
            ...flightBlank,
            type: type,
            isNew: true,
            fid: type === FLIGHT_TYPE.Departure ? -3 : -4
        };
        handleItinerary({
            flights: [...itinerary.flights, newFlight]
        });
    }
    const handleDeleteFlight = (id) => {
        const updatedFlights = itinerary.flights.filter(
          (flight) => (flight.fid != id)
        );
      
        handleItinerary({
          flights: updatedFlights,
        });
    };

    useEffect(() => {
        if(userId){
            refetchItinerary();
        }
        // eslint-disable-next-line
    }, [userId]);

    useEffect(() => {
        if(data){
            if(data.itinerary && !data.itinerary.isDeleted){ // update
                setItinerary({
                    ...data.itinerary,
                    flights: data.itinerary.flights?.map(f => ({...f, fid: f.id}))
                });

            } else { // create
                const itineraryBlankData = {
                    id: 0,
                    type: type,
                    jobId: state?.jobId,
                    userId: userId,
                    airFare: 0,
                    hotelId: null,
                    hotelDaysInfo: '',
                    noOfDaysInHotel: 0,
                    hotelRate: 0,
                    hotelConfirmation: '',
                    hotelAward: '',
                    hotelTotal: 0,
                    carRentalId: null,
                    carRentalDaysInfo: '',
                    noOfDaysForCarRental: 0,
                    carRentalRate: 0,
                    carRentalConfirmation: '',
                    carRentalAward: '',
                    carRentalTotal: 0,
                    flights: [
                        {
                            fid: -1,
                            type: FLIGHT_TYPE.Departure,
                            ...flightBlank,
                        },
                        {
                            fid: -2,
                            type: FLIGHT_TYPE.Return,
                            ...flightBlank,
                        }
                    ]
                }
                setItinerary(itineraryBlankData);
            }
        }
        // eslint-disable-next-line
    }, [data, isFetching])

    return (
        <ItineraryContext.Provider
            value={useMemo(
                () => ([itinerary, handleItinerary, refetchItinerary]),
                // eslint-disable-next-line
                [itinerary]
            )}
        >
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="lg">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Itinerary
                </DialogTitle>
                <DialogContent className="pt-4">
                    <div className="d-flex justify-content-between mb-3">
                        Setup Itinerary for {userName}
                        <Button color="primary"
                                size="sm"
                                className="ms-2 px-3"
                                onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>

                    {/* Flight section */}
                    <BorderBox title="Flight" contentStyle="py-3 rounded-bottom-0">
                        {
                            itinerary?.flights?.filter(f => f.type === FLIGHT_TYPE.Departure).map(
                                (flight, flightIndex) => (
                                    <CarrierInfo
                                        key={`departure_${flightIndex}`}
                                        id={`departure_${flightIndex}`}
                                        data={flight}
                                        type={FLIGHT_TYPE.Departure}
                                        showDelete={flightIndex>0}
                                        onDelete={handleDeleteFlight}
                                    />
                                )
                            )
                        }
                        {
                            itinerary?.flights?.filter(f => f.type === FLIGHT_TYPE.Return).map(
                                (flight, flightIndex) => (
                                    <CarrierInfo
                                        key={`return_${flightIndex}`}
                                        data={flight}
                                        type={FLIGHT_TYPE.Return}
                                        showDelete={flightIndex>0}
                                        onDelete={handleDeleteFlight}
                                    />
                                )
                            )
                        }

                        <div className="d-flex align-items-center justify-content-end mt-3">
                            <label className={`${styles.horizontalLabel} text-ellipsis w-auto`}>Total Airfare</label>
                            <div className="flex-shrink-0">
                                <CustomTextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    // label=""
                                    name="airFare"
                                    variant="outlined"
                                    //value={numFloat(itinerary?.airFare) ?? 0.00}
                                    defaultValue={numFloat(itinerary?.airFare) ?? 0.00}
                                    key={numFloat(itinerary?.airFare)}
                                    onChange={(event) => {
                                        //handleItinerary({airFare: event.target.value})
                                    }}
                                    onBlur={(event) => {
                                        event.target.value = numFloat(event.target.value);
                                        handleItinerary({airFare: numFloat(event.target.value)})
                                    }}
                                />
                            </div>
                        </div>
                    </BorderBox>
                    <AddFlight
                        flights={itinerary?.flights}
                        onAddFlight={handleAddFlight}
                    />

                    {/* Hotel section */}
                    <BorderBox title="Hotel" contentStyle="py-3 rounded-bottom-0" boxStyle="mt-3 mt-md-4">
                        <Row>
                            <Col lg={4} className="mb-2 mb-lg-0">
                                <Hotel
                                    label=""
                                    name={itinerary?.hotel?.name}
                                    onUpdate={refetchItinerary}
                                    dispatchCallback={dispatchCallback}
                                />
                            </Col>
                            <Col lg={8}>
                                <ItineraryInfo
                                    type="hotel"
                                />
                            </Col>
                        </Row>
                    </BorderBox>

                    {/* Car Rental section */}
                    <BorderBox title="Car Rental" contentStyle="py-3 rounded-bottom-0" boxStyle="mt-3 mt-md-4">
                        <Row>
                            <Col lg={4} className="mb-2 mb-lg-0">
                                <CarRental
                                    label=""
                                    name={itinerary?.carRental?.name}
                                    onUpdate={refetchItinerary}
                                    dispatchCallback={dispatchCallback}
                                />
                            </Col>
                            <Col lg={8}>
                                <ItineraryInfo
                                    type="carRental"
                                />
                            </Col>
                        </Row>
                    </BorderBox>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" outline className="ms-2 px-3" size="sm"
                            onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" className="ms-2 px-3" size="sm" onClick={handleSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </ItineraryContext.Provider>
    );
}

export default ItineraryModal;
