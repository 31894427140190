import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Box
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useDispatch} from 'react-redux';
import {useCreateFreightCarrierMutation, useGetFreightCarriersQuery, useUpdateFreightCarrierMutation} from '../../services/authService';
import States from '../States';
import Countries from '../Countries';
import BorderBox from '../ui/BorderBox';
import Error from '../../pages/Error';
import { CustomTextField } from '../../style/mui/customStyle';
import { setCarriers } from '../../redux/reducers/carrier/carrierSlice';
import useAuthentication from '../../hooks/useAuthentication';


// Define the validation schema using Yup
const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
  address: Yup.object().shape(
    {
      address1: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      countryCode: Yup.string().required('Country is required'),
     // province: Yup.string().required('State is required'),
     // stateCode: Yup.string().required('State is required'),

          stateCode: Yup.string().when('countryCode', ([countryCode], schema) => {
              if (countryCode === "USA")
                  return Yup.string().required('State is required');
              else if (countryCode !== "USA")
                  return Yup.string().nullable();
              return schema;
          }),
          province: Yup.string().when('countryCode', ([countryCode], schema) => {
            if (countryCode === "USA")
                return Yup.string().nullable();
            else if (countryCode !== "USA")
                return Yup.string().required('Province is required');
            return schema;
        }),
      zip: Yup.string().required('ZIP is required'),
      phone: Yup.string().required('Phone is required'),
      fax: Yup.string().nullable()
    }
  )
});

function CarrierForm(props) {
    const { onClose, carrier, setCarrier } = props;
  const dispatch = useDispatch();
  const { data, refetch: refetchCarriers } = useGetFreightCarriersQuery();
  dispatch(setCarriers(data && data.freightCarriers));
  const [error, setError] = useState(null); // State variable for error message
  const [createFreightCarrier, {
      isLoading,
      isError
    }
  ] = useCreateFreightCarrierMutation();
  const [updateFreightCarrier, {
    isLoading: updatingFreightCarrier,
    isError: errorUpdateFreightCarrier
  }
  ] = useUpdateFreightCarrierMutation();
  useAuthentication([errorUpdateFreightCarrier,isError]);
  
  const formik = useFormik({
    initialValues: {
      name: carrier ? carrier.name : '',
      address: {
        address1: carrier && carrier.address ? carrier.address.address1 : '',
        address2: carrier && carrier.address ? carrier.address.address2 : '',
        city: carrier && carrier.address ? carrier.address.city : '',
        stateCode: carrier && carrier.address ? carrier.address.stateCode : '',
        province: carrier && carrier.address ? carrier.address.province : '',
        zip: carrier && carrier.address ? carrier.address.zip : '',
        countryCode: carrier && carrier.address ? carrier.address.countryCode : 'USA',
        phone: carrier && carrier.address ? carrier.address.phone : '',
        fax: carrier && carrier.address ? carrier.address.fax : ''
      }
    },
      validationSchema,
      onSubmit: async (values) => {
          if (values.address.countryCode !== "USA") {
              values.address.stateCode = null;
          }
          
          let response;
          let newFreight = false;
          if (carrier) {
            values.id = carrier.id;
            response = await updateFreightCarrier(values);
          } else {
              response = await createFreightCarrier(values);
              newFreight = true;
          }
          
          if ( response.data ) {        
            const refetch = await refetchCarriers();
              dispatch(setCarriers(refetch.data.freightCarriers));

              if (newFreight) {
                  const filteredCarrier = refetch.data.freightCarriers && refetch.data.freightCarriers?.filter((obj) => obj.id == response.data);
                  const carrierObj = filteredCarrier[0];
                  setCarrier({ name: carrierObj?.name, id: carrierObj?.id });
              }
            formik.resetForm();
            onClose();
          } else if ( response.error) {
            const messages = response.error.data.map( item => item.errorMessage);
            setError( messages ? messages : 'Error in saving carrier');
          }
      }
  });

  return (
    <form onSubmit={
      formik.handleSubmit
    }>
      <DialogTitle>{ carrier ? 'Edit' : 'Add' } Freight Carrier</DialogTitle>
      <DialogContent className='pt-3'>
        { isError && <Error >{error}</Error> }
        <BorderBox>
          <FormControl fullWidth className='mb-2'>
            {/* <InputLabel htmlFor="name">Name</InputLabel> */}
            <CustomTextField fullWidth id="name" name="name" label="Name" variant="outlined"
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              value={
                formik.values.name
              }
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && formik.errors.name
              }/>
          </FormControl>
          <Grid container
            spacing={2}
            className='mb-2'>
            <Grid item
              xs={6}>
              <CustomTextField fullWidth id="address.phone" name="address.phone" label="Phone" variant="outlined"
                onChange={
                  formik.handleChange
                }
                onBlur={
                  formik.handleBlur
                }
                value={
                  formik.values.address.phone
                }
                error={
                  formik.touched.address?.phone && Boolean(formik.errors.address?.phone)
                }
                helperText={
                  formik.touched.address?.phone && formik.errors.address?.phone
                }/>
            </Grid>
            <Grid item
              xs={6}>
              <CustomTextField fullWidth id="address.fax" name="address.fax" label="Fax" variant="outlined"
                onChange={
                  formik.handleChange
                }
                onBlur={
                  formik.handleBlur
                }
                value={
                  formik.values.address.fax
                }
                error={
                  formik.touched.address?.fax && Boolean(formik.errors.address?.fax)
                }
                helperText={
                  formik.touched.address?.fax && formik.errors.address?.fax
                }/>
            </Grid>
          </Grid>
          <Box mb={2}>
            <Countries formik={formik} fieldName="address.countryCode" countryCode={formik.values.address.countryCode} />
          </Box>
          <Box mb={2}>
            <CustomTextField fullWidth id="address.address1" name="address.address1" label="Address Line 1" variant="outlined"
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              value={
                formik.values.address.address1
              }
              error={
                formik.touched.address?.address1 && Boolean(formik.errors.address?.address1)
              }
              helperText={
                formik.touched.address?.address1 && formik.errors.address?.address1
              }/>
          </Box>
          <Box mb={2}>
            <CustomTextField fullWidth id="address.address2" name="address.address2" label="Address Line 2" variant="outlined"
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              value={
                formik.values.address.address2
              }/>
          </Box>
          <Box mb={2}>
            <CustomTextField fullWidth id="address.city" name="address.city" label="City" variant="outlined"
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              value={
                formik.values.address.city
              }
              error={
                formik.touched.address?.city && Boolean(formik.errors.address?.city)
              }
              helperText={
                formik.touched.address?.city && formik.errors.address?.city
              }
              />
          </Box>
          <Grid container
            spacing={2}
            className='mb-2'>
            <Grid item
              xs={7}>
              
              {formik.values.address.countryCode === "USA" ?
                  <States
                      formik={formik}
                      name="address.stateCode"
                      fieldName="address.stateCode"
                      stateCode={formik.values.address.stateCode}
                  />
                  :
                  <CustomTextField
                      fullWidth
                      label="Province"
                      name="address.province"
                      variant="outlined"
                      value={formik.values.address.province}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                          formik.touched.address?.province && Boolean(formik.errors.address?.province)
                      }                      
                      helperText={
                        formik.touched.address?.province && formik.errors.address?.province
                      }
                  />
              }
                          {/*
                              <>
                                  <States
                                      formik={formik}
                                      name="address.stateCode"
                                      fieldName="address.stateCode"
                                      stateCode={formik.values.address.stateCode}
                                  />

                                  <CustomTextField
                                      fullWidth
                                      label="Province"
                                      name="address.province"
                                      variant="outlined"
                                      value={formik.values.address.province}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      error={
                                          formik.touched.address?.province && Boolean(formik.errors.address?.province)
                                      }
                                      helperText={
                                          formik.touched.address?.province && formik.errors.address?.province
                                      }
                                  />
                              </>
                          */}

            </Grid>
            <Grid item
              xs={5}>
              <CustomTextField fullWidth id="address.zip" name="address.zip" label="Zip/Postal Code" variant="outlined"
                onChange={
                  formik.handleChange
                }
                onBlur={
                  formik.handleBlur
                }
                value={
                  formik.values.address.zip
                }
                error={
                  formik.touched.address?.zip && Boolean(formik.errors.address?.zip)
                }
                helperText={
                  formik.touched.address?.zip && formik.errors.address?.zip
                }/>
            </Grid>
          </Grid>
        </BorderBox>
      </DialogContent>
      <DialogActions>
        <ActionBar hideDialog={onClose}
          loadingSave={isLoading}
          onSave={
            formik.handleSubmit
          }/>
      </DialogActions>
    </form>
  );
}

export default CarrierForm;
