import {useState} from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmDialog from "../ConfirmDialog";
import {useDeleteFreightCarrierMutation, useGetFreightCarriersQuery} from "../../services/authService";
import { useDispatch } from "react-redux";
import { setCarriers } from "../../redux/reducers/carrier/carrierSlice";
import Error from "../../pages/Error";
import useAuthentication from "../../hooks/useAuthentication";

function DeleteCarrier(props) {
  const {selectedCarrier} = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCarrier] = useDeleteFreightCarrierMutation();
  const dispatch = useDispatch();
  const { data, isFetching, isError, refetch: refetchCarriers } = useGetFreightCarriersQuery();
  const [error, setError] = useState(null); // State variable for error message
  
  useGetFreightCarriersQuery();
  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }
  useAuthentication([isError]);
  
  const handleDelete = async () => {
    const response = await deleteCarrier(selectedCarrier.id);
    if (response.data){
      const refetch = await refetchCarriers();
      dispatch(setCarriers(refetch.data.freightCarriers));
    } else if ( response.error) {
      const messages = response.error.data.title;
      setError( messages ? messages : 'Error in delete carrier');
    }
  };

  return (
    <>
      <DeleteOutlinedIcon fontSize="small"
        style={
          {
            color: "grey",
            cursor: "pointer"
          }
        }
        onClick={openDeleteModalHandler}/>
      <ConfirmDialog title="Delete Carrier"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this carrier?
      </ConfirmDialog>
      { isError && <Error >{error}</Error> }
    </>
  )
}

export default DeleteCarrier;
