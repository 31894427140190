import {useState} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {Dialog} from "@mui/material";
import ExhibitForm from "./ExhibitForm";

function EditExhibit(props) {
  const {
    exhibit,
    onExhibitUpdated,
    setSelectedExhibit,
    notes,
    graphics
  } = props;
  const [isEditOpen, setEditOpen] = useState(false);

  function openEditExhibitHandler() {
    setEditOpen(true);
  }

  function closeEditExhibitHandler() {
    setEditOpen(false);
  }

  return (
    <>
      <EditOutlinedIcon fontSize="small"
        style={
          {
            color: "grey",
            cursor: "pointer"
          }
        }
        onClick={openEditExhibitHandler}/>
      <Dialog open={isEditOpen}
        onClose={closeEditExhibitHandler}
        maxWidth="sm" 
        fullWidth
        slotProps={
          {
            backdrop: {
              onClick: closeEditExhibitHandler
            }
          }
      }>
        <ExhibitForm exhibit={
            exhibit ? {
              id: exhibit.id,
              name: exhibit.name,
              notes: notes,
              graphics: graphics
            } : {}
          }
          onCancel={closeEditExhibitHandler}
          onExhibitUpdated={onExhibitUpdated}
          setSelectedExhibit={setSelectedExhibit}/>
      </Dialog>
    </>
  )
}

export default EditExhibit;
