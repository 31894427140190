import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../showName/EditShowName.module.scss";
import FacilityForm from "./FacilityForm";

const AddFacility = ({ isOpen, onAdd, onCancel, setNewFacility, name }) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add facility
                </DialogTitle>
                <FacilityForm
                    onCancel={onCancel}
                    onAdd={onAdd}
                    setNewFacility={setNewFacility}
                    name={name}
                />
            </Dialog>
        </div>
    )
}

export default AddFacility;
