import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Col, Container, Row} from "reactstrap";
import dayjs from 'dayjs';

import BorderBox from "../../components/ui/BorderBox";
import ActionBar from "../../components/ui/ActionBar";
import LabelInfo from "../../components/ui/LabelInfo";
import ShowName from "../../components/lookups/ShowName";
import Facility from "../../components/lookups/Facility";
import SetupDismantle from "../../components/setupdismantle/SetupDismantle";
import ConfirmDialog from "../../components/ConfirmDialog";

import {
    useGetJobDetailsQuery,
    useUpdateJobDetailsMutation,
    useUpdateJobStatusMutation
} from "../../services/authService";
import {setShowInfo, setShowInfoSave, setShowStatus} from "../../redux/reducers/showInfo/showInfoSlice";
import {DISPATCH_TYPES, FORMATS, JOB_STATUS} from "../../utils/constants";
import {formatDateTimeDayjs} from "../../utils/common";
import messages from "../../utils/messages";
import {CustomDateTimePicker, CustomTextField} from "../../style/mui/customStyle";
import styles from "./ShowInfo.module.scss";
import ContractorContact from "../../components/lookups/ContractorContact";
import PrintShowInfo from "./PrintShowInfo";
import useAuthentication from "../../hooks/useAuthentication";


const ShowInfoNew = () => {
    let params = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // Queries
    const { data: showInfoData, isFetching, error: errorShowInfo, refetch: refetchJobDetails } = useGetJobDetailsQuery(params.id);
    const [updateJobDetails, { isSuccess: isJobDetailsUpdateSuccess, error: updateJobDetailsError, reset: jobDetailsUpdateReset }] = useUpdateJobDetailsMutation();
    const [updateJobStatus, { isSuccess: isJobStatusUpdateSuccess, isLoading: loadingUpdateJobStatus, error: updateJobStatusError, reset: jobStatusUpdateReset }] = useUpdateJobStatusMutation();
    useAuthentication([updateJobDetailsError, updateJobStatusError, errorShowInfo]);

    // Local state
    const [show, setShow] = useState('');
    const [facility, setFacility] = useState('');
    const [contractorDetails, setContractorDetails] = useState(null);
    const [contractorName, setContractorName] = useState('');
    const [contractorContactId, setContractorContactId] = useState(null);
    const [boothSpace, setBoothSpace] = useState('');
    const [boothSize, setBoothSize] = useState('');
    const [showOpens, setShowOpens] = useState(dayjs());
    const [showCloses, setShowCloses] = useState(dayjs());
    const [setupDismantles, setSetupDismantles] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const [printDailog, setPrintDialog] = useState(false);

    const { showInfoSave, showStatus } = useSelector(state => state.showData);

    const [initialLoad, setInitialLoad] = useState(true);

    const handleSave = () => {
        const data = {
            ...showInfoSave,
            boothSpace: boothSpace,
            boothSize: boothSize,
            opensOn: showOpens && showOpens.isValid() ? formatDateTimeDayjs(showOpens, FORMATS.local) : null,
            closesOn: showCloses && showCloses.isValid() ? formatDateTimeDayjs(showCloses, FORMATS.local) : null,
        }
        updateJobDetails(data);
    }

    const cancelJobHandler = () => {
        console.log("cancelJobHandler");
        const data = {
            jobId: showInfoSave?.jobId,
            status: JOB_STATUS.cancelled,
        }
        updateJobStatus(data);
    }
    const dispatchCallback = (type, value) => {
        switch (type){
            case DISPATCH_TYPES.showName:
                dispatch(setShowInfoSave({showNameId: value}));
                break;
            case DISPATCH_TYPES.facility:
                dispatch(setShowInfoSave({facilityId: value}));
                break;
            case DISPATCH_TYPES.contractor:
                dispatch(setShowInfoSave({generalContractorId: value}));
                break;
            case DISPATCH_TYPES.contact:
                dispatch(setShowInfoSave({generalContractorContactId: value}));
                break;
            default:
                break;
        }
    }

    const handleComplete = () => {
        const data = {
            jobId: showInfoSave?.jobId,
            status: JOB_STATUS.complete,
        }
        updateJobStatus(data);
    }

    const handleCompleteConfirm = () => {
        setConfirmOpen(true);
    }

    const handleCancelConfirm = () => {
        setConfirmCancelOpen(true);
    }

    const handleMessages = () => {
        if(isJobStatusUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateJobStatus}`, { variant: 'success' });
            dispatch(setShowStatus(JOB_STATUS.complete));
            jobStatusUpdateReset();
            refetchJobDetails();
        }
        if(isJobDetailsUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateJobDetails}`, { variant: 'success' });
            jobDetailsUpdateReset();
            refetchJobDetails();
        }
    }
    
    function openPrintDialog(){
        setPrintDialog(true);
      }
    
    function closePrintDialog(){
    setPrintDialog(false);
    }

    function updateHandler(){
        console.log("updateHandler");
    }

    useEffect(() => {
        if(isJobStatusUpdateSuccess || isJobDetailsUpdateSuccess){
            handleMessages();
        }
    }, [isJobStatusUpdateSuccess, isJobDetailsUpdateSuccess]);

    // useEffect(() => {
    //     console.log("is this called after update");
    //     if(showInfoData){
    //         let showInfo = showInfoData?.show;
    //         dispatch(setShowStatus(showInfo?.job?.status));
    //         dispatch(setShowInfo({showId: showInfo?.id}));
    //         dispatch(setShowInfoSave({
    //             jobId: showInfo?.jobId,
    //             showNameId: showInfo?.showNameId,
    //             facilityId: showInfo?.facilityId,
    //             boothSpace: showInfo?.boothSpace,
    //             boothSize: showInfo?.boothSize,
    //             opensOn: showInfo?.opensOn,
    //             closesOn: showInfo?.closesOn,
    //             generalContractorId: showInfo?.generalContractorId,
    //             generalContractorContactId: showInfo?.generalContractorContactId
    //         }));

    //         setShow(showInfo?.showName?.name);
    //         setFacility(showInfo?.facility?.name);
    //         setContractorName(showInfo?.generalContractor?.name);
    //         setContractorContactId(showInfo?.generalContractorContactId);
    //         setSetupDismantles(showInfo?.setupDismantles.filter(sd => !sd.isDeleted));
    //         setShowOpens(dayjs(showInfo?.opensOn).isValid() ? dayjs(showInfo?.opensOn) : null);
    //         setShowCloses(dayjs(showInfo?.closesOn).isValid() ? dayjs(showInfo?.closesOn) : null);
    //         setBoothSpace(showInfo?.boothSpace);
    //         setBoothSize(showInfo?.boothSize);
    //     }
    // }, [showInfoData, isFetching, dispatch]);

    useEffect(() => {
        console.log("is this called after update");
        if(showInfoData){
            let showInfo = showInfoData?.show;
            if (initialLoad) {
                dispatch(setShowStatus(showInfo?.job?.status));
                dispatch(setShowInfo({showId: showInfo?.id}));
                dispatch(setShowInfoSave({
                    showId: showInfo?.id,
                    jobId: showInfo?.jobId,
                    showNameId: showInfo?.showNameId,
                    facilityId: showInfo?.facilityId,
                    boothSpace: showInfo?.boothSpace,
                    boothSize: showInfo?.boothSize,
                    opensOn: showInfo?.opensOn,
                    closesOn: showInfo?.closesOn,
                    generalContractorId: showInfo?.generalContractorId,
                    generalContractorContactId: showInfo?.generalContractorContactId
                }));

                setShow(showInfo?.showName?.name);
                setFacility(showInfo?.facility?.name);
                setContractorName(showInfo?.generalContractor?.name);
                setContractorContactId(showInfo?.generalContractorContactId);
                setShowOpens(dayjs(showInfo?.opensOn).isValid() ? dayjs(showInfo?.opensOn) : null);
                setShowCloses(dayjs(showInfo?.closesOn).isValid() ? dayjs(showInfo?.closesOn) : null);
                setBoothSpace(showInfo?.boothSpace);
                setBoothSize(showInfo?.boothSize);
            }            
            setSetupDismantles(showInfo?.setupDismantles.filter(sd => !sd.isDeleted));
            setInitialLoad(false);
        }
    }, [showInfoData, isFetching, dispatch, initialLoad]);

    const refetchJobDetailsHandler = () => {
        refetchJobDetails();
    }

    console.log("initialLoad----", initialLoad);
    return (
        <Container className={`${styles.container} py-3 p-md-4`} style={{ maxWidth: "sm" }}>
            <ActionBar
            onCancelAction={showStatus !== JOB_STATUS.cancelled ? handleCancelConfirm : null}
            loading={loadingUpdateJobStatus}
            onPrint={openPrintDialog}
            onSave={handleSave}
            onComplete={showStatus !== JOB_STATUS.complete ? handleCompleteConfirm : null}
            />   
            <Row className="py-3 py-md-4">
                <Col>
                    <LabelInfo label="CMC Job Number" info={showInfoSave?.jobId}/>
                </Col>
                <Col>
                    <LabelInfo label="Status" info={showStatus}/>
                </Col>
            </Row>

            <BorderBox title="Show Details" boxStyle="mb-3 mb-md-4">
                <div className="mb-2">
                    <ShowName
                        name={show}
                        // onUpdate={refetchJobDetails}
                        onUpdate={updateHandler}
                        dispatchCallback={dispatchCallback}
                    />
                </div>

                <div className="mb-2">
                    <Facility
                        name={facility}
                        // onUpdate={refetchJobDetails}
                        onUpdate={updateHandler}
                        dispatchCallback={dispatchCallback}
                    />
                </div>

                <Row className="gx-2" style={{width: 'calc(100% - 54px)'}}>
                    <Col>
                        <CustomTextField
                            fullWidth
                            label="Booth Space"
                            variant="outlined"
                            value={boothSpace}
                            onChange={(event) => {
                                setBoothSpace(event.target.value);
                            }}
                        />
                    </Col>
                    <Col>
                        <CustomTextField
                            fullWidth
                            label="Booth Size"
                            variant="outlined"
                            value={boothSize}
                            onChange={(event) => {
                                setBoothSize(event.target.value);
                            }}
                        />
                    </Col>
                </Row>
            </BorderBox>

            <BorderBox title="Set Up / Dismantle" boxStyle="mb-3 mb-md-4">
                <h4 className={styles.subTitle}>Set Up</h4>
                <SetupDismantle
                    type="Setup"
                    data={setupDismantles}
                    onUpdate={refetchJobDetailsHandler}
                    containerStyle="mb-4"
                />

                <Row className="mb-3 gx-2">
                    <Col>
                        <CustomDateTimePicker
                            label='Show Opens'
                            size={"small"}
                            value={showOpens}
                            onChange={(newValue) => setShowOpens(newValue)}
                        />
                    </Col>
                    <Col>
                        <CustomDateTimePicker
                            label="Show Closes"
                            size={"small"}
                            value={showCloses}
                            onChange={(newValue) => setShowCloses(newValue)}
                        />
                    </Col>
                </Row>

                <h4 className={styles.subTitle}>Dismantle</h4>
                <SetupDismantle
                    type="Dismantle"
                    onUpdate={refetchJobDetailsHandler}
                    data={setupDismantles}
                />
            </BorderBox>

            <BorderBox title="General Contractor" boxStyle="mb-3 mb-md-4">
                <ContractorContact
                    id={contractorContactId}
                    contractorName={contractorName}
                    contractor={contractorDetails}
                    setContractor={setContractorDetails}
                    // onUpdate={refetchJobDetails}
                    onUpdate={updateHandler}
                    dispatchCallback={dispatchCallback}
                />
            </BorderBox>

            <ActionBar
                onSave={handleSave}
            />

            <ConfirmDialog
                title="Complete Job?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleComplete}
            >
                Are you sure you want to complete the Job?
            </ConfirmDialog>
            <ConfirmDialog
                title="Cancel Job?"
                open={confirmCancelOpen}
                setOpen={setConfirmCancelOpen}
                onConfirm={cancelJobHandler}
            >
                Are you sure you want to cancel the Job?
            </ConfirmDialog>
        {
          printDailog && 
          <PrintShowInfo
            onCancel={closePrintDialog}
            isOpen={printDailog}
          />
        }
        </Container>
    )
}

export default ShowInfoNew;