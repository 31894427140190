import { createSlice } from '@reduxjs/toolkit'
import {userLogin, userForgotPassword, addNewUser, resetUserPassword} from '../actions/authActions'


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : ''

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken') // delete token from storage
      localStorage.removeItem('showInfoId') // delete selected job id
      state.loading = false
      state.userInfo = null
      state.userToken = ''
      state.error = null
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userToken = payload.token
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },    
    [userForgotPassword.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [userForgotPassword.fulfilled]: (state, { payload }) => {
    state.loading = false
    state.success = true
    },
    [userForgotPassword.rejected]: (state, { payload }) => {
    state.loading = false
    state.success = false
    state.error = payload
    },    
    // register new user
    [addNewUser.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [addNewUser.fulfilled]: (state, { payload }) => {
    state.loading = false
    state.success = true // registration successful
    },
    [addNewUser.rejected]: (state, { payload }) => {
    state.loading = false
    state.error = payload
    },
    [resetUserPassword.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [resetUserPassword.fulfilled]: (state, { payload }) => {
    state.loading = false
    state.success = true
    },
    [resetUserPassword.rejected]: (state, { payload }) => {
    state.loading = false
    state.success = false
    state.error = payload
    },    
  },
})

export const { logout, setCredentials } = authSlice.actions

export default authSlice.reducer