
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const backendURL = process.env.REACT_APP_API_URL

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ userName, password, rememberMe}, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post(
        `${backendURL}/api/account/login-by-password`,
        { userName, password, rememberMe },
        config
      )
      // store user's token in local storage
      localStorage.setItem('userToken', data.token)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const resetUserPassword = createAsyncThunk(
    'auth/resetpassword',
    async ({ username, password, passwordResetToken }, { rejectWithValue }) => {
      try {
        // configure header's Content-Type as JSON
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const { data } = await axios.post(
          `${backendURL}/api/account/reset-password`,
          { username, password, passwordResetToken  },
          config
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

export const userForgotPassword = createAsyncThunk(
    'auth/forgotpassword',
    async ({ username }, { rejectWithValue }) => {
      try {        
        await axios.get(
          `${backendURL}/api/account/password-reset-token?username=${username}`
        )
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

export const addNewUser = createAsyncThunk(
    'auth/register',
    async ({ firstName, lastName, email, username, token }, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`,
          },
        }
        await axios.post(
          `${backendURL}/api/account/register`,
          { firstName, lastName, email, username },
          config
        )
      } catch (error) {
      // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )