import Exhibit from '../../components/exhibit/Exhibit';

function ShowExhibit(props) {
  return (
    <>
      <Exhibit>{
        props.children
      }</Exhibit>
    </>
  )
}
export default ShowExhibit;
