import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from "@mui/material";
import dayjs from 'dayjs';
import sxTableStyles from "../../style/sxTableStyles";
import { CustomDatePicker, CustomFormControlLabel, CustomIconButton, CustomTableCellOne, CustomTextField, CustomDateTimePicker } from "../../style/mui/customStyle";
import { FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";
import { Container, Row, Col } from 'reactstrap';

function DirectShipping(props) {
  const {handleDirectShippingChange, formik, checkedDirect} = props;
  return (
    <>
          <Container>
              <Row>
                  <Col xs={12} md={7} style={{ paddingLeft: '0px' }}>
                      <CustomFormControlLabel
                          fullWidth
                          control={<Checkbox checked={checkedDirect} size='sm' onChange={handleDirectShippingChange} />}
                          label="Direct"
                          labelPlacement="start"
                          style={{ marginLeft: '0px' }}
                          value={formik.values.isDirect}
                          name="isDirect"
                      />
                      <CustomTextField name="directShippingAddress" onChange={formik.handleChange} value={formik.values.directShippingAddress} multiline fullWidth rows={5} className="mb-2"
                      />
                  </Col>
                  <Col xs={12} md={5} className="d-flex justify-content-center align-items-center clsDirectRightCol">
                      <TableContainer component={Paper}
                          className="rounded-0 shadow-none mb-2">
                          <Table sx={sxTableStyles} className="clsDirectTbl">
                              <TableBody>
                                  <TableRow>
                                      <TableCell className="cellheading" style={{ width: '33%' }}>Ready</TableCell>
                                      <CustomTableCellOne className="cellcontent">
                                          <CustomIconButton className="table-cell-picker">
                                              <CustomDatePicker variant="standard" name="directShippingReadyDate" style={{ width: '100%' }} 
                                                  value={formik.values.directShippingReadyDate ? dayjs(formik.values.directShippingReadyDate) : ''}
                                                  onChange={(newValue) => { formik.setFieldValue('directShippingReadyDate', formatDateTimeDayjs(newValue, FORMATS.date)) }}
                                              />
                                          </CustomIconButton>
                                      </CustomTableCellOne>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell className="cellheading"  style={{ width: '33%' }}>Target</TableCell>
                                      <CustomTableCellOne className="px-2 cellcontent">
                                          <CustomDatePicker variant="outlined" name="directShippingDeadline" style={{ width: '100%' }} 
                                              sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                                              value={formik.values.directShippingDeadline ? dayjs(formik.values.directShippingDeadline) : ''}
                                              onChange={(newValue) => { formik.setFieldValue('directShippingDeadline', formatDateTimeDayjs(newValue, FORMATS.local)) }}
                                              fullWidth
                                          />
                                      </CustomTableCellOne>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell className="cellheading" style={{ width: '33%' }}>Handling Rate</TableCell>
                                      <CustomTableCellOne className="px-2 cellcontent">
                                          <CustomTextField variant="standard" name="directHandlingRate" style={{ width: '100%' }} 
                                              value={formik.values.directHandlingRate ? formik.values.directHandlingRate : ''}
                                              onChange={formik.handleChange}
                                          />
                                      </CustomTableCellOne>
                                  </TableRow>
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Col>
              </Row>
        </Container>
      
      
    </>
  )

}

export default DirectShipping;
