import {Fragment, useEffect, useState} from "react"
import {useGetStatesQuery} from "../services/authService"
import {TextField } from "@mui/material";
import sxAutocomplete from "../style/sxAutocompleteStyles";
import {CustomAutocomplete} from "../style/mui/customStyle";

const States = (props) => {
  const {formik, fieldName, stateCode, onChange} =props;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event, value) => {
      if(formik){
          formik.setFieldValue(fieldName, value?.stateCode || '');
      }
      setSelectedOptions(value);
      if(onChange){
          onChange(event, value);
      }
  };
  const { data, isFetching } = useGetStatesQuery()

  useEffect(() => {
    if (data) {
      setOptions(data?.states);
      const fetchedOption = data?.states?.find(option => option.stateCode === stateCode);
      setSelectedOptions(fetchedOption);
    }
  }, [data, stateCode]);

  return (
    <Fragment>
      <CustomAutocomplete
        id="states-list"
        sx={sxAutocomplete}
        loading={isFetching}
        onChange={handleChange}
        options={options}
        value={selectedOptions}
        renderOption={(props, option) => (
          <li {...props} key={option.stateCode}>
            <span className="autocomplete-option-label">{option.name || ''}</span>
          </li>
        )}
        getOptionLabel={(option) => (option ? option.name || '' : '')}
        style={{ width: '100%' }}
        noOptionsText="State not found"
        renderInput={(params) => (
          <TextField {...params}
            label={
              <label className="autocomplete-input-label">State</label>
            }
            error={formik && (formik.touched['address'] && formik.touched['address']['stateCode'] && Boolean(formik.errors['address']&&formik.errors['address']['stateCode']))}
            helperText={formik && (formik.touched['address'] && formik.touched['address']['stateCode'] && formik.errors['address']&& formik.errors['address']['stateCode'])}
            variant="outlined"
          />
        )} />

      {/* <div><pre>{JSON.stringify(selectedOptions, null, 2)}</pre></div>          */}
    </Fragment>
  )
}

export default States