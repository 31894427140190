import React, {useState} from "react";
import {MenuItem} from "@mui/material";
import {TASK_STATUS} from "../../utils/constants";
import {CustomSelect} from '../../style/mui/customStyle';


const TaskStatus = ({ status, onChange }) => {
    const statusData = Object.values(TASK_STATUS);
    const [taskStatus, setTaskStatus] = useState(status);

    const handleChange = (e) => {
        let value = e.target.value;
        setTaskStatus(value);
        if(onChange){
            onChange(value);
        }
    }

    return (
        <CustomSelect
            labelId="task-status-label"
            // id="task-status"
            name="Status"
            fullWidth
            displayEmpty
            size="small"
            label="Status"
            value={taskStatus}
            onChange={handleChange}
        >
            <MenuItem disabled value="">
                <em>Status</em>
            </MenuItem>
            {statusData?.map(status => (
                <MenuItem key={`status-${status}`} value={status}>
                    {status}
                </MenuItem>
            ))}
        </CustomSelect>
    )
}

export default TaskStatus;
