// ProtectedRoute.js

import React from 'react';
import { useSnackbar } from 'notistack';
import { Outlet, Navigate } from 'react-router-dom';
import messages from './utils/messages';

const ProtectedRoute = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userToken = localStorage.getItem('userToken');

  if (!userToken || userToken === 'null') {
    enqueueSnackbar(`${messages.error.prefix} ${messages.error.unauthorizedAccess}`, { variant: 'error' });
    // Redirect to the login page
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
