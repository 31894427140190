import React, { useRef } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Layout from './components/Layout';
import AuthLayout from "./components/AuthLayout";
import LoginScreen from './pages/logon/Login';
import ProtectedRoute from './ProtectedRoute';
import ForgotPassword from './pages/logon/ForgotPassword';
import TaskList from './pages/dashboard/TaskList';
import NewUser from './pages/logon/NewUser';
import ResetPassword from './pages/logon/ResetPassword';
import ShowInfoNew from "./pages/showInfo/ShowInfoNew";
import InstallDismantle from "./pages/InstallDismantle";
import Placeholder from "./pages/Placeholder";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from './utils/colors';
import './App.scss';
import ShowExhibit from './pages/exhibits/ShowExhibit';
import ShowShipment from './pages/shipments/ShowShipment';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import ServicesRequest from './pages/servicesRequest/ServicesRequest';
import Expenses from './pages/expenses/Expenses';
import Client from './pages/client/Client';

export default function App() {
  const snackRef = useRef(null);

  return (
      <div className="app">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
          className="snack-container"
          ref={snackRef}
          maxSnack={3}
          preventDuplicate={true}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          action={(snackbarId) => (
              <button
                  className="btn btn-link p-1 position-absolute top-0 end-0"
                  onClick={() => closeSnackbar(snackbarId)}>
                <CloseRoundedIcon className="text-white" />
              </button>
          )}
      >
        <Router>
            <Routes>
              <Route element={<AuthLayout />}>
                <Route path='/login' element={<LoginScreen />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route element={<Layout />}>
                  <Route path='/show-info/:id' element={<ShowInfoNew />} />
                  <Route path='/exhibit-giveaway/:id' element={<ShowExhibit />} />
                  <Route path='/install-dismantle/:id' element={<InstallDismantle />} />
                  <Route path='/job-shipment/:id/:sId?' element={<ShowShipment />} />
                  <Route path='/tasklist' element={<TaskList />} />
                  <Route path='/new-user' element={<NewUser />} />
                  <Route path="*" element={<Placeholder pageName="Dashboard" link="/tasklist" />} />
                  <Route path='/services-request/:id' element={<ServicesRequest />} />
                  <Route path='/expenses/:id' element={<Expenses />} />
                  <Route path='/clients' element={<Client />} />
                </Route>
              </Route>
              <Route path="*" element={<Placeholder pageName="Login" link="/login" />} />
            </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
    </LocalizationProvider>
      </div>
  );  
}
