import {useEffect} from "react";
import {useSnackbar} from "notistack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useCreateIDSetupDismantleMutation, useUpdateIDSetupDismantleMutation} from "../../../services/authService";
import {useInstallDismantle} from "../../../context/InstallDismantleContext";
import {SETUP_DISMANTLE} from "../../../utils/constants";
import messages from "../../../utils/messages";
import styles from "../../setupdismantle/SetupDismantle.module.scss";

const EditSetupDismantleRow = ({ row, type }) => {
    const [state,, refetch] = useInstallDismantle();
    const [createSetupDismantle, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateIDSetupDismantleMutation();
    const [updateSetupDismantle, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateIDSetupDismantleMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleUpdate = () => {
        const data = {
            id: row?.id,
            installDismantleId: state?.id,
            eventDate: row.eventDate,
            startTime: row.startTime,
            numberOfMen: row.numberOfMen,
            numberOfHours: row.numberOfHours,
            rate: row.rate,
            estimate: row.estimate,
            actual: row.actual,
            type: type
        }
        console.log(data);
        if(row.isNew) {
            console.log('create');
            createSetupDismantle(data);
        } else {
            console.log('update');
            updateSetupDismantle(data);
        }
    }

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(
                type === SETUP_DISMANTLE.setup  ? `${messages.success.createSetup}` : `${messages.success.createDismantle}`,
                { variant: 'success' }
            );
            createReset();
            refetch();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(
                type === SETUP_DISMANTLE.setup  ? `${messages.success.updateSetup}` : `${messages.success.updateDismantle}`,
                { variant: 'success' }
            );
            updateReset();
            refetch();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <AddCircleOutlineIcon
            fontSize="small"
            onClick={handleUpdate}
            className={`${styles.icon}`}
        />
    )
}

export default EditSetupDismantleRow;
