import React, { useState, useEffect , useRef} from 'react';
import { Table, TableHead, TableBody, TableRow } from "@mui/material";
import { Container } from "reactstrap";
import BorderBox from '../../components/ui/BorderBox';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import styles from "./Expenses.module.scss";
import { CustomSpan, CustomSpanBold, CustomTableCell, CustomTableCellOne, CustomTextField } from '../../components/customStyled/servicesRequestDetailsStyle';
import { useDeleteExpenseMutation, useGetExpensesQuery, useUpdateExpenseMutation } from '../../services/authService';
import AddRowButton from '../../components/ui/AddRowButton';
import ConfirmDialog from '../../components/ConfirmDialog';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LabelInfo from '../../components/ui/LabelInfo';
import { numFloat } from "../../utils/common";

const Expenses = () => {
    let params = useParams();
    let estimateTotal = 0;
    let actualTotal = 0;
    let invoiceTotal = 0;
    const [newRowId, setNewRowId] = useState(0);
    const expensesTable = useRef(null);
    const [addDisabled, setAddDisabled] = useState(false);

    const { data: expensesData, isFetching, refetch: getExpenseData } = useGetExpensesQuery(params.id);
    const [updateExpense, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateExpenseMutation();
    const [deleteExpense, { isSuccess: isDeleteSuccess, error: isDeleteError, reset: deleteReset }] = useDeleteExpenseMutation();
    const [updatedRowData, setUpdatedRowData] = useState();
    const [tableData, setTableData] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState({ expenseId: null, index: null });

    const newRow = {
        expenseId: 0,
        jobId: params.id,
        item: null,
        description: '',
        estimate: 0.00,
        actual: 0.00,
        invoice: 0.00,
        isNew: true,
    };

    const handleMessages = () => {
        if (isUpdateSuccess) {
            enqueueSnackbar(
                `${messages.success.updateExpense}`,
                { variant: 'success' }
            );
            updateReset();
            getExpenseData();
        }

        if (isUpdateError) {
            let errorMessage = isUpdateError?.data[0]?.errorMessage ? isUpdateError?.data[0]?.errorMessage : messages.error.generic;
            enqueueSnackbar(`${messages.error.prefix} ${errorMessage}`, { variant: 'error' });
        }

        if (isDeleteError) {
            let errorMessage = isDeleteError?.data[0]?.errorMessage ? isDeleteError?.data[0]?.errorMessage : messages.error.generic;
            enqueueSnackbar(`${messages.error.prefix} ${errorMessage}`, { variant: 'error' });
        }

        if (isDeleteSuccess) {
            enqueueSnackbar(
                `${messages.success.deleteExpense}`,
                { variant: 'success' }
            );
            // deleteReset();
            // getExpenseData();
        }
    }
    const handleValidationMessage = (message) => {
        enqueueSnackbar(`${messages.error.validationError} ${message}`, { variant: 'error' });
    };

    useEffect(() => {
        if (isUpdateSuccess || isUpdateError || isDeleteError || isDeleteSuccess) {
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isUpdateSuccess, isUpdateError, isDeleteError || isDeleteSuccess]);

    useEffect(() => {
        if (expensesData) {
            const { serviceRequests } = expensesData;
            setTableData(serviceRequests)
        }
    }, [expensesData]);

    useEffect(() => {
        if (updatedRowData) {
            handleSubmitRow(updatedRowData);
        }
    }, [updatedRowData]);

    const handleSubmitRow = (data) => {
        let serviceRequestObj = {
            serviceRequest: {}
        };
        serviceRequestObj.serviceRequest = data;
        updateExpense(serviceRequestObj);
    };

    const openDeleteModalHandler = (expenseId, index) => {
        setSelectedExpense({ expenseId: expenseId, index: index });
        setDeleteModalOpen(true);
    };

    const setDescription = (data, newDescription) => {
        setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, description: newDescription }));
    };

    const setEstimate = (data, newEstimate) => {
        if (isNaN(+newEstimate)) {
            handleValidationMessage("Estimate value must be a number.");
            return;
        }
        setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, estimate: newEstimate }));
    };

    const setActual = (data, newActual) => {
        if (isNaN(+newActual)) {
            handleValidationMessage("Actual value must be a number.");
            return;
        }
        setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, actual: newActual }));
    };

    const setInvoice = (data, newInvoice) => {
        if (isNaN(+newInvoice)) {
            handleValidationMessage("Invoice value must be a number.");
            return;
        }
        setUpdatedRowData(updatedRowData => ({ ...updatedRowData, ...data, invoice: newInvoice }));
    };

    const handleFieldChange = (event, expenseId, field) => {
        const newData = tableData.map((item, i) => {
            if (item.expenseId === expenseId) {
                let fieldValue = event.target.value;
                if (field === "description" && event.target.value === "") {
                    fieldValue = "";
                } else if (event.target.value === "") {
                    fieldValue = 0;
                }
                return {
                    ...item,
                    [field]: fieldValue,
                };
            }
            return item;
        });
        setTableData(newData);
    };

    const showDescriptionValue = (val) => {
        let descriptionValue = "";
        let otherDesc = val?.description ?? "Other";
        
        if (val?.category === "Furnishings") { //val?.description
            descriptionValue = val?.label === "Other" ? `Rental Furnishing: ${otherDesc}` : `Rental Furnishing: ${val?.item}`;
        }
        else if (val?.category === "Services") {
            descriptionValue = val?.label === "Other" ? `Services: ${otherDesc}` : `${val?.item}`;
        }
        else if (val?.category === "Facility") {
            descriptionValue = val?.label === "Other" ? `Facility: ${otherDesc}` : `${val?.item}`;
        } else {
            descriptionValue = `${otherDesc}`;
        }        

        return descriptionValue;
    }

    const getFieldValue = (expenseId, field) => {
        const fieldState = tableData.find((field) => field.expenseId === expenseId);
        let fieldValue = fieldState[`${field}`];
        if (field === "description") {
            fieldValue = showDescriptionValue(fieldState);
        } else {
            fieldValue = numFloat(fieldValue);
        }
        
        return fieldState ? fieldValue : '';
    };

    const handleBlurDescription = (event, val) => {
        setDescription(val, event.target.value);
    };

    const handleBlurEstimate = (event, val) => {
        let estimateValue = event.target.value;
        if (estimateValue === "") {
            estimateValue = 0;
        }
        setEstimate(val, estimateValue);
    };

    const handleFocusElement = (event, val) => {
        if (event.target.value == "0.00") {
            event.target.setSelectionRange(0, 4);
        }
    }

    const handleBlurActual = (event, val) => {
        let actualValue = event.target.value;
        if (actualValue === "") {
            actualValue = 0;
        }
        setActual(val, actualValue);
    };

    const handleBlurInvoice = (event, val) => {
        let estimateValue = event.target.value;
        if (estimateValue === "") {
            estimateValue = 0;
        }
        setInvoice(val, estimateValue);
    };


    const handleDelete = (id, index) => {
        if (id == null || id === 0) {
            // setTableData(test => {
            //     return test.filter((value, i) => i !== index)
            // });
            setTableData((currentTableData) => {
                const updatedTableData = [...currentTableData];
                updatedTableData.splice(index, 1);
                return updatedTableData;
            });
        } else {
            deleteExpense(id);
        }
    };

    const handleAddRow = () => {
        setTableData((tableData) => [...tableData, newRow]);
    };

    useEffect(() => {
        if (tableData) {
            const disabledFlag = tableData?.some((row) => row.isNew)
            setAddDisabled(disabledFlag)
        }
    }, [tableData]);

    return (
        <Container className={`${styles.container} py-3 p-md-4`} style={{ maxWidth: '800px' }}>
            <div style={{ marginBottom: "10px" }}>
                <LabelInfo label="CMC Job Number" info={params.id} />
            </div>
            <BorderBox title="Expenses" boxStyle="mb-3">
                <div className="mb-3" style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <div style={{ border: "1px  solid gray" }}>
                        <Table ref={expensesTable}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell>Description</CustomTableCell>
                                    <CustomTableCell>Estimate</CustomTableCell>
                                    <CustomTableCell>Actual</CustomTableCell>
                                    <CustomTableCell>Invoice</CustomTableCell>
                                    <CustomTableCell>Action</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((val, index) => {
                                    estimateTotal = (parseFloat(estimateTotal) + parseFloat(val.estimate)).toFixed(2);
                                    actualTotal = (parseFloat(actualTotal) + parseFloat(val.actual)).toFixed(2);
                                    invoiceTotal = (parseFloat(invoiceTotal) + parseFloat((val.invoice ? val.invoice : 0))).toFixed(2);
                                    return (
                                        ((val?.category == null && val?.description != "") ||
                                            (val?.item != "" || val?.description !== "" || val?.estimate !== 0 || val?.actual !== 0 || val?.invoice !== 0)) &&
                                        <TableRow key={index}>
                                            <CustomTableCellOne>
                                                {(val.category == null || val.category === "ManualExpense") ? (
                                                    <CustomTextField
                                                        id="outlined-basic"
                                                        label=""
                                                        variant="outlined"
                                                        sx={{ width: '100%', border: 'none', "& fieldset": { border: 'none' }, }}
                                                        onChange={(event) => handleFieldChange(event, val.expenseId, 'description')}
                                                        onBlur={(event) => handleBlurDescription(event, val, index)}
                                                        value={getFieldValue(val.expenseId, 'description')}
                                                    />
                                                ) :
                                                    <CustomSpan>{/*val.description*/}
                                                        {showDescriptionValue(val)}
                                                    </CustomSpan>
                                                }
                                            </CustomTableCellOne>
                                            <CustomTableCellOne>
                                                {(val.category == null || val.category === "ManualExpense") ? (
                                                    <CustomTextField
                                                        id="outlined-basic"
                                                        label=""
                                                        variant="outlined"
                                                        sx={{ maxWidth: '75px', border: 'none', "& fieldset": { border: 'none' }, }}
                                                        onBlur={
                                                                (event) => {
                                                                    handleBlurEstimate(event, val);
                                                                    handleFieldChange(event, val.expenseId, 'estimate');
                                                                }
                                                        }
                                                        //onChange={(event) => handleFieldChange(event, val.expenseId, 'estimate')}
                                                        onFocus={(event) => handleFocusElement(event, val)}
                                                        defaultValue={getFieldValue(val.expenseId, 'estimate') ?? ''}
                                                        key={getFieldValue(val.expenseId, 'estimate') ?? ''}
                                                    />
                                                ) :
                                                    <CustomSpan>{val.estimate ? val.estimate.toFixed(2) : (0.00).toFixed(2)}</CustomSpan>
                                                }
                                            </CustomTableCellOne>
                                            <CustomTableCellOne>
                                                <CustomTextField
                                                    id="outlined-basic"
                                                    label=""
                                                    variant="outlined"
                                                    sx={{ maxWidth: '75px', border: 'none', "& fieldset": { border: 'none' }, }}
                                                    onBlur={(event) => { handleBlurActual(event, val); handleFieldChange(event, val.expenseId, 'actual') }}
                                                    //onChange={(event) => handleFieldChange(event, val.expenseId, 'actual')}
                                                    onFocus={(event) => handleFocusElement(event, val)}
                                                    defaultValue={getFieldValue(val.expenseId, 'actual') ?? ''}
                                                    key={getFieldValue(val.expenseId, 'actual') ?? ''}
                                                />
                                            </CustomTableCellOne>
                                            <CustomTableCellOne>
                                                <CustomTextField
                                                    id="outlined-basic"
                                                    label=""
                                                    variant="outlined"
                                                    sx={{ maxWidth: '75px', border: 'none', "& fieldset": { border: 'none' }, }}
                                                    onBlur={(event) => { handleBlurInvoice(event, val); handleFieldChange(event, val.expenseId, 'invoice') }}
                                                    //onChange={(event) => handleFieldChange(event, val.expenseId, 'invoice')}
                                                    onFocus={(event) => handleFocusElement(event, val)}
                                                    defaultValue={getFieldValue(val.expenseId, 'invoice') ?? ''}
                                                    key={getFieldValue(val.expenseId, 'invoice') ?? ''}
                                                />
                                            </CustomTableCellOne>
                                                <CustomTableCellOne>{window.console.log("===>", val.category)}
                                                    {(val.category == null || val.category === "ManualExpense") && (
                                                    <DeleteOutlinedIcon fontSize="small"
                                                        style={
                                                            {
                                                                color: "grey",
                                                                cursor: "pointer"
                                                            }
                                                        }
                                                        //  onClick={openDeleteModalHandler}
                                                        onClick={() => openDeleteModalHandler(val.expenseId, index)}
                                                    />
                                                )}

                                            </CustomTableCellOne>
                                        </TableRow>
                                    );
                                    
                                })}
                                <TableRow>
                                    <CustomTableCellOne><CustomSpanBold>Total</CustomSpanBold></CustomTableCellOne>
                                    <CustomTableCellOne><CustomSpanBold>{estimateTotal}</CustomSpanBold></CustomTableCellOne>
                                    <CustomTableCellOne><CustomSpanBold>{actualTotal}</CustomSpanBold></CustomTableCellOne>
                                    <CustomTableCellOne><CustomSpanBold>{invoiceTotal}</CustomSpanBold></CustomTableCellOne>
                                    <CustomTableCellOne></CustomTableCellOne>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <AddRowButton
                            onAdd={handleAddRow}
                            disabled={addDisabled}
                        >
                            + Add Expense
                        </AddRowButton>
                        <ConfirmDialog title="Delete Expense"
                            open={isDeleteModalOpen}
                            setOpen={setDeleteModalOpen}
                            onConfirm={() => {
                                handleDelete(selectedExpense.expenseId, selectedExpense.index);
                            }}>
                            Are you sure you want to delete this expense?
                        </ConfirmDialog>
                    </div>
                </div>
            </BorderBox>
        </Container>
    )

}

export default Expenses;