import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Collapse, Row} from "reactstrap";
import SetupDismantleLabor from "./SetupDismantleLabor";
import LeadSupervisor from "../lookups/LeadSupervisor";
import SetupDismantleTable from "./SetupDismantleTable";
import ContractorContact from "../lookups/ContractorContact";
import BorderBox from "../ui/BorderBox";
import styles from "../../pages/InstallDismantle.module.scss";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {DISPATCH_TYPES, SETUP_DISMANTLE, TASK_TYPE} from "../../utils/constants";
import {setInstallDismantleInfo} from "../../redux/reducers/installDismantle/installDismantleSlice";
import ItineraryModal from "./ItineraryModal";

const Dismantle = () => {
    const dispatch = useDispatch();
    const [state, handleState, refetch] = useInstallDismantle();

    const [dismantleContractorDetails, setDismantleContractorDetails] = useState(null);
    const [dismantleSecondContractorDetails, setDismantleSecondContractorDetails] = useState(null);
    const [isSecondContractorOpen, setIsSecondContractorOpen] = useState(false);

    const [isSecondSupervisorOpen, setIsSecondSupervisorOpen] = useState(false);

    const [dismantleLaborData, setDismantleLaborData] = useState(null);
    const [dismantleItineraryData, setDismantleItineraryData] = useState(null);
    const [showItineraryModal, setShowItineraryModal] = useState(false);
    const [showSecondItineraryModal, setShowSecondItineraryModal] = useState(false);

    const [setupDismantles, setSetupDismantles] = useState([]);

    const dispatchCallback = (type, value) => {
        switch (type) {
            case DISPATCH_TYPES.contractor:
                dispatch(setInstallDismantleInfo({dismantleContractorId: value}));
                break;
            case DISPATCH_TYPES.contact:
                dispatch(setInstallDismantleInfo({dismantleContractorContactId: value}));
                break;
            case DISPATCH_TYPES.leadSupervisor:
                dispatch(setInstallDismantleInfo({dismantleSupervisorId: value?.id}));
                handleState({
                    dismantleSupervisorId: value?.id,
                    dismantleSupervisor: value
                });
                break;
            default:
                break;
        }
    }

    const dispatchSecondCallback = (type, value) => {
        switch (type) {
            case DISPATCH_TYPES.contractor:
                dispatch(setInstallDismantleInfo({secondDismantleContractorId: value}));
                break;
            case DISPATCH_TYPES.contact:
                dispatch(setInstallDismantleInfo({secondDismantleContractorContactId: value}));
                break;
            case DISPATCH_TYPES.leadSupervisor:
                dispatch(setInstallDismantleInfo({secondDismantleSupervisorId: value?.id}));
                handleState({
                    secondDismantleSupervisorId: value?.id,
                    secondDismantleSupervisor: value
                });
                break;
            default:
                break;
        }
    }

    const filterTasksByType = (type) => {
        return state?.tasks.find(task => task.type === type) ?? null
    }

    const filterSetupDismantles = (type) => {
        return state?.installDismantleSetupDismantles.filter(item => item.type === type)
    }

    useEffect(() => {
        if(state?.tasks?.length) {
            const dismantleLaborTasks = filterTasksByType(TASK_TYPE.DismantleContractor);
            setDismantleLaborData(dismantleLaborTasks);

            const dismantleItineraryTasks = filterTasksByType(TASK_TYPE.DismantleItineraryReservations);
            setDismantleItineraryData(dismantleItineraryTasks);
        }
        // eslint-disable-next-line
    }, [state?.tasks]);

    useEffect(() => {
        if(state?.secondDismantleContractor && state?.secondDismantleContractorId){
            setIsSecondContractorOpen(true);
        }
        if(state?.secondDismantleSupervisor && state?.secondDismantleSupervisorId){
            setIsSecondSupervisorOpen(true);
        }
    }, [state?.secondDismantleContractor, state?.secondDismantleContractorId, state?.secondDismantleSupervisor, state?.secondDismantleSupervisorId]);

    useEffect(() => {
        if(state?.installDismantleSetupDismantles?.length){
            const setupDismantleData = filterSetupDismantles(SETUP_DISMANTLE.dismantle);
            setSetupDismantles(setupDismantleData);
        }
        // eslint-disable-next-line
    }, [state?.installDismantleSetupDismantles]);

    return (
        <BorderBox title="Dismantle" boxStyle="mb-3 mb-md-4">
            <h4 className={styles.subTitle}>Dismantle Contractor</h4>
            <ContractorContact
                id={state?.dismantleContractorContactId}
                contractorName={state?.dismantleContractor?.name}
                contractor={dismantleContractorDetails}
                setContractor={setDismantleContractorDetails}
                onUpdate={refetch}
                dispatchCallback={dispatchCallback}
            />
            <div>
                <Collapse isOpen={isSecondContractorOpen}>
                    <h4 className="sub-title mt-4">Second Dismantle Contractor</h4>
                    <ContractorContact
                        id={state?.secondDismantleContractorContactId}
                        contractorName={state?.secondDismantleContractor?.name}
                        contractor={dismantleSecondContractorDetails}
                        setContractor={setDismantleSecondContractorDetails}
                        onUpdate={refetch}
                        dispatchCallback={dispatchSecondCallback}
                    />
                </Collapse>
                <Button
                    color="link"
                    className={`${styles.textButton} mt-2`}
                    onClick={() => setIsSecondContractorOpen(true)}
                    disabled={isSecondContractorOpen}
                >+ Add Dismantle Contractor</Button>
            </div>           

            <Row className="mb-2 mt-3 mt-md-4 align-items-center">
                <Col>
                    <LeadSupervisor
                        name={state?.dismantleSupervisor?.fullName}
                        onUpdate={refetch}
                        dispatchCallback={dispatchCallback}
                        placeholder="Dismantle Supervisor"
                    />
                </Col>
                <Col>
                    <Button color="secondary" size="sm"
                            disabled={!state?.dismantleSupervisor}
                            onClick={() => setShowItineraryModal(true)}
                    >
                        Itinerary
                    </Button>
                    {
                        showItineraryModal &&
                        <ItineraryModal
                            type="Dismantle"
                            userId={state?.dismantleSupervisorId}
                            userName={state?.dismantleSupervisor?.fullName}
                            isOpen={showItineraryModal}
                            onCancel={() => setShowItineraryModal(false)}
                        />
                    }
                </Col>
            </Row>
            <div>
                <Collapse isOpen={isSecondSupervisorOpen}>
                    <Row className="mb-2 mt-3 mt-md-4 align-items-center">
                        <Col>
                            <LeadSupervisor
                                name={state?.secondDismantleSupervisor?.fullName}
                                onUpdate={refetch}
                                dispatchCallback={dispatchSecondCallback}
                                placeholder="Dismantle Supervisor"
                            />
                        </Col>
                        <Col>
                            <Button color="secondary" size="sm"
                                    disabled={!state?.secondDismantleSupervisor}
                                    onClick={() => setShowSecondItineraryModal(true)}
                            >
                                Itinerary
                            </Button>
                            {
                                showSecondItineraryModal &&
                                <ItineraryModal
                                    type="Dismantle"
                                    userId={state?.secondDismantleSupervisorId}
                                    userName={state?.secondDismantleSupervisor?.fullName}
                                    isOpen={showSecondItineraryModal}
                                    onCancel={() => setShowSecondItineraryModal(false)}
                                />
                            }
                        </Col>
                    </Row>
                </Collapse>
                <Button
                    color="link"
                    className={`${styles.textButton} mt-2`}
                    onClick={() => setIsSecondSupervisorOpen(true)}
                    disabled={isSecondSupervisorOpen}
                >+ Add Dismantle Supervisor</Button>
            </div>

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Dismantle Itinerary Reservation</h4>
            <SetupDismantleLabor
                type={TASK_TYPE.DismantleItineraryReservations}
                data={dismantleItineraryData}
            />

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Dismantle Labor</h4>
            <SetupDismantleLabor
                type={TASK_TYPE.DismantleContractor}
                data={dismantleLaborData}
            />

            <h4 className={`${styles.subTitle} mt-3 mt-md-4`}>Dismantle</h4>
            <SetupDismantleTable
                type={SETUP_DISMANTLE.dismantle}
                data={setupDismantles}
            />
        </BorderBox>
    )
}

export default Dismantle;
