import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material/styles";
import DialogContentText from "@mui/material/DialogContentText";

export const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "normal",
  fontFamily: "Helvetica",
  height: "25px",
  padding: "5px 6px",
  borderRadius: "1px"
});
export const CustomDialogTitle = styled(DialogTitle)({
  backgroundColor: '#635D63',
  color: 'white',
  textAlign: 'center',
  padding: '5px',
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "normal",
  fontFamily: "Helvetica"
});
export const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    letterSpacing: '0px',
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif',
    textAlign: 'center',
    padding: '0px 0px'
  },
  '& .MuiInputBase-input': {
    letterSpacing: '0px',
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    height: '45px'
  },
  // '& .MuiInputLabel-root': {
  //     textAlign: 'center',
  //     padding: '0px 0px'
  // },
});
export const CustomDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialog-paper': {
    maxWidth: '500px'
  }
}));
export const CustomDialogContentText = styled(DialogContentText)({
  fontStyle: "normal",
  fontSize: "13px",
  lineHeight: "15px",
  fontWeight: "normal",
  fontFamily: "Helvetica"
});

export const CustomInput = styled("input")`
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
    text-align: center;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
`;

export const CustomError = styled(Error)({
  fontStyle: "normal",
  fontSize: "13px",
  lineHeight: "15px",
  fontWeight: "normal",
  fontFamily: "Helvetica"
});
