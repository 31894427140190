import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetItemsInShipmentQuery, useUpdateShipmentItemMutation, useCreateShipmentItemMutation } from '../../services/authService';
import { enqueueSnackbar } from 'notistack';
import messages from '../../utils/messages';
import { setShipmentItems, setShipmentItemsTotal } from '../../redux/reducers/shipmentSlice';

function EditShipmentItem(props) {
    const { item, refetchItemsToShip, setAvailableItems,edit} = props;
    const dispatch = useDispatch();
    const shipment = useSelector((state) => state.shipmentData.shipment);
    const [updateShipmentItem, { isLoading, isError, isSuccess, error }] = useUpdateShipmentItemMutation();
    const [createShipmentItem, { isCreateLoading, isCreateSuccess, isCreateError }] = useCreateShipmentItemMutation();
  
    const { data: itemsInShipment, isFetching: isFetchingItemsInShipment, refetch: refetchItemsInShipment } = useGetItemsInShipmentQuery(shipment && shipment?.shipment?.id);

    const handleUpdate = async () => {

        let data = {};

        if (edit === false) {  //manual shipment item create
            data = {
                shipmentId: item?.shipmentId,
                itemId: item?.id,
                length: item?.length,
                width: item?.width,
                height: item?.height,
                weight: item?.weight,
                content: item?.content,
                description: item?.item?.description,
                quantity: item?.item?.quantityForShipmentPrint
            }
            const response = await createShipmentItem(data);
            if (response.data) {
                const responseItemsInShipment = await refetchItemsInShipment();
                dispatch(setShipmentItems(responseItemsInShipment.data.shipmentItems));
                dispatch(setShipmentItemsTotal(responseItemsInShipment.data.shipmentItemsTotal));

                const responseItemsToShip = await refetchItemsToShip();
                setAvailableItems(responseItemsToShip.data.shipmentItems);
                enqueueSnackbar(`${messages.success.createShipmentItem}`, { variant: 'success' });
            } else {
                const errorMessages = response.error.data.map(error => error.errorMessage);
                enqueueSnackbar(`${messages.error.prefix} ${errorMessages ? errorMessages : messages.error.generic}`, { variant: 'error' });
            }
        }else {
            data = {
                id: item?.id,
                length: item?.length,
                width: item?.width,
                height: item?.height,
                weight: item?.weight,
                content: item?.content,
                isManualShipmentItem: item?.isManualShipmentItem
            }
            if (item?.isManualShipmentItem) { // manual shipment item edit
                Object.assign(data,
                    { quantity: item?.item?.quantityForShipmentPrint, description: item?.item?.description }
                );
            }
            updateShipmentItem(data);
        }
    
      /*window.console.log("====data====", data);
      console.log('==== update shipment item====', item);*/
   
  }

  const handleMessages = async () => {
    if (!isFetchingItemsInShipment) {
      const refetchResult = await refetchItemsInShipment();
      const refetchedItems = refetchResult.data.shipmentItems;
      dispatch(setShipmentItemsTotal(refetchResult.data.shipmentItemsTotal));
      dispatch(setShipmentItems(refetchedItems));
    }
      const refetchResponse = await refetchItemsToShip();
    setAvailableItems(refetchResponse.data.shipmentItems);

      if (isSuccess) {
          enqueueSnackbar(`${messages.success.updateShipmentItem}`, { variant: 'success' }  );
       }
      if (isError) {
        enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
       }
  }

    useEffect(() => {        
      if (isSuccess || isError){
          handleMessages();
      }
  }, [isSuccess, isError]);

  return (
    <>
      <Tooltip title="Update Item">
        <AddCircleOutlineIcon
          fontSize="small"
          style={{
            color: "grey",
            cursor: "pointer"
          }}
          onClick={handleUpdate}
        />
      </Tooltip>
    </>
  );
}

export default EditShipmentItem;
