import {useEffect, useState} from "react";
import {Paper, TextField} from "@mui/material";
import AddFlightCarrier from "./flightCarrier/AddFlightCarrier";
import EditFlightCarrier from "./flightCarrier/EditFlightCarrier";
import {useGetCarriersQuery} from "../../services/authService";
import {DISPATCH_TYPES} from "../../utils/constants";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import DeleteFlightCarrier from "./flightCarrier/DeleteFlightCarrier";

const FlightCarrier = ({name, onUpdate, dispatchCallback, size, label = 'Flight Carrier', containerStyle}) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const {data, isFetching } = useGetCarriersQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        console.log('handleChange', value);
        if(value){
            dispatchCallback(DISPATCH_TYPES.flightCarrier, value);
            setSelectedOption(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.flightCarrier, null);
            setOpen(true);
        }
    };

    const handleInputChange = (event, value) => {
        console.log('handleInputChange', value);
        if(value === '') {
            setSelectedOption(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            setOptions(data?.carriers);
            let selOption = data?.carriers?.find(option => option.name === filter);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.flightCarrier, selOption);
        }
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const AddNewCarrier = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Flight Carrier
            </div>
        </Paper>
    );

    const handleOpen = () => {
        if(!filter && !selectedOption){
            setSelectedOption(null);
            setFilter('');
        }       
        setOpen(true);
    }

    const setFlightCarrier = (name) => {
        setFilter(name);
    }


    return (
        <div className={`d-flex align-items-center ${containerStyle}`}>
            <CustomAutocomplete
                fullWidth
                size={size ?? "medium"}
                className="flex-grow-1"
                open={open}
                onOpen={() => handleOpen()}
                onClose={() => setOpen(false)}
                sx={sxAutocomplete}
                loading={isFetching}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options || []}
                value={selectedOption}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.name || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.name || '' : '')}
                noOptionsText="Flight Carrier not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">{label}</label>
                       }
                       variant="outlined"
                    />
                )}
                PaperComponent={AddNewCarrier}
            />

            <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                <EditFlightCarrier
                    flightCarrier={selectedOption}
                    setFlightCarrier={setFlightCarrier}
                    onUpdate={onUpdate}
                />

                <DeleteFlightCarrier
                    flightCarrier={selectedOption}
                    setFlightCarrier={setFlightCarrier}
                    onDelete={onUpdate}
                />
            </div>

            <AddFlightCarrier
                isOpen={addDialogOpen}
                onAdd={onUpdate}
                onCancel={closeAddDialog}
                setFlightCarrier={setFlightCarrier}
            />

        </div>
    )
}

export default FlightCarrier;
