import {useEffect, useState} from "react";
import {Navbar, NavbarToggler, NavbarBrand, Collapse} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/reducers/authSlice";
import TabNav from "./TabNav";
import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as Logo } from "../../assets/image/logo.svg";
import styles from './NavMenu.module.scss';
import IconButton from "@mui/material/IconButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Inventory from '@mui/icons-material/Inventory';
import useRouteMatch from "../../hooks/useRouteMatch";
import { useNavigate } from "react-router-dom";
import { resetJob } from "../../redux/reducers/jobSlice";
import { resetShipment } from "../../redux/reducers/shipmentSlice";
import { resetCarriers } from "../../redux/reducers/carrier/carrierSlice";
import { resetExhibit } from "../../redux/reducers/exhibit/exhibitSlice";
import { resetInstallDismantle } from "../../redux/reducers/installDismantle/installDismantleSlice";
import { resetShowInfo } from "../../redux/reducers/showInfo/showInfoSlice";
import InventoryDialog from '../../components/invertoryItems/InventoryDialog';

const NavMenu = () => {
  const dispatch = useDispatch();
  const { jobData: { personalTaskId, jobCheckListId } } = useSelector((state) => state);
  const [collapsed, setCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInfoId, setShowInfoId] = useState('');
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [invDialog, setInvDialog] = useState(false);
  
  const routeMatch = useRouteMatch(['/show-info/:id', '/install-dismantle/:id', '/exhibit-giveaway/:id', '/services-request/:id', '/expenses/:id', '/job-shipment/:id',  '/tasklist', '/new-user', '/clients']);
  const { userToken } = useSelector((state) => state.loginData);
  useEffect(() => {
    checkJobIds();
    // eslint-disable-next-line
  }, [personalTaskId, jobCheckListId]);

  useEffect(() => {
    setShowInfoId(window.localStorage.getItem("showInfoId"));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("showInfoId", showInfoId ?? '');
  }, [showInfoId]);

  function logoutHanlder(){
    dispatch(logout());
    dispatch(resetJob());
    dispatch(resetShipment());
    dispatch(resetCarriers());
    dispatch(resetExhibit());
    dispatch(resetInstallDismantle());
    dispatch(resetShowInfo());
    navigate('/login');
  }
  const checkJobIds = () => {
    if(personalTaskId !== null) {
      setShowInfoId(personalTaskId);
    }
    if(jobCheckListId !== null) {
      setShowInfoId(jobCheckListId);
    }
    if(personalTaskId === null && jobCheckListId === null){
        setShowInfoId(null);
    }
  }

  const tabData = [
    {
      id: 1,
      label: "Dashboard",
      path: '/tasklist',
      hidden: false,
    },
    {
      id: 2,
      label: "Show Info",
      pattern: '/show-info/:id',
      path: `/show-info/${showInfoId}`,
      hidden: !showInfoId,
    },
    {
      id: 3,
      label: "Exhibit / Give Aways",
      pattern: '/exhibit-giveaway/:id',
      path: `/exhibit-giveaway/${showInfoId}`,
      hidden: !showInfoId,
    },
    {
      id: 4,
      label: "Install / Dismantle",
      pattern: '/install-dismantle/:id',
      path: `/install-dismantle/${showInfoId}`,
      hidden: !showInfoId,
    },
    {
      id: 5,
      label: "Services Request",
      pattern: '/services-request/:id',
      path: `/services-request/${showInfoId}`,
      hidden: !showInfoId,
      disabled: false,
    },
    {
      id: 6,
      label: "Shipments",
      pattern: '/job-shipment/:id',
      path: `/job-shipment/${showInfoId}`,
      hidden: !showInfoId,
      disabled: false,
    },
    {
      id: 8,
      label: "Expenses",
      pattern: '/expenses/:id',
      path: `/expenses/${showInfoId}`,
      hidden: !showInfoId,
      disabled: false,
    },
    {
      id: 7,
      label: "Clients",
      path: '/clients',
      hidden: false,
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
    };

    const showInventory = () => {
        setInvDialog(true)
    }

    function closeInvDialog() {
        setInvDialog(false);
    }

  const handleChange = (event, newValue) => {
    // setTabIndex(newValue);
  };

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
      <header className="flex-shrink-0">
      <Navbar
        className="navbar-toggleable-sm ng-white box-shadow flex-wrap"
        container
        light
        expand="lg"
      >
        <NavbarBrand href={ userToken ? '/tasklist': '/' } className="me-auto">
          <Logo className={styles.logo} />
        </NavbarBrand>
        <div className="d-flex gap-2">
          <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
          >
            <Users />
          </IconButton>
          <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.2))',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 6,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
            <MenuItem sx={{ minHeight: 1 }} onClick={showInventory}>
                <ListItemIcon sx={{ minWidth: '30px !important' }}>
                   <Inventory fontSize="small" />
                </ListItemIcon>
                CMC Inventory
            </MenuItem>
            <MenuItem sx={{minHeight: 1}} onClick={handleClose} disabled>
              <ListItemIcon sx={{minWidth: '30px !important'}}>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem sx={{minHeight: 1}} onClick={logoutHanlder}>
              <ListItemIcon sx={{minWidth: '30px !important'}}>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
        <NavbarToggler onClick={toggleNavbar} className={`ms-3 ${styles.toggle}`} />
        <div className="w-100">
          <Collapse isOpen={!collapsed} navbar>
            <div className={`w-100 ${styles.tabWrapper}`}>
                {
                    invDialog &&
                    <InventoryDialog
                        onCancel={closeInvDialog}
                        isOpen={invDialog}

                    />
                }
              <TabNav
                  // tabIndex={tabIndex}
                  current={routeMatch?.pattern?.path}
                  handleChange={handleChange}
                  data={tabData}
              />
            </div>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
};
export default NavMenu;