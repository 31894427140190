import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link } from 'react-router-dom';
import {theme} from "../../utils/colors";
import styles from './TabNav.module.scss';

const TabNav = (props) => {
  const {handleChange,data= [], current} = props;
  
  return (
      <Box>
        <Tabs
          value={current}
          onChange={handleChange}
          aria-label="basic tabs example"
          selected={true}
          disabled={false}
          variant="scrollable"
          scrollButtons={false}
          sx={{
            "& .MuiTabs-indicator": {
                height: 4,
            }
          }}
        >
          {data?.map((val, idx) => (
            <Tab
              className={styles.tab}
              component={Link}
              to={val.path}
              label={val.label}
              id={`simple-tab${val.id}`}
              value={val.pattern ? val.pattern : val.path}
              key={idx}
              sx={{
                color: theme.palette.text.main,
                "&:hover": {
                  color: theme.palette.text.main,
                },
              }}
              hidden={val.hidden}
              disabled={val.disabled ? val.disabled : false}
            />
          ))}
        </Tabs>
      </Box>
  );
};
export default TabNav;
