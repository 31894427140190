import { theme } from '../utils/colors';

const sxAutocomplete = {
    "& .MuiInputBase-root": {
      // height: "45px",
      paddingTop: "6px",
      paddingBottom: "6px",
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-root": {
      // backgroundColor: "#fff",
      color: theme.palette.text.main,
      fontFamily: "Arial, Helvetica, sans-serif",
      fontSize: "13px",
      fontWeight: "normal",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        },
        "&.MuiInputLabel-sizeSmall": {
            top: 0,
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -2px) scale(0.9)", //-9
                lineHeight: 1,
            },
        }
    },
    "& .MuiInputBase-input": {
        paddingTop: "6px",
        paddingBottom: "6px",
    },
    "&.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
}

export default sxAutocomplete
