import DialogTitle from "@mui/material/DialogTitle";
import styles from "./EditShowName.module.scss";
import ShowNameForm from "./ShowNameForm";
import Dialog from "@mui/material/Dialog";

const AddShowName = ({ name, isOpen, onAdd, onCancel, setNewShowName }) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="xs">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add show name
                </DialogTitle>
                <ShowNameForm
                    onCancel={onCancel}
                    onAdd={onAdd}
                    setNewShowName={setNewShowName}
                    name={name}
                />
            </Dialog>
        </div>
    )
}

export default AddShowName;
