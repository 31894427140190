import {TableRow, TableCell, Checkbox} from "@mui/material";
import DeleteClientConsumableItem from "./DeleteClientConsumableItem";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styles from "../lookups/showName/DeleteShowName.module.scss";

function ClientConsumableItem(props) {
    const { item, showAction, setConsumable, onEditOpen, handlePrintConsumables, onConsumableUpdated } = props;

  const handleEditClientConsumable = (item) => {
    setConsumable(item);
    onEditOpen(true);
  };

  return (
    <TableRow>{
      item ? (
        <>
          <TableCell>
                      <Checkbox value={item.id} name="printConsumableItem" checked={item?.checked ?? false} onChange={handlePrintConsumables}/>
            {
              item.description
            }
          </TableCell>
          <TableCell>
            {item.totalQuantity}
          </TableCell>
          <TableCell>
            {item.weight}
          </TableCell>
          
          {showAction && 
            <TableCell style={{ width: "75px" }}>
              <EditOutlinedIcon
                  fontSize="small"
                  onClick={() => handleEditClientConsumable(item)}
                  className={`${styles.icon} m-1`}
              />
                          <DeleteClientConsumableItem setConsumable={setConsumable} consumable={item} onConsumableUpdated={onConsumableUpdated} />
            </TableCell>
          }
        </>
      ) : <TableCell align="center" colSpan={ showAction ? 4 : 3 } className="py-1">
            No Consumables found
          </TableCell>
    }</TableRow>
  )
}

export default ClientConsumableItem;
