import {useEffect, useState} from "react";
import {useGetContractorsQuery} from "../../services/authService";
import {Paper, TextField} from "@mui/material";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import AddContractor from "./contractor/AddContractor";
import EditContractor from "./contractor/EditContractor";
import DeleteContractor from "./contractor/DeleteContractor";
import {DISPATCH_TYPES} from "../../utils/constants";

const Contractor = ({ name, setContractor, onUpdate, dispatchCallback }) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const {data, isFetching } = useGetContractorsQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        if(value){
            dispatchCallback(DISPATCH_TYPES.contractor, value.id);
            setSelectedOption(value);
            setContractor(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.contractor, null);
            setContractor(null);
            setOpen(true);
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            setContractor(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            setOptions(data?.contractors);
            let selOption = data?.contractors?.find(option => option.name === filter);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.contractor, selOption?.id);
            setContractor(selOption);
        }
        // eslint-disable-next-line
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const AddNewContractorRow = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Contractor
            </div>
        </Paper>
    );
    const handleOpen = () => {
        if(!filter && !selectedOption){
            setSelectedOption(null);
            setFilter('');
        }       
        setOpen(true);
    }
    
    const setNewContractor = (name) => {
        setFilter(name);
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <CustomAutocomplete
                    // id="contractor-list"
                    className="flex-grow-1"
                    sx={sxAutocomplete}
                    open={open}
                    onOpen={() => handleOpen()}
                    onClose={() => setOpen(false)}
                    loading={isFetching}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    options={options || []}
                    value={selectedOption}
                    filterOptions={(x) => x}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <span className="autocomplete-option-label">{option.name || ''}</span>
                        </li>
                    )}
                    getOptionLabel={(option) => (option ? option.name || '' : '')}
                    // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                    noOptionsText="Contractor not found"
                    renderInput={(params) => (
                        <TextField {...params}
                           label={
                               <label className="autocomplete-input-label">Contractors</label>
                           }
                           variant="outlined"
                        />
                    )}
                    PaperComponent={AddNewContractorRow}
                />

                <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                    <EditContractor
                        contractor={selectedOption}
                        setContractor={setSelectedOption}
                        onUpdate={onUpdate}
                        setNewContractor={setNewContractor}
                        selectedOption={selectedOption}
                    />

                    <DeleteContractor
                        contractor={selectedOption}
                        setContractor={setSelectedOption}
                        onDelete={onUpdate}
                    />
                </div>

                <AddContractor
                    isOpen={addDialogOpen}
                    onAdd={onUpdate}
                    onCancel={closeAddDialog}
                    setNewContractor={setNewContractor}
                    selectedOption={selectedOption}
                />

            </div>

            { selectedOption?.address ? (
                <div className="bg-white p-2 mt-2 common-text">
                    <div>{selectedOption.address.address1}</div>
                    <div>{selectedOption.address.address2}</div>
                    <div>{selectedOption.address.city ? `${selectedOption.address.city}, ` : ''}{selectedOption.address.countryCode === "USA" ? selectedOption.address.stateCode : selectedOption.address.province} {selectedOption.address.zip} {selectedOption.address.countryCode}</div>
                    <div style={{marginTop:"5px"}}>Phone: {selectedOption.address.phone}</div>
                    <div>Fax: {selectedOption.address.fax}</div>
                </div>
            ): (
                <div className="bg-white p-3 mt-2 common-text">
                    <div>{ selectedOption ? 'No contractor address found' : 'No contractor selected'}</div>
                </div>
            )
            }
        </>
    )
}

export default Contractor;