import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/reducers/authSlice';
import { enqueueSnackbar } from 'notistack';
import messages from '../utils/messages';

const useAuthentication = (errors) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const unauthorizedError = errors.find((error) => error && error.originalStatus === 401);
    if (unauthorizedError) {
      dispatch(logout());
      enqueueSnackbar(`${messages.error.prefix} ${messages.error.unauthorizedAccess}`, { variant: 'error' });
      navigate('/login');
    }
  }, [dispatch, errors, navigate]);
};

export default useAuthentication;
