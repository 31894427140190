import {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Col, Row} from "reactstrap";
import {useSnackbar} from "notistack";
import {useFormik} from "formik";
import * as Yup from "yup";
import BorderBox from "../../ui/BorderBox";
import {useCreateLeadSupervisorMutation, useUpdateLeadSupervisorMutation} from "../../../services/authService";
import messages from "../../../utils/messages";
import {CustomButton, CustomTextField} from "../../../style/mui/customStyle";
import {USER_TYPES} from "../../../utils/constants";

const leadSupervisorSchema = Yup.object({
    firstName: Yup.string().required('Supervisor First Name is required'),
    lastName: Yup.string(),
    username: Yup.string(),
    email: Yup.string().email('Email should be a valid email address')
});

const cleanData = (obj) => {
    const cleaned = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== null) {
        cleaned[key] = obj[key];
      }
    });
    return cleaned;
};

const LeadSupervisorForm = ({ supervisor , setSupervisor, onCancel, onUpdate, onAdd,setFilter}) => {
    const [createLeadSupervisor, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateLeadSupervisorMutation();
    const [updateLeadSupervisor, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateLeadSupervisorMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [previousName, setPreviousName] = useState(null);
    const formik = useFormik({
        initialValues: {
            firstName: supervisor?.firstName ?? '',
            lastName: supervisor?.lastName ?? '',
            username: supervisor?.username ?? '',
            email: supervisor?.normalizedEmailAddress ?? '',
        },
        validationSchema: leadSupervisorSchema,
        onSubmit: async (values) => {
            const requestObject = cleanData(values);
            const fullName = (formik?.values?.firstName && formik?.values?.lastName) ? formik?.values?.firstName + ' ' + formik?.values?.lastName : formik?.values?.firstName;
            setPreviousName(fullName);
            setSupervisor('');
            if(supervisor){ // edit
                await updateLeadSupervisor({
                    id: supervisor?.id,
                    userType: USER_TYPES.leadSupervisor,
                    ...requestObject
                });
            } else { // add
                await createLeadSupervisor({
                    id: 0,
                    userType: USER_TYPES.leadSupervisor,
                    ...requestObject
                });
                // if (response.data){
                //     const id = response.data;
                //     setSupervisor({
                //         id: id,
                //         ...requestObject
                //     });
                // }
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createLeadSupervisor}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onAdd();
            onCancel();
            setSupervisor(previousName);

        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateLeadSupervisor}`, { variant: 'success' })
            updateReset();
            onUpdate();
            onCancel();
            setSupervisor(previousName);
            // setSupervisor({
            //     id: supervisor.id,
            //     ...formik.values
            // });
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    }

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <Row className="gx-2 mb-2">
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="First name"
                                name="firstName"
                                variant="outlined"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Col>
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Last name"
                                name="lastName"
                                variant="outlined"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Col>
                    </Row>
                    <CustomTextField
                        fullWidth
                        label="Username"
                        name="username"
                        variant="outlined"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        className="mt-2"
                    />
                    <CustomTextField
                        fullWidth
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        className="mt-2"
                    />
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default LeadSupervisorForm;
