import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {CustomDatePicker} from '../style/mui/customStyle';

export default function BasicDatePicker(props) {
  const {onDateChange, value} =props
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <CustomDatePicker
          value={value}
          onChange={onDateChange}
          disableRipple
          size={"small"}
          disableElevation={true}
        // disableOpenPicker={true}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
