import DialogTitle from "@mui/material/DialogTitle";
import styles from "./EditClientName.module.scss";
import ClientNameForm from "./ClientNameForm";
import Dialog from "@mui/material/Dialog";

const AddClientName = ({ isOpen, setClientName, onCancel, storedClientId, setCreatedClientId, setFilter }) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="xs">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add client name
                </DialogTitle>
                <ClientNameForm
                    onCancel={onCancel}
                    setClientName={setClientName}
                    storedClientId={storedClientId}
                    setCreatedClientId={setCreatedClientId}
                    setFilter={setFilter}
                />
            </Dialog>
        </div>
    )
}

export default AddClientName;
