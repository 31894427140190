export const FORMATS = {
    dateTime: 'YYYY-MM-DDT00:00:00',
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
    local: 'YYYY-MM-DDTHH:mm:ss',
    tableDate: 'MM/DD/YYYY h:mm A',
    customDateTime: 'MM/DD/YYYY h:mm A',
    displayDate: 'MMM D, YYYY'
}

export const JOB_STATUS = {
    open: 'Open',
    complete: 'Complete',
    cancelled: 'Cancelled'
}

export const SETUP_DISMANTLE = {
    setup: 'Setup',
    dismantle: 'Dismantle'
}

export const USER_TYPES = {
    employee: 'Employee',
    leadSupervisor: 'LeadSupervisor'
}

export const DISPATCH_TYPES = {
    showName: 'showName',
    clientName: 'clientName',
    facility: 'facility',
    contractor: 'contractor',
    contact: 'contact',
    carRental: 'carRental',
    flightCarrier: 'flightCarrier',
    hotel: 'hotel',
    leadSupervisor: 'leadSupervisor',
    branch: 'branch',
}

export const TASK_TYPE = {
    InstallItineraryReservations: 'InstallItineraryReservations',
    DismantleItineraryReservations: 'DismantleItineraryReservations',
    InstallationContractor: 'InstallationContractor',
    DismantleContractor: 'DismantleContractor',
    ShipExhibitToShow: 'ShipExhibitToShow',
    VerifyExhibitAtShow: 'VerifyExhibitAtShow',
    Chairs: 'Chairs',
    Tables: 'Tables',
    Wastebaskets: 'Wastebaskets',
    Carpet: 'Carpet',
    Padding: 'Padding',
    FurnishingsOther1: 'FurnishingsOther1',
    FurnishingsOther2: 'FurnishingsOther2',
    FurnishingsOther3: 'FurnishingsOther3',
    FurnishingsOther4: 'FurnishingsOther4',
    MaterialHandling: 'MaterialHandling',
    Cleaning: 'Cleaning',
    Computer: 'Computer',
    AudioVideo: 'AudioVideo',
    Floral: 'Floral',
    LeadRetrieval: 'LeadRetrieval',
    ServicesOther: 'ServicesOther',
    Electrical: 'Electrical',
    Telephone: 'Telephone',
    FacilityOther: 'FacilityOther'
}

export const TASK_TYPE_SAVE = {
    installItineraryReservations: 'installItineraryReservations',
    dismantleItineraryReservations: 'dismantleItineraryReservations',
    installationContractor: 'installationContractor',
    dismantleContractor: 'dismantleContractor',
}

export const TASK_STATUS = {
    None: 'None',
    Assigned: 'Assigned',
    Complete: 'Complete',
    Cancelled: 'Cancelled',
    ReadyToShip: 'ReadyToShip',
    Shipped: 'Shipped',
    Delivered: 'Delivered',
    Ordered: 'Ordered',
    Verified: 'Verified'
}

export const TRAVEL_EXPENSE = {
    Airfare: 'Airfare',
    CarRental: 'CarRental',
    Hotel: 'Hotel',
    MiscTravel: 'MiscTravel',
}

export const FLIGHT_TYPE = {
    Departure: 'Departure',
    Return: 'Return'
}

export const EXPENSE_TYPE = {
    Estimate: 'estimate',
    Actual: 'actual'
}

export const ADDRESSES = {
    cmcAddress: 'CMC Exhibit Managers \n1940 S. Carboy \nMt. Prospect, IL 60056'
}
