import {useEffect, useState} from "react";
import {useGetFacilitiesQuery} from "../../services/authService";
import sxAutocomplete from "../../style/sxAutocompleteStyles";
import {Paper, TextField} from "@mui/material";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import AddFacility from "./facility/AddFacility";
import EditFacility from "./facility/EditFacility";
import DeleteFacility from "./facility/DeleteFacility";
import {DISPATCH_TYPES} from "../../utils/constants";

const Facility = ({name, onUpdate, dispatchCallback}) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const {data, isFetching } = useGetFacilitiesQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        if(value){
            dispatchCallback(DISPATCH_TYPES.facility, value.id);
            setSelectedOption(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.facility, null);
            setOpen(true);
        }
    };

    const handleInputChange = (event, value) => {
        if(value === '') {
            setSelectedOption(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            setOptions(data?.facilities);
            let selOption = data?.facilities?.find(option => option.name === filter);
            console.log("selOption--", selOption);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.facility, selOption?.id);
        }
    }, [data, filter]);
    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }

    const setNewFacility = (name) => {
        setFilter(name);
    }

    const AddNewFacilityRow = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Facility
            </div>
        </Paper>
    );
    const handleOpen = () => {
        if(!filter && !selectedOption){
            setSelectedOption(null);
            setFilter('');
        }       
        setOpen(true);
    }

    return (
        <>
        <div className="d-flex align-items-center">
            <CustomAutocomplete
                id="facility-list"
                className="flex-grow-1"
                sx={sxAutocomplete}
                open={open}
                onOpen={() => handleOpen()}
                onClose={() => setOpen(false)}
                loading={isFetching}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options || []}
                value={selectedOption}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.name || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.name || '' : '')}
                // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                noOptionsText="Facility not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">Facilities</label>
                       }
                       variant="outlined"
                    />
                )}
                PaperComponent={AddNewFacilityRow}
            />

            <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                <EditFacility
                    facility={selectedOption}
                    setFacility={setSelectedOption}
                    onUpdate={onUpdate}
                    setNewFacility={setNewFacility}
                />

                <DeleteFacility
                    facility={selectedOption}
                    setFacility={setSelectedOption}
                    onDelete={onUpdate}
                />
            </div>

            <AddFacility
                isOpen={addDialogOpen}
                onAdd={onUpdate}
                onCancel={closeAddDialog}
                setNewFacility={setNewFacility}
                name={name}
            />
        </div>

        {selectedOption?.address ? (
            <div className="bg-white p-2 mt-1 common-text">
                <div>{selectedOption.address.address1}</div>
                <div>{selectedOption.address.address2}</div>
                <div>
                    {selectedOption.address.city ? `${selectedOption.address.city}, ` : ''}{selectedOption.address.countryCode === "USA" ? selectedOption.address.stateCode : selectedOption.address.province}{" "}
                    {selectedOption.address.zip} {selectedOption.address.countryCode}
                </div>
                <div style={{marginTop:"5px"}}>Phone: {selectedOption.address.phone}</div>
                <div>Fax: {selectedOption.address.fax}</div>
            </div>
        ) : (
            <div className="bg-white p-3 mt-2 common-text">
                <div>{selectedOption ? 'No facility address found' : 'No facility selected'}</div>
            </div>
        )}
        </>
    )
}

export default Facility;
