import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  shipment: null
}

const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setShipment: (state, action) => {
      state.shipment = action.payload;
    },
    setShipmentItems: (state, action) => {
      state.shipment = { 
        ...state.shipment,
        shipmentItemMapping: action.payload
        };
      state.shipment.ItemsChanged = true; // this object is added to stop refreshing shipment details other than items in ShipmentDetails.js
       
      return state;
    },
    setShipmentItemsTotal: (state, action) => {
      state.shipment = { 
        ...state.shipment,
        shipmentItemsTotal: action.payload
        };
        state.shipment.ItemsChanged = true; // this object is added to stop refreshing shipment details other than items in ShipmentDetails.js
      return state;
    },
    setShipmentExpenses: (state, action) => {
      state.shipment = { 
        ...state.shipment,
        shipmentExpensesTotal: action.payload
        };
      return state;
    },
    resetShipment: () => initialState
  }
});

export const {setShipment, setShipmentItems, setShipmentItemsTotal, setShipmentExpenses, resetShipment} = shipmentSlice.actions;

export default shipmentSlice.reducer;
