import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import AddRowButton from "../ui/AddRowButton";
import EditSetupDismantleRow from "./setupDismantleTable/EditSetupDismantleRow";
import DeleteSetupDismantleRow from "./setupDismantleTable/DeleteSetupDismantleRow";
import {formatDateTimeDayjs, numFloat} from "../../utils/common";
import {FORMATS} from "../../utils/constants";
import {useInstallDismantle} from "../../context/InstallDismantleContext";
import {CustomTextField} from "../../style/mui/customStyle";
import sxTableTextFieldStyle from "../../style/sxTableTextFieldStyles";
import sxTableStyles from "../../style/sxTableStyles";

const sxCellStyle = {
    "& .MuiStack-root": {
        paddingTop: 0,

        "& .MuiTextField-root": {
            backgroundColor: 'transparent',
            width: '100%',
            minWidth: 100,
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 0,
        }
    }
}

const SetupDismantleTable = ({type, data, containerStyle}) => {
    const [state] = useInstallDismantle();
    const [rows, setRows] = useState([]);
    const [addDisabled, setAddDisabled] = useState(false);

    const newRow = {
        id: 0,
        installDismantleId: state?.id,
        eventDateOnly: '',
        startTime: '00:00:01',
        numberOfMen: 0,
        numberOfHours: 0.0,
        rate: 0.00,
        estimate: 0.00,
        actual: 0.00,
        isUpdated: true,
        isNew: true,
    }

    const handleRowUpdate = (id, update) => {
        setRows((rows) => {
            return rows.map(row => ((row.id === id) ?
                {
                    ...row,
                    ...update,
                    isUpdated: true,
                }
                : row))
        })
    }

    const handleDate = (id, value) => {
        console.log(formatDateTimeDayjs(value, FORMATS.date));
        handleRowUpdate(id,{
            eventDate: formatDateTimeDayjs(value, FORMATS.dateTime),
            eventDateOnly: formatDateTimeDayjs(value, FORMATS.date),
        });
    }

    const handleStartTime = (id, value) => {
        console.log(formatDateTimeDayjs(value, FORMATS.time));
        handleRowUpdate(id,{
            startTime: formatDateTimeDayjs(value, FORMATS.time),
        });
    }

    const handleAddRow = () => {
        setRows((rows) => [...rows, newRow]);
    }

    useEffect(() => {
        data && setRows(data.filter(item => !item.isDeleted));
    }, [data]);

    useEffect(() => {
        if(rows){
            const disabledFlag = rows?.some((row) => row.isNew)
            setAddDisabled(disabledFlag)
        }
    }, [rows]);

    return (
        <div className={`${containerStyle}`}>
            <TableContainer component={Paper} className={`rounded-0 shadow-none overflow-hidden`}>
                <Table sx={sxTableStyles}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>Men</TableCell>
                            <TableCell>Hours</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Estimate</TableCell>
                            <TableCell>Actual</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows?.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell className="p-0" sx={sxCellStyle} style={{ width: 150 }}>
                                            <DatePicker
                                                value={dayjs(row.eventDateOnly) ?? ""}
                                                onDateChange={(newValue) => handleDate(row.id, newValue)}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <TimePicker
                                                value={dayjs(`${row.eventDateOnly}T${row.startTime}`) ?? ""}
                                                onTimeChange={(newValue) => handleStartTime(row.id, newValue)}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <CustomTextField
                                                fullWidth
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                sx={sxTableTextFieldStyle}
                                                name="numberOfMen"
                                                value={row.numberOfMen ? parseInt(row.numberOfMen) : 0}
                                                onChange={(event) => {
                                                    handleRowUpdate(row.id, { numberOfMen: parseInt(event.target.value) });
                                                }}
                                                onBlur={(event) => {
                                                    handleRowUpdate(row.id, { numberOfMen: parseInt(Number(event.target.value)) });
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <CustomTextField
                                                fullWidth
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                sx={sxTableTextFieldStyle}
                                                name="numberOfHours"
                                                value={row.numberOfHours ? row.numberOfHours : 0 }
                                                onChange={(event) => {
                                                    handleRowUpdate(row.id, { numberOfHours: event.target.value });
                                                }}
                                                onBlur={(event) => {
                                                    handleRowUpdate(row.id, { numberOfHours: event.target.value != 0 ? numFloat(event.target.value,1) : event.target.value });
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <CustomTextField
                                                fullWidth
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                sx={sxTableTextFieldStyle}
                                                name="rate"
                                                value={row.rate ?? 0}
                                                onChange={(event) => {
                                                    handleRowUpdate(row.id, { rate: event.target.value });
                                                }}
                                                onBlur={(event) => {
                                                    handleRowUpdate(row.id, { rate: event.target.value != 0 ? numFloat(event.target.value) : event.target.value });                                                
                                                }}
                                                
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <CustomTextField
                                                fullWidth
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                sx={sxTableTextFieldStyle}
                                                name="estimate"
                                                value={row.estimate ?? 0}
                                                onChange={(event) => {
                                                    handleRowUpdate(row.id, { estimate: event.target.value });
                                                }}
                                                onBlur={(event) => {
                                                    handleRowUpdate(row.id, { estimate: event.target.value != 0 ? numFloat(event.target.value) : event.target.value });
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="p-0" sx={sxCellStyle}>
                                            <CustomTextField
                                                fullWidth
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                sx={sxTableTextFieldStyle}
                                                name="actual"
                                                value={row.actual ?? 0}
                                                onChange={(event) => {
                                                    handleRowUpdate(row.id, { actual: event.target.value });
                                                }}
                                                onBlur={(event) => {
                                                    handleRowUpdate(row.id, { actual: event.target.value != 0 ? numFloat(event.target.value) : event.target.value });
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="px-1" style={{ width: 36 }}>
                                            {
                                                row.isUpdated ?
                                                    <EditSetupDismantleRow
                                                        type={type}
                                                        row={row}
                                                    />
                                                    :
                                                    <DeleteSetupDismantleRow
                                                        type={type}
                                                        id={row.id}
                                                    />
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            !rows?.length &&
                            <TableRow>
                                <TableCell align="center" colSpan={8} className="py-1">No rows to show</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRowButton
                onAdd={handleAddRow}
                disabled={addDisabled}
            >
                + Add {type}
            </AddRowButton>
        </div>
    )
}

export default SetupDismantleTable;
