import ReturnItem from "./ReturnItem";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import classes from './ReturnItemList.module.css'
import {Button} from "reactstrap";
import { useState, useEffect } from "react";
import AddReturnItem from "./AddReturnItem";
import sxTableStyles from "../../style/sxTableStyles";
import AddRowButton from "../ui/AddRowButton";
import {useDispatch, useSelector} from "react-redux";

function ReturnItemList(props) {
  const {items, showAddItem} = props;
  const [isExhibitItemOpen, setExhibitItemOpen] = useState(false);
  const [updatedItems, setUpdatedItems] = useState(items);
  const {jobExhibit, loading, error} = useSelector((state) => {
    return state.jobExhibitData;
  });
  useEffect(() => {
    if (jobExhibit) {
      setUpdatedItems(jobExhibit?.exhibitGiveAway?.consumables);
    }
  }, [jobExhibit]);
  const handleReturnItemChange = (itemobj, numReturned) => {
    const updatedItemsCopy = [];
    updatedItems.forEach(item => {
      if (item.id == itemobj.id) {
        updatedItemsCopy.push({ ...item, numReturned: numReturned});
      } else {
        updatedItemsCopy.push(item);
      }
    });
    setUpdatedItems(updatedItemsCopy);
  };  
  function openExhibitItemHandler() {
    setExhibitItemOpen(true);
  }
  function closeExhibitItemHandler() {
    setExhibitItemOpen(false);
  }

  return (
    <>
      <AddReturnItem exhibitId={1}
        isOpen={isExhibitItemOpen}
        onClose={closeExhibitItemHandler}/> {/* <h3 className={
        `lh-sm ${
          classes.title
        }`
      }>Items</h3> */}
      <TableContainer component={Paper}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell className={
                classes.table_cell
              }>Description</TableCell>
              <TableCell className={
                classes.table_cell
              }>Quantity</TableCell>
              <TableCell className={
                classes.table_cell
              }>Returned</TableCell>
              <TableCell className={
                classes.table_cell
              }>Inventory</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{
            updatedItems && updatedItems.length > 0 ? (updatedItems.map((item, index) => {
              return <ReturnItem item={item}
              key={`return-item-${index}`} handleReturnItemChange={handleReturnItemChange} />
            })) : <ReturnItem item={null} key={`no-return-item`}/>
          }</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ReturnItemList;
