import {useState} from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import classes from './ClientJobsItemList.module.css'
import sxTableStyles from "../../style/sxTableStyles";
import AddRowButton from "../ui/AddRowButton";
import ClientJobsItem from "./ClientJobsItem";
import AddClientJobsItem from "./AddClientJobsItem";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function ClientJobsList(props) {
  const {items, clientId, clientJobEdit, handleJobSort, sortConfig} = props;
  const [isJobsItemOpen, setJobsItemOpen] = useState(false);
  
  const openAddJobsHandler = () => {
    setJobsItemOpen(true);
  };

  const closeAddJobsHandler = () => {
    setJobsItemOpen(false);
  };

  const editClientJob = (jobId) => {
    clientJobEdit(jobId);
  };

  return (
    <>
      <AddClientJobsItem        
        isOpen={isJobsItemOpen}
        onCancel={closeAddJobsHandler}
        clientId={clientId}
        clientJobEdit={editClientJob}
        />
      
      <TableContainer component={Paper} style={{maxHeight:'600px', overflow: 'auto'}}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_cell} onClick={() => handleJobSort('jobId')} style={{ cursor: 'pointer' }}>CMC Job No
              {sortConfig.sortProperty == 'jobId' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell className={classes.table_cell } onClick={() => handleJobSort('showName')} style={{ cursor: 'pointer' }}>Show
              {sortConfig.sortProperty == 'showName' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
              <TableCell className={classes.table_cell } onClick={() => handleJobSort('opensOn')} style={{ cursor: 'pointer' }}>Open
              {sortConfig.sortProperty == 'opensOn' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
              </TableCell>
               <TableCell className={classes.table_cell} onClick={() => handleJobSort('status')} style={{ cursor: 'pointer' }}>Status
               {sortConfig.sortProperty == 'status' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon style={{height:'15px',width:'15px',paddingBottom:'2px'}} /> : '')}
               </TableCell>
              <TableCell className={
                classes.table_cell
              }>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{
            items && items.length > 0 ? (items.map((item, index) => {
              return <ClientJobsItem item={item} onEdit={editClientJob}
                key={`clientJobs-item-${index}`} />
            })) : <ClientJobsItem item={null} key='clientJobs-no-item' />
          }</TableBody>
        </Table>
      </TableContainer>
      <AddRowButton onAdd={openAddJobsHandler}>+ Add Client Job </AddRowButton>
    </>
  )
}

export default ClientJobsList;
