import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import { authApi } from '../../services/authService';
import showReducer from './showInfo/showInfoSlice';
import jobReducer from './jobSlice';
import exhibitSlice from './exhibit/exhibitSlice';
import installDismantleReducer from "./installDismantle/installDismantleSlice";
import carrierSlice from './carrier/carrierSlice';
import shipmentSlice from './shipmentSlice';

const rootReducer = combineReducers({
    loginData: authReducer,
    jobData: jobReducer,
    showData: showReducer,
    installDismantleData: installDismantleReducer,
    jobExhibitData: exhibitSlice,
    carrierData: carrierSlice,
    shipmentData: shipmentSlice,
    [authApi.reducerPath] : authApi.reducer,
});

export default rootReducer;