import { useNavigate } from "react-router-dom";

const Placeholder = ({pageName, link}) => {
    const navigate = useNavigate();

    return (
        <div className="flex-grow-1 text-center d-flex align-items-center justify-content-center">
            <div>
                <h3 className="fw-light muted opacity-50 mb-4">The page you are looking for doesn't exist</h3>
                <button className="btn btn-primary" onClick={() => navigate(link)}>Go to {pageName}</button>
            </div>
        </div>
    )
}

export default Placeholder;