import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { logout } from "../../redux/reducers/authSlice";
import {gridStyle} from "./styles/taskListStyle";
import GridSwatchSelector from "../../components/GridSwatchSelector";
import { ReactComponent as TextColor } from "../../assets/icons/textColor.svg";
import { ReactComponent as RowColor } from "../../assets/icons/rowColor.svg";
import {textColors, rowColors} from "./constants";
import {formatDateTime, getColorClasses} from "../../utils/common";
import {
    useGetPersonalTaskListQuery,
    useUpdatePersonalTaskListColorMutation
} from "../../services/authService";
import {setPersonalTaskId} from "../../redux/reducers/jobSlice";
import useDebounce from "../../hooks/useDebounce";
import StatusToggle from "../../components/dashboard/StatusToggle";
import GridSearch from "../../components/dashboard/GridSearch";
import {useSnackbar} from "notistack";
import messages from "../../utils/messages";
import useAuthentication from "../../hooks/useAuthentication";

const PersonalTaskList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [status, setStatus] = useState("Open");

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [perTaskRows, setPerTaskRows] = useState([]);
    const [perTaskRowCount, setPerTaskRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const defaultSort = [{ field: 'deadline', sort: 'asc' }];
    const [sortModel, setSortModel] = useState(defaultSort);

    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText);
    const defaultFilter = {
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [],
    };
    const [filterModel, setFilterModel] = useState(defaultFilter);
    const [rowTaskId, setRowTaskId] = useState(0);

    const {
        data,
        isFetching,
        error: getPersonalTaskListError,
    } = useGetPersonalTaskListQuery(
        { status, paginationModel, sortModel, searchText: debouncedSearchText },
        { refetchOnMountOrArgChange: true }
    );
    const [
        updatePersonalTaskListColor,
        {
            isLoading: isPersonalTaskListUpdateLoading,
            error: isPersonalTaskListUpdateError,
            isSuccess: isPersonalTaskListUpdateSuccess,
            reset: personalTaskListReset,
        },
    ] = useUpdatePersonalTaskListColorMutation();
    useAuthentication([getPersonalTaskListError, isPersonalTaskListUpdateError]);
    const taskListColumns = [
        {
            field: 'taskId',
            headerName: 'Task #',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'jobId',
            headerName: 'Job #',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'showName',
            headerName: 'Show',
            flex: 1,
            minWidth: 75,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            minWidth: 75,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'deadline',
            headerName: 'Deadline',
            type: 'dateTime',
            renderCell: (params) => (<span>{(params.row.deadline ? formatDateTime(params.row.deadline) : "")}</span>),
            valueGetter: ({ value }) => value && new Date(value),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 75,
        },
        {
            field: 'icon',
            headerName: '',
            width: 70,
            filterable: false,
            sortable: false,
            cellClassName: 'justify-content-end',
            renderCell: (row, params) => (
                <div className="flex-shrink-0">
                    <GridSwatchSelector
                        colors={textColors}
                        taskId={row.id}
                        rowId={row.id}
                        handleColor={handleTextColor}
                        icon={TextColor}
                        title="Select text color"
                    />
                    <GridSwatchSelector
                        colors={rowColors}
                        taskId={row.id}
                        rowId={row.id}
                        handleColor={handleRowColor}
                        icon={RowColor}
                        title="Select row color"
                    />
                </div>
            ),
        },
    ];


    useEffect(() => {
        if (getPersonalTaskListError?.status === 401) {
            dispatch(logout());
        }
    }, [dispatch, getPersonalTaskListError]);

    useEffect(() => {
        if (data) {
            setPerTaskRows(data?.results);
            setPerTaskRowCount(data?.totalNumberOfRows);
        }
    }, [data]);

    useEffect(() => {
        if(isPersonalTaskListUpdateSuccess || isPersonalTaskListUpdateError || getPersonalTaskListError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isPersonalTaskListUpdateSuccess, isPersonalTaskListUpdateError, getPersonalTaskListError]);

    const handleMessages = () => {
        if (isPersonalTaskListUpdateSuccess) {
            personalTaskListReset();
        }
        if(isPersonalTaskListUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${messages.error.updateColor}`, { variant: 'error' });
        }
        if(getPersonalTaskListError){
            enqueueSnackbar(`${messages.error.prefix} ${messages.error.getPersonalTaskList}`, { variant: 'error' });
        }
    }

    const handleTextColor = (rowId, taskId, color) => {
        console.log(`text clicked with ID: ${rowId} - ${color}`);
        let row = document.querySelector(`.${rowId}`);
        row.style.color = color !== 'transparent' ? color : '';

        if (color === 'transparent') {

            for (let i = row.classList.length - 1; i >= 0; i--) {
                const className = row.classList[i];
                if (className.startsWith('textColor')) {
                    row.classList.remove(className);
                }
            }

            row.style.color = "#000";
            row.style.opacity = "0.87";
        }


        let mutationParams = {
            taskId: rowTaskId,
            textHexColorCode: color !== 'transparent' ? color : null,
            taskColorType: "Foreground"
        }
        updatePersonalTaskListColor(mutationParams);
    };

    const handleRowColor = (rowId, taskId, color) => {
        console.log(`Row clicked with ID: ${rowId} - ${color}`);
        console.log(`Row clicked with taskId: ${rowTaskId}`);
        let row = document.querySelector(`.${rowId}`);
        row.style.backgroundColor = color !== 'transparent' ? color : '';


        if (color === 'transparent') {

            for (let i = row.classList.length - 1; i >= 0; i--) {
                const className = row.classList[i];
                if (className.startsWith('bgColor')) {
                    row.classList.remove(className);
                }
            }

            if (Array.from(row.parentNode.children).indexOf(row) % 2 === 0) {
                row.style.backgroundColor = "#E8E7E8";
            }
            else {
                row.style.backgroundColor = "#fff";
            }

        }
    
        let mutationParams = {
            taskId: rowTaskId,
            backgroundHexColorCode: color !== 'transparent' ? color : null,
            taskColorType: "Background"
        }

        updatePersonalTaskListColor(mutationParams);
    };

    const onSearchTextChange = (e) => {
        let text = e.target.value;
        setSearchText(text);
        setFilterModel({
            ...filterModel,
            quickFilterValues: text === '' ? [] : text.split(" ")
        })
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <Box className="w-100">
            <div className="d-flex justify-content-between align-items-end mb-2">
                <StatusToggle status={status} onChange={handleChange} />
                <GridSearch search={searchText} onSearch={onSearchTextChange} />
            </div>
            <DataGrid
                autoHeight
                rows={perTaskRows}
                sx={gridStyle}
                columns={taskListColumns}
                loading={isFetching || isPersonalTaskListUpdateLoading}
                rowCount={perTaskRowCount}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                getRowId={(row) => "row_" + row.id}
                pageSizeOptions={[5, 10, 20, 50]}
                disableColumnMenu={true}
                paginationMode="server"
                sortingMode="server"
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                    if(!newRowSelectionModel.length){
                        dispatch(setPersonalTaskId(null));
                    }
                }}
                onRowClick={(selectedRow) => {
                    setRowTaskId(selectedRow.row.id);
                    dispatch(setPersonalTaskId(selectedRow.row.jobId));
                }}
                onRowDoubleClick={(selectedRow) => {
                    switch (selectedRow?.row?.type) {
                        case "InstallItineraryReservations":
                        case "DismantleItineraryReservations":
                        case "InstallationContractor":
                        case "DismantleContractor":
                            navigate(`/install-dismantle/${selectedRow?.row?.jobId}`);
                        break;
                        case "ShipExhibitToShow":
                        case "VerifyExhibitAtShow":
                            navigate(`/job-shipment/${selectedRow?.row?.jobId}/${selectedRow?.row?.shipmentId}`);
                        break;
                        default:
                            navigate(`/services-request/${selectedRow?.row?.jobId}`); 
                        break;
                    }
                }}
                rowHeight={30}
                getRowClassName={(params) => {
                    let colorClassesArr = getColorClasses(params.row.backgroundHexColorCode, params.row.textHexColorCode);
                    let colorClasses = colorClassesArr.filter(Boolean).join(' ');
                    return colorClasses !== '' ? `${colorClasses} ${params.id}` : params.id;
                    }
                }
                columnVisibilityModel={{
                    taskId: false,
                }}
                initialState={{
                    sorting: {
                        sortModel: defaultSort,
                    },
                    filter: {
                        filterModel: filterModel,
                    },
                }}
            />
        </Box>
    );
};
export default PersonalTaskList;
