import {useEffect, useState} from "react";
import {useSnackbar} from 'notistack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Dialog, DialogTitle, Paper} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddRowButton from "../../ui/AddRowButton";
import ConfirmDialog from "../../ConfirmDialog";
import sxTableStyles from "../../../style/sxTableStyles";
// import {useDeleteContactMutation, useDeleteInventoryItemMutation, useGetInventoryItemsQuery} from "../../../services/authService";
import { useGetClientInventoryItemsQuery, useGetClientInventoriesQuery, useDeleteClientInventoryMutation} from "../../../services/authService";
import messages from "../../../utils/messages";
import AddInventoryItem from "./AddInventoryItem";
import EditInventoryItem from "./EditInventoryItem";
import styles from "./InventoryList.module.scss";
import useAuthentication from "../../../hooks/useAuthentication";

function InventoryList(props) {
  const { clientId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);

  const [inventoryItems, setInventoryItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({sortProperty: 'createdDate', sortDirection: 'asc'});

  const {data: inventoryItemsData, isLoading, error: inventoryItemsError , refetch: getInventoryItems} = useGetClientInventoriesQuery({clientId: clientId, ...sortConfig });
  const [deleteInventoryItem, { isLoading: isDeletingItem, error: deleteItemError }] = useDeleteClientInventoryMutation();
  const {enqueueSnackbar} = useSnackbar();
  let actionCellWidth = 66;
  useAuthentication([inventoryItemsError, deleteItemError]);
  // useEffect(() => {
  //   if (inventoryItemsData && clientId) {
  //     setInventoryItems(inventoryItemsData.inventoryItems);
  //   }
  // }, [inventoryItemsData, clientId, sortConfig]);

  const handleRequestSort = (sortProperty) => {
    let sortDirection = 'asc';
    if (sortConfig.sortProperty === sortProperty && sortConfig.sortDirection === 'asc')
      sortDirection = 'desc';
    setSortConfig({sortProperty, sortDirection});
    handleInventoryUpdated();
  };
    
  useEffect(() => {
    const fetchInventoryItems = async () => {
      const response = await getInventoryItems();
      setInventoryItems(response.data?.inventoryItems);
    };

    if (clientId) {
      fetchInventoryItems();
    }
  }, [clientId, sortConfig, inventoryItemsData]);

  const handleInventoryUpdated = async () => {
    const response = await getInventoryItems();
    setInventoryItems(response.data.inventoryItems);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleDeleteConfirm = (id) => {
    setSelectedId(id);
    setConfirmOpen(true);
  };

  const handleEditInventoryItem = (exhibit) => { 
    setSelectedInventory(exhibit);
    setOpenEditDialog(true);
  };

  const handleDelete =  async () => {
    const response = await deleteInventoryItem(selectedId);
    if (response.data === true) {
      handleInventoryUpdated();
      enqueueSnackbar(`${messages.success.deleteClientInventory}`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
    }
  };

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleRequestSort('description')} style={{ cursor: 'pointer' }}>Description 
              {sortConfig.sortProperty == 'description' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')} 
              </TableCell>
              <TableCell onClick={() => handleRequestSort('quantity')} style={{ cursor: 'pointer' }}>Quantity 
              {sortConfig.sortProperty == 'quantity' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('length')} style={{ cursor: 'pointer' }}>Length 
              {sortConfig.sortProperty == 'length' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('width')} style={{ cursor: 'pointer' }}>Width 
              {sortConfig.sortProperty == 'width' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('height')} style={{ cursor: 'pointer' }} >Height 
              {sortConfig.sortProperty == 'height' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('weight')} style={{ cursor: 'pointer' }}>Weight 
              {sortConfig.sortProperty == 'weight' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell onClick={() => handleRequestSort('content')} style={{ cursor: 'pointer' }}>Content
              {sortConfig.sortProperty == 'content' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{
            inventoryItems?. map((item) => (
              <TableRow key={item.id}>
                <TableCell className="px-2">{item.description}</TableCell>
                <TableCell className="px-2">{item.totalQuantity}</TableCell>
                <TableCell className="px-2">{item.length}</TableCell>
                <TableCell className="px-2">{item.width}</TableCell>
                <TableCell className="px-2">{item.height}</TableCell>
                <TableCell className="px-2">{item.weight}</TableCell>
                <TableCell className="px-2">{item.content}</TableCell>
                <TableCell className="px-1" style={{width: actionCellWidth}}>
                  <EditOutlinedIcon fontSize="small"
                    onClick={
                      () => handleEditInventoryItem(item)
                    }
                    className={`${styles.icon} m-1`}
                  />
                  <DeleteOutlinedIcon fontSize="small"
                    onClick={
                      () => handleDeleteConfirm(item.id)
                    }
                    className={`${styles.icon}`}
                  />
                </TableCell>
              </TableRow>
            ))
          }
            {
            !inventoryItems.length && <TableRow>
              <TableCell align="center"
                colSpan={4}
                className="py-1">No inventory items found</TableCell>
            </TableRow>
          }</TableBody>
        </Table>
      </TableContainer>
      <AddRowButton onAdd={openAddDialog}>
        + Add Inventory Item
      </AddRowButton>
      <AddInventoryItem addDialogOpen={addDialogOpen}
        openAddDialog={openAddDialog}
        closeAddDialog={closeAddDialog}
        onUpdated={handleInventoryUpdated}
        clientId={clientId}
      />
      <EditInventoryItem open={openEditDialog}
        onClose={handleCloseEditDialog}
        item={selectedInventory}
        onUpdated={handleInventoryUpdated}
        clientId={clientId}
    /> 
    {
      confirmOpen && <ConfirmDialog title="Delete Inventory Item"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}>
        Are you sure you want to delete this inventory item?
      </ConfirmDialog>
    } </>
  )
}

export default InventoryList;
