import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../lookups/showName/EditShowName.module.scss";
import ClientConsumableForm from "./ClientConsumableForm";

const AddClientConsumableItem = ({ clientId, isOpen, onCancel, setConsumables, consumables, sortConfig, onConsumableUpdated }) => {
    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`${styles.header} text-center`}>
                Add Consumable
            </DialogTitle>
            <ClientConsumableForm
                consumables={consumables}
                setConsumables={setConsumables}
                clientId={clientId}
                onCancel={onCancel}
                sortConfig={sortConfig}
                onConsumableUpdated={onConsumableUpdated}
            />
        </Dialog>
    )
}

export default AddClientConsumableItem;