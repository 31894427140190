import {Link} from "react-router-dom";
import {Button} from "reactstrap";

// onCancelAction is called only in show info screen
const ActionBar = ({onAdd, loadingAdd, onPrint, loadingPrint, onSave, loadingSave, onComplete, onCancel, hideDialog, onCancelAction, loading}) => {
  return (
    <div className="d-flex justify-content-end">
      {
      hideDialog && <Button color="secondary" size="sm" className="ms-2 px-3"
        onClick={hideDialog}>Close</Button>
    }
      {
      onCancel && <Link to={onCancel}>
      <Button variant="contained" color="secondary" size="sm" className="ms-2 px-3">
        Cancel
      </Button>
    </Link>
    }
    {
      onCancelAction && <Button color="secondary" size="sm" className="ms-2 px-3"
        onClick={onCancelAction}>{ loading ? "Please Wait..." : "Cancel" }</Button>
    }
      {
      onPrint && <Button color="primary" size="sm" className="ms-2 px-3"
        onClick={onPrint} disabled={loadingPrint}>{ loadingPrint ? "Please Wait..." : "Print" }</Button>
    }
      {
      onSave && <Button color="primary" size="sm" className="ms-2 px-3"
        onClick={onSave} disabled={loadingSave}>{ loadingSave ? "Saving" : "Save" }</Button>
    }
    {
      onAdd && <Button color="primary" size="sm" className="ms-2 px-3"
        onClick={onAdd} disabled={loadingAdd}>{ loadingAdd ? "Adding" : "Add" }</Button>
    }
      {
      onComplete && <Button color="primary" size="sm" className="ms-2 px-3"
        onClick={onComplete}>Complete</Button>
    } </div>
  )
}

export default ActionBar;
