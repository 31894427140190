import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {CustomTextField} from "../../../style/mui/customStyle";
import styles from "../ItineraryModal.module.scss";
import {useItinerary} from "../../../context/ItineraryContext";
import {numFloat} from "../../../utils/common";

const sxTableTextFieldStyle = {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    }
}

const ItineraryInfo = ({ type }) => {
    const [itinerary, handleItinerary] = useItinerary();

    let isHotel = type === "hotel";
    const [hotelTotal, setHotelTotal] = useState(itinerary?.hotelTotal ?? 0);
    const [carRentalTotal, setCarRentalTotal] = useState(itinerary?.carRentalTotal ?? 0);

    useEffect(() => {
        isHotel ? 
            setHotelTotal(numFloat(itinerary?.hotelTotal)) //itinerary?.hotelRate * itinerary?.noOfDaysInHotel
            :
            setCarRentalTotal(numFloat(itinerary?.carRentalTotal)); //itinerary?.carRentalRate * itinerary?.noOfDaysForCarRental
    }, [itinerary?.hotelRate, itinerary?.carRentalRate, itinerary?.noOfDaysInHotel, itinerary?.noOfDaysForCarRental, isHotel]);

    useEffect(() => {
        
    }, []);

    return (
        <>
            <Row className="gx-2">
                <Col className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}>Dates</label>
                    <CustomTextField
                        size="small"
                        fullWidth
                        // label="Dates"
                        name={isHotel ? 'hotelDaysInfo' : 'carRentalDaysInfo'}
                        variant="outlined"
                        value={isHotel ? itinerary?.hotelDaysInfo : itinerary?.carRentalDaysInfo}
                        onChange={(event) => {
                            let newValue = event.target.value;
                            isHotel ?
                                handleItinerary({hotelDaysInfo: newValue})
                                :
                                handleItinerary({carRentalDaysInfo: newValue})
                        }}
                    />
                </Col>
            </Row>
            <Row className="gx2 mt-2">
                <Col md className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}># of Days</label>
                    <CustomTextField
                        type="number"
                        size="small"
                        fullWidth
                        // label="# of Days"
                        name={isHotel ? 'noOfDaysInHotel' : 'noOfDaysForCarRental'}
                        variant="outlined"
                        value={isHotel ? itinerary?.noOfDaysInHotel : itinerary?.noOfDaysForCarRental}
                        onChange={(event) => {
                            let newValue = parseInt(event.target.value);
                            isHotel ?
                                handleItinerary({noOfDaysInHotel: newValue})
                                :
                                handleItinerary({noOfDaysForCarRental: newValue})
                        }}
                    />
                </Col>
                <Col md className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}>Rate</label>
                    <CustomTextField
                        type="number"
                        size="small"
                        fullWidth
                        // label="Rate"
                        name={isHotel ? 'hotelRate' : 'carRentalRate'}
                        variant="outlined"
                        sx={sxTableTextFieldStyle}
                        defaultValue={isHotel ? numFloat(itinerary?.hotelRate) : numFloat(itinerary?.carRentalRate) ?? 0.00} 
                        key={isHotel ? numFloat(itinerary?.hotelRate) : numFloat(itinerary?.carRentalRate) ?? 0.00} 
                        //value={isHotel ? numFloat(itinerary?.hotelRate) : numFloat(itinerary?.carRentalRate) ?? 0.00}
                        onChange={(event) => {
                            /*let newValue = event.target.value;
                            isHotel ?
                                handleItinerary({hotelRate: newValue})
                                :
                                handleItinerary({carRentalRate: newValue})*/
                        }}
                        onBlur={(event) => {
                            let newValue = numFloat(event.target.value);
                            event.target.value = newValue;
                            isHotel ?
                                handleItinerary({hotelRate: newValue})
                                :
                                handleItinerary({carRentalRate: newValue})
                        }}
                    />
                </Col>
                <Col md className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}>Total</label>
                    <CustomTextField
                        type="number"
                        size="small"
                        fullWidth
                        // label="Total"
                        name={isHotel ? 'hotelTotal' : 'carRentalTotal'}
                        variant="outlined"
                        sx={sxTableTextFieldStyle}
                        defaultValue={isHotel ? hotelTotal : carRentalTotal ?? 0.00}
                        key={isHotel ? hotelTotal : carRentalTotal ?? 0.00} 
                        onChange={(event) => {
                            /*let newValue = event.target.value;
                            if(isHotel){
                                setHotelTotal(newValue);
                                handleItinerary({hotelTotal: newValue});
                            } else {
                                setCarRentalTotal(newValue);
                                handleItinerary({carRentalTotal: newValue})
                            }*/
                        }}
                        onBlur={(event) => {
                            let newValue = numFloat(event.target.value);
                            if(isHotel){
                                setHotelTotal(newValue);
                                handleItinerary({hotelTotal: newValue});
                            } else {
                                setCarRentalTotal(newValue);
                                handleItinerary({carRentalTotal: newValue})
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row className="gx-2 mt-2">
                <Col sm className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}>Confirm #</label>
                    <CustomTextField
                        size="small"
                        fullWidth
                        // label="Confirm #"
                        name={isHotel ? 'hotelConfirmation' : 'carRentalConfirmation'}
                        variant="outlined"
                        value={(isHotel ? itinerary?.hotelConfirmation : itinerary?.carRentalConfirmation) ?? ''}
                        onChange={(event) => {
                            let newValue = event.target.value;
                            isHotel ?
                                handleItinerary({hotelConfirmation: newValue})
                                :
                                handleItinerary({carRentalConfirmation: newValue})
                        }}
                    />
                </Col>
                <Col sm className="d-flex align-items-center">
                    <label className={`${styles.horizontalLabel} text-ellipsis`}>Rewards #</label>
                    <CustomTextField
                        size="small"
                        fullWidth
                        // label="Rewards #"
                        name={isHotel ? 'hotelAward' : 'carRentalAward'}
                        variant="outlined"
                        value={(isHotel ? itinerary?.hotelAward : itinerary?.carRentalAward) ?? ''}
                        onChange={(event) => {
                            let newValue = event.target.value;
                            isHotel ?
                                handleItinerary({hotelAward: newValue})
                                :
                                handleItinerary({carRentalAward: newValue})
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ItineraryInfo;
