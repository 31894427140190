import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Endpoints } from '../../../core/networking';
const backendURL = process.env.REACT_APP_API_URL;

export const showInfoSave = createAsyncThunk(
    'showInfo/saveDetail',
    async ({jobId,showNameId, facilityId, boothSpace,boothSize, opensOn,closesOn, generalContractorId,generalContractorContactId,token },{ rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            }
            const response = await axios.put(
                `${backendURL}/${Endpoints.SHOW_INFO_SAVE_DETAIL}`,
                {jobId,showNameId, facilityId, boothSpace,boothSize, opensOn,closesOn, generalContractorId,generalContractorContactId },
                config
            )
            return response
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const showInfoComplete = createAsyncThunk(
    'showInfo/completeDetail',
    async ({jobId, status, token}, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            }
            const response = await axios.put(
                `${backendURL}/${Endpoints.SHOW_INFO_COMPLETE_DETAIL}`,
                {jobId, status},
                config
            )
            return response
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
