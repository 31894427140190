import React from 'react';
import Paper from '@mui/material/Paper';

function CustomPaper(props) {
  const { children, clickHandler, displayText, ...other } = props;
  return (
    <Paper {...other}>
      {children}
      <div
        style={{
          color: '#fff',
          backgroundColor: '#635d63',
          cursor: 'pointer',
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          clickHandler();
          // You can pass an onClick handler from the parent component if needed
        }}
      >
        <span>{displayText}</span>
      </div>
    </Paper>
  );
}

export default CustomPaper;
