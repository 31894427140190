import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useCreateExhibitMutation, useUpdateExhibitMutation} from '../../services/authService';
import {DialogContent, FormControl} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import {useDispatch, useSelector} from 'react-redux';
import ItemForm from '../invertoryItems/ItemForm';
import {CustomTextField} from '../../style/mui/customStyle';
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import useAuthentication from '../../hooks/useAuthentication';
import { setExhibitName } from '../../redux/reducers/exhibit/exhibitSlice';

const validationSchema = yup.object({name: yup.string().required('Name is required')});

function ExhibitForm(props) {
  const {
    exhibit,
    onCancel,
    onExhibitAdded,
    onExhibitUpdated,
    setSelectedExhibit,
  } = props;
  const dispatch = useDispatch();
  const {jobExhibit, loading, error} = useSelector((state) => {
    return state.jobExhibitData;
  });
  
  const [createExhibit, {
      isLoading : isCreateExhibitLoading,
      isError : isCreateExhibitError
    }
  ] = useCreateExhibitMutation();
  const [updateExhibit, {
      isLoading : isUpdateExhibitLoading,
      isError : isUpdateExhibitError
    }
  ] = useUpdateExhibitMutation();
  useAuthentication([isCreateExhibitError, isUpdateExhibitError]);
  
  const formik = useFormik({
    initialValues: {
      name: exhibit ? exhibit.name : '',
      notes: jobExhibit?.exhibitGiveAway?.exhibit?.notes,
      clientId: jobExhibit && jobExhibit?.exhibitGiveAway?.job?.clientId
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (exhibit) {
        const response = await updateExhibit({
          id: exhibit.id,
          ...values
        });
        if (response.data === true) {
          onExhibitUpdated(values.name);
          dispatch(setExhibitName(values.name));
          // setSelectedExhibit({id: exhibit.id, ...values })
          enqueueSnackbar(`${messages.success.updateExhibit}`, { variant: 'success' });
          onCancel();
        } else {
          enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
      } else { // Add new exhibit
        await createExhibit(values);
        formik.resetForm();
        onExhibitAdded();
        onCancel();
      }
    }
  });

  return (
    <>

      <DialogContent>
        <form onSubmit={
          formik.handleSubmit
        }>
          <ActionBar hideDialog={onCancel}
            loadingSave={
              isCreateExhibitLoading || isUpdateExhibitLoading
            }
            onSave={
              formik.handleSubmit
            }
            />
          <FormControl fullWidth className='mt-2'>
            <CustomTextField label="Name" name="name" fullWidth
              value={
                formik.values.name
              }
              onChange={
                formik.handleChange
              }
              onBlur={
                formik.handleBlur
              }
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && formik.errors.name
              }/>
          </FormControl>
        </form>
        <ItemForm showAction={true}/>
      </DialogContent>
    </>
  );
}

export default ExhibitForm;
