import {Dialog} from "@mui/material";
import ConsumableItemForm from "./ConsumableItemForm";

function AddConsumableItem(props) {
  const {isOpen, onClose} = props;

  return (
    <Dialog open={isOpen}
      maxWidth="sm"
      fullWidth
      onClose={onClose}>
      <ConsumableItemForm onClose={onClose}/>
    </Dialog>
  )
}
export default AddConsumableItem;
