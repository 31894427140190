import ConsumableItem from "./ConsumableItem";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import classes from './ConsumableItemList.module.css'
import {Button} from "reactstrap";
import {useState} from "react";
import AddConsumableItem from "./AddConsumableItem";
import sxTableStyles from "../../style/sxTableStyles";
import AddRowButton from "../ui/AddRowButton";

function ConsumableItemList(props) {
  const {items, showAddItem, showAction, setConsumableItemsState} = props;
  const [isExhibitItemOpen, setExhibitItemOpen] = useState(false);

  function openExhibitItemHandler() {
    setExhibitItemOpen(true);
  }
  function closeExhibitItemHandler() {
    setExhibitItemOpen(false);
  }
  return (
    <>
      <AddConsumableItem
        isOpen={isExhibitItemOpen}
        onClose={closeExhibitItemHandler}/>
      {/* <h3 className={
        `lh-sm ${
          classes.title
        }`
      }>Items</h3> */}
      <TableContainer component={Paper}
        className="rounded-0 shadow-none">
        <Table sx={sxTableStyles}>
          <TableHead>
            <TableRow>
              <TableCell className={
                classes.table_cell
              }>Description</TableCell>
              <TableCell className={
                classes.table_cell
              }>Quantity</TableCell>
              <TableCell className={
                classes.table_cell
              }>Weight</TableCell>
              { showAction && <TableCell className={
                classes.table_cell
              }>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{
            items && items.length > 0 ? (items.map((item, index) => {
              return <ConsumableItem item={item} showAction={showAction} 
                key={`consumable-item-${index}`} setConsumableItemsState={setConsumableItemsState} />
            })) : <ConsumableItem item={null} showAction={showAction}  key='consumable-no-item' />
          }</TableBody>
        </Table>
      </TableContainer>
      { showAddItem ? (<AddRowButton onAdd={openExhibitItemHandler}> Add Consumable </AddRowButton>) : null }
    </>
  )
}

export default ConsumableItemList;
