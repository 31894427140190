import {useEffect, useState} from "react";
import {Paper, TextField} from "@mui/material";
import EditCarRental from "./carRental/EditCarRental";
import DeleteCarRental from "./carRental/DeleteCarRental";
import AddCarRental from "./carRental/AddCarRental";
import {useGetCarRentalsQuery} from "../../services/authService";
import {DISPATCH_TYPES} from "../../utils/constants";
import {CustomAutocomplete} from "../../style/mui/customStyle";
import sxAutocomplete from "../../style/sxAutocompleteStyles";

const CarRental = ({name, onUpdate, dispatchCallback, size, label = 'Car Rental', containerStyle}) => {
    const [filter, setFilter] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const {data, isFetching } = useGetCarRentalsQuery(filter, { skip: filter === null });

    const handleChange = (event, value) => {
        console.log('handleChange', value);
        if(value){
            dispatchCallback(DISPATCH_TYPES.carRental, value);
            setSelectedOption(value);
        } else {
            dispatchCallback(DISPATCH_TYPES.carRental, null);
        }
    };

    const handleInputChange = (event, value) => {
        console.log('handleInputChange', value);
        if(value === '') {
            setSelectedOption(null);
            setFilter('');
        } else {
            setFilter(value);
        }
    }

    useEffect(() => {
        if(name){
            setFilter(name);
        }
    }, [name]);

    useEffect(() => {
        if(data){
            console.log('useEffect', filter, data?.carRentals);
            setOptions(data?.carRentals);
            let selOption = data?.carRentals?.find(option => option.name === filter);
            setSelectedOption(selOption ?? null);
            dispatchCallback(DISPATCH_TYPES.carRental, selOption);

        }
    }, [data, filter]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    }
    const updateHandler = () => {
        // console.log("updateHandler");
    }
    const setCarRental = (name) => {
        setFilter(name);
    }
    const AddNewCarRental = ({ children, ...other }) => (
        <Paper {...other}>
            {children}
            <div
                className="bg-secondary text-white cursor-pointer p-2 common-text"
                onMouseDown={(event) => {
                    event.preventDefault();
                    openAddDialog();
                }}
            >
                +Add Car Rental
            </div>
        </Paper>
    );

    return (
        <div className={`d-flex align-items-center ${containerStyle}`}>
            <CustomAutocomplete
                fullWidth
                size={size ?? "medium"}
                // id="car-rental-list"
                className="flex-grow-1"
                sx={sxAutocomplete}
                loading={isFetching}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options || []}
                value={selectedOption}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        <span className="autocomplete-option-label">{option.name || ''}</span>
                    </li>
                )}
                getOptionLabel={(option) => (option ? option.name || '' : '')}
                // isOptionEqualToValue ={(option, value) => option?.id === value?.id}
                noOptionsText="Car Rental not found"
                renderInput={(params) => (
                    <TextField {...params}
                       label={
                           <label className="autocomplete-input-label">{label}</label>
                       }
                       variant="outlined"
                    />
                )}
                PaperComponent={AddNewCarRental}
            />

            <div className={`flex-shrink-0 ps-2 ${!selectedOption && 'pe-none opacity-50'}`}>
                <EditCarRental
                    carRental={selectedOption}
                    setCarRental={setCarRental}
                    onUpdate={updateHandler}
                />

                <DeleteCarRental
                    carRental={selectedOption}
                    setCarRental={setCarRental}
                    onDelete={updateHandler}
                />
            </div>

            <AddCarRental
                isOpen={addDialogOpen}
                onAdd={updateHandler}
                onCancel={closeAddDialog}
                setCarRental={setCarRental}
            />

        </div>
    )
}

export default CarRental;
