import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../ConfirmDialog';
import { useDeleteFlightMutation, useGetItemsInShipmentQuery, useRemoveShipmentItemMutation } from '../../../services/authService';
import { enqueueSnackbar } from 'notistack';
import messages from '../../../utils/messages';

function DeleteFlight(props) {
  const {flightId, onDelete, fid} = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteFlight, { isSuccess, isLoading, isError, error }] = useDeleteFlightMutation();

  useDeleteFlightMutation();
  function openDeleteModalHandler() {
    setDeleteModalOpen(true);
  }

  const handleDelete = () => {
    if (flightId){
      deleteFlight(flightId);
    } else {
      onDelete(fid);
    }
  };

  const handleMessages = () => {
    if(isSuccess){
      onDelete(fid);
    }
    if(isError){
      enqueueSnackbar(`${messages.error.prefix} ${error?.data[0]?.errorMessage}`, { variant: 'error' });
    }
}

useEffect(() => {
    if(isSuccess || error){
        handleMessages();
    }
    // eslint-disable-next-line
}, [isSuccess, error]);

  return (
    <>
      <Tooltip title="Remove Item">
        <RemoveCircleOutlineIcon
          fontSize="small"
          style={{
            color: "grey",
            cursor: "pointer"
          }}
          onClick={openDeleteModalHandler}
        />
      </Tooltip>
      <ConfirmDialog title="Remove Flight"
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={handleDelete}>
        Are you sure you want to remove this flight?
      </ConfirmDialog>
    </>
  )
}

export default DeleteFlight;