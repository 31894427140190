import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent } from "@mui/material";
import { useSnackbar } from 'notistack';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConfirmDialog from '../../components/ConfirmDialog';
import sxTableStyles from "../../style/sxTableStyles";
import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useGetClientInventoriesQuery, useDeleteClientInventoryMutation } from "../../services/authService";
import messages from '../../utils/messages';
import styles from "../../components/lookups/inventory/InventoryList.module.scss";
import AddRowButton from "../../components/ui/AddRowButton";
import AddInventoryItem from "../../components/lookups/inventory/AddInventoryItem";
import EditInventoryItem from "../../components/lookups/inventory/EditInventoryItem";
import ActionBar from '../../components/ui/ActionBar';

function InventoryDialog(props) {
    const { isOpen, onCancel } = props;
    const [sortConfig, setSortConfig] = useState({ sortProperty: 'createdDate', sortDirection: 'asc' });
    const { data: inventoryItemsData, isLoading, error: inventoryItemsError, refetch: getInventoryItems } = useGetClientInventoriesQuery({ clientId: null, ...sortConfig });
    const [deleteInventoryItem, { isLoading: isDeletingItem, error: deleteItemError }] = useDeleteClientInventoryMutation();
    const [inventoryItems, setInventoryItems] = useState([]);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedInventory, setSelectedInventory] = useState(null);
    let actionCellWidth = 66;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchInventoryItems = async () => {
            const response = await getInventoryItems();
            setInventoryItems(response?.data?.inventoryItems);
        };
        fetchInventoryItems();
    }, [sortConfig, inventoryItemsData]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    };

    const closeAddDialog = () => {
        setAddDialogOpen(false);
    };

    const handleDelete = async () => {
        const response = await deleteInventoryItem(selectedId);
        if (response.data === true) {
            handleInventoryUpdated();
            enqueueSnackbar(`${messages.success.deleteClientInventory}`, { variant: 'success' });
        } else {
            enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic}`, { variant: 'error' });
        }
    };

    const handleInventoryUpdated = async () => {
        const response = await getInventoryItems();
        setInventoryItems(response.data.inventoryItems);
    };    

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleDeleteConfirm = (id) => {
        setSelectedId(id);
        setConfirmOpen(true);
    };

    const handleEditInventoryItem = (exhibit) => {
        setSelectedInventory(exhibit);
        setOpenEditDialog(true);
    };

    const handleRequestSort = (sortProperty) => {
        let sortDirection = 'asc';
        if (sortConfig.sortProperty === sortProperty && sortConfig.sortDirection === 'asc')
            sortDirection = 'desc';
        setSortConfig({ sortProperty, sortDirection });
        handleInventoryUpdated();
    };

    return (
        <>
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
            <DialogTitle styles={`text-center`}> CMC Inventory Items </DialogTitle>
            <DialogContent>
                <div className='mt-3 mb-3'>
                    <TableContainer component={Paper}
                        className="rounded-0 shadow-none">
                        <Table sx={sxTableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => handleRequestSort('description')} style={{ cursor: 'pointer' }}>Description
                                        {sortConfig.sortProperty == 'description' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('quantity')} style={{ cursor: 'pointer' }}>Quantity
                                        {sortConfig.sortProperty == 'quantity' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('length')} style={{ cursor: 'pointer' }}>Length
                                        {sortConfig.sortProperty == 'length' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('width')} style={{ cursor: 'pointer' }}>Width
                                        {sortConfig.sortProperty == 'width' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('height')} style={{ cursor: 'pointer' }} >Height
                                        {sortConfig.sortProperty == 'height' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('weight')} style={{ cursor: 'pointer' }}>Weight
                                        {sortConfig.sortProperty == 'weight' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell onClick={() => handleRequestSort('content')} style={{ cursor: 'pointer' }}>Content
                                        {sortConfig.sortProperty == 'content' && (sortConfig.sortDirection === 'asc' ? <ArrowUpwardIcon className={styles.arrowIcon} /> : sortConfig.sortDirection === 'desc' ? <ArrowDownwardIcon className={styles.arrowIcon} /> : '')}
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{
                                inventoryItems?.length > 0 && inventoryItems?.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell className="px-2">{item.description}</TableCell>
                                        <TableCell className="px-2">{item.totalQuantity}</TableCell>
                                        <TableCell className="px-2">{item.length}</TableCell>
                                        <TableCell className="px-2">{item.width}</TableCell>
                                        <TableCell className="px-2">{item.height}</TableCell>
                                        <TableCell className="px-2">{item.weight}</TableCell>
                                        <TableCell className="px-2">{item.content}</TableCell>
                                        <TableCell className="px-1" style={{ width: actionCellWidth }}>
                                            <EditOutlinedIcon fontSize="small"
                                                onClick={
                                                    () => handleEditInventoryItem(item)
                                                }
                                                className={`${styles.icon} m-1`}
                                            />
                                            <DeleteOutlinedIcon fontSize="small"
                                                onClick={
                                                    () => handleDeleteConfirm(item.id)
                                                }
                                                className={`${styles.icon}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                                {
                                    !inventoryItems?.length && <TableRow>
                                        <TableCell align="center"
                                            colSpan={4}
                                            className="py-1">No inventory items found</TableCell>
                                    </TableRow>
                                }</TableBody>
                        </Table>
                        </TableContainer>
                        <AddRowButton onAdd={openAddDialog}>
                            + Add CMC Inventory Item
                        </AddRowButton>
                        <AddInventoryItem addDialogOpen={addDialogOpen}
                            openAddDialog={openAddDialog}
                            closeAddDialog={closeAddDialog}
                            onUpdated={handleInventoryUpdated}
                            dialogTitle="CMC"
                            clientId={null}
                        />
                        <EditInventoryItem open={openEditDialog}
                            onClose={handleCloseEditDialog}
                            item={selectedInventory}
                            onUpdated={handleInventoryUpdated}
                            dialogTitle="CMC"
                            clientId={null}
                        />
                        <br/>
                        <ActionBar hideDialog={onCancel} />
                    </div>
                </DialogContent>
            </Dialog>
            {
                confirmOpen && <ConfirmDialog title="Delete Inventory Item"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={handleDelete}>
                    Are you sure you want to delete this inventory item?
                </ConfirmDialog>
            }
        </>
    );
}

export default InventoryDialog;