import {useState} from "react";
import SelectCarrier from "./SelectCarrier";
import AddCarrier from "./AddCarrier";
import EditCarrier from "./EditCarrier";
import DeleteCarrier from "./DeleteCarrier";
import { setCarriers } from "../../redux/reducers/carrier/carrierSlice";
import { useGetFreightCarriersQuery } from "../../services/authService";
import { useSelector, useDispatch } from "react-redux";
import { useEffect} from "react";
import useAuthentication from "../../hooks/useAuthentication";

function Carrier(props) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedCarrier, setCarrier] = useState(null);
  const {carrierId, fieldName, formik} = props;
  const carriers = useSelector((state) => state.carrierData.carriers);
  const shipment = useSelector((state) => state.shipmentData.shipment);  
  const dispatch = useDispatch();

  const { data, isFetching, isError } = useGetFreightCarriersQuery();

  useAuthentication([isError]);
  
  useEffect(() => {
    dispatch(setCarriers(data && data.freightCarriers));
  }, [data, dispatch]);

  function openAddCarrierDialog() {
    setDialogOpen(true);
  }

  useEffect(() => {
    if (shipment && carriers) {
      const filteredCarrier = carriers && carriers?.filter((obj) => obj.id == shipment?.shipment?.freightCarrierId);
      const carrierObj = filteredCarrier[0];
      setCarrier({ name: carrierObj?.name, id: carrierObj?.id });
    }
    if (!shipment) {
      setCarrier(null);
    }
  }, [shipment, carriers]);

  return (
    <>
      <div className="d-flex align-items-center">
        <SelectCarrier openAddCarrierDialog={openAddCarrierDialog}
          setCarrier={setCarrier}
          selectedCarrier={selectedCarrier}
          formik={formik}
          fieldName={fieldName}
          carrierId={carrierId}
        /> {
        selectedCarrier && <EditCarrier onClose={
            () => setDialogOpen(false)
          }
          selectedCarrier={selectedCarrier}
          
          />
      }
        {
        selectedCarrier && <DeleteCarrier selectedCarrier={selectedCarrier}/>
      } </div>
          <AddCarrier isOpen={isDialogOpen} setCarrier={setCarrier}
        onClose={
          () => setDialogOpen(false)
        }/>
    </>
  )
}

export default Carrier;
