import {
  Dialog,
  DialogTitle
} from "@mui/material";
import ItemForm from "./ItemForm";

function AddItem(props) {
  const {isOpen, onClose} = props;

  return (
    <Dialog open={isOpen}
      onClose={onClose}
      maxWidth="sm" 
      fullWidth
      >
      <ItemForm onClose={onClose}/>
    </Dialog>
  )
}
export default AddItem;
