import AddRowButton from "../ui/AddRowButton";

const AddProNumber = ({setRows, disabled}) => {

    const newRow = {
        id: 0,
        proNumber: '',
        isUpdated: true,
        isNew: true
    }

    const handleAddRow = () => {
        setRows((rows) => [...rows, newRow]);
    }

    return (
        <AddRowButton onAdd={handleAddRow} disabled={disabled} > + Add ProNumber </AddRowButton>
    )
}

export default AddProNumber;
