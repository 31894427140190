import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CarRentalForm from "./CarRentalForm";
import styles from "../showName/EditShowName.module.scss";

const AddCarRental = ({ isOpen, onAdd, onCancel, setCarRental}) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
                <DialogTitle styles={`${styles.header} text-center`}>
                    Add car rental
                </DialogTitle>
                <CarRentalForm
                    onCancel={onCancel}
                    onAdd={onAdd}
                    setCarRental={setCarRental}
                />
            </Dialog>
        </div>
    )
}

export default AddCarRental;
