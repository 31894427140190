import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText
} from '@mui/material';
import ActionBar from '../ui/ActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { setAllocatedExhibitItems } from '../../redux/reducers/exhibit/exhibitSlice';
import ItemList from './ItemList';
import { useGetAssignedExhibitItemsQuery, useGetClientInventoryItemsQuery, useGetCmcInventoryItemsQuery, useUpdateItemToExhibitMutation } from '../../services/authService';
import { CustomSelect } from '../../style/mui/customStyle';
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import useAuthentication from '../../hooks/useAuthentication';

const validationSchema = Yup.object().shape({
  itemId: Yup.number().required('Item is required'), 
  exhibitId: Yup.number().required('Exhibit ID is required'),
  itemType: Yup.string().required('Item Type is required'),
});

function ItemForm(props) {
  const { onClose, onSubmit, showAction } = props;
  const dispatch = useDispatch();
  const { jobExhibit } = useSelector((state) => {
    return state.jobExhibitData;
  });

  const [updateItemToExhibit, { isLoading, isError }] = useUpdateItemToExhibitMutation();
  const clientInventoryQueryResult = useGetClientInventoryItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.exhibit?.clientId);
  const fetchingClientInventoryItems = clientInventoryQueryResult.isFetching;
  const refetchClientInventoryItems = clientInventoryQueryResult.refetch;
  const clientInventoryItems = clientInventoryQueryResult.data ? clientInventoryQueryResult.data.items : [];
  const [ clientInventoryItemsState,  setClientInventoryItemsState ] = useState(null);
  const [cmcInventoryItemsState,  setCmcInventoryItemsState] = useState(null);


  const cmcInventoryQueryResult = useGetCmcInventoryItemsQuery();
  const fetchingCmcInventoryItems = cmcInventoryQueryResult.isFetching;
  const refetchCmcInventoryItems = cmcInventoryQueryResult.refetch;
  const cmcInventoryItems = cmcInventoryQueryResult.data ? cmcInventoryQueryResult.data.items : [];
  
  useEffect( () => {
    if (clientInventoryItems){
      setClientInventoryItemsState(clientInventoryItems);
    }
    if (cmcInventoryItems){
      setCmcInventoryItemsState(cmcInventoryItems);
    }
  }, [cmcInventoryItems, clientInventoryItems]);
  
  const { data: getAssignedExhibitItems, isFetching, error: getAssignedExhibitItemsError, refetch: refetchGetAssignedExhibitItems } = useGetAssignedExhibitItemsQuery(jobExhibit && jobExhibit?.exhibitGiveAway?.exhibit?.id);
  
  useAuthentication([getAssignedExhibitItemsError]);
  
  const formik = useFormik({
    initialValues: {
      itemId: '',
      exhibitId: jobExhibit?.exhibitGiveAway?.exhibit?.id,
      itemType: 'Client',
    },
    validationSchema,
    onSubmit: async (values) => {
      const response = await updateItemToExhibit(values);
      if (response.data === true) {
        const refetch = await refetchGetAssignedExhibitItems();
        dispatch(setAllocatedExhibitItems(refetch.data.items));
        const refetchClientResponse = await refetchClientInventoryItems();
        setClientInventoryItemsState(refetchClientResponse.data.items);
        const refetchCmcResponse = await refetchCmcInventoryItems();
        setCmcInventoryItemsState(refetchCmcResponse.data.items);
        enqueueSnackbar(`${messages.success.updateItemToExhibit}`, { variant: 'success' });
        // formik.resetForm();
      } else {
        enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (formik.values.itemType === 'CMC') {
      formik.setFieldValue('itemId', ''); // Clear the selected item when changing type
    }
  }, [formik.values.itemType]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth className='mt-2'>
        <InputLabel htmlFor="itemType">Item Type</InputLabel>
        <CustomSelect 
          label="Item Type" 
          name="itemType"
          value={formik.values.itemType}
          onChange={formik.handleChange}
          error={formik.touched.itemType && Boolean(formik.errors.itemType)}
        >
          <MenuItem value="CMC">CMC</MenuItem>
          <MenuItem value="Client">Client</MenuItem>
        </CustomSelect>
        {formik.touched.itemType && formik.errors.itemType && (
          <FormHelperText error>
            {formik.errors.itemType}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth className='mt-2'>
        <InputLabel htmlFor="itemId">Item</InputLabel>
        <CustomSelect 
          label="Item" 
          name="itemId"
          value={formik.values.itemId}
          onChange={formik.handleChange}
          error={formik.touched.itemId && Boolean(formik.errors.itemId)}
        >
          <MenuItem disabled value="">
            <em>Select Item</em>
          </MenuItem>
          {!fetchingClientInventoryItems && formik.values.itemType === 'Client' && clientInventoryItemsState &&  clientInventoryItemsState.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.displayText}
            </MenuItem>
          ))}
          {!fetchingCmcInventoryItems && formik.values.itemType === 'CMC'  && cmcInventoryItemsState && cmcInventoryItemsState.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.displayText}
            </MenuItem>
          ))}
        </CustomSelect>
        {formik.touched.itemId && formik.errors.itemId && (
          <FormHelperText error>
            {formik.errors.itemId}
          </FormHelperText>
        )}
      </FormControl>

      <div className='mt-2'>
        <ActionBar hideDialog={onClose} loadingAdd={isLoading} onAdd={formik.handleSubmit} />
      </div>

      <div className='mt-3 mb-3'>
        <ItemList showAddItem={false} showAction={showAction} items={jobExhibit?.exhibitGiveAway?.exhibit?.items} setCmcInventoryItemsState={setCmcInventoryItemsState} setClientInventoryItemsState={setClientInventoryItemsState} />
      </div>
    </form>
  );
}

export default ItemForm;
