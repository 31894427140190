import {useEffect} from "react";
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {useFormik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BorderBox from "../ui/BorderBox";
import {CustomButton, CustomTextField} from "../../style/mui/customStyle";
import {Col, Row} from "reactstrap";
import messages from "../../utils/messages";
import { useCreateClientConsumableMutation, useUpdateClientConsumableMutation } from "../../services/authService";

import useAuthentication from "../../hooks/useAuthentication";
const validationSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    totalQuantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1').integer('Quantity must be a whole number'),
    weight: Yup.number().nullable(true)
  });

const ClientConsumableForm = ({ onConsumableUpdated,clientId, consumable, setConsumable, onCancel, consumables, setConsumables, sortConfig, setClientConsumables }) => {
    
    const [createClientConsumable, { isSuccess: isCreateSuccess, error: isCreateError, reset: createReset }] = useCreateClientConsumableMutation();
    const [updateClientConsumable, { isSuccess: isUpdateSuccess, error: isUpdateError, reset: updateReset }] = useUpdateClientConsumableMutation();
    const { enqueueSnackbar } = useSnackbar();
    
    useAuthentication([isCreateError,isUpdateError]);

    const formik = useFormik({
        initialValues: {
            description: consumable?.description ?? '',
            totalQuantity: consumable?.totalQuantity ?? '',
            clientId: consumable?.clientId ?? clientId,
            weight: consumable?.weight ?? null
        },
        validationSchema,
        onSubmit: async (values) => {
            let consumableId;
            if (consumable) { // edit
                if (values?.weight === "") values.weight = null;
                await updateClientConsumable({
                    id: consumable?.id,
                    ...values
                });
            } else { // add
                consumableId = await createClientConsumable({
                    id: 0,
                    ...values
                });
                
                //window.console.log("===refetch==", refetch)
            }
        },
    });

    const handleMessages = () => {
        if(isCreateSuccess) {
            enqueueSnackbar(`${messages.success.createClientConsumable}`, { variant: 'success' });
            formik.resetForm();
            createReset();
            onCancel();
            onConsumableUpdated();
        }
        if(isUpdateSuccess) {
            enqueueSnackbar(`${messages.success.updateClientConsumable}`, { variant: 'success' })
            updateReset();
            onCancel();
            onConsumableUpdated();
        }
        if(isCreateError) {
            enqueueSnackbar(`${messages.error.prefix} ${isCreateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
        if(isUpdateError){
            enqueueSnackbar(`${messages.error.prefix} ${isUpdateError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        if(isCreateSuccess || isUpdateSuccess || isCreateError || isUpdateError){
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isCreateSuccess, isUpdateSuccess, isCreateError, isUpdateError]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <DialogContent className="pt-4">
                <BorderBox contentStyle="py-4">
                    <CustomTextField
                        fullWidth
                        label="Description"
                        name="description"
                        variant="outlined"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 50 }}
                        error={
                            formik.touched.description && Boolean(formik.errors.description)
                          }
                          helperText={
                            formik.touched.description && formik.errors.description
                          }
                    />
                    <Row className="gx-2">
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Quantity"
                                name="totalQuantity"
                                variant="outlined"
                                value={formik.values.totalQuantity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.totalQuantity && Boolean(formik.errors.totalQuantity)
                                  }
                                  helperText={
                                    formik.touched.totalQuantity && formik.errors.totalQuantity
                                  }
                                className="mt-2"
                            />
                        </Col>
                        <Col>
                            <CustomTextField
                                fullWidth
                                label="Weight"
                                name="weight"
                                variant="outlined"
                                value={formik.values.weight ?? ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.weight && Boolean(formik.errors.weight)
                                  }
                                  helperText={
                                    formik.touched.weight && formik.errors.weight
                                  }
                                className="mt-2"
                            />
                        </Col>
                    </Row>                   
                </BorderBox>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" color="primary" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton type="submit" variant="contained" color="primary">Save</CustomButton>
            </DialogActions>
        </form>
    )
}

export default ClientConsumableForm;
