import { styled } from "@mui/material/styles";
import {Autocomplete, TextField, Button, Select, TableCell, IconButton, FormControlLabel} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {theme} from "../../utils/colors";

export const CustomTextField = styled(TextField)({
    padding: "2px 0px",

    "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        top: "4px",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        },
        "&.MuiInputLabel-sizeSmall": {
            top: 0,
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 0) scale(0.9)", //-9
                lineHeight: 1,
            },
        }
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        height: "47px",

        "&.MuiInputBase-multiline": {
            height: "auto",
        },
        "&.MuiInputBase-sizeSmall": {
            height: "32px",
        }
    },
    "& .MuiInputBase-input": {
        letterSpacing: "0px",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",

        "&.MuiInputBase-inputSizeSmall": {

        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
    }
})

export const CustomSelect = styled(Select)({
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: "#fff",

    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
        "&:Mui-focused": {
            borderColor: theme.palette.secondary.main,
        }
    },
    
    "& .MuiSelect-select": {
        backgroundColor: 'white',
        padding: "10px",
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    
    "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        }
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        height: "47px",
    },
    "& .MuiInputBase-input": {
        paddingTop: "6px",
        paddingBottom: "6px",
        letterSpacing: "0px",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
    },
})


export const CustomDateTimePicker = styled(DateTimePicker)({
    padding: "2px 0px",

    "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
        fontSize: "13px",
        top: "3px",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        }
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        height: "47px",
    },
    "& .MuiSvgIcon-root": {
        width: "18px",
        height: "18px",
    },
    "& .MuiInputBase-input": {
        letterSpacing: "0px",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        // overflow: 'none'
    },
    '& .MuiPickersModal-dialogRoot': {
        '& .MuiDialogContent-root': {
            padding: '200px',
        },
    },
    '& MuiDateCalendar-root': {
        width: '278px'
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
    }
});

export const CustomAutocomplete = styled(Autocomplete)({
    "& .MuiInputBase-input": {
        letterSpacing: "0px",
        fontSize: "13px",
        lineHeight: "15px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
    }
});

export const CustomButton = styled(Button)({
    textTransform: "capitalize",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "normal",
    fontFamily: "Arial, Helvetica, sans-serif",
    height: "25px",
    padding: "5px 6px",
    borderRadius: "1px",
});

export const CustomDatePicker = styled(DatePicker)({
    overflow: 'hidden',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSvgIcon-root': {
        width: '15px',
        height: '15px'
    },
    '& .MuiInputLabel-root': {
        fontSize: '13px',
        lineHeight: '15px',
    },
    "& .MuiOutlinedInput-input": {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiInputBase-input': {
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
});

export const CustomDatePicker2 = styled(DatePicker)({
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    "& .MuiOutlinedInput-input": {
        paddingTop: 10,
        paddingBottom: 10,
    },
    '& .MuiInputBase-root': {
        backgroundColor: '#fff',
    },
    '& .MuiInputBase-input': {
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
});

export const CustomTimePicker = styled(TimePicker)(({ theme }) => ({
    backgroundColor: '#fff',
    overflow: 'hidden',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSvgIcon-root': {
        width: '15px',
        height: '15px'
    },
    "& .MuiOutlinedInput-input": {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiInputBase-input': {
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
}));

export const CustomTimePickerSmall = styled(TimePicker)(({ theme }) => ({
    borderRadius: 4,
    backgroundColor: '#fff',
    overflow: 'hidden',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSvgIcon-root': {
        width: '15px',
        height: '15px'
    },
    "& .MuiOutlinedInput-input": {
        paddingTop: 8,
        paddingBottom: 8,
    },
    '& .MuiInputBase-input': {
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
}));

export const CustomTableCellOne= styled(TableCell)({
    backgroundColor: "white"
});

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
    '& .MuiTextField-root':{
        minWidth: '100px !important' ,
        overflow: 'hidden !important',
        // backgroundColor:'white !important'
    },
    '& MuiButtonBase-root':{
        overflow: 'hidden !important'
    },
    '.& .MuiIconButton-root':{
        overflow: 'hidden !important'
    },
    '.& .MuiStack-root':{
        overflow: 'hidden !important'
    }
}));
export const CustomTableCell= styled(TableCell)({
    backgroundColor: "rgb(99, 93, 99)",
    border: "1px solid black",
    color: "#fff",
    letterSpacing: '0px',
    fontSize: '12px',
    lineHeight:'18px',
    fontWeight: 'normal',
    fontFamily: 'Arial, Helvetica, sans-serif',
    paddingLeft: '10px !important',
    '& .MuiTableCell-root':{
        paddingLeft: '10px !important'
    },
});

export const CustomFormControlLabel = styled(FormControlLabel)({
    // width: '100%',
    "& .MuiFormControlLabel-label": {
        letterSpacing: '0px',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: 'normal',
        fontFamily: 'Arial, Helvetica, sans-serif',
    }
})

export const CustomTimePickerForm = styled(TimePicker)({
    padding: "2px 0px",

    "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
        fontSize: "13px",
        top: "3px",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -4px) scale(0.9)", //-9
            lineHeight: 1,
        }
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        height: "32px",
    },
    "& .MuiSvgIcon-root": {
        width: "18px",
        height: "18px",
    },
    "& .MuiInputBase-input": {
        letterSpacing: "0px",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Arial, Helvetica, sans-serif",
        // overflow: 'none'
    },
    '& .MuiPickersModal-dialogRoot': {
        '& .MuiDialogContent-root': {
            padding: '200px',
        },
    },
    '& MuiDateCalendar-root': {
        width: '278px'
    }
});

export const CustomSpan = styled('span')`
    white-space: nowrap;
    letter-spacing: 0px;
    font-size: 8pt;
    line-height: 18px;
    font-weight: normal;
    text-align: center;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
`;