// actions/exhibitActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'
import { showExhibitGiveaway, updateExhibitGiveaway } from '../../../api/exhibitApi';

import axios from 'axios'
const backendURL = process.env.REACT_APP_API_URL


export const saveExhibit = createAsyncThunk(
  'exhibit/saveExhibit',
  async ({jobId, exhibitId}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.put(
        `${backendURL}/api/Job/exhibit-giveaway`,
        {jobId, exhibitId },
        config
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchJobExhibit = createAsyncThunk('jobExhibit/fetchExhibit', async (exhibitId) => {
  const response = await showExhibitGiveaway(exhibitId);
  return response;
});

export const updateJobExhibit = createAsyncThunk('jobExhibit/updateJobExhibit', async (data) => {
  const response = await updateExhibitGiveaway(data);
  return response;
});
